export default {
    namespaced: true,
    actions: {
        async getBussinessHistory(context, params) { 
            let { data } = await axios.get('ymfr/freight/api/user_order/list', {params});
            return data;
        },
        async recordUserCLick(context, params) { //
            let { data } = await axios.get('ymfr/freight/api/user_order_rel/save', {params});
            return data;
        },
        async getBussinessDetail(context, params) { //
            let { data } = await axios.get('ymfr/freight/api/order/details', {params});
            return data;
        },
        async getSopList(context, params) { //
            let { data } = await axios.get('ymfr/freight/api/sop/list', {params});
            return data;
        },
        async bussinessSearch(context, params) { //
            let { data } = await axios.get('ymfr/freight/api/order/info', {params});
            return data;
        },
        async getPortList(context, params) { //
            let { data } = await axios.post('ymfr/admin_freight/api/port/keyword', params);
            return data;
        },
        async recommend_port(context, params) { //
            let { data } = await axios.post('ymfr/freight/api/recommend_port/info', params);
            return data;
        },
        async getHistoryList(context, params) { //
            let { data } = await axios.get('ymfr/freight/api/search/history', {params});
            return data;
        },
    },
}
