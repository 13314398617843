export default {
    namespaced: true,
    actions: {
        async bookingElementsConfig(context, params) {                    //订舱要素列表
            let { data } = await axios.post('admin_booking/api/booking_elements_config/query', params);
            return data;
        },
        async checkNo(context, params) {                    //订舱要素列表
            let { data } = await axios.post('booking/api/quotation_no/check', params);
            return data;
        },
        async searchCasNo(context, params) {                    //查找casno
            let { data } = await axios.post('booking/api/cas/info', params);
            return data;
        },
        async submitEntrust(context, params) {                    //提交委托
            let { data } = await axios.post('booking/api/upload_entrust/save', params);
            return data;
        },
        async getQueryFclList(context, params) {                    //委托费用 - PC（整箱委托）
            let { data } = await axios.post('booking/api/entrust_fee/query', params);
            return data;
        },
      
    }
}