const BOOTH = {
  namespaced: true,
  actions: {
    async commentList(content, params) {//企业评价列表
      let {data} = await axios.post("company/api/company_comment", params)
      return data
    },
    async addComment(content, params){//新增评论
      let {data}=await axios.post("company/api/company_comment/save",params)
      return data
    },
    async deleteMyComment(context, params){//删除我的评论
      let {data}=await axios.post("company/api/company_comment/del", params)
      return data
    },
    async getAgendaList(content, params){//获取议程
      let {data}=await axios.get("meeting/api/agenda_info/booth_id", {params})
      return data
    },
    async getVideoList(content, params){//获取视频
      let {data}=await axios.get("meeting/api/get/video_inquiry", {params})
      return data
    },
    async getImageList(content, params) {//展台获取图片
      let {data} = await axios.get("daily_chat/api/booth/picture_list", {params})
      return data
    },
    async getCompanyAllPictrue(content,params) {//获取公司所有图片
      let {data}=await axios.post("media/api/company_picture/list",params)
      return data
    },
    async addPictrueToBooth(content,params) {//展台添加删除图片
      let {data}=await axios.post('job/api/job_booth_pic/save',params)
      return data
    },
    async getBoothData(content, params){//获取资料
      let {data}=await axios.post('meeting_new/api/meeting_booth_data/list',params)
      return data
    },
    async getCompanyPersonList(content, params){//获取面试官列表
      let {data}=await axios.get("meeting/api/booth/user_info", {params})
      return data
    },
    async viewPosition(content, params){//我看过的职位
      let {data}=await axios.post("job/api/job_position_history/list",params)
      return data
    },
    async getCompanyVideos(content, params){//公司视频列表
      let {data}=await axios.post("media/api/video/list",params)
      return data
    },
    async addBoothVideo(context, params){//添加展台视频
      let {data}=await axios.get("meeting/api/save/booth_video", {params})
      return data
    },
    async deleteBoothData(context, params){//删除展台资料
      let {data}=await axios.get("meeting_new/api/meeting_booth_data/delete",{params})
      return data
    },
    async addBoothData(context, params){//添加展台资料
      let {data}=await axios.post("meeting_new/api/upload_meeting_booth_data",params)
      return data
    },
    async getCompanyAllExhibitionList(context, params){//公司参加会议的所有参展商
      let {data}=await axios.get("meeting/api/company_exhibition/list",{params})
      return data
    },
    async boothAddUser(context, params){//展台添加面试官
      let {data}=await axios.get("meeting/api/save/booth_user_info",{params})
      return data
    },
    async companyBoothTopInfo(context, params){//公司相关信息
      let {data}=await axios.post("meeting/api/booth/company_info",params)
      return data
    },
    async saveBenefitsFromBooth(content,params){//保存福利待遇
      let {data}=await axios.post("job/api/set_booth/extend",params)
      return data
    },
    async getPositionList(context, params) {//公司职位列表
      let {data} = await axios.post("job/api/company/recruit", params)
      return data
    },
    async getBoothIdByCompanyId(content, params){//根据公司id获取展台id
      let {data} =await axios.get("meeting/api/get_booth_id",{params})
      return data
    },
    async getMeetingBaseInfo(content,params){//获取会议基本信息会议时间
      let {data}=await axios.get("meeting/api/meeting/basics_info",{params})
      return data
    },
    async boothAddOneAgenda(content,params){//展台添加议程
      let {data}=await axios.post('meeting/api/save/booth_agenda',params)
      return data
    },
    async getBoohAgendaList(content, params){//获取展台议程
        let {data}=await axios.get("meeting/api/agenda_info/booth_id",{params})
      return data
    },
    async reservationMeetingBoothAgenda(content, params){//预约直播
      let {data}=await axios.post("meeting/api/meeting_remind/setting",params)
      return data
    },
    async getMeetingAgendaList(content, params){//获取会议议程列表
      let { data } = await axios.post("nsf/api/live/list", params);
      return data
    },
    async deleteLiveOrder(content, params){//取消议程提醒
      let {data}=await axios.get('meeting_new/api/appointment_remind/del',{params})
      return data
    },
    async deleteAgenda(context, params){//删除议程
      let {data}=await axios.get("meeting/api/agenda/del",{params})
      return data
    },
    async getGuestList(context, params){//获取议程嘉宾
      let {data}=await axios.get("meeting/api/agenda_guests/list",{params})
      return data
    },
    async handleHuests(context, params){//添加嘉宾
      let {data}=await axios.post("meeting/api/meeting_agenda_guests/save", params);
      return data;
    },
    async getLIveList(context, params){//获取直播信息
      let {data}=await axios.get("meeting/api/guests/list", { params });
      return data
    },
    async checkHasLive(context, params){//获取是否有直播
      let {data}=await axios.get("meeting/api/is_live_broadcast",{params})
      return data
    }
  }
}

export default BOOTH
