import Cookies from 'js-cookie'
class LC_store {
    set(key, value) {
        window.localStorage.setItem(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_LS`, JSON.stringify(value));
    }
    get(key) {
        return JSON.parse(window.localStorage.getItem(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_LS`));
    }
    remove(key) {
        if (this.get(key) != null) {
            window.localStorage.removeItem(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_LS`);
        }
    }
}
export let LCstore = new LC_store()

class SS_store {
    set(key, value) {
        window.sessionStorage.setItem(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_SS`, JSON.stringify(value));
    }
    get(key) {
        return JSON.parse(window.sessionStorage.getItem(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_SS`));
    }
    remove(key) {
        if (this.get(key) != null) {
            window.sessionStorage.removeItem(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_SS`);
        }
    }
}
export let SSstore = new SS_store()

class CK_store {
    set(key, value, config) {
        if (config) {
            Cookies.set(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_CK`, value, config);
        } else {
            Cookies.set(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_CK`, value);
        }

    }
    get(key) {
        return Cookies.get(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_CK`);
    }
    remove(key) {
        if (this.get(key) != null) {
            Cookies.remove(`$_AILA${process.env.VUE_APP_PROJECT_NAME.toUpperCase()}_${key.toUpperCase()}_CK`);
        }
    }
}
export let CKstore = new CK_store()