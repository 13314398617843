export default {
    namespaced: true,
    actions: {
        async getPavilionBanner(context, params) {//获取展馆banner
            let { data } = await axios.post("meeting/api/meeting_pavilion/list", params);
            return data;
        },
        async getExhibitionByMeenting(content, params) {//获取会议下展馆
            let { data } = await axios.post('nsf/api/pavilion_info/meeting', params)
            return data
        },
        async expCompanyGetCompanyNameList(context, params) { //获取展台公司列表(联想提示， 报名的)
            let { data } = await axios.post('nsf/api/company_info/pavilion_id', params);
            return data;
        },
        async getBoothList(context, params) { //获取展台列表
            let { data } = await axios.post('expo/api/expo_booth_list/whole', params);
            return data;
        },
        async getExpoExhibitorList(context, params) { //获取展商，中通海外列表（联想提示）
            let { data } = await axios.post('expo/api/expo_exhibitor/list', params);
            return data;
        },
        async getExpoExhibitorUserList(context, params) { //获取展商参照人列表
            let { data } = await axios.post('expo/api/attendee/manage', params);
            return data;
        },
        async getMiniBoothInfo(context, params) { //获取mini展台
            let { data } = await axios.get('expo/api/mini_booth/info', { params });
            return data;
        },
        async getAgendaLiveList(context, params) {//   获取会议议程列表
            let { data } = await axios.post("nsf/api/live/list", params);
            return data
        },
        async getAllBoothNoList(context, params) {//获取展台列表
            let {data}=await axios.post("meeting_new/api/booth_no/list",params)
            return data
        },
        async checkCompanySelect(context, params) {//公司预定了多少个展位
            let {data}=await axios.get("meeting_new/api/check_booth_number/count",{params})
            return data
        },
        async saveCompanySelectBooth(context, params){//保存展位
            let {data}=await axios.post("meeting_new/api/booth_no/apply",params)
            return data
        },
        async getmyReservedList(context, params) {//我的预约列表
            let { data } = await axios.post("daily_chat/api/wechat_daily_one2one_chat/list", params);
            return data
        },
        async getAdvertisingList(context, params) {// 广告
            let { data } = await axios.post("siffa/api/advertising/info", params)
            return data
        },
        async boothList(context, params) {// 展位列表
            let { data } = await axios.get("admin_shippingexpo/api/hbh_booth", {params})
            return data
        },
        async boothType(context, params) {// 展位类型
            let { data } = await axios.get("admin_shippingexpo/api/hbh_booth_type", {params})
            return data
        },
        async getBoothBook(context, params) {//我的预定
            let { data } = await axios.get("shippingexpo/api/booth_book", {params})
            return data
        },
        
    }
}