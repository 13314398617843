export default {
  AbouttheAxpo:"关于物博会",
  meeting_add:"青岛国际会展中心崂山馆4号馆 \n青岛市崂山区苗岭路9号",
  meeting_model:"线上云展会+线下实体展会",
  big_guster:"重磅嘉宾 尖峰论坛",
  big_guster_des:"本次青岛物博会，主办方将会邀请到物流货代行业资深专家和行业大咖，以最前沿的头部独角兽的视角深度挖掘行业资本潜力和专业潜力，从数字化角度进行国际物流时代探索与布局的探讨，重新定位传统货代行业的角色，给展会带来一场又一场视听盛宴，也给行业及地区货代物流行业发展带来全新的解决方案与行业视角。",
  big_guster_arr_name_1:"唐红斌",
  big_guster_arr_job_1:"鸭嘴兽创始人",
  // big_guster_arr_zi_1:"分享主题：数字化集装箱运输",
  big_guster_arr_name_2:"姚溯",
  big_guster_arr_job_2:"全球捷运物流集团&总裁",
  big_guster_arr_zi_2:"",
  big_guster_arr_name_3:"张梓靖",
  big_guster_arr_job_3:"AiLa创始人&CEO",
  big_guster_arr_zi_3:"分享主题：数字化货代",
  big_guster_arr_name_4:"袁峰",
  big_guster_arr_job_4:"货图科技创始人",
  big_guster_arr_zi_4:"分享主题：跨境电商创新平台",
  meetingBrief1_1:"此次青岛物博会由青岛当地极具辐射性影响力的三大物流联盟组织：青岛市现代服务业联合会 港航服务专业委员会、青岛市国际物流商会、青岛唯之国际供应链有限公司联合主办，主办方将充分发挥东道主的主观能动性，将此次展会办成一场物流行业的空前盛会。此次展会由AiLa作为主承办，AiLa将以自身的互联网基因赋能线下盛会，将此次展会打造为线上与线下相结合，内容丰富，形式丰满的行业标杆。此次展会将打破后疫情时代的行业困局，充分调动海内外航运物流企业的积极性，将展会打造成创造机遇，商机无限的行业盛宴",
  meetingBrief1_2:"作为观众，线下观展不但可以欣赏青岛优美的海滨风光，品尝鲜美的饕餮盛宴，还可以在展会上通过逛展、自由洽谈、预约洽谈、行业研讨会、海外线上洽谈、欢迎商务社交晚宴来进行商务洽谈与商机发现。",
  big_guster_arr_name_5:"现在报名参会，",
  big_guster_arr_name_6:"与更多大咖面对面",
  hotExhibitorTitle1: '“线上全景云展--线下以展带会',
  hotExhibitorTitle2: '“行研论坛会”',
  hotExhibitorTitle3: '“海外展区”',
  hotExhibitorTitle4: '“直播精彩不停”',
  hotExhibitorConent1: '“本次青岛物博会将采取线下+线上相结合的方式，通过线上官网和微信小程序设立线上360°全景展会，深度模拟线下展会的模式，辅以数字化新功能，为企业搭建线上云展位，在线视频洽谈，突破时间和空间的限制，实现行业共赢和发展。线下现场将通过标准展位区、特装展位区、功能区、一对一洽谈区、海外迷你展区，充分展示参展企业的企业优势及形象，促进企业之间的商务交往，为企业自身经营及业务拓展提供良好契机。”',
  hotExhibitorConent2: '“行研论坛会”设立的目的是为参展商和观众打造一个具有行业针对性和专业深度的前沿咨询、政策、热点探讨和分享的独立空间，将全国优秀的航运资深人士及行业专家集结起来，通过研讨会、圆桌会议、专项分享会的形式，将自己的行业见解与同仁分享。同时，行业研讨会也将以线上同步直播的方式与全球关注本次展会的物流行业伙伴共同分享。”',
  hotExhibitorConent3: '“海外展区是本次青岛物博会的又一大亮点，针对海外客户无法线下参会的显示原因，设置了全景式线上参会通道。参会的海外客户可在展会官网注册自己的账号，添加线上坐席，通过线上线下提前预约的形式，锁定预约对象和预约时间，开展期间按时上线沟通，为跨境物流拓展商务渠道。”',
  hotExhibitorConent4: '“本次青岛物博会将对开幕式、行业研讨会做低延时的现场直播，同时将会对线下的展会情况做整体的、全天候的图文直播，保证不论是否到场参展，都能感受到组委会的真诚和展会的精彩。再次拓展参展企业的品牌影响力。”',
  supportUnit1: '排名不分先后',
  supportUnit2: '青岛市国际物流商会',
  supportUnit3: '青岛市国际物流商会',
  supportUnit4: 'MPA',
  supportUnit5: '滴答购',
  supportUnit6: '富达会',
  supportUnit7: '甲必丹',
  supportUnit8: '宁波市跨境电子商务协会',
  supportUnit9: '宁波市联运协会',
  supportUnit10: '上海市国际货运代理行业协会',
  supportUnit11: '天津市国际货运代理行业协会',
  supportUnit12: '武汉货运代理业协会',
  supportUnit13: '中山市货运代理协会',
  supportUnit14: '宁波空运协会',
  jsCodeText: '或微信搜索青岛物博会进入官方小程序',
  copyrightText: '版权所有 © 中国（青岛）国际物流博览会 备案:',
  exhibitorServe1TitleBrif1: '参展商线上展示、对接管理',
  exhibitorServe1TitleBrif2: '参展商线上宣传推广',
  exhibitorServe1TitleBrif3: '线上发红包，展台推广宣传',
  exhibitorServeText1: '注册/登录云展厅管理平台',
  exhibitorServeText2: '完善企业信息',
  exhibitorServeText3: '创建直播活动',
  exhibitorServeText4: '展台发红包',
  exhibitorServeTitle1: '如何注册、登录账号',
  exhibitorServeTitle2: '如何管理线上展台',
  exhibitorServeTitle3: '如何举行线上展台活动',
  sponsorChanceMoneyRMB: '国内价格（人民币）',
  sponsorChanceMoneyUSD:"海外价格（美元）",
  xiangmu:"项目",
  guangchang_qiqiu:"广场升空气球",
  guangchang_qiqiu_right1:"每对2个，处于展馆广场进馆广场中心位置，进入广场后即刻映入眼帘（根据实际场地大小设置尺寸)； 赠送展会付费门票2张（含门票所有权益）（海外客户赠送线上云展位）； 晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  zhanting_bannner:"进厅立柱间挂幅",
  zhanting_bannner_right1:"位于进入展馆后的大厅内，顶天立地的立柱，全包广告，每家3根（根据实际场地大小设置尺寸)； 赠送展会付费门票2张（含门票所有权益）（海外客户赠送线上云展位）； 晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  zhanting_bannner_2:"进厅立柱挂幅",
  zhanting_bannner_2_right1:"位于进入展馆后的大厅内，顶天立地的立柱，全包广告，每家3根（根据实际场地大小设置尺寸)； 赠送展会付费门票2张（含门票所有权益）（海外客户赠送线上云展位）； 晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  pic_shuiying:"现场图片直播水印",
  pic_shuiying_right1:"展会现场照片直播水印； 同步展示在官网和小程序，每一张展示图片均有独家水印； 产品价格：30000元（含制作）； 售卖数量：仅限1家； （左图为样图，水印样式和颜色可定制；",
  guan_qiang_ad:"馆内墙面广告位",
  guan_qiang_ad_right1:"",

  position_size_money:"按位置和尺寸不同精准报价",
  offLine_luntan:"线下论坛专题分享",
  offLine_luntan_right1:"物博会两天出嘉宾演讲和圆桌论坛外设置10场专题演讲论坛活动，可进行产品介绍，企业推介",
  offLine_luntan_text:"/场 25分钟",
  qianting_big_banner:"前厅巨幅广告",
  qianting_big_banner_right1:"展会入口处超大巨幅广告展会2天全程展示，覆盖范围广，关注度高； 赠送展会付费门票2张（含门票所有权益）； 晚宴2人主桌席位，展会全程享受VIP尊贵待遇； 产品价格：30000元（含制作）； 售卖数量：仅限2家；",

  jinzhanguan_men_banner:"进馆门上广告",
  jinzhanguan_men_banner_right1:"位于进入展厅的入口大门上侧，在前厅任何地方均可看到（根据实际场地大小设置尺寸)； 赠送展会付费门票2张（含门票所有权益）（海外客户赠送线上云展位）； 晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  nanqiang_LED:"进厅南墙LED屏幕",
  nanqiang_LED_right1:"位于进入展厅内部的大门两侧，LED屏幕显示，可展示企业业务优势、公司介绍等信息（根据实际场地大小设置尺寸)； 赠送展会付费门票2张（含门票所有权益）（海外客户赠送线上云展位）； 晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  led_maney_rmb:"100/分钟   5000/小时",
  led_maney_usd:"15/分钟 770/小时",
  m_fu:"/副",
  sponsorTableText1_1: '网站首页广告；',
  sponsorTableText1_2: '展馆置顶广告；',
  sponsorTableText1_3: '2个展馆排名第一的线上展位；',
  sponsorTableText1_4: '1个PC展台；',
  sponsorTableText1_5: '5个坐席位；',
  sponsorTableText1_6: '一场直播推广；',
  sponsorTableText1_7: '企业高层接受专访；',
  sponsorTableText1_8: '独家冠名商报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈， 抖音，微博等多渠道社交媒体宣传推广；',
  sponsorTableText1_9: '成为展会线上线下独家冠名商，与主办方同步曝光；',
  sponsorTableText1_10: '会议官网拉幕广告官宣：会议前3天至会议结束，广告位宣传；',
  sponsorTableText1_11: '赠送标准展位1个（含展位所有权益）；',
  sponsorTableText1_12: '赠送彩插1P；',
  sponsorTableText1_13: '晚宴2人主桌就座，展会全程享受VIP尊贵待遇；',
  sponsorTableText1_14: '赠送正价门票2张；',
  sponsorTableText2_1: '仅限海外客户购买',
  sponsorTableText2_2: '作为海外展区独家冠名商，与主办方同步曝光。',
  sponsorTableText2_3: '海外展区冠名报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈，抖音，微博等多渠道社交媒体宣传推广。',
  sponsorTableText2_4: '展会专属迷你展位，独特企业标识（含迷你展位所拥有权益） 。通过视频会议系统实现国内与海外客户即时洽谈，形式新颖，创意独特。',
  sponsorTableText2_5: '会议官网海外展区优先排名。',
  sponsorTableText2_6: '会议官网首页Banner图展会期间全程展示，挂展位入口',
  sponsorTableText2_7: '宣传效果覆盖整个海外展区',
  sponsorTableText3_1: '拥有盛大欢迎晚宴独家冠名权。',
  sponsorTableText3_2: '晚宴期间企业享有5分钟演讲时间。',
  sponsorTableText3_3: '赠送晚宴期间企业宣传视频3分钟。',
  sponsorTableText3_4: '晚宴2人主桌就座，展会全程享受VIP尊贵待遇。',
  sponsorTableText3_5: '晚宴主持人口播企业宣传广告 。',
  sponsorTableText3_6: '50多家行业媒体追踪报道。',
  sponsorTableText3_7: '赠送标准展位1个 （含展位所有权益）。',
  sponsorTableText3_8: '赠送会刊彩插1p',
  sponsorTableText4_1: '（含制作）/组',
  sponsorTableText4_1_01: '（含制作）/对',
  sponsorTableText4_1_02: '/对',
  sponsorTableText4_1_1: '（含制作）/个',
  sponsorTableText4_1_2: '（含制作 不含设计）',
  sponsorTableText4_1_02: '（含制作 不含设计）/个',
  sponsorTableText4_1_03: '按尺寸进行精准报价',
  sponsorTableText4_1_3: '（含制作 不含设计）/组',
  sponsorTableText4_1_4: '（含设计及制作）',
  sponsorTableText4_1_5: '（不含设计）',
  sponsorTableText4_2: '/组',
  sponsorTableText4_3: '每组2个，处于展馆高空位置，入馆后即刻映入眼帘（根据实际场地大小设置尺寸)。',
  sponsorTableText4_4: '赠送会刊彩插1p。',
  sponsorTableText4_5: '赠送展会正价门票2张（含门票所有权益）（海外客户赠送线上云展位）。',
  sponsorTableText4_6: '晚宴2人主桌就座，展会全程享受VIP尊贵待遇。',
  sponsorTableText4_7: '30000RMB/组（含制作）',
  sponsorTableText5_1: '（含设计及制作）',
  sponsorTableText5_2: '展会入口处6m*4m超大巨幅广告展会2天全程展示，覆盖范围广，关注度高 。',
  sponsorTableText5_3: '赠送展会正价门票2张（含门票所有权益）。',
  sponsorTableText5_4: '晚宴2人主桌席位，展会全程享受VIP尊贵待遇。',
  sponsorTableText5_5: '30000RMB（含设计及制作）。',
  sponsorTableText6_1: '（含制作）',
  sponsorTableText6_2: '所有付费参会人员统一发放人手一套。',
  sponsorTableText6_3: '赞助企业logo全套印刷。',
  sponsorTableText6_4: '极具纪念意义，使用周期长，品牌展示效果持久。',
  sponsorTableText7_1: '尺寸为30cm*10cm*40cm 。',
  sponsorTableText7_2: '专业量身定制，高品质精美印刷LOGO、公司名等 。',
  sponsorTableText7_3: '付费参会客户人手1个，展会移动式广告展示平台，提升品牌影响力 。',
  sponsorTableText7_4: '赞助商展示板全程展示企业LOGO，扩大企业知名度。',
  sponsorTableText8_1: '5对（ 10个），展馆入口两侧摆放，尺寸：高5M，单面喷绘布：宽1.2m，高3.5m 。',
  sponsorTableText8_2: '赠送会刊彩插1p 。',
  sponsorTableText8_3: '赠送正价门票1张（含门票所有权益）。',
  sponsorTableText9_1: '自由洽谈区-T形板',
  sponsorTableText9_2: '自由洽谈区周边位置摆放，入口及两侧对称摆放。',
  sponsorTableText9_3: '满足企业定制化需求，双面印刷，立体展示 。',
  sponsorTableText9_4: '会议2天全程展示。',
  sponsorTableText9_5: '含2个T型版，尺寸：180cm*80cm 。',
  sponsorTableText9_6: '6000RMB（含制作）/组。',
  sponsorTableText10_1: '正反双面体现赞助商宣传信息 。',
  sponsorTableText10_2: '所有参会人员展会2天全程佩戴 。',
  sponsorTableText10_3: '会场内外移动式广告 。',
  sponsorTableText10_4: '展会期间参展商进入会场的唯一凭证，使用率高。',
  sponsorTableText10_5: '10000RMB',
  sponsorTableText11_1: '公司名、LOGO、宣传语全面展示。',
  sponsorTableText11_2: '参展商、洽谈客户进入会场的唯一凭证，曝光率高。',
  sponsorTableText11_3: '会场内外移动式广告。',
  sponsorTableText11_4: '10000RMB',
  sponsorTableText12_1: '统一向参展商、洽谈客户发放2天午餐1天晚宴共3张用餐券。',
  sponsorTableText12_2: '将在餐券上展示公司名、logo等企业信息。',
  sponsorTableText12_3: '展会期间就餐唯一凭证，曝光率高。',
  sponsorTableText13_1: '赞助商可将印有企业LOGO及广告的纪念品交至组委会统一派发给参会客户，也可以根据赞助商需求定制精美纪念品，以展示公司实力和品牌价值，扩大影响力。',
  sponsorTableText14_1: '会刊彩插',
  sponsorTableText14_2: '元/P',
  sponsorTableText14_3: '封一，封二，封底',
  sponsorTableText14_4: '（含制作费）',
  sponsorTableText14_5: '会刊印刷1000册。',
  sponsorTableText14_6: '会刊采用精美彩色印刷 。',
  sponsorTableText14_7: '展会的资源整合手册，使用率高，信息展示全面 。',
  sponsorTableText14_8: '付费参会代表人手一本。',
  sponsorTableText14_9: '封一，封二，封底：10000RMB/P；其他：8000RMB/P（含制作费）。',
  sponsorTableText15_1: '日程水牌',
  sponsorTableText15_2: '主办方将在展会入口等必经之地设置至少3块日程水牌，迅速提升关注度和流量 。',
  sponsorTableText15_3: '展会期间参会企业必看展示板，赞助商展示信息浏览受众广、曝光率极高。',
  sponsorTableText16_1: '矿泉水赞助',
  sponsorTableText16_2: '展会期间的饮用水瓶身粘贴赞助商信息，用于会议现场服务，移动式广告,共计制作 3000瓶。',
  sponsorTableText16_3: '主办方将在晚宴，专项对接会，开幕式等环节摆放饮用水，曝光率极高。',
  sponsorTableText16_4: '20000RMB（含设计及制作）',
  sponsorTableText17_1: '企业宣传视频广告',
  sponsorTableText17_2: '地处展会核心区域，7*3m LED高清视频展示客户企业形象。',
  sponsorTableText17_3: '每家企业2分钟以内宣传视频，共计5家，会议全程LED轮播，极具视觉效果。',
  sponsorTableText18_1: '1V1视频页Banner',
  sponsorTableText18_2: '1V1视频窗口上方 （限2家）',
  sponsorTableText18_3: '视频窗口上方广告位 限两家',
  sponsorTableText18_4: '点开视频界面即实时展现广告',
  sponsorTableText18_5: '视频广告 15000RMB 2250USD,Banner位广告 10000RMB 1500USD',
  sponsorTableText19_1: '首页弹窗广告',
  sponsorTableText19_2: '博览会首页的大幅弹窗广告， 手动关闭（限1家）',
  sponsorTableText19_3: '打开网站首页弹出广告界面',
  sponsorTableText19_4: '静态展示 持续5秒',
  sponsorTableText20_1: '网站首页轮播 banner广告',
  sponsorTableText20_2: '博览会首页banner位置轮播展示（限3家）',
  sponsorTableText20_3: '博览会网站首页',
  sponsorTableText20_4: '支持轮播展示',
  sponsorTableText21_1: '1v1窗口视频广告',
  sponsorTableText21_2: '1V1视频开启前15秒播放（限1家）',
  sponsorTableText22_1: '会展大厅首页展示',
  sponsorTableText22_2: '会展大厅页面Banner (限10家）',
  sponsorTableText22_3: '会展大厅首页展示',
  sponsorTableText22_4: '进入企业界面即可展示',
  sponsorTableText23_1: '热门企业推荐',
  sponsorTableText23_2: '博览会首页热门企业推荐（限4家）',
  sponsorTableText23_3: '网站首页热门展商区块',
  sponsorTableText23_4: '首页进展时前三位',
  sponsorTableText24_1: '直通海外页面',
  sponsorTableText24_2: '中间横幅Banner（独家）',
  sponsorTableText24_3: '直通海外页面，中间横幅Banner',
  sponsorTableText25_1: '登录注册页面',
  sponsorTableText25_2: '左侧广告位置（独家）',
  sponsorTableText25_3: '登录注册页面，左侧广告位置',
  audienceServiceTitleBrif1: '一键交换名片，展会商机不错过',
  audienceServiceTitleBrif2: '浏览线上展台，参加线上抢红包活动',
  audienceServiceTitleBrif3: '查看议程列表，活动不错过',
  audienceServiceTitle1: '如何注册、登录账号',
  audienceServiceTitle2: '如何管理我的名片',
  audienceServiceTitle3: '如何进行1v1洽谈',
  audienceServiceText1_1: '注册/登录云展厅管理平台',
  audienceServiceText2_1: '完善名片信息',
  audienceServiceText3_1: '线下1v1洽谈',
  audienceServiceText3_2: '线上1v1洽谈',
  exhibitionagendaText1_0: '上海跨国采购会展中心',
  exhibitionagendaText1_1: '布展、提前签到',
  exhibitionagendaText1_2: '展位发送企业红包、代金券',
  //线上参展 线下参展
  offLine_tb_1_1:"● 观看直播，访问展台，发布消息，在线交换名片，参加欢迎晚宴。",
  offLine_tb_1_1_1:"● 观看直播，访问展台，发布消息，在线交换名片。",
  offLine_tb_1_2:"● 参与线下专项论坛，商务午餐一份，资料袋一个。",
  offLine_tb_2_1:"线上权益：",
  offLine_tb_2_1_offline:"线下权益：",
  offLine_tb_2_2:"凡购买线下展位，均赠送线上展位一个，权益参见线上付费展位；",
  offLine_tb_2_3:"●三面或两面围板、楣板、洽谈桌1张、椅子2把、射灯2个、10A插座1个。标准展位费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1200元（另行支付），楣板由主办方设计、制作及搭建。",
  offLine_tb_2_4:"●欢迎晚宴2人。",
  offLine_tb_2_5:"●资料袋2个。",
  offLine_tb_2_6:"●商务午餐2份/天。",
  offLine_tb_3_1:"线上权益：",
  offLine_tb_3_2:"●特装展位企业，赠送线上展位一个，权益参见线上付费展位。",
  offLine_tb_3_3:"●赠送线上1个展位1展馆前10展示席位。",
  offLine_tb_3_4:"线下展位说明：",
  offLine_tb_3_5:"●含4张展会门票，2个晚宴名额，8份商务午餐，4个资料袋。",
  offLine_tb_3_6:"●36平或72平超大空间，组委会推荐优质搭建商，由参展商直接沟通设计，个性化完成布展和搭建。",
  online_sea_qy_1:"●线上展位1个。",
  online_sea_qy_2:"●2个参会名额，24小时1v1预约。",
  online_sea_qy_3:"●企业宣传视频在海外展区30台电视同时同步播放。",
  online_sea_qy_4:"●企业宣传单页+名片各500",
  online_sea_names:"海外展区（仅限海外客户购买）",

  off_qtmp:"洽谈门票",
  stand_qy_1:"● 单场专项对接会1人，限一场。",
  stand_qy_2:"● 资料袋一个（含会刊等参会资料）。",
  stand_qy_3:"● 欢迎晚宴1人。",
  stand_qy_4:"● 参加线下一对一洽谈，自由洽谈。",
  stand_qy_5:"● 参加线下展会，线上云展洽谈。",
  stand_qy_6:"● 观看直播。",
  stand_qy_7:"● 商务午餐1份/天。",
  wsmcz_title_off:"为什么参展",
  off_gnzq:"国内展区",
  off_eve:'自由洽谈区',
  off_zxdjh:'行业研讨会',
  off_hywy:'欢迎晚宴',
  off_hwzq:'海外展区',
  off_hwzq_min:'海外展区-迷你展台',
  off_czlc:"线下参展流程",
  on_czlc:"线上参展流程",
  off_czfy:"参展费用",
  time_before_1_30:"2月28日前",
  time_after_1_30:"2月28日后",
  off_bzzw:"标准展位",
  off_tb_msg1:"● 三面围板、楣板搭建、洽谈桌1张、椅子2把、射灯2个、10A插座1个。围板由客户自行设计，楣板由主办方设计，由主办方负责搭建。",
  off_tb_msg2:"● 欢迎晚宴2人。",
  off_tb_msg3:"● 资料袋2个（含会刊等参会资料）。",
  off_tb_msg4:"● 单场专项对接会1人（仅限一场，1人参加）。",
  off_tb_msg5:"● 商务午餐2份/天。",
  off_tb_msg6:"● 含线上云展位一个。",
  off_tb_msg7:"● 3个标准展位起售。",
  off_tb_msg8:"● 两个易拉宝，或一台电视。",
  off_tb_msg9:"● 5个展位送两个电视。",
  off_tb_msg10:"● 射灯2 电视显示器1 笔记本电脑1 耳麦1 座椅1。",
  off_tb_msg11:"● 免费印刷200份宣传资料，摆放在展位上，供逛展客户领取，如需加量则根据实际产生费用收取加印费。",
  off_tb_msg12:"● 含4张展会门票，2个晚宴名额，2个专项名额，8份商务午餐。",
  off_tb_msg13:"● 含线上云展位一个。",
  off_tb_msg14:"● 36平方米超大空间，组委会提供推荐搭建商，由参展商直接沟通搭建商，个性化设计及搭建。",
  off_xhxxzt:"协会线下展台",
  off_cwzzs:"成为赞助商",
  off_djgm:"博览会独家冠名",
  off_qunayi:"线下权益",
  off_qunayi_item1:"企业高层接受专访；",
  off_qunayi_item2:"独家冠名商报道：行业网站主流媒体推送新闻撰稿， 微信公众号，朋友圈，抖音，微博等多渠道社交媒体宣传推广；",
  off_qunayi_item3:"成为展会线上线下独家冠名商，与主办方同步曝光；",
  off_qunayi_item4:"会议官网拉幕广告官宣：会议前3天至会议结束，广告位宣传；",
  off_qunayi_item5:"赠送标准展位1个（含展位所有权益）；",
  off_qunayi_item6:"赠送彩插1P；",
  off_qunayi_item7:"晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  off_qunayi_item8:"线下赠送正价门票2张；",
  on_quanyi:"线上权益",
  on_quanyi_item1:"网址首页广告",
  on_quanyi_item2:"展馆指定广告",
  on_quanyi_item3:"2个展馆排名第一的线上展位",
  on_quanyi_item4:"1个PC展台",
  on_quanyi_item5:"五个坐席位",
  on_quanyi_item6:"一场直播推广",
  off_hwzq_title:"海外展区冠名",
  off_hwzq_tiem1:"作为海外展区独家冠名商，与主办方同步曝光",
  off_hwzq_tiem2:"海外展区冠名报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈，抖音，微博等多渠道社交媒体宣传推广",
  off_hwzq_tiem3:"展会专属迷你展位，独特企业标识（含迷你展位所有权益）。通过视频会议系统实现国内与海外客户即时洽谈，形式新颖，创意独特",
  off_hwzq_tiem4:"会议官网海外展区优先排名",
  off_hwzq_tiem5:"会议官网首页Banner图展会期间全程展示，挂展位入口",
  off_hwzq_tiem6:"宣传效果覆盖整个海外展区",
  off_wygm_title:"晚宴冠名",
  off_wygm_item1:"拥有盛大欢迎晚宴独家冠名权。",
  off_wygm_item2:"晚宴期间企业享有5分钟演讲时间。",
  off_wygm_item3:"赠送晚宴期间企业宣传视频3分钟。",
  off_wygm_item4:"晚宴2人主桌就坐，展会全程享受VIP尊贵待遇。",
  off_wygm_item5:"晚宴主持人口播企业宣广告。",
  off_wygm_item6:"50多家行业媒体追踪报道。",
  off_wygm_item7:"赠送标准展位一个（含展位所以权益）。",
  off_wygm_item8:"赠送会刊彩插1p。",
  wsmbmxszh:"为什么报名线上展会",
  wsmbmxszh_msg1_1:"线上迷你展位是本次青岛物博会的一大亮点，针对海外客户不能线下参展的现实情况，采取线上坐展、线下参展的方式，在展会现场设置海外专区，通过智能化控制和数字化展示，使海外客户足不出户也能瞬时参展，确保疫情防控不放松、国际交流不断线。",
  wsmbmxszh_msg1_2:"智能电子名片，设置展会个性化名片内容，增加图片、文章和音视频等多种展示形式，让你全方位、多维化地展示自己。智能名片可以通过小程序直接转发到微信进行分享，也能够通过生成名片海报进行分享；轻松一键互换，线上线下参展逛展，无需担心错过商机。",
  wsmbmxszh_msg1_3:"展台投票活动，开展前期，主办方将在线上发起物博会人气企业、明星个人投票活动，充分调动参展企业的积极性，深入参与到展会的各个环节中，增加企业与企业之间的互动与交流。主办方为投票优胜企业准备了丰厚的大奖，将在开展首日欢迎晚宴上公布颁发。",
  online_quyi:"1个展馆展示，2个洽谈席位，企业投票、支持企业自主布展（包括简介、图片、资质、视频展示、设置企业洽谈坐席等），可进行现场直播，可在线上进行一对一预约洽谈等",


  wsmbmxszh_msg2:"智能电子名片，设置展会个性化名片内容，增加图片、文章和音视频等多种展示形式，让你全方位、多维化地展示自己。智能名片可以通过小程序直接转发到微信进行分享，也能够通过生成名片海报进行分享；轻松一键互换，线上线下参展逛展，无需担心错过商机。",
  wsmbmxszh_msg3:"线下核销使用，一气呵成；即吸引线上展台访客增加展台流量，提高线下业务交易量，又能让参展观众得到优惠，调动参展积极性。",
  on_czs:"参展商",
  on_xszt:"线上展台",
  on_zzfw:"增值服务",
  on_xsffzt:"付费线上展台",
  on_bxhnw:"（不限海内外）",
  on_tb_lin1_1:"● 展馆展示，2个洽谈席位，企业投票、支持企业自主布展，可进行现场直播（包含简介、图片、资质、视频展示等）。",
  on_tb_mini:"迷你展位",
  on_tb_hwzh:"（仅针对海外客户）",
  on_tb_jgxj:"价格详见",
  on_tb_xxmp:"线下门票及展位",
  on_tb_line2_content1:"● 展馆展示，2个洽谈席位，企业投票、支持企业自主布展，可进行现场直播（包含简介、图片、资质、视频展示等）。",
  on_tb_line2_content2:"● 无客户视频通话时展示客户宣传片、宣传PPT、宣传视频等，素材由客户提供。",
  on_tb_line2_content3:"● 展览游客与海外客户对话时，笔记本可登陆展位洽谈系统，提供可视洽谈、文字聊天、线上名片互换等功能。",
  on_tb_line2_content4:"● 可放置客户的宣传材料和名片供参展客户线下取用交换。（其他线下权益见：线下门票及展位）。",
  on_tb_line3_left:"协会线下展台",
  on_tb_line3_right:"● 线上独立开设协会展馆。按照12家会员/页排布，至少需要排满一页，可享受半价（即1000元/展台），排列顺序由协会决定，默认按报名顺序先后。",
  on_tb_line4_left:"1v1洽谈坐席",
  on_tb_line4_right:"● 线上展台内增设1v1坐席。",
  on_tb_line5_left:"展馆展示",
  on_tb_line5_right:"● 为企业展台增加1个展馆展示。",
  on_tb_line6_left:"展台红包",
  // on_tb_line6_center_top:"预充值",
  // on_tb_line6_center_bottom:"￥600 功能免费",
  on_tb_line6_right:"● 企业展台可设置现金红包和业务红包，现金红包总金额保底600元。",
  on_tb_line7_left:"主题演讲",
  on_tb_line7_right:"● 展台直播可享有线上直播主办方会协助分享、转发等。",
  on_become_yzs:"成为云展赞助商",
  on_become_item1_lable:"1V1窗口视频及banner广告",
  on_become_item1_small_1:"视频窗口上方广告位 限两家 ",
  on_become_item1_small_2:"点开视频界面即实时展现广告 视频广告",
  on_become_item1_small_3_lable:"视频广告",
  on_become_item1_small_3_orange_left:"¥15000 RMB",
  on_become_item1_small_3_orange_right:"$2250 USD",
  on_become_item1_small_4_lable:"Banner位广告",
  on_become_item1_small_4_orange_left:"¥10000 RMB",
  on_become_item1_small_4_orange_right:"$1500 USD",
  on_become_item2_lable:"网页弹窗广告",
  on_become_item2_small_1:"打开网站首页弹出广告界面",
  on_become_item2_small_2:"静态展示 持续5秒",
  on_become_item2_small_3_lable:"限一家",
  on_become_item3_lable:"网站首页轮播banner",
  on_become_item3_small_1:"博览会网站首页",
  on_become_item3_small_2:"支持轮播展示",
  Offlinetour_address_tit:"地理位置",
  Offlinetour_address_dsc:"上海跨国采购会展中心，位于长风生态商务区中江路最南端。区域内“水、绿、建筑”完美组合，先进的信息网络系统，齐全的商业配套设施，体现了现代服务业集聚区的综合优势。",
  Offlinetour_Taxi:"出租车",
  Offlinetour_Bus:"公共交通",
  Offlinetour_taxi_left_from1:"上海浦东国际机场",
  Offlinetour_taxi_left_way1:"距会场约55公里 ，无堵车的情况下约58分钟，约196元人民币。",
  Offlinetour_taxi_left_from2:"上海虹桥国际机场",
  Offlinetour_taxi_left_way2:"距会场约16公里，无堵车的情况下约30分钟,约50元人民币。",
  Offlinetour_taxi_right1:"上海站  距会场约10公里，无堵车的情况下约25分钟，约33元人民币。",
  Offlinetour_taxi_right2:"上海虹桥站  距会场约16公里，无堵车的情况下约30分钟，约50元人民币。",
  Offlinetour_taxi_right3:"上海南站  距会场约13公里，无堵车的情况下约28分钟，约40元人民币。",
  Offlinetour_bus1:"☉毗邻内环线、中环线、虹桥机场",
  Offlinetour_bus2:"☉周边环绕轨道交通2、13、15号线",
  Offlinetour_bus3:"☉区域巴士将在会展中心和各大公交站点穿梭",
  Offlinetour_bus4:"☉古北路桥、祁连山路桥、泸定路桥横跨苏州河南北，直抵虹桥商务中心区",
  Offlinetour_periphery_title:"周边配套",
  Offlinetour_periphery_item1:"☉成龙电影艺术馆；",
  Offlinetour_periphery_item2:"☉方圆两公里内十一家四五星级高档酒店和三十多家商务经济型酒店；",
  Offlinetour_periphery_item3:"☉2个游艇码头100个游艇泊位、长风公园；",
  Offlinetour_periphery_item4:"☉长风景畔娱乐中心、大型商业中心“国盛中心”。",
  VisitExhibition_left_title:"线下观展",
  VisitExhibition_quanyi:"权益说明",

  VisitExhibition_left_title_free:"免费观众权益",
  VisitExhibition_left_title_money:"付费观众权益（享有全部免费观众权益）",

 
  VisitExhibition_left_item2:"观看直播",
  VisitExhibition_left_item3:"访问展台",
  VisitExhibition_left_item4:"发布消息",
  VisitExhibition_left_item5:"在线交换名片",
  VisitExhibition_left_item6:"参加欢迎晚宴",
  VisitExhibition_left_item7:"参与线下专项论坛",
  VisitExhibition_left_item8:"商务餐一份",
  VisitExhibition_left_item9:"资料袋一个",

  VisitExhibition_right_title:"线上观展",
  VisitExhibition_right_item1:"无限制观看直播；",
  VisitExhibition_right_item2:"无限制访问展台；",
  VisitExhibition_right_item3:"仅在自由聊天区可随机洽谈；",
  VisitExhibition_right_item4:"发布消息；",
  VisitExhibition_right_item5:"在线交换名片；",
  VisitExhibition_right_item6:"留言",

  // VisitExhibition_Early_bird:"早鸟票价",
  // time_before_1_31:"1月31日前",
  // VisitExhibition_youhui:"优惠票价",
  // VisitExhibition_gn_price:"国内价格",
  // VisitExhibition_hw_price:"海外价格",
  VisitExhibition_Signupnow:"立即报名",

  VisitExhibition_Sigfree:"免费报名",
  VisitExhibition_weihe_title:"为何观展",
  VisitExhibition_Highlights_off_title:"线下展会亮点",
  VisitExhibition_Highlights_on_title:"线上展会亮点",
  VisitExhibition_Highlights_on_item1:"企业云展台",
  VisitExhibition_Highlights_on_item2:"全程直播",
  VisitExhibition_Highlights_on_item3:"自由洽谈区",
  VisitExhibition_Highlights_on_item4:"线上展馆",
  VisitExhibition_Highlights_on_item5:"在线1v1洽谈",

  VisitExhibition_Highlights_1:"电子名片",
  VisitExhibition_Highlights_2:"在线人气投票",
  VisitExhibition_Highlights_3:"线上云展馆",
  VisitExhibition_Highlights_4:"线上云展台",


  czlc_item_1:"网上注册",
  czlc_item_2:"主办方审核确认",
  czlc_item_3:"展位付费确认",
  czlc_item_4:"线上布展/ 线下展位提供 设计图等素材",
  czlc_item_5:"展馆现场布展参展",
  myExhibitionsummary_p1:'此次中国（青岛）国际物流博览会将会是物流行业展会的又一大IP品牌。展会布局在美丽的滨海之城青岛，亦是我国重要的港口城市之一，兼具地理优势与行业优势。此次展会由青岛当地极具辐射性影响力的四大物流联盟组织联合主办，将充分发挥东道主的主观能动性，将此次展会办成一场物流行业的空前盛会。此次展会由深青数字供应链创新中心和智能物流联盟AiLa作为承办，致力于将此次展会打造为线上与线下相结合，内容丰富，形式丰满的行业标杆。此次展会将打破后疫情时代的行业困局，充分调动海内外航运物流企业的积极性，将展会打造成创造机遇，商机无限的行业盛宴。',
  // myExhibitionsummary_p2:'本次“博览会”将采用“线上+线下”并行的模式，并延续展会企业间、展会企业与意向客户间面对面直接对话的方式，帮助展会企业拓展人脉，积累资源，促进相互了解，寻找合作商机，提高展商业内影响力，为行业发展披荆斩棘提供方向指引，为企业间合作共赢搭建平台。 本次“博览会”将通过国内品牌展区、海外展区、精品专项对接会、一对一洽谈、自由洽谈等形式打破时间和空间的交流限制，加强业内的合作，为行业整体环境发展以及企业自身经营、拓展提供良好契机，促使业内人士相互交流经验，洞察客户需求，实现行业的共赢和发展。开幕式当天将举办欢迎晚宴，可以抛开日常生活工作的烦闷和喧嚣，在轻松愉悦的环境下，与行业精英一同探讨企业发展的新契机，为所有与会者提供沟通交流平台。',
  zhubanfang_big_tit:"联合主办方介绍",
  chengbanfang_big_tit:"承办方介绍",
  xiebanfang_big_tit:"协办方介绍",
  zhubanfang_name_1:"青岛市国际物流商会",
  zhubanfang_name_2:"青岛唯之国际供应链有限公司",
  zhubanfang_name_3:"青岛市现代服务业联合会 港航服务专业委员会",
  zhubanfang_name_4:"中国航运物流俱乐部",
  zhubanfang_content_1:"青岛市国际物流商会，是物流行业非营利性的社会公益组织，是由国际物流界的优秀企业、个人，及相关单位自愿组成的自律性社会团体。商会志在打造青岛物流行业信息化、标准化、智能化平台。依托平台促进会员企业在行业内交流合作互惠互利的资源互动。广拓社会各界资源和渠道，加强供求信息，合作伙伴等资源促进会员资源整合。维护行业利益，了解相关政策，向职能部门反应物流行业经营诉求，协助会员做大做强。",
  zhubanfang_content_2:"唯之联盟（V-LOG UNION）是在2018年3月应运而生的一个优秀物流企业联盟，也是第一家在青岛当地发起成立的联盟组织，致力于打造真正无疆界的同行业异企业资源共享平台，一站式解决客户在青岛的所有物流需求。",
  zhubanfang_content_2_2: "目前参与V-LOG的成员公司，均为青岛航运物流领域良好运营多年的航线代理，并各自具备明显的专业特色，在联盟中成为互补共赢的角色。",
  zhubanfang_content_3:"青岛市现代服务业联合会是由从事现代服务业的各种所有制的企事业单位，以及从事与现代服务业有关工作的专家、学者、专业人士、社会活动家自愿参加具有社团法人资格的社会组织。",
  zhubanfang_content_3_2:"联合会瞄准一个目标，合力打造“青岛服务”品牌，推进服务业产业集群；围绕两条主线，就是为政府和企业服务；打造三个平台即专家智库、“双招双引”、中介服务；做实四项功能，就是桥梁纽带、诚信自律、协商维权、平台窗口的功能；实现特色旅游、智慧城市、创业设计、标准宣贯、金融服务五个突破的工作思路，合力助推服务业再上新台阶。",
  zhubanfang_content_4:"中航运物流股份有限公司是由全国各主要口岸的知名航运物流公司于2015年成立的创新型航运物流企业，总部设于厦门经济特区，注册资本5000万人民币，在上海、深圳、青岛、天津、宁波、厦门均有分枝机构。中航运物流股份有限公司以信任、合作、发展、共赢为核心价值观，以成为中国航运物流业最专业最具有价值的项目助推平台为愿景，以服务于中航运物流俱乐部成员企业为使命，致力于成为资源整合平台及项目投资管理的业界典范。",
  aila_event_name1:"AiLa全球物流合作伙伴⼤会",
  aila_event_name2:"2020第四届中国（宁波）国际航运物流交易会（云展会）",
  aila_event_name3:"2021全球物流企业发展博览会",


  myExhibitionsummary_p15:`全球物流企业发展博览会`,
  myExhibitionsummary_p3:'锦程物流网成立于2003年，注册资金1亿元人民币，致力成为全球物流交易和结算服务平台。企业拥有近300名优秀的互联网和物流行业人才组成的专业团队，打造全球百万物流企业生态圈。',
  myExhibitionsummary_p4:'锦程物流网将继续秉承自己的企业文化、坚守使命与目标，为中小物流企业赋能，成为全球物流交易和结算服务平台。',
  myExhibitionsummary_p5:'AiLa （Ai Logistics Alliance）是一家为全球物流行业协会提供数字化综合解决方案的平台。通过与全球的国际物流行业协会建立全方位战略合作模式，为协会提供网络平台、移动端平台、综合会员管理SaaS系统等，为协会进行数字化赋能以及互联网运营支持。帮助全球物流行业协会以在线化、网络化、智能化的方式更好的服务其会员单位。同时，AiLa 具备全球领先的国际物流行业“云展会”产品，并联合多家行业协会持续开展“线下+线上”创新展会活动，集约化整合全球优质国际物流企业服务商。',
  myExhibitionsummary_p9:"大连锦程物流网络技术有限公司（以下简称锦程物流网）成立于2003年，注册资本金10000万元，经过17年的发展，已经成为汇聚全球资源的网上物流交易市场，极具影响力的国际物流综合服务平台，为物流企业客户提供营销推广、在线揽货、在线交易结算、交易保障等多元化创新服务。锦程物流网在全球拥有800万企业用户，注册会员超过百万，上线的活跃客户约30万，遍布全球227个国家。锦程物流网作为全球最大的网上物流交易市场，为国内外物流供需双方提供了一个有企业信誉的安全交易平台，搭建了中国物流行业排名第一的电子商务平台，是中国物流行业最大的网络传媒平台，多次获得行业殊荣。",
  jincheng_c:"大连锦程物流网络技术有限公司",
  myExhibitionsummary_p6:'青岛国际会展中心是一座集展览、会议、商务、餐饮、娱乐等多功能于一体的现代智能化展馆，位于风光旖旎的青岛崂山区世纪广场，濒临大海，环境优美，设施完善，是举办国际展览、国际会议的理想场所。近万平方米的中、高档餐厅和快餐厅，可为来宾提供中西式快餐、大型自助餐等多档次餐饮服务。',
  myExhibitionsummary_p7:'会展中心占地 25 万平方米， 5 万多平方米的室内展览面积共可设置 3000 多个国际标准展位；拥有 5 万多平方米的室外展览面积；拥有 30 多个中小型会议室、洽谈室和贵宾室配有多媒体大屏幕、红外同声传译等现代媒介系统，是会议、 推介、洽谈的理想场所。毗邻地铁站，公共交通发达青岛火车站至会展中心 最短地铁47分钟流亭飞机场至会展中心 最短机场大巴42钟市内可乘110路，230路，375路，380路，382路，386路，606路，610路，机场巴士3号线至会展中心站，321路至青岛博物馆站，104路，301路，304路，313路，362路至青岛大剧院站下车。乘地铁M2号线在苗岭路站下车，乘地铁M11号线在会展中心站下车',
  myExhibitionsummary_p8:"",
  shenqing_company_name:"深青数字供应链创新中心",
  shenqing_des:"深青数字供应链创新中心（ShenQing Digital Supply Chain Innovation Center）以下简称“创新中心”，是致力于打造民营资本广泛参与国民经济的多渠道、综合化、全产业链的综合咨询服务平台。创新中心突破传统的“链式”运营模式，打造“一个平台一张网”为发展模式，充分运用5G、云技术、大数据分析、区块链、AI等信息技术，打造智慧供应链平台。业务领域以数字供应链管理与服务的创新与传播为导向，顺应新一轮技术革命和产业变革趋势，坚持全球化、数字化和智能化方向。同时，依托强大的产业供应链规划能力，打造“政产学研金服”共同体平台，提供集政策分析、应用研究、管理咨询、运营实操、技术开发、人才培养为一体的业务内容。以“数字化+生态绿色供应链”的服务理念，为政府、大型企业、各产业开发园区提供产业数字化平台搭建服务、系统集成服务、全链或环节数字化转型服务，以及针对客户要求搭建交易平台、物流交付平台、供应链金融平台等定制化系统服务，增强核产业链供应链安全稳定，赋能上下游企业。",
  my_activity_p1:'1、企业奖项：预设“物流人气企业”一个奖项。',
  my_activity_p2:'2、个人奖项：预设“物流女神”、“物流型男”两个奖项。',
  my_activity_p3:'报名时间：2021.07.20-2022.07.31',
  my_activity_p4:'投票时间：2021.08.01-2022.08.03 18:00',
  my_activity_p5:'1、“物流女神”、“物流型男”：共计2人，投票截止后，分别统计“物流女神”、“物流型男”各自投票累计第一名（5千票以上参与评选）',
  my_activity_p6:'奖励：晚宴现场颁发奖杯，并获得3分钟演讲时间及才艺展示。',
  my_activity_p7:'2、物流人气企业：投票截止后，分别统计投票累计前三名（5千票以上参与评选）。奖励如下：',
  my_activity_p8:'票数累计第三名：2022/2023年青岛物博会现场企业推介一场',
  my_activity_p16:'票数累计第二名：2022/2023年青岛物博会任意赞助5折优惠卷',
  my_activity_p17:'票数累计第一名：2022/2023年青岛物博会标准展位免费1个',
  my_activity_p9:'已认证并完善信息的物流领域企业及从业人员。',
  my_activity_p10:'2、参展企业有资格推荐个人，但适度放开 4个人活跃用户，即可不受企业购买展台限制；',
  my_activity_p11:'每日登录PC端平台或小程序各自可获得10票，每日全天均可投票；',
  my_activity_p12:'1、2021年3月29日起，每周对个人奖项进行投票数统计，截止日期前每个奖项票选出3位周冠军，共计9人次周冠军，获得“周冠军奖励”。',
  my_activity_p13:'2、2021年4月17日，组委会将对三个个人奖项的总票数分别进行统计，最终得到票选的3位人气总冠军，获得“人气冠军奖励”。',
  my_activity_p14:'3、2021年4月17日，组委会将对两个企业奖项的总票数分别进行统计，每个奖项的前三名，即共计6家企业将获得“明星企业奖励”。',
  my_activity_p15:'4、2021年4月17日，组委会将对企业奖项进行综合投票排名，排名第一的企业将获得“博览会终极大奖”。',
  my_registerSuccess_p1:'感谢您报名中国（青岛）国际物流博览会!',
  my_registerSuccess_p2:'您的专属服务团队将会在2个小时内联系到您，请耐心等候，并保持联系方式畅通',
  // 控制台
  replenishInfoText1: '请选择业务，不超过5个',
  replenishInfoText2: '请选择航线，不超过5个',
  replenishInfoText3: '这个人很懒，什么都没留下',
  replenishInfoText4: '非必填，最多添加不超过3个',
  // 酒店预定
  HotelReservation_des:`主办方为参会的观众、展商、嘉宾预留了四星级酒店房间，供参展差旅入住，酒店价格远低于市场价，若需预定请在下方填写入住信息，在入住当天持本人身份证办理入住！
  非合同价参考预定当天网上预订价格；酒店入住时间为当天14：00，退房时间为12：00；`,
  HotelReservation_tips: "温馨提示：若您没有通过本系统进行预定，可能出现无房或无法享受协议价的情况哦。",
  HotelReservation_tips2:"如您需要升级为豪华房，请直接联系主办方工作人员。",
  HotelReservation_tips_room1:"标间：550元/间夜",
  HotelReservation_tips_room_des1:"酒店双床包含2份早餐，若需加餐请直接至前台办理",
  HotelReservation_tips_room2:"大床房：550元/间夜",
  HotelReservation_tips_room_des2:"酒店大床包含1份早餐，若需加餐请直接至前台办理",
  HotelReservation_breakfast_standard:"酒店双床包含2份早餐",
  HotelReservation_breakfast_big:"酒店大床包含1份早餐",
  HotelReservation_Notice_1:"1.如需发票请和酒店前台沟通；",
  HotelReservation_Notice_2:"2.酒店入住时间为当天14:00，退房时间为12:00,请及时入住；",
  HotelReservation_Notice_3:"酒店入住时间为当天14:00，退房时间为12:00；",
  HotelReservation_money:"实际金额线下入住时支付",
  HotelReservation_yd_success:"您已成功提交预定信息，请在入住当天持身份证办理入住！",
  HotelReservation_time:"离店时间要大于入住时间",
  HotelReservation_placeholder:"每个房间至少填写一名联系人\n例如：\n\n房间1\n姓名\n手机\n身份证号\n\n房间2\n姓名\n手机\n身份证号\n\n ...",
  


  
  
  
  
  
  
  
  
  
  
  
  
  
  selectBooth1:'标准展位',
  selectBooth2:'单价：RMB 5000-6000',
  selectBooth3:'线上权益：',
  selectBooth4:'凡购买线下展位，均赠送线上展位一个，权益参见线上付费展位；',
  selectBooth5:'三面或两面围板、楣板、洽谈桌1张、椅子2把、射灯2个、10A插座1个。标准展位费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1200元（另行支付），楣板由主办方设计、制作及搭建。',
  selectBooth6:'欢迎晚宴2人。',
  selectBooth7:'资料袋2个。',
  selectBooth8:'商务午餐2份/天。',
  selectBooth9:'特装展位',
  selectBooth10:'单价：RMB 13400-26800',
  selectBooth11:'线上权益：',
  selectBooth12:'特装展位企业，赠送线上展位一个，权益参见线上付费展位。',
  selectBooth13:'赠送线上1个展位1展馆前10展示席位。',
  selectBooth14:'线下展位说明：',
  selectBooth15:'含4张展会门票，2个晚宴名额，8份商务午餐，4个资料袋。',
  selectBooth16:'36平或72平超大空间，组委会推荐优质搭建商，由参展商直接沟通设计，个性化完成布展和搭建。',
  selectBooth17:'海外展区',
  selectBooth22:"单价：RMB 500",
  selectBooth18:'线上展位1个。',
  selectBooth19:'2个参会名额，24小时1v1预约。',
  selectBooth20:'企业宣传视频在海外展区30台电视同时同步播放。',
  selectBooth21:'企业宣传单页+名片各500',
  selectBooth22:'2022.08.11-2022.08.12',
  selectBooth23:'展会延期，开展时间待定，请关注官方信息',
  selectBooth24:'投票活动进行中，现在报名赢取大奖',
  selectBooth25:'展会延期，开放时间待定',
  selectBooth26:"单价：RMB 3000",
  selectBooth27:"线下权益：",
  selectBooth28:"权益：",
  selectBooth29:'赠送线上展位一个，权益参见线上付费展位。',
  selectBooth30:'预定展台',
  selectBooth31:'国内渠道负责人',
  selectBooth32:'国外渠道负责人',
  selectBooth33:'扫码添加微信获取详细信息',

}
