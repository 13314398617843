export default {
    namespaced: true,
    actions: {
        async user_Login(context, params) { //用户登录
            let { data } = await axios.post('user/api/login', params);
            let _data = data;
            if (_data.success) {
                let { data } = await context.dispatch('user_getUserInfo', { user_id: _data.data.user_id });
                context.commit('baseStore/SET_USER_ID', _data.data.user_id, { root: true });
            }
            return data;
        },
        async user_getUserInfo(context, params) { //获取用户信息
            let { data } = await axios.get('user/api/userinfo', { params });
            if (data.success) {
                context.commit('baseStore/SET_USER_INFO', data.data, { root: true });
                context.commit('baseStore/SET_USER_ID', data.data.id, { root: true })
            }
            return data;
        },
        async user_checkIdentifier(context, params) {     //校验
            let { data } = await axios.get('user/api/identity/check', { params });
            return data;
        },
        async user_Register(context, params) {                  //注册
            let { data } = await axios.post('user/api/signup', params);
            return data;
        },
        async user_saveUserInfo(context, params) {              //保存个人信息
            let { data } = await axios.post('user/api/info/save', params);
            return data;
        },
        async user_resetPassword(context, params) {             //重置密码
            let { data } = await axios.post('user/api/password/reset', params);
            return data;
        },
        async getMeetAgendaList(context, params) {//获取1v1议程列表
            if (JSON.stringify(context.rootState.oneToOneAgendaList) == '{}' || params.isRefersh) {
                delete params.isRefersh;
                let { data } = await axios.get("meeting_new/api/meeting_agenda/list", { params });
                // context.commit('SET_ONE_TO_ONE_AGENDA_LIST', data, { root: true })
                return data;
            } else {
                return context.rootState.oneToOneAgendaList
            }
        },
        async user_resumeEdit(context, params) {             //编辑简历
            let { data } = await axios.post('job/api/my_resume/save', params);
            return data;
        },
        async user_getMyresume(context, params) {             //获取简历信息
            let { data } = await axios.get('job/api/my_resume/list', { params });
            return data;
        },
        async user_bingIndentity(context, params) {            //账号绑定邮箱/手机号
            let { data } = await axios.post('user/api/identity/bind', params);
            if (data.success) {
                let result = await context.dispatch('user_getUserInfo', { user_id: params.user_id })
            }
            return data;
        },
        async user_updataPassword(context, params) {      //修改密码
            let { data } = await axios.post('user/api/password/update', params);
            return data;
        },
        async user_meetingEnroll(context, params) {         //会议报名
            let { data } = await axios.post('job/api/conference/registration', params);
            return data;
        }

    },
}
