const APIPOSITION = {
  namespaced: true,
  actions: {
    // 获取职位直播列表
    async getAgendaMeetingList(content, params) {
      let { data } = await axios.post('job/api/agenda_meeting/list', params);
      return data;
    },
    // 获取职位列表
    async getJobPositionList(content, params) {
      let { data } = await axios.post('job/api/job_position/list', params);
      return data;
    },
    // 获取人才库列表
    async getTalentsList(content, params) {
      let { data } = await axios.post("job/api/talents/list", params);
      console.log(data)
      return data;
    },
    // 获取浏览记录职位列表
    async getJobHistoryPositionList(content, params) {
      let { data } = await axios.post('job/api/job_position_history/list', params);
      return data;
    },
    // 获取广告
    async getJobAdvertisementList(content, params) {
      let { data } = await axios.post('job_position_history/list', params);
      return data;
    },
    // 投递简历
    async userJobPositionSave(content, params) {
      let { data } = await axios.post('job/api/user_job_position/save', params);
      return data;
    },
    // 收藏职位
    async JobCollection(content, params) {
      let { data } = await axios.post('job/api/job_collection/save', params);
      return data;
    },
    // 职位详情
    async jobPositionDetail(content, params) {
      let { data } = await axios.post('job/api/job_position/detail', params);
      return data;
    },
    // 相似职位列表
    async jobRecommendSame(content, params) {
      let { data } = await axios.post('job/api/similar_position/recommend', params);
      return data;
    },
    // 预约面试
    async jobAppointment(content, params) {
      let { data } = await axios.post('job/api/intervie/appointment', params);
      return data;
    },
    async cencelApply(context, params) {//取消面试
      let { data } = await axios.post("job/api/daily_chat/apply", params );
      return data;
    },
    async getMeetAgendaList(context, params) {//获取1v1议程列表
      let { data } = await axios.get("meeting_new/api/meeting_agenda/list", { params });
      return data;
    },
    async historyAdd(context, params) {//获取1v1议程列表
      let { data } = await axios.post("job/api/job_position_history/add", params );
      return data;
    },
    async getIsOnline(context, params) {//获取当前负责人是否在线
      let { data } = await axios.post("job/api/check/status", params );
      return data;
    },
    async getPositionStatus(context, params) {//获取当前职位的各种状态
      let { data } = await axios.post("job/api/job_position_apply/status", params );
      return data;
    },
    async orderStatus(context, params) {//获取预约的各种状态
      let { data } = await axios.post("job/api/status/check", params );
      return data;
    },
    async company_vip(context, params) {// 申请入会（物贸联合会）
      let { data } = await axios.post("expo/api/company_vip/save", params );
      return data;
    },
    async company_vip_Detail(context, params) {// 申请入会的详情（物贸联合会）
      let { data } = await axios.get("expo/api/company_vip/detail", {params} );
      return data;
    },
    async association_video_comment(context, params) {// 协会视频评论列表
      let { data } = await axios.post("media/api/association_video_comment/list",params);
      return data;
    },
    async DEL_video_comment(context, params) {// 协会视频评论删除接口
      let { data } = await axios.post("media/api/association_video_comment/update",params);
      return data;
    },
    async advertising_Info(context, params) {// 广告为信息
	  let { data } = await axios.post("siffa/api/advertising/info",params);
	  return data;
	},
    async submit_position(context, params) {// 投递简历
      let { data } = await axios.post("job/api/user_job_resume/save",params);
      return data;
    },
    async lookOnce(context, params) {// 投递简历
      let { data } = await axios.post("job/api/update_job/views",params);
      return data;
    },
    async getEcode(context, params) {//获取二维码
      let {data}=await axios.post("wechat/api/generate_rq_code",params)
      return data
    },
    async checkEventApply(context, params) {//培训活动校验是否报名
      let { data } = await axios.post("siffa/api/check/association_event_apply",params);
      return data
    },
  },
}
export default APIPOSITION