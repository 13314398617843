export const AREACODELIST = [
  {
    "id": "1",
    "name_zh": "中国",
    "name_en": "China",
    "area_code": "+86"
  },
  {
    "id": "2",
    "name_zh": "阿尔巴尼亚",
    "name_en": "Albania",
    "area_code": "+355"
  },
  {
    "id": "3",
    "name_zh": "阿尔及利亚",
    "name_en": "Algeria",
    "area_code": "+213"
  },
  {
    "id": "4",
    "name_zh": "阿富汗",
    "name_en": "Afghanistan",
    "area_code": "+93"
  },
  {
    "id": "5",
    "name_zh": "阿根廷",
    "name_en": "Argentina",
    "area_code": "+54"
  },
  {
    "id": "6",
    "name_zh": "阿拉伯联合酋长国",
    "name_en": "United Arab Emirates",
    "area_code": "+971"
  },
  {
    "id": "7",
    "name_zh": "阿鲁巴",
    "name_en": "Aruba",
    "area_code": "+297"
  },
  {
    "id": "8",
    "name_zh": "阿曼",
    "name_en": "Oman",
    "area_code": "+968"
  },
  {
    "id": "9",
    "name_zh": "阿塞拜疆",
    "name_en": "Azerbaijan",
    "area_code": "+994"
  },
  {
    "id": "10",
    "name_zh": "阿森松岛",
    "name_en": "Ascension Island",
    "area_code": "+247"
  },
  {
    "id": "11",
    "name_zh": "埃及",
    "name_en": "Egypt",
    "area_code": "+20"
  },
  {
    "id": "12",
    "name_zh": "埃塞俄比亚",
    "name_en": "Ethiopia",
    "area_code": "+251"
  },
  {
    "id": "13",
    "name_zh": "爱尔兰",
    "name_en": "Ireland",
    "area_code": "+353"
  },
  {
    "id": "14",
    "name_zh": "爱沙尼亚",
    "name_en": "Estonia",
    "area_code": "+372"
  },
  {
    "id": "15",
    "name_zh": "安道尔",
    "name_en": "Andorra",
    "area_code": "+376"
  },
  {
    "id": "16",
    "name_zh": "安哥拉",
    "name_en": "Angola",
    "area_code": "+244"
  },
  {
    "id": "17",
    "name_zh": "安圭拉",
    "name_en": "Anguilla",
    "area_code": "+1264"
  },
  {
    "id": "18",
    "name_zh": "安提瓜岛和巴布达",
    "name_en": "Antigua and Barbuda",
    "area_code": "+1268"
  },
  {
    "id": "19",
    "name_zh": "澳大利亚",
    "name_en": "Australia",
    "area_code": "+61"
  },
  {
    "id": "20",
    "name_zh": "奥地利",
    "name_en": "Austria",
    "area_code": "+43"
  },
  {
    "id": "21",
    "name_zh": "奥兰群岛",
    "name_en": "Aland lslands",
    "area_code": "+358"
  },
  {
    "id": "22",
    "name_zh": "巴巴多斯岛",
    "name_en": "Barbados",
    "area_code": "+1246"
  },
  {
    "id": "23",
    "name_zh": "巴布亚新几内亚",
    "name_en": "Papua New Guinea",
    "area_code": "+675"
  },
  {
    "id": "24",
    "name_zh": "巴哈马",
    "name_en": "Bahamas",
    "area_code": "+1242"
  },
  {
    "id": "25",
    "name_zh": "巴基斯坦",
    "name_en": "Pakistan",
    "area_code": "+92"
  },
  {
    "id": "26",
    "name_zh": "巴拉圭",
    "name_en": "Paraguay",
    "area_code": "+595"
  },
  {
    "id": "27",
    "name_zh": "巴勒斯坦",
    "name_en": "Palestinian Authority",
    "area_code": "+930"
  },
  {
    "id": "28",
    "name_zh": "巴林",
    "name_en": "Bahrain",
    "area_code": "+973"
  },
  {
    "id": "29",
    "name_zh": "巴拿马",
    "name_en": "Panama",
    "area_code": "+507"
  },
  {
    "id": "30",
    "name_zh": "巴西",
    "name_en": "Brazil",
    "area_code": "+55"
  },
  {
    "id": "31",
    "name_zh": "白俄罗斯",
    "name_en": "Belarus",
    "area_code": "+375"
  },
  {
    "id": "32",
    "name_zh": "百慕大",
    "name_en": "Bermuda",
    "area_code": "+1441"
  },
  {
    "id": "33",
    "name_zh": "保加利亚",
    "name_en": "Bulgaria",
    "area_code": "+359"
  },
  {
    "id": "34",
    "name_zh": "北马里亚纳群岛",
    "name_en": "Northern Mariana Islands",
    "area_code": "+1670"
  },
  {
    "id": "35",
    "name_zh": "贝宁",
    "name_en": "Benin",
    "area_code": "+229"
  },
  {
    "id": "36",
    "name_zh": "比利时",
    "name_en": "Belgium",
    "area_code": "+32"
  },
  {
    "id": "37",
    "name_zh": "冰岛",
    "name_en": "Iceland",
    "area_code": "+354"
  },
  {
    "id": "38",
    "name_zh": "波多黎各",
    "name_en": "Puerto Rico",
    "area_code": "+1787"
  },
  {
    "id": "39",
    "name_zh": "波兰",
    "name_en": "Poland",
    "area_code": "+48"
  },
  {
    "id": "40",
    "name_zh": "玻利维亚",
    "name_en": "Bolivia",
    "area_code": "+591"
  },
  {
    "id": "41",
    "name_zh": "波斯尼亚和黑塞哥维那",
    "name_en": "Bosnia and Herzegovina",
    "area_code": "+387"
  },
  {
    "id": "42",
    "name_zh": "博茨瓦纳",
    "name_en": "Botswana",
    "area_code": "+267"
  },
  {
    "id": "43",
    "name_zh": "伯利兹",
    "name_en": "Belize",
    "area_code": "+501"
  },
  {
    "id": "44",
    "name_zh": "不丹",
    "name_en": "Bhutan",
    "area_code": "+975"
  },
  {
    "id": "45",
    "name_zh": "布基纳法索",
    "name_en": "Burkina Faso",
    "area_code": "+226"
  },
  {
    "id": "46",
    "name_zh": "布隆迪",
    "name_en": "Burundi",
    "area_code": "+257"
  },
  {
    "id": "47",
    "name_zh": "布韦岛",
    "name_en": "Bouvet Island",
    "area_code": ""
  },
  {
    "id": "48",
    "name_zh": "朝鲜",
    "name_en": "North Korea",
    "area_code": "+850"
  },
  {
    "id": "49",
    "name_zh": "丹麦",
    "name_en": "Denmark",
    "area_code": "+45"
  },
  {
    "id": "50",
    "name_zh": "德国",
    "name_en": "Germany",
    "area_code": "+49"
  },
  {
    "id": "51",
    "name_zh": "东帝汶",
    "name_en": "Timor-Leste",
    "area_code": "+670"
  },
  {
    "id": "52",
    "name_zh": "多哥",
    "name_en": "Togo",
    "area_code": "+228"
  },
  {
    "id": "53",
    "name_zh": "多米尼加",
    "name_en": "Dominica",
    "area_code": "+1767"
  },
  {
    "id": "54",
    "name_zh": "多米尼加共和国",
    "name_en": "Dominican Republic",
    "area_code": "+1890"
  },
  {
    "id": "55",
    "name_zh": "俄罗斯",
    "name_en": "Russia",
    "area_code": "+7"
  },
  {
    "id": "56",
    "name_zh": "厄瓜多尔",
    "name_en": "Ecuador",
    "area_code": "+593"
  },
  {
    "id": "57",
    "name_zh": "厄立特里亚",
    "name_en": "Eritrea",
    "area_code": "+291"
  },
  {
    "id": "58",
    "name_zh": "法国",
    "name_en": "France",
    "area_code": "+33"
  },
  {
    "id": "59",
    "name_zh": "法罗群岛",
    "name_en": "Faroe Islands",
    "area_code": "+298"
  },
  {
    "id": "60",
    "name_zh": "法属波利尼西亚",
    "name_en": "French Polynesia",
    "area_code": "+689"
  },
  {
    "id": "61",
    "name_zh": "法属圭亚那",
    "name_en": "French Guiana",
    "area_code": "+594"
  },
  {
    "id": "62",
    "name_zh": "法属南部领地",
    "name_en": "French Southern and Antarctic Lands",
    "area_code": ""
  },
  {
    "id": "63",
    "name_zh": "梵蒂冈",
    "name_en": "Vatican City",
    "area_code": "+379"
  },
  {
    "id": "64",
    "name_zh": "菲律宾",
    "name_en": "Philippines",
    "area_code": "+63"
  },
  {
    "id": "65",
    "name_zh": "斐济",
    "name_en": "Fiji Islands",
    "area_code": "+679"
  },
  {
    "id": "66",
    "name_zh": "芬兰",
    "name_en": "Finland",
    "area_code": "+358"
  },
  {
    "id": "67",
    "name_zh": "佛得角",
    "name_en": "Cape Verde",
    "area_code": "+238"
  },
  {
    "id": "68",
    "name_zh": "弗兰克群岛",
    "name_en": "Falkland Islands",
    "area_code": ""
  },
  {
    "id": "69",
    "name_zh": "冈比亚",
    "name_en": "Gambia",
    "area_code": "+220"
  },
  {
    "id": "70",
    "name_zh": "刚果",
    "name_en": "Congo",
    "area_code": "+242"
  },
  {
    "id": "71",
    "name_zh": "刚果民主共和国",
    "name_en": "Congo(DRC)",
    "area_code": "+243"
  },
  {
    "id": "72",
    "name_zh": "哥伦比亚",
    "name_en": "Colombia",
    "area_code": "+57"
  },
  {
    "id": "73",
    "name_zh": "哥斯达黎加",
    "name_en": "Costa Rica",
    "area_code": "+506"
  },
  {
    "id": "74",
    "name_zh": "格恩西岛",
    "name_en": "Guernsey",
    "area_code": ""
  },
  {
    "id": "75",
    "name_zh": "格林纳达",
    "name_en": "Grenada",
    "area_code": "+1809"
  },
  {
    "id": "76",
    "name_zh": "格陵兰",
    "name_en": "Greenland",
    "area_code": "+299"
  },
  {
    "id": "77",
    "name_zh": "古巴",
    "name_en": "Cuba",
    "area_code": "+53"
  },
  {
    "id": "78",
    "name_zh": "瓜德罗普",
    "name_en": "Guadeloupe",
    "area_code": "+590"
  },
  {
    "id": "79",
    "name_zh": "关岛",
    "name_en": "Guam",
    "area_code": "+1671"
  },
  {
    "id": "80",
    "name_zh": "圭亚那",
    "name_en": "Guyana",
    "area_code": "+592"
  },
  {
    "id": "81",
    "name_zh": "哈萨克斯坦",
    "name_en": "Kazakhstan",
    "area_code": "+327"
  },
  {
    "id": "82",
    "name_zh": "海地",
    "name_en": "Haiti",
    "area_code": "+509"
  },
  {
    "id": "83",
    "name_zh": "韩国",
    "name_en": "Korea",
    "area_code": "+82"
  },
  {
    "id": "84",
    "name_zh": "荷兰",
    "name_en": "Netherlands",
    "area_code": "+31"
  },
  {
    "id": "85",
    "name_zh": "荷属安地列斯",
    "name_en": "Netherlands Antilles",
    "area_code": "+599"
  },
  {
    "id": "86",
    "name_zh": "赫德和麦克唐纳群岛",
    "name_en": "Heard Island and McDonald Islands",
    "area_code": ""
  },
  {
    "id": "87",
    "name_zh": "洪都拉斯",
    "name_en": "Honduras",
    "area_code": "+504"
  },
  {
    "id": "88",
    "name_zh": "基里巴斯",
    "name_en": "Kiribati",
    "area_code": "+686"
  },
  {
    "id": "89",
    "name_zh": "吉布提",
    "name_en": "Djibouti",
    "area_code": "+253"
  },
  {
    "id": "90",
    "name_zh": "吉尔吉斯斯坦",
    "name_en": "Kyrgyzstan",
    "area_code": "+331"
  },
  {
    "id": "91",
    "name_zh": "几内亚",
    "name_en": "Guinea",
    "area_code": "+224"
  },
  {
    "id": "92",
    "name_zh": "几内亚比绍",
    "name_en": "Guinea-Bissau",
    "area_code": "+245"
  },
  {
    "id": "93",
    "name_zh": "加拿大",
    "name_en": "Canada",
    "area_code": "+1"
  },
  {
    "id": "94",
    "name_zh": "加纳",
    "name_en": "Ghana",
    "area_code": "+233"
  },
  {
    "id": "95",
    "name_zh": "加蓬",
    "name_en": "Gabon",
    "area_code": "+241"
  },
  {
    "id": "96",
    "name_zh": "柬埔寨",
    "name_en": "Cambodia",
    "area_code": "+855"
  },
  {
    "id": "97",
    "name_zh": "捷克共和国",
    "name_en": "Czech Republic",
    "area_code": "+420"
  },
  {
    "id": "98",
    "name_zh": "津巴布韦",
    "name_en": "Zimbabwe",
    "area_code": "+263"
  },
  {
    "id": "99",
    "name_zh": "喀麦隆",
    "name_en": "Cameroon",
    "area_code": "+237"
  },
  {
    "id": "100",
    "name_zh": "卡塔尔",
    "name_en": "Qatar",
    "area_code": "+974"
  },
  {
    "id": "101",
    "name_zh": "开曼群岛",
    "name_en": "Cayman Islands",
    "area_code": "+1345"
  },
  {
    "id": "102",
    "name_zh": "科科斯群岛",
    "name_en": "Cocos(Keeling)Islands",
    "area_code": ""
  },
  {
    "id": "103",
    "name_zh": "科摩罗",
    "name_en": "Comoros",
    "area_code": "+269"
  },
  {
    "id": "104",
    "name_zh": "科特迪瓦",
    "name_en": "Cote d'Ivoire",
    "area_code": "+225"
  },
  {
    "id": "105",
    "name_zh": "科威特",
    "name_en": "Kuwait",
    "area_code": "+965"
  },
  {
    "id": "106",
    "name_zh": "克罗地亚",
    "name_en": "Croatia",
    "area_code": "+383"
  },
  {
    "id": "107",
    "name_zh": "肯尼亚",
    "name_en": "Kenya",
    "area_code": "+254"
  },
  {
    "id": "108",
    "name_zh": "库克群岛",
    "name_en": "Cook Islands",
    "area_code": "+682"
  },
  {
    "id": "109",
    "name_zh": "拉脱维亚",
    "name_en": "Latvia",
    "area_code": "+371"
  },
  {
    "id": "110",
    "name_zh": "莱索托",
    "name_en": "Lesotho",
    "area_code": "+266"
  },
  {
    "id": "111",
    "name_zh": "老挝",
    "name_en": "Laos",
    "area_code": "+856"
  },
  {
    "id": "112",
    "name_zh": "黎巴嫩",
    "name_en": "Lebanon",
    "area_code": "+961"
  },
  {
    "id": "113",
    "name_zh": "利比里亚",
    "name_en": "Liberia",
    "area_code": "+231"
  },
  {
    "id": "114",
    "name_zh": "利比亚",
    "name_en": "Libya",
    "area_code": "+218"
  },
  {
    "id": "115",
    "name_zh": "立陶宛",
    "name_en": "Lithuania",
    "area_code": "+370"
  },
  {
    "id": "116",
    "name_zh": "列支敦士登",
    "name_en": "Liechtenstein",
    "area_code": "+423"
  },
  {
    "id": "117",
    "name_zh": "留尼旺岛",
    "name_en": "Reunion",
    "area_code": "+262"
  },
  {
    "id": "118",
    "name_zh": "卢森堡",
    "name_en": "Luxembourg",
    "area_code": "+352"
  },
  {
    "id": "119",
    "name_zh": "卢旺达",
    "name_en": "Rwanda",
    "area_code": "+250"
  },
  {
    "id": "120",
    "name_zh": "罗马尼亚",
    "name_en": "Romania",
    "area_code": "+40"
  },
  {
    "id": "121",
    "name_zh": "马达加斯加",
    "name_en": "Madagascar",
    "area_code": "+261"
  },
  {
    "id": "122",
    "name_zh": "马尔代夫",
    "name_en": "Maldives",
    "area_code": "+960"
  },
  {
    "id": "123",
    "name_zh": "马耳他",
    "name_en": "Malta",
    "area_code": "+356"
  },
  {
    "id": "124",
    "name_zh": "马拉维",
    "name_en": "Malawi",
    "area_code": "+265"
  },
  {
    "id": "125",
    "name_zh": "马来西亚",
    "name_en": "Malaysia",
    "area_code": "+60"
  },
  {
    "id": "126",
    "name_zh": "马里",
    "name_en": "Mali",
    "area_code": "+223"
  },
  {
    "id": "127",
    "name_zh": "马其顿",
    "name_en": "Macedonia,Former Yugoslav Republic of",
    "area_code": "+389"
  },
  {
    "id": "128",
    "name_zh": "马绍尔群岛",
    "name_en": "Marshall Islands",
    "area_code": "+692"
  },
  {
    "id": "129",
    "name_zh": "马提尼克",
    "name_en": "Martinique",
    "area_code": "+596"
  },
  {
    "id": "130",
    "name_zh": "马约特岛",
    "name_en": "Mayotte",
    "area_code": "+269"
  },
  {
    "id": "131",
    "name_zh": "曼岛",
    "name_en": "Isle of Man",
    "area_code": ""
  },
  {
    "id": "132",
    "name_zh": "毛里求斯",
    "name_en": "Mauritius",
    "area_code": "+230"
  },
  {
    "id": "133",
    "name_zh": "毛里塔尼亚",
    "name_en": "Mauritania",
    "area_code": "+222"
  },
  {
    "id": "134",
    "name_zh": "美国",
    "name_en": "United States",
    "area_code": "+1"
  },
  {
    "id": "135",
    "name_zh": "美属萨摩亚",
    "name_en": "American Samoa",
    "area_code": "+684"
  },
  {
    "id": "136",
    "name_zh": "美属外岛",
    "name_en": "United States Minor Outlying Islands",
    "area_code": ""
  },
  {
    "id": "137",
    "name_zh": "蒙古",
    "name_en": "Mongolia",
    "area_code": "+976"
  },
  {
    "id": "138",
    "name_zh": "蒙特塞拉特",
    "name_en": "Montserrat",
    "area_code": "+1664"
  },
  {
    "id": "139",
    "name_zh": "孟加拉",
    "name_en": "Bangladesh",
    "area_code": "+880"
  },
  {
    "id": "140",
    "name_zh": "密克罗尼西亚",
    "name_en": "Micronesia",
    "area_code": "+691"
  },
  {
    "id": "141",
    "name_zh": "秘鲁",
    "name_en": "Peru",
    "area_code": "+51"
  },
  {
    "id": "142",
    "name_zh": "缅甸",
    "name_en": "Myanmar",
    "area_code": "+95"
  },
  {
    "id": "143",
    "name_zh": "摩尔多瓦",
    "name_en": "Moldova",
    "area_code": "+373"
  },
  {
    "id": "144",
    "name_zh": "摩洛哥",
    "name_en": "Morocco",
    "area_code": "+212"
  },
  {
    "id": "145",
    "name_zh": "摩纳哥",
    "name_en": "Monaco",
    "area_code": "+377"
  },
  {
    "id": "146",
    "name_zh": "莫桑比克",
    "name_en": "Mozambique",
    "area_code": "+258"
  },
  {
    "id": "147",
    "name_zh": "墨西哥",
    "name_en": "Mexico",
    "area_code": "+52"
  },
  {
    "id": "148",
    "name_zh": "纳米比亚",
    "name_en": "Namibia",
    "area_code": "+264"
  },
  {
    "id": "149",
    "name_zh": "南非",
    "name_en": "South Africa",
    "area_code": "+27"
  },
  {
    "id": "150",
    "name_zh": "南极洲",
    "name_en": "Antarctica",
    "area_code": ""
  },
  {
    "id": "151",
    "name_zh": "南乔治亚和南桑德威奇群岛",
    "name_en": "South Georgia and South Sandwich Islands",
    "area_code": ""
  },
  {
    "id": "152",
    "name_zh": "瑙鲁",
    "name_en": "Nauru",
    "area_code": "+674"
  },
  {
    "id": "153",
    "name_zh": "尼泊尔",
    "name_en": "Nepal",
    "area_code": "+977"
  },
  {
    "id": "154",
    "name_zh": "尼加拉瓜",
    "name_en": "Nicaragua",
    "area_code": "+505"
  },
  {
    "id": "155",
    "name_zh": "尼日尔",
    "name_en": "Niger",
    "area_code": "+227"
  },
  {
    "id": "156",
    "name_zh": "尼日利亚",
    "name_en": "Nigeria",
    "area_code": "+234"
  },
  {
    "id": "157",
    "name_zh": "纽埃",
    "name_en": "Niue",
    "area_code": "+683"
  },
  {
    "id": "158",
    "name_zh": "挪威",
    "name_en": "Norway",
    "area_code": "+47"
  },
  {
    "id": "159",
    "name_zh": "诺福克",
    "name_en": "Norfolk Island",
    "area_code": "+6723"
  },
  {
    "id": "160",
    "name_zh": "帕劳群岛",
    "name_en": "Palau",
    "area_code": "+680"
  },
  {
    "id": "161",
    "name_zh": "皮特凯恩",
    "name_en": "Pitcairn Islands",
    "area_code": ""
  },
  {
    "id": "162",
    "name_zh": "葡萄牙",
    "name_en": "Portugal",
    "area_code": "+351"
  },
  {
    "id": "163",
    "name_zh": "格鲁吉亚",
    "name_en": "Georgia",
    "area_code": "+995"
  },
  {
    "id": "164",
    "name_zh": "日本",
    "name_en": "Japan",
    "area_code": "+81"
  },
  {
    "id": "165",
    "name_zh": "瑞典",
    "name_en": "Sweden",
    "area_code": "+46"
  },
  {
    "id": "166",
    "name_zh": "瑞士",
    "name_en": "Switzerland",
    "area_code": "+41"
  },
  {
    "id": "167",
    "name_zh": "萨尔瓦多",
    "name_en": "El Salvador",
    "area_code": "+503"
  },
  {
    "id": "168",
    "name_zh": "萨摩亚",
    "name_en": "Samoa",
    "area_code": "+685"
  },
  {
    "id": "169",
    "name_zh": "塞尔维亚",
    "name_en": "Serbia",
    "area_code": "+381"
  },
  {
    "id": "170",
    "name_zh": "塞拉利昂",
    "name_en": "Sierra Leone",
    "area_code": "+232"
  },
  {
    "id": "171",
    "name_zh": "塞内加尔",
    "name_en": "Senegal",
    "area_code": "+221"
  },
  {
    "id": "172",
    "name_zh": "塞浦路斯",
    "name_en": "Cyprus",
    "area_code": "+357"
  },
  {
    "id": "173",
    "name_zh": "塞舌尔",
    "name_en": "Seychelles",
    "area_code": "+248"
  },
  {
    "id": "174",
    "name_zh": "沙特阿拉伯",
    "name_en": "Saudi Arabia",
    "area_code": "+966"
  },
  {
    "id": "175",
    "name_zh": "圣诞岛",
    "name_en": "Christmas Island",
    "area_code": "+61"
  },
  {
    "id": "176",
    "name_zh": "圣多美和普林西比",
    "name_en": "Sao Tome and Principe",
    "area_code": "+239"
  },
  {
    "id": "177",
    "name_zh": "圣赫勒拿",
    "name_en": "St.Helena",
    "area_code": "+290"
  },
  {
    "id": "178",
    "name_zh": "圣基茨和尼维斯",
    "name_en": "St.Kitts and Nevis",
    "area_code": "+1809"
  },
  {
    "id": "179",
    "name_zh": "圣卢西亚",
    "name_en": "St.Lucia",
    "area_code": "+1758"
  },
  {
    "id": "180",
    "name_zh": "圣马力诺",
    "name_en": "San Marino",
    "area_code": "+378"
  },
  {
    "id": "181",
    "name_zh": "圣皮埃尔和米克隆群岛",
    "name_en": "St.Pierre and Miquelon",
    "area_code": ""
  },
  {
    "id": "182",
    "name_zh": "圣文森特和格林纳丁斯",
    "name_en": "St.Vincent and the Grenadines",
    "area_code": "+1784"
  },
  {
    "id": "183",
    "name_zh": "斯里兰卡",
    "name_en": "Sri Lanka",
    "area_code": "+94"
  },
  {
    "id": "184",
    "name_zh": "斯洛伐克",
    "name_en": "Slovakia",
    "area_code": "+421"
  },
  {
    "id": "185",
    "name_zh": "斯洛文尼亚",
    "name_en": "Slovenia",
    "area_code": "+386"
  },
  {
    "id": "186",
    "name_zh": "斯瓦尔巴和扬马廷",
    "name_en": "Svalbard and Jan Mayen",
    "area_code": ""
  },
  {
    "id": "187",
    "name_zh": "斯威士兰",
    "name_en": "Swaziland",
    "area_code": "+268"
  },
  {
    "id": "188",
    "name_zh": "苏丹",
    "name_en": "Sudan",
    "area_code": "+249"
  },
  {
    "id": "189",
    "name_zh": "苏里南",
    "name_en": "Suriname",
    "area_code": "+597"
  },
  {
    "id": "190",
    "name_zh": "所罗门群岛",
    "name_en": "Solomon Islands",
    "area_code": "+677"
  },
  {
    "id": "191",
    "name_zh": "索马里",
    "name_en": "Somalia",
    "area_code": "+252"
  },
  {
    "id": "192",
    "name_zh": "塔吉克斯坦",
    "name_en": "Tajikistan",
    "area_code": "+992"
  },
  {
    "id": "193",
    "name_zh": "泰国",
    "name_en": "Thailand",
    "area_code": "+66"
  },
  {
    "id": "194",
    "name_zh": "坦桑尼亚",
    "name_en": "Tanzania",
    "area_code": "+255"
  },
  {
    "id": "195",
    "name_zh": "汤加",
    "name_en": "Tonga",
    "area_code": "+676"
  },
  {
    "id": "196",
    "name_zh": "特克斯和凯克特斯群岛",
    "name_en": "Turks and Caicos Islands",
    "area_code": ""
  },
  {
    "id": "197",
    "name_zh": "特里斯坦达昆哈",
    "name_en": "Tristan da Cunha",
    "area_code": ""
  },
  {
    "id": "198",
    "name_zh": "特立尼达和多巴哥",
    "name_en": "Trinidad and Tobago",
    "area_code": "+1809"
  },
  {
    "id": "199",
    "name_zh": "突尼斯",
    "name_en": "Tunisia",
    "area_code": "+216"
  },
  {
    "id": "200",
    "name_zh": "图瓦卢",
    "name_en": "Tuvalu",
    "area_code": "+688"
  },
  {
    "id": "201",
    "name_zh": "土耳其",
    "name_en": "Turkey",
    "area_code": "+90"
  },
  {
    "id": "202",
    "name_zh": "土库曼斯坦",
    "name_en": "Turkmenistan",
    "area_code": "+993"
  },
  {
    "id": "203",
    "name_zh": "托克劳",
    "name_en": "Tokelau",
    "area_code": "+690"
  },
  {
    "id": "204",
    "name_zh": "瓦利斯和福图纳",
    "name_en": "Wallis and Futuna",
    "area_code": ""
  },
  {
    "id": "205",
    "name_zh": "瓦努阿图",
    "name_en": "Vanuatu",
    "area_code": "+678"
  },
  {
    "id": "206",
    "name_zh": "危地马拉",
    "name_en": "Guatemala",
    "area_code": "+502"
  },
  {
    "id": "207",
    "name_zh": "维尔京群岛，美属",
    "name_en": "Virgin Islands",
    "area_code": ""
  },
  {
    "id": "208",
    "name_zh": "维尔京群岛，英属",
    "name_en": "Virgin Islands,British",
    "area_code": ""
  },
  {
    "id": "209",
    "name_zh": "委内瑞拉",
    "name_en": "Venezuela",
    "area_code": "+58"
  },
  {
    "id": "210",
    "name_zh": "文莱",
    "name_en": "Brunei",
    "area_code": "+673"
  },
  {
    "id": "211",
    "name_zh": "乌干达",
    "name_en": "Uganda",
    "area_code": "+256"
  },
  {
    "id": "212",
    "name_zh": "乌克兰",
    "name_en": "Ukraine",
    "area_code": "+380"
  },
  {
    "id": "213",
    "name_zh": "乌拉圭",
    "name_en": "Uruguay",
    "area_code": "+598"
  },
  {
    "id": "214",
    "name_zh": "乌兹别克斯坦",
    "name_en": "Uzbekistan",
    "area_code": "+233"
  },
  {
    "id": "215",
    "name_zh": "西班牙",
    "name_en": "Spain",
    "area_code": "+34"
  },
  {
    "id": "216",
    "name_zh": "希腊",
    "name_en": "Greece",
    "area_code": "+30"
  },
  {
    "id": "217",
    "name_zh": "新加坡",
    "name_en": "Singapore",
    "area_code": "+65"
  },
  {
    "id": "218",
    "name_zh": "新喀里多尼亚",
    "name_en": "New Caledonia",
    "area_code": "+687"
  },
  {
    "id": "219",
    "name_zh": "新西兰",
    "name_en": "New Zealand",
    "area_code": "+64"
  },
  {
    "id": "220",
    "name_zh": "匈牙利",
    "name_en": "Hungary",
    "area_code": "+36"
  },
  {
    "id": "221",
    "name_zh": "叙利亚",
    "name_en": "Syria",
    "area_code": "+963"
  },
  {
    "id": "222",
    "name_zh": "牙买加",
    "name_en": "Jamaica",
    "area_code": "+1876"
  },
  {
    "id": "223",
    "name_zh": "亚美尼亚",
    "name_en": "Armenia",
    "area_code": "+374"
  },
  {
    "id": "224",
    "name_zh": "也门",
    "name_en": "Yemen",
    "area_code": "+967"
  },
  {
    "id": "225",
    "name_zh": "伊拉克",
    "name_en": "Iraq",
    "area_code": "+964"
  },
  {
    "id": "226",
    "name_zh": "伊朗",
    "name_en": "Iran",
    "area_code": "+98"
  },
  {
    "id": "227",
    "name_zh": "以色列",
    "name_en": "Israel",
    "area_code": "+972"
  },
  {
    "id": "228",
    "name_zh": "意大利",
    "name_en": "Italy",
    "area_code": "+39"
  },
  {
    "id": "229",
    "name_zh": "印度",
    "name_en": "India",
    "area_code": "+91"
  },
  {
    "id": "230",
    "name_zh": "印度尼西亚",
    "name_en": "Indonesia",
    "area_code": "+62"
  },
  {
    "id": "231",
    "name_zh": "英国",
    "name_en": "United Kingdom",
    "area_code": "+44"
  },
  {
    "id": "232",
    "name_zh": "英属印度洋领地",
    "name_en": "British Indian Ocean Territory",
    "area_code": ""
  },
  {
    "id": "233",
    "name_zh": "约旦",
    "name_en": "Jordan",
    "area_code": "+962"
  },
  {
    "id": "234",
    "name_zh": "越南",
    "name_en": "Vietnam",
    "area_code": "+84"
  },
  {
    "id": "235",
    "name_zh": "赞比亚",
    "name_en": "Zambia",
    "area_code": "+260"
  },
  {
    "id": "236",
    "name_zh": "泽西岛",
    "name_en": "Jersey",
    "area_code": "+44"
  },
  {
    "id": "237",
    "name_zh": "乍得",
    "name_en": "Chad",
    "area_code": "+235"
  },
  {
    "id": "238",
    "name_zh": "直布罗陀",
    "name_en": "Gibraltar",
    "area_code": "+350"
  },
  {
    "id": "239",
    "name_zh": "智利",
    "name_en": "Chile",
    "area_code": "+56"
  },
  {
    "id": "240",
    "name_zh": "中非共和国",
    "name_en": "Central African Republic",
    "area_code": "+236"
  },
  {
    "id": "241",
    "name_zh": "中国台湾",
    "name_en": "China Taiwan",
    "area_code": "+886"
  },
  {
    "id": "242",
    "name_zh": "黑山",
    "name_en": "Montenegro",
    "area_code": "+382"
  },
  {
    "id": "243",
    "name_zh": "科索沃",
    "name_en": "Kosovo",
    "area_code": "+384"
  },
  {
    "id": "244",
    "name_zh": "中国香港",
    "name_en": "Hong Kong, China",
    "area_code": "+852"
  },
  {
    "id": "245",
    "name_zh": "中国澳门",
    "name_en": "Macao China",
    "area_code": "+853"
  }
]
