import baseConfig from '~/baseConfig/index.js'
export default {
    actions: {
        async user_saveUserInfo(context, params) {           //保存用户个人信息
            let { data } = await axios.post('user/api/info/save', params);
            if (data.success) {
                let { result } = await context.dispatch('user_getUserInfo', { user_id: params.user_id });
            }
            return data;
        },
        async user_getUserInfo(context, params) { //获取用户信息
            let { data } = await axios.get('user/api/userinfo', { params });
            if (data.success) {
                context.commit('baseStore/SET_USER_INFO', data.data, { root: true });
                let { auth } = await context.dispatch('getUserAuth', { user_id: params.user_id })
            }
            return data;
        },
        async getUserAuth(context, params) {                       //获取用户权限
            let { data } = await axios.get('user/api/permission', { params });
            if (data.success) {
                context.commit('baseStore/INIT_USERAUTH', data.data, { root: true });
            }
            return data;
        },
        async user_getCheckEmail(context, params) {            //获取校验邮件
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.get('user/api/send_check_mail', { params });
            return data;
        },
        async user_bingIndentity(context, params) {            //账号绑定邮箱/手机号
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.post('user/api/identity/bind', params);
            if (data.success) {
                let result = await context.dispatch('user_getUserInfo', { user_id: params.user_id })
            }
            return data;
        },
        async user_updataPassword(context, params) {      //修改密码
            let { data } = await axios.post('user/api/password/update', params);
            return data;
        },
        async user_checkUserInfo(context, params) {      //检查用户信息
            let { data } = await axios.get('user/api/identity/info', {params});
            return data;
        },
        async user_realSave(context, params) {      //实名认证
            let { data } = await axios.post('user/api/identity/save', params);
            return data;
        },
        async get_sales_list(context, params) {      //获取销售列表
            let { data } = await axios.get('user/api/bind_sales/info_list', {params});
            return data;
        },
    }
}