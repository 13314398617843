import _axios from '~/basePlugins/_axios'
export default {
    actions: {
        async booth_pictureCollection(context, params) { //展台的图片集
            let { data } = await axios.post('daily_chat/api/picture_collection/list', params);
            return data;
        },
        async booth_videoInquiry(context, params) { //展台详情-查询展台关联的询盘、视频信息
            let { data } = await axios.get('meeting/api/get/video_inquiry', { params });
            return data;
        },
        async addBoothVideo(context, params) {//添加展台视频
            let { data } = await axios.get("meeting/api/save/booth_video", { params })
            return data;
        },
        async getBoothData(content, params) {//展台资料列表
            let { data } = await axios.post("meeting_new/api/meeting_booth_data/list", params)
            return data
        },
        async addBoothData(content, params) {//上传展台资料
            let { data } = await axios.post("meeting_new/api/upload_meeting_booth_data", params)
            return data
        },
        async deleteBoothData(content, params) {//上传展台资料
            let { data } = await axios.get("meeting_new/api/meeting_booth_data/delete", { params })
            return data
        },
        async deleteImageCollection(content, params) {//删除展台下的图片集
            let { data } = await axios.get("daily_chat/api/picture_collection/del", { params })
            return data
        },
        async getImageByImageCollection(content, params) {//获取图片集下面的图片
            let { data } = await axios.post("daily_chat/api/collection_picture_rel/list", params)
            return data
        },
        async delBoothVideo(context, params) {//删除展台视频
            let { data } = await axios.get("meeting/api/del/booth_video", { params })
            return data;
        },
        async orderImageCollection(content, params) {//图片集排序
            let { data } = await axios.post("expo/api/picture_collection/order", params)
            return data
        },
        async addImageCollection(content, params) {//添加图片集
            let { data } = await axios.post("expo/api/picture_collection/add", params)
            return data
        },
        async getLineList(content, params) {//获取直播频道列表
            let { data } = await axios.get("expo/api/meeting_booth_live/list", { params })
            return data
        },
        async boothAgaent(content, params) {//议程创建，删除，修改
            let { data } = await axios.post("meeting/api/save/booth_agenda", params)
            return data
        },
        async saveBoothUserInfo(content, params) {//添加展台坐席人员
            let { data } = await axios.get("meeting/api/save/booth_user_info", { params })
            return data
        },
        async getContactPerson(content, params) {//获取展台坐席人员
            let { data } = await axios.get("database/api/booth/contact_person", { params })
            return data
        },
        async delContactPerson(content, params) {//删除展台坐席人员
            let { data } = await axios.post("expo/api/booth_person/del", params)
            return data
        },
        async countSeats(content, params) {//获取展台坐席人员
            let { data } = await axios.get("expo/api/seat/count", { params })
            return data
        },
        async getAgaentList(content, params) {//获取议程列表接口
            let { data } = await axios.post("meeting_new/api/booth_agenda/list", params)
            return data
        },
        async boothIsSHow(content, params) {//设置展台是否开启
            let { data } = await axios.post("expo/api/set_booth/is_show", params)
            return data
        },
        async getLiveHas(content, params) {//获取展台是否有直播
            let { data } = await axios.get("meeting/api/is_live_broadcast", {params})
            return data
        },
        async getMeetingMsg(content, params) {//获取会议信息
            let { data } = await axios.get("job/api/meeting/info", {params})
            return data
        },
    }
}