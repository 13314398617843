export default {
  actions: {
    async talent_user_getMyresume(context, params) {//获取简历信息
      let {data} = await axios.get('job/api/my_resume/list', {params})
      return data
    },
    async talent_user_resumeEdit(context, params) {//编辑简历
      let {data} = await axios.post('job/api/my_resume/save', params)
      console.log(data)
      return data
    },
    async talent_update_resume(context, params) {//简历是否开放
      let {data} = await axios.post('job/api/my_resume_publice/update', params)
      return data
    },
    async talent_my_applylist(context, params) {//我申请的列表
      let {data} = await axios.post('job/api/my_apply/list', params)
      return data
    },
    async talent_handleDaily_chat(context, params) {//   接受邀请， 拒绝  面试
      let {data} = await axios.post("job/api/daily_chat/apply", params)
      return data
    },
    async talent_order_status(context, params) {//获取预约的各种状态
      let {data} = await axios.post('job/api/status/check', params)
      return data
    },
    async talent_job_appointment(context, params){//预约面试
      let {data}=await axios.post('job/api/intervie/appointment',params)
      return data
    }
  }
}