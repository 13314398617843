<template>
    <div class="tc mationDialog">
        <div class="title">{{$t('incompleteResume')}}</div>
        <div class="">{{$t('attentionYou')}}</div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.mationDialog{
    font-size: 14px;
    .title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
}

</style>