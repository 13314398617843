export default{
  namespaced:true,
  actions:{
    async getPlayAuth(context, params){//获取视频播放权限
      let {data}=await axios.get("media/api/video_play_auth", {params})
      return data
    },
    async viewPosition(content, params){//我看过的职位
      let {data}=await axios.post("job/api/job_position_history/list",params)
      return data
    },
  }
}
