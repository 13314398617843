export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/logo/logo_b.png',
        bgColor: '#27292e',
        textColor: '#fff',
        activeTextColor: '#1374F2'
    },
    pageConfig: {
        companyPage: {
            url: '/companyDetail',
            isEncode: true,
        },
        signPage: {
            url: '/sign'
        }
    },
    accountConfig: {
        notUseWx: true
    }
}