import zhWords from './zh/i18nWord'
import zhPara from './zh/i18nPara'
import enWords from './en/i18nWord'
import enPara from './en/i18nPara'

let zhLocales = {};
let enLocales = {};

zhLocales = Object.assign(zhLocales, zhWords);
zhLocales = Object.assign(zhLocales, zhPara);
enLocales = Object.assign(enLocales, enWords);
enLocales = Object.assign(enLocales, enPara);

export default {
    zhLocales,
     enLocales
}