export default {
    state: {
        USER_AUTH: [],               //用户权限集
        MENUAUTH: [],                //用户菜单权限集
        BUTTONAUTH: [],              //用户按钮权限集
        HANDLEAUTH: [],              //用户按钮可操作权限集
        DIRECTORYAUTH: []            //用户目录权限集
    },
    mutations: {
        INIT_USERAUTH(state, authCodes) {
            state.USER_AUTH = authCodes;
            state.MENUAUTH = authCodes.menu ? authCodes.menu.map(item => item.identifier) : []
            state.BUTTONAUTH = authCodes.button ? authCodes.button.map(item => item.identifier) : []
            state.HANDLEAUTH = authCodes.button ? authCodes.button.filter(item => item.operability).map(item => item.identifier) : [];
            state.DIRECTORYAUTH = authCodes.directory ? authCodes.directory.map(item => item.identifier) : []
        }
    },
    actions: {
        inspectRoute(context, payload) {
            return context.state.MENUAUTH.indexOf(payload) != -1;
        },
    }
}