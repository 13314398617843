export default {
    actions: {
        async getOrderList(context, params) {//获取订单列表
            let { data } = await axios.post('pay_new/api/order/list', params)
            return data
        },
        async getOrderDetail(context, params) {//获取订单详情
            let { data } = await axios.get('pay_new/api/order/detail', { params })
            return data
        },
        async getMyCourseList(context, params) {//获取订单详情
            let { data } = await axios.post('siffa/api/my_course/list', params)
            return data
        },
    }
}