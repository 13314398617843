
import Vue from 'vue'

import Element from 'element-ui'
import locale_en from 'element-ui/lib/locale/lang/en'
import locale_zh from 'element-ui/lib/locale/lang/zh-CN.js'
// import '~/baseAssets/baseStyle/elementStyle/index.css'

import { confirmProjectLocale } from '~/baseUtils'
import i18nConfig from '~/baseConfig/i18n'
let currenti18nConfig = i18nConfig[process.env.VUE_APP_PROJECT_NAME], locale;
const websiteLocale = currenti18nConfig.locale ? currenti18nConfig.locale : confirmProjectLocale()
locale = websiteLocale == 'en' ? locale_en : locale_zh;

Vue.use(Element, {
    locale,
    zIndex: 3000
})