import baseConfig from '~/baseConfig/index.js'

export default {
    actions: {
        async harbour_sailing_date(context, params) {               
            //港到港实时船期查询
            params._PREFIX='ptss'
            let { data } = await axios.post('shipdata/api/harbour_sailing_date',  params );
            return data;
        },
        async shipdataGetHarbour(context, params) {
            //港到港实时船期查询
            params._PREFIX='ptss'
            let { data } = await axios.post('shipdata/api/get_harbour',  params );
            return data;
        },
        async shipdataHotHarbour(context, params) {
            //港到港实时船期查询
            params._PREFIX='ptss'
            let { data } = await axios.get('shipdata/api/hot_harbour',  { params });
            return data;
        },
    }
}