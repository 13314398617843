import _axios from '~/basePlugins/_axios'
export default {
    actions: {
        async company_getCertificateList(context, params) { //获取企业证书列表
            let is_self = params.is_self;
            delete params.is_self;
            let { data } = await axios.post('company_vip/api/company/qualification', params);
            if (data.success) {
                if (is_self) {
                    context.commit('baseConsole/SET_SELF_CERTIFICATE', data.data, { root: true })
                }
            }
            return data;
        },
        async company_YMPgetCertificateList(context, params) { //获取企业证书列表
            let is_self = params.is_self;
            delete params.is_self;
            let { data } = await axios.post('company/api/company/qualification', params);
            if (data.success) {
                if (is_self) {
                    context.commit('baseConsole/SET_SELF_CERTIFICATE', data.data, { root: true })
                }
            }
            return data;
        },
        async company_getCompanyInfo(context, params) { //公司信息查询
            let is_self = params.is_self;
            delete params.is_self;
            let { data } = await axios.get('company/api/company/info', { params });
            if (data.success) {
                if (is_self) {
                    context.commit('baseConsole/SET_SELF_COMPANY_INFO', data.data, { root: true })
                }
            }
            return data;
        },
        async company_saveCompanyInfo(context, params) { //公司信息保存
            let { data } = await axios.post('company/api/info/save', params);
            if (data.success) {
                let result = await context.dispatch('company_getCompanyInfo', { company_id: params.company_id, is_self: true })
            }
            return data;
        },
        async company_bindInfo(context, params) { //变更企业
            let { data } = await axios.post('company/api/bind_company', params);
            if (data.success) {
                let { result } = await context.dispatch('user_getUserInfo', { user_id: params.user_id });
            }
            return data;
        },
        async agent_company_bindInfo(context, params) { // 玉漭代绑定公司
            let { data } = await axios.post('company/api/bind_company', params);
            return data;
        },
        async company_uploadQualification(context, params) { //上传企业证书
            let { data } = await axios.post('media/api/company_qualification/upload', params);
            if (data.success) {
                let result = await context.dispatch('company_getCertificateList', { company_id: params.company_id, certificate_type: params.certificate_type, is_self: true })
            }
            return data;
        },
        async company_changePicture(context, params) { //公司宣传图修改信息
            let { data } = await axios.post('media/api/picture_name/change', params);
            return data;
        },
        async company_getCompanyPicture(context, params) { //获取公司图片列表
            let { data } = await axios.post('media/api/company_picture/list', params);
            return data;
        },
        async company_savePicture(context, params) { //公司宣传图片保存
            let { data } = await axios.post('media/api/company_picture/save', params);
            return data;
        },
        async company_deletePicture(context, params) { //公司宣传图片删除
            let { data } = await axios.post('media/api/company_picture/delete', params);
            return data;
        },
        async company_getPictureDetail(context, params) { //公司宣传图片详情
            let { data } = await axios.get('media/api/company_picture/detail', { params });
            return data;
        },
        async company_accountManage(content, params) {//企业账号列表
            let { data } = await axios.post('company/api/company_account/list', params)
            return data
        },
        async company_roleManage(context, params) {//公司角色列表
            let { data } = await axios.post('company/api/enterprise_role_management/list', params)
            return data
        },
        async company_AddAccount(context, params) {//添加账号
            let { data } = await axios.post('company/api/company_administrator/add', params)
            return data
        },
        async company_editAccountInfo(context, params) {//修改账号信息
            let { data } = await axios.post('user/api/info/save', params)
            return data
        },
        async company_deleteAccount(context, params) {//删除未激活用户
            let { data } = await axios.get('company/api/company_administrator/del_user', { params })
            return data
        },
        async company_unbindUser(context, params) {//账号解除绑定
            let { data } = await axios.post('company/api/unbind/user', params)
            return data
        },
        async company_applyJoin(context, params) {////申请加入公司审核
            let { data } = await axios.post('company/api/apply/audit', params)
            return data
        },
        async company_distributionRole(context, params) {//可分配额角色列表
            let { data } = await axios.get('company/api/assignable_roles/list', { params })
            return data
        },
        async company_roleRelationEdit(context, params) {//用户角色关系修改
            let { data } = await axios.post('company/api/role_user_rel/update', params)
            return data
        },
        async company_getCompanyVideo(context, params) { //获取公司视频列表
            let { data } = await axios.post('media/api/video/list', params);
            return data;
        },
        async company_getVideoDetail(context, params) { //公司宣传视频详情
            let { data } = await axios.get('media/api/video/info', { params });
            return data;
        },
        async company_changeVideoMsg(context, params) { //获取公司视频修改
            let { data } = await axios.post('media/api/video/update', params);
            return data;
        },
        async company_deleteVideo(context, params) { //公司宣传视频删除
            let { data } = await axios.post('media/api/video/delete', params);
            return data;
        },
        async company_getBussinessInfo(context, params) { // 获取企业业务信息
            let { data } = await axios.get('company/api/company_business/info', { params })
            return data
        },
        async company_savaBussiness(context, params) { //保存企业业务信息
            let param = params
            param._NOCLEAR = true
            let { data } = await axios.post("company/api/company_extend/save", param)
            return data
        },
        async company_deleteBussiness(context, params) { //删除企业业务信息
            let { data } = await axios.post("company/api/company_extend/del", params)
            return data
        },
        async base_getCompains(context, params) {         //获取船公司列表
            let { data } = await axios.post('base/api/shipping_company/list', params);
            return data;
        },
        async company_deleteCertificate(context, params) { //行业证书删除
            let { data } = await axios.post('company/api/qualifications/delete', params);
            return data;
        },
        async getCompanyFollowUpList(context, params) {//企业关注列表
            let { data } = await axios.post('company/api/all_follow/list', params)
            return data
        },
        async getCompanyFollowedList(context, params) {//企业被关注列表
            let { data } = await axios.post('company/api/followed/list', params)
            return data
        },
        async getMyLikeList(context, params) {//我点赞的列表
            let { data } = await axios.post('company/api/company_like/list', params)
            return data
        },
        async getCollectionMemberList(context, params) {//用户收藏列表
            let { data } = await axios.post("siffa/api/siffa_collection/list", params)
            return data
        },
        async getCollectionVideosList(context, params) {//用户收藏视频列表
            let { data } = await axios.post("media/api/association_video_like/list", params)
            return data
        },
        async handleAssociationVideoLike(context, params) {//用户取消收藏视频
            let { data } = await axios.post("media/api/association_video/like", params)
            return data
        },
        async handlePlayVideoCountAdd(context, params) {//点击协会视频-增加播放次数
            let { data } = await axios.post("media/api/play_count/add", params)
            return data
        },
        async getVistCompanyLikeList(context, params) {//点赞我的列表
            let { data } = await axios.post('company/api/visit_company_like/list', params)
            return data
        },
        async createLive(context, params) { //创建展台直播
            let { data } = await axios.post('expo/api/create/live', params)
            return data
        },
        async getVistCompanyBoothId(context, params) {//获取展台id
            let { data } = await axios.get('meeting/api/get_booth_id', {params})
            return data
        },
        async getBoothLiveList(context, params) {//会议展台直播关联列表
            let { data } = await axios.post('expo/api/meeting_booth_live_rel/list', params)
            return data
        },
        async getBoothLiveDelete(context, params) {//会议展台直播关联删除
            let { data } = await axios.post('expo/api/meeting_booth_live_rel/delete', params)
            return data
        },
        async getLiveLink(context, params) {//检查直播是否关联议程Live_link_
            let { data } = await axios.get('expo/api/check/meeting_live', {params})
            return data
        },
        async company_vip_Detail(context, params) {// 申请入会的详情（物贸联合会）
            let { data } = await axios.get("expo/api/company_vip/detail", {params} );
            return data;
        },
        async company_change_admin(context,params){//更新管理员
            let {data}=await axios.post("siffa/api/set/administrator",params)
            return data
        },
        async ymcompany_change_admin(context,params){//玉莽更新管理员
            let {data}=await axios.post("admin/api/admin/change",params)
            return data
        },
        async user_checkIdentifier(context, params) {    //检查邮箱,手机，用户名，微信，facebook是否已存在
            console.log(params);
            let { data } = await axios.get('user/api/identity/check', { params });
            return data;
        },
        async user_checkPhoneHas(context, params) {    //检查邮箱,手机，用户名，微信，facebook是否已存在
            console.log(params);
            let { data } = await axios.get('company/api/check/mobile', { params });
            return data;
        },
        async addinvoiceMsg(context, params) {    //添加发票信息
            console.log(params);
            let { data } = await axios.post('booking/api/invoice/save',  params );
            return data;
        },
        async getinvoiceList(context, params) {    //获取发票信息
            console.log(params);
            let { data } = await axios.post('booking/api/invoice/info',  params );
            return data;
        },
        async getProductInfoList(context,params){//产品信息列表
            let {data}=await axios.post("job/api/company_product/list",params)
            return data
        },
        async addProductInfoByCompany(context,params){//公司添加产品信息
            let { data } = await axios.post("job/api/company_product/save",
            params
            );
            console.log(data);
            return data
        },
        async getVideoInfoByAliYunInProduct(context,params){//获取视频信息
            let { data } = await axios.get("media/api/video_info/aliyun_id",
            {params}
            );
            return data
        },
        async getPreClassificationList(context,params){//预归类列表查询
            let { data } = await axios.post("classification/api/pre_classification/query",params);
            return data
        },
        async getPreClassificationInfo(context,params){//预归类详情查询
            let { data } = await axios.post("classification/api/pre_classification/detail",params);
            return data
        },
        async editPreClassificationInfo(context,params){//预归类信息编辑
            let { data } = await axios.post("classification/api/pre_classification/update",params);
            return data
        },
        async getJournalInfo(context,params){//会刊
            let { data } = await axios.post("admin_meeting_new/api/journal/list",params);
            return data
        },
    }
}
