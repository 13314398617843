export default {
	namespaced:true,
	actions:{
		async certificateName(context,params){//证书名称列表查询
			let {data}=await axios.post('dga/api/dga_certificate_name/query',params)
			return data
		},
		async searchUserCertificate(context,params){//个人查询证书内容
			let {data}=await axios.post('dga/api/dga_certificate/query',params)
			return data
		},
		async searchCompanyCertificate(context,params){//公司查询证书内容
			let {data}=await axios.post('dga/api/dga_certificate/list',params)
			return data
		},
		async uploadFile(context,params){// 上传文件
			let {data}=await axios.post('dga/api/dga_certificate/upload',params)
			return data
		},		
		async uploadFileEdit(context,params){// 文件编辑
			let {data}=await axios.post('dga/api/dga_file/edit',params)
			return data
		},
		async getDgaFilesList(context,params){// 文件管理列表
			let {data}=await axios.post('dga/api/dga_files/query',params)
			return data
		},
		async fileQuery(context,params){// 文件查询
			let {data}=await axios.post('dga/api/dga_manage/edit_info',params)
			return data
		},
		async getDgaFileTypeList(context,params){// 文件查询
			let {data}=await axios.post('dga/api/dga_file_type/query',params)
			return data
		},
		async dgsaPageFtypeExportExcelList(context,params){//导出列表
			let {data}=await axios.post('dga/api/dga_files/excel',params)
			return data
		},
		async dgsaBatcgDownloadAll(context,params){//批量下载
			let {data}=await axios.post('dga/api/dga_files/pack_download',params)
			return data
		},
		async sendEmail(context,params){//咨询台发送邮件
      let {data} =await axios.post("dga/api/dga_send_email",params)
      return data
    }
	}
}