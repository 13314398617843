export default {

    data() {
        return {
            defaultAvatar: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/grounp.png',
            defaultLogo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/companyLogo.png',

        }
    },
    methods: {
        INFO_MATION() {
            return new Promise(async (resolve, reject) => {
                if (this.IS_LOGIN) {
                    let data = await this.$store.dispatch("API_user/user_getMyresume", { user_id: this.USER_ID })
                    if (data.success && JSON.stringify(data.data) == '{}') {
                        this.$GLOBALEVENT.$emit('SHOWIMPROVERESUME')
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                } else {
                    resolve("login");
                }

            })
        }
    },
    computed: {
        MEETING_ID() {
            return this.$store.state.meeting_id;
        },
        MEETING_STATUS() {              //1,未开始，2，进行中，3.已结束
            return this.$store.state.meeting_status;
        },
        MEETING_INFO() {
            return this.$store.state.meeting_info;
        },
        CHECK_LOGIN() {
            if (!this.IS_LOGIN) {
                this.$GLOBALEVENT.$emit('SHOWGLOBALLOGIN')
                return false;
            } else {
                return true;
            }
        },
    },

}