export default {
    
    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/expoLogo.png',
        bgColor: '#027FFF',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: false
    },
    sendCard: true,//发送名片，等待对方确认
}