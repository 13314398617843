export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/logo.png',
        bgColor: '#5f5f5f',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: false
    },
    basicInfoLocale: "zh",//en All
}