import { CKstore } from '~/baseUtils/storage'
import { constName } from '~/baseUtils/constName'
let currentConstNames = constName[process.env.VUE_APP_PROJECT_NAME]
import auth from './auth'
import baseApis from './baseApis'
import baseSignApis from './baseSignApis'
import baseBooth from './baseBooth'
import baseVideoChatApis from './baseVideoChatApis'
import baseInquiry from './baseInquiry'
import ship from './ship'
export default {
    namespaced: true,
    state: {
        userId: '', //用户ID
        userInfo: '', //用户信息
        locales: ['zh', 'en'],
        locale: '',
        enrollInfo: {},
        meetingInfo: {}
    },
    mutations: {
        SET_USER_ID_SESSION(state, payload) {
            CKstore.set(currentConstNames.userId, payload);
            state.userId = payload
        },
        SET_USER_ID(state, payload) {
            CKstore.set(currentConstNames.userId, payload, {
                expires: 7
            });
            state.userId = payload
        },
        SET_GLOBAL_USER_ID(state, payload) {
            if (payload.expires) {
                CKstore.set(currentConstNames.userId, payload.user_id, {
                    expires: payload.expires
                });
            } else {
                CKstore.set(currentConstNames.userId, payload.user_id);
            }
            state.userId = payload.user_id
        },
        SET_USER_INFO(state, payload) {
            state.userInfo = payload
            state.userId = payload.id
        },
        CLEAR_USER_INFO(state, payload) {
            state.userId = '';
            state.userInfo = '';
            CKstore.remove(currentConstNames.userId);
            CKstore.remove('shippingOrderList');
        },
        SET_LANG(state, locale) {                                   //设置语言环境
            if (state.locales.indexOf(locale) !== -1) {
                state.locale = locale
            }
        },
        SET_ENROLL_INFO(state, payload) {
            state.enrollInfo = payload;
        },
        SET_MEETING_INFO(state, payload) {
            state.meetingInfo = payload;
        }
    },
    modules: { auth, baseApis, baseSignApis, baseBooth,baseVideoChatApis,baseInquiry,ship }
}
