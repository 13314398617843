import baseConfig from '~/baseConfig/index.js'

export default {
    actions: {
        async getAllI18nWords(context, params) {                    //获取国际化字段
            let { data } = await axios.get('https://ailaworld.com/tools/i18n/queryAll', { params });
            //let { data } = await axios.get('http://192.168.1.21:3001/i18n/queryAll', { params });
            return data;
        },

        async getVerifyCode(context, params) {                       //获取手机号验证码
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.get('sms/api/get_verify_code', { params });
            return data;
        },
        async checkVerifyCode(context, params) {
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            if (params.code == '20190315') {                        //万能验证码
                return { success: true, data: true };
            } else {
                let { data } = await axios.get('sms/api/check_verify_code', { params });
                return data;
            }
        },
        async basicInfoCompanyComplate(context, params) {       //公共组件companyNameComplate公司名联想提示
            let { data } = await axios.post("company/api/filter/list_lite", params)
            return data
        },
        async getPlayAuth(context, params) {                    //公共组件videoPlay获取视频播放权限
            let { data } = await axios.get("media/api/video_play_auth", { params })
            return data
        },
        async company_uploadQualification(content, params) {        //公共组件uploadListDialog上传证书接口
            let { data } = await axios.post('media/api/company_qualification/upload', params)
            return data
        },
        async addBoothData(content, params) {                       //公共组件uploadListDialog上传展台资料
            let { data } = await axios.post("meeting_new/api/upload_meeting_booth_data", params)
            return data
        },
        async company_savePicture(context, params) {                //公共组件uploadListDialog上传企业宣传图片
            let { data } = await axios.post('media/api/company_picture/save', params)
            return data
        },
        async sms_getUploadVideoToken(context, params) {         //获取视频上传token          
            let { data } = await axios.post('media/api/upload_video_token', params);
            return data;
        },
        async getVideoInfoByAliyunId(context, params) {
            let { data } = await axios.get('media/api/video_info/aliyun_id', { params });
            return data;
        },
        async company_getCompanyNameList(context, params) {             //获取公司列表(联想提示)
            let { data } = await axios.post('company/api/list/lite', params);
            return data;
        },
        async company_getAllCompany(context) {                      //获取所有公司
            let { data } = await axios.get('company/api/all_company');
            return data;
        },
        async company_getCompanyExtendInfo(context, params) {           //获取公司扩展信息
            let { data } = await axios.get('company/api/company_extend/info', { params });
            return data;
        },
        async company_associateSearch(context, params) {                    //公司联想搜索ES
            let { data } = await axios.post('company/api/search/associate', params);
            return data;
        },
        async getCompanyCommentList(context, params) {            //企业评论列表
            let { data } = await axios.post('company/api/company_comment', params);
            return data;
        },
        async saveCompanyComment(context, params) {            //企业评论列表
            let { data } = await axios.post('company/api/company_comment/save', params);
            return data;
        },
        async delCompanyComment(context, params) {            //企业评论列表
            let { data } = await axios.post('company/api/company_comment/del', params);
            return data;
        },
        async companyInvite(context, params) {                  //1V1预约
            let { data } = await axios.post('daily_chat/api/daily_one2one_chat/invite', params)
            return data
        },
        async getpolyvSign(context, params) {                //获取保利威视签名
            let { data } = await axios.get('live/api/polyv/channel/sign', { params });
            return data;
        },
        async getMeeingBasicInfo(context, params) {            //获取会议详情信息
            let { data } = await axios.get('meeting/api/meeting/basics_info', { params });
            context.commit('baseStore/SET_MEETING_INFO', data.data, { root: true })
            return data;
        },
        async getVideoPlayUrl(context, params) {                  //获取视频播放url
            let { data } = await axios.get('media/api/video_play_info', { params });
            return data;
        },
        async setupVideoCover(context, params) {                  //上传视频封面
            let { data } = await axios.post('media/api/video_save_by_aliyun_id', params);
            return data;
        },
        async getMeetingEnrollCountry(context, params) {           //获取会议报名国家列表
            let { data } = await axios.get('expo/api/booth_country_rel/list', { params });
            return data;
        },
        async getMeetingEnrollCity(context, params) {             //获取会议报名国家城市
            let { data } = await axios.get('expo/api/booth_city_rel/list', { params });
            return data;
        },
        async reservationMeetingBoothAgenda(content, params) {//预约直播
            let { data } = await axios.post("meeting/api/meeting_remind/setting", params)
            return data
        },
        async getAgendaLiveList(context, params) {//   获取会议议程列表
            let { data } = await axios.post("nsf/api/live/list", params);
            return data
        },
        async getHotBoothList(context, params) {//   获取热门展商列表
            let { data } = await axios.post("expo/api/top_exhibitors/list", params);
            return data
        },
        async getHotVideoList(context, params) {//   获取精彩同步列表
            let { data } = await axios.post("expo/api/live/list", params);
            return data
        },
        async getPolyvliveImageSign(content, params) {        //获取图文直播签名
            let { data } = await axios.get('live/api/polyv/tuwen/sign', { params });
            return data;
        },
        async getPolyvliveImage(context, params) {          //获取保利威视图文直播
            let { data } = await axios.get('https://api.polyv.net/live/v3/channel/watch/tuwen/list', { params });
            return data;
        },
        async getCollectionDetail(context, params) {//名片夹详情
            let { data } = await axios.post('expo/api/card/info', params)
            return data
        },
        async exchangeCard(context, params) {// 交换名片
            let { data } = await axios.post('expo/api/exchange/card', params)
            return data
        },
        async getAudienceList(context, params) {// 观众名录列表
            let { data } = await axios.post('expo/api/meeting/audience/list', params)
            return data
        },
        async multChatLogin(contenxt, params) {//我的洽谈-参会人登录（针对被邀请）
            let { data } = await axios.post("daily_chat/api/daily_chat/invite_login", params)
            return data
        },
        async getChatNickname(contenxt, params) {//洽谈获取参会人名称
            let { data } = await axios.get("daily_chat/api/get/daily_chat_invite/nickname", { params })
            return data
        },
        async getChatUidList(context, params) {             //获取视频聊天房间用户ID列表
            let { data } = await axios.get('meeting/api/uid/list', { params })
            return data;
        },
        async getAssociationBanner(context, params) {//获取协会的banner
            let {data}=await axios.get('media/api/home_page/banner',{params})
            return data
        },





        async getServerDowmLoadNums(context, params) {
            //获取服务下载量 
            let { data } = await axios.get(`https://api.ailaworld.com/atlas/resource/company/service?service_type=${params.service_type}&nopage=1`);
            return data;
        },
        async dowmLoadServer(context, params) {
            //记录下载（企业服务） 
            let { data } = await axios.post(`https://api.ailaworld.com/atlas/resource/company/service`,params);
            return data;
        },


        async getCompanyServiceVideo(context, params) {//获取企业服务-视频列表
            let {data}=await axios.get('https://api.ailaworld.com/atlas/resource/company/service_video',{params})
            return data
        },
        async getCompanyEnums(context, params) {//枚举信息查询
            let {data}=await axios.get(`https://api.ailaworld.com/atlas/company/enums/${params.code}`)
            return data
        },
        async getAppletQRCode(context, params) {// 获取二维码
            let {data} = await axios.get("https://api.ailaworld.com/atlas/wechat/rqcode/unlimit", { params })
            return data
        },
    }
}