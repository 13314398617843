import company from './API_Company'
import negotiationMange from './API_NegotiationMange.js'
import account from './API_Account'
import consoleConfig from '~bac/utils/consoleConfig/index'
import conference from './API_Conference'
import booth from './API_Booth'
import talentCenter from './API_TalentCenter'
import dealManagement from './API_dealManagement'
import Inquiry from './API_Inquiry'
import talent from './API_talent'
import order from './API_Order'
export default {
    namespaced: true,
    state: {
        config: consoleConfig[process.env.VUE_APP_PROJECT_NAME],
        selfCompanyInfo: {}, //自己公司信息
        selfCertificate: [],//自己公司的企业资质信息
    },
    mutations: {
        SET_SELF_COMPANY_INFO(state, payload) {
            state.selfCompanyInfo = payload;
        },
        SET_SELF_CERTIFICATE(state, payload) {
            state.selfCertificate = payload;
        }
    },
    actions: {},
    modules: { company, negotiationMange, account, conference, booth, talentCenter, dealManagement, Inquiry, talent, order }
}
