export default {
    actions: {
        async getInquiryList(contact, params) {//发盘列表
            let { data } = await axios.post("inquiry/api/my_inquiry/list", params);
            return data;
        },
        async delInquiry(contact, params) {//删除发盘
            let { data } = await axios.get("inquiry/api/inquiry/delete", { params });
            return data;
        },
        async inquiry_updataStatus(context, params) { //更新询盘状态
            let { data } = await axios.post('inquiry/api/inquiry_status/update', params);
            return data;
        },
        async inquiry_consignorEvaluation(content, params) {
            //写评价
            let { data } = await axios.post("inquiry/api/inquiry_evaluation/save", params);
            return data;
        },
        async inquiry_searchConsignor(content, params) {//货主对货代评价
            let { data } = await axios.get("inquiry/api/inquiry_evaluation/detail", { params })
            return data
        },
        async inquiry_evaluationList(content, params) {//我的询盘评价列表
            let { data } = await axios.post("inquiry/api/inquiry_comment/list", params)
            return data
        },
        async inquiry_inquiryDetail(context, params) {
            let { data } = await axios.get('inquiry/api/inquiry/detail', { params })
            return data;
        },
        async quotation_info_list(context, params) { // 我的报价-销售
            let { data } = await axios.post('freight/api/quotation/query', params)
            return data;
        },
        async offer_info(context, params) { // 我的报价--详情
            let { data } = await axios.get('freight/api/offer/info', {params})
            return data;
        },
        async addBaseInfo(context, params) { //备注
            let { data } = await axios.post('freight/api/update/quotation', params);
            return data;
        },
        async quotation_visit_query(context, params) { // 访问记录列表
            let { data } = await axios.post('new_freight/api/quotation_visit/query', params)
            return data;
        },
        async quotation_share_query(context, params) { // 我的报价详情
            let { data } = await axios.post('new_freight/api/quotation_share/query', params)
            return data;
        },
        async get_fee(context, params) { // 费用查询
            let { data } = await axios.post('freight/api/get_fee', params)
            return data;
        },
        async check_unno(context, params) { // 检查unno 是否正确
            let { data } = await axios.get('freight/api/check/unno', {params})
            return data;
        },
        async new_freight_detail(context, params) { // 获取报价 信息
            let { data } = await axios.post('freight/api/new_freight/detail', params)
            return data;
        },
        async offer_save(context, params) { // 获取报价 信息
            let { data } = await axios.post('freight/api/offer/save', params)
            return data;
        },
        async sell_inquiry(context, params) { // 销售端--客户询价 列表
            let { data } = await axios.post('freight/api/sell_inquiry', params)
            return data;
        },
        async particulars(context, params) { // 销售端--客户询价 详情
            let { data } = await axios.post('freight/api/sell_inquiry/particulars', params)
            return data;
        },
        async res_to_inquiry_save(context, params) { // 销售端-- 被动报价
            let { data } = await axios.post('freight/api/res_to_inquiry/save', params)
            return data;
        },
        async getcurrencyList(context,params) {                 //币种列表
            let { data } = await axios.post('admin_freight/api/currency/info', params);
            return data;
        },
        async getMeasureUnitList(context,params) {                 //计量单位列表
            let { data } = await axios.post('admin_freight/api/measure_unit/list', params);
            return data;
        },
        async within_warehouse(context,params) {                 //内装仓库
            let { data } = await axios.post('booking/api/within_warehouse/query', params);
            return data;
        },
        async sync_warehouse(context,params) {                 //同步进仓信息
            let { data } = await axios.get('booking/api/sync_warehouse/query', {params});
            return data;
        },
        async self_warehouse_no(context,params) {                 //同步进仓--更新私有进仓编号
            let { data } = await axios.post('booking/api/save/self_warehouse_no', params);
            return data;
        },
        async freight_share_info(context,params) {                 //运价分享列表
            let { data } = await axios.post('new_freight/api/freight_share/info', params);
            return data;
        },
        async freight_share_detail(context,params) {                 //运价分享详情
            let { data } = await axios.post('new_freight/api/freight_share/detail', params);
            return data;
        },
        async word_info(context,params) {                 //PDF
            let { data } = await axios.get('booking/api/business_order/word_info', {params});
            return data;
        },
        async my_bill_list(context,params) {                 //我的账单列表
            let { data } = await axios.post('finance/api/bill/list', params);
            return data;
        },
        async my_bill_details(context,params) {                 //我的账单详情
            let { data } = await axios.get('finance/api/bill/details', {params});
            return data;
        },
        async confirm_fba_fee(context,params) {                 //账单费用确认
            let { data } = await axios.post('finance/api/confirm/bill_fba_fee', params);
            return data;
        },
        async cooperative_client_list(context,params) {                 //玉漭  合作客户查询
            let { data } = await axios.post('user/api/cooperative/client_list', params);
            return data;
        },
        async user_getOtherUserInfo(context, params) { // 通过 userID 获取用户信息
            let { data } = await axios.get('user/api/userinfo', { params });
            return data;
        },
        async user_saveOtherUserInfo(context, params) {           // 通过 userID 保存用户个人信息
            let { data } = await axios.post('user/api/info/save', params);
            return data;
        },
        async getSeaBasicInfo(context, params) {                    //海运单订单 - 基本信息
            let { data } = await axios.get('booking/api/fba_order_details/query', { params });
            return data;
        },
        async getSeaContainerList(context, params) {           // 海运订单 整箱或者拼箱主票下的箱信息列表
            let { data } = await axios.post('booking/api/container/query', params);
            return data;
        },
        async getBookingbusinessOrderFilesList(context, params) {                   //海运订单 - 已成单 - 文件信息
            let { data } = await axios.get('booking/api/order_file/query', {params} );
            return data;
        },
        async seaOrderFileAdd(context, params) {                   //海运订单 - 已成单 - 文件信息上传
            let { data } = await axios.post('booking/api/order_file/add', params );
            return data;
        },
        async seaOrderFileDelete(context, params) {                   //海运订单 - 已成单 - 文件信息删除
            let { data } = await axios.post('booking/api/order_file/deleted', params );
            return data;
        },
        async getInteriorWarehouse(context, params) {                 
            let { data } = await axios.get('booking/api/warehouse_interior/details', {params} );
            return data;
        },
        async getSeaSpecialGoods(context, params) {                   //海运订单 - 特殊货物列表
            let { data } = await axios.get('booking/api/special_goods/query', {params} );
            return data;
        },
        async getSeaSpecialGoodsDetail(context, params) {                   //海运订单 - 特殊货物详情
            let { data } = await axios.get('booking/api/special_goods/details', {params} );
            return data;
        },
        async getSeaLadingDetail(context, params) {                   //海运订单 - 提单查询
            let { data } = await axios.get('booking/api/fba_lading_bill/details', {params} );
            return data;
        },
        async getSeaWordInfo(context, params) {                   //海运订单 - 提单查询
            let { data } = await axios.get('booking/api/sea_order/word_info', {params} );
            return data;
        },
        async getSeaBillList(context, params) {                   //海运订单 - 获取订单下的账单
            let { data } = await axios.get('booking/api/sea_order/bill_list', {params} );
            return data;
        },
        async getSeaNewestSopstatus(context, params) {                   //海运订单 - sop状态列表，显示最新的
            let { data } = await axios.get('booking/api/sea_order/newest_sop_status', {params} );
            return data;
        },
        async getSeaSopstatus(context, params) {                   //海运订单 - sop状态列表，显示全部的
            let { data } = await axios.get('booking/api/fba_order_sop/query', {params} );
            return data;
        },
        async getSeaFeeList(context, params) {           // 海运订单 费用列表
            let { data } = await axios.post('booking/api/fba_fee/query', params);
            return data;
        },
        async getFeeTotalPrices(context, params) {           // 海运订单 查询订单对应费用总和
            let { data } = await axios.get('booking/api/fba_fee/total_prices', {params});
            return data;
        },
        async ladingBillUpdate(context, params) {           // 海运订单 提单确认
            let { data } = await axios.post('booking/api/lading_bill/update', params);
            return data;
        },
        async seaApplyPullOrder(context, params) {           // 海运订单 申请放单
            let { data } = await axios.post('booking/api/order/apply_release', params);
            return data;
        },
        async seaFeeRemarksUpdate(context, params) {           // 海运订单 账单 费用备注编辑
            let { data } = await axios.post('booking/api/fee_remarks/update', params);
            return data;
        },
        async seaBillFeeConfirm(context, params) {           // 海运订单 账单 费用确认
            let { data } = await axios.post('finance/api/confirm/user_order_fee', params);
            return data;
        },
    }
}