<template>
  <div class="alImage-Components">
    <el-image
      :src="src"
      :fit="fit"
      :alt="alt"
      :referrerPolicy="referrerPolicy"
      :lazy="lazy"
      :scroll-container="scrollContainer"
      :preview-src-list="previewSrcList"
      :z-index="zIndex"
    >
      <div slot="placeholder" class="image-loading-slot">
        <i class="el-icon-loading"></i>
      </div>
      <div slot="error" class="image-error-slot">
        <img
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/imageError.png"
          alt=""
        />
      </div>
    </el-image>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    fit: {
      type: String,
      default: "scale-down",
    },
    alt: {
      type: String,
      default: "",
    },
    referrerPolicy: {
      type: String,
      default: "",
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    scrollContainer: {
      default: "",
    },
    previewSrcList: {
      type: Array,
      default: null,
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
  },
};
</script>

<style scoped lang="less">
.alImage-Components {
  width: 100%;
  height: 100%;
  position: relative;
  .el-image {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    .image-loading-slot,
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
      .el-icon-loading {
        font-size: 22px;
        color: #999;
      }
    }
  }
}
</style>