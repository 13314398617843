export default{
  namespaced:true,
  actions:{
    async getNewPerson(context, params){//获取新加入的公司
      let {data}=await axios.post('company/api/catalog_new_company/list', params)
      return data
    },
    async getNewCompany(context, params){//获取新加入的成员
      let {data}=await axios.post('company/api/catalog_new_person/list', params)
      return data
    },
    async getCompanyList(context, params){//企业名录列表
      let {data} =await axios.post('company/api/list/whole',params)
      return data
    },
    async getMemberList(context, params){//会员名录（联系人列表）
      let {data} =await axios.post('siffa/api/member/list',params)
      return data
    },
    async companyInvite(context, params){//1V1预约
      let {data}=await axios.post('daily_chat/api/daily_one2one_chat/invite',params)
      return data
    },
    async siffaCompanyList(context, params){//公司名联想提示
      let {data}=await axios.post('siffa/api/siffa_company/list',params)
      return data
    },
    async favoritesCompany(context, params){//公司收藏
      let {data}=await axios.post('siffa/api/siffa_collection/save',params)
      return data
    },
    async getCompanyHeadInfo(context, params){//  获取企业头部信息
      let { data } = await axios.post('siffa/api/company_info', params)
      return data
    },
    async getCompanyBrief(context, params){//  获取企业介绍
      let { data } = await axios.get('company/api/company/info', { params })
      return data
    },
    async getCompanyCertificate(context, params){//  获取企业证书照
      let { data } = await axios.post('company/api/company_certificate/list', params)
      return data
    },
    async getCompanyBusiness(context, params){//  获取企业业务信息
      let { data } = await axios.get('company/api/company_business/info',{ params })
      return data
    },
    async getCommentList(content, params) {//企业评价列表
      let {data} = await axios.post("company/api/company_comment", params)
      return data
    },
    async addComment(content, params){//新增评论
      let {data}=await axios.post("company/api/company_comment/save",params)
      return data
    },
    async deleteMyComment(context, params){//删除我的评论
      let {data}=await axios.post("company/api/company_comment/del", params)
      return data
    },
    async getVideoList(context, params){//公司视频图片
      let {data}=await axios.get('media/api/video_and_picture/list',{params})
      return data
    },
    async getAccountList(context, params){//公司成员信息
      let {data}=await axios.post('company/api/company_account/list',params)
      return data
    },
    async getCompanyCreditList(context, params){//获取信用评价数据
      let {data} =await axios.post('siffa/api/credit_evaluation/list',params)
      return data
    },
    async getHistoryCreditList(context, params){//获取历史信用评价数据
      let {data}=await axios.post("siffa/api/credit_evaluation_history/list",params)
      return data
    },
    async basicInfoCompanyComplete(context, params){//basicInfo公司名联想提示
      let {data}=await axios.post("company/api/filter/list_lite",params)
      return data
    },
    async getNewCompanyAndPerson(content, params){//新加入成员和新企业
      let {data}=await axios.post("siffa/api/siffa_new_company/list",params)
      return data
    },
    async getCompanCityList(content, params){//上海货代协会-会员公司所在城市列表
      let {data}=await axios.get(`siffa/api/siffa_company_vip_city/list?source=${params.source}`)
      return data
    },
    async getCreditCityList(content, params){//上上海货代协会-信用等级所在城市列表
      let {data}=await axios.get(`siffa/api/siffa_credit_city/list`)
      return data
    },
    async association_video_List(content,params){//物贸学院-协会视频列表
      let { data }=await axios.post('media/api/association_video/list',params)
      return data
    },
    async play_count_Add(content,params){//点击协会视频-增加播放次数
      let { data }=await axios.post('media/api/play_count/add',params)
      return data
    },
    async association_video_Like(content,params){//协会视频-收藏、取消收藏
      let { data }=await axios.post('media/api/association_video/like',params)
      return data
    },
    async check_video_SC(context, params){//检查是否收藏过视频
      let {data}=await axios.get('media/api/association_video_like/check',{params})
      return data
    },
    async add_video_comment(context, params){//协会视频-添加评论接口
      let {data}=await axios.post('media/api/association_video_comment/add',params)
      return data
    },
    async association_video_comment(context, params) {// 协会视频评论列表
      let { data } = await axios.post("media/api/association_video_comment/list",params);
      return data;
    },
    async DEL_video_comment(context, params) {// 协会视频评论删除接口
      let { data } = await axios.post("media/api/association_video_comment/update",params);
      return data;
    },
    async checkNewCompanyList(context, params){//新加入公司
      let {data}=await axios.post('siffa/api/top_three_company/list',params)
      return data
    },
    async getBusinessList(context, params){//优势业务
      let {data}=await axios.get('https://api.ailaworld.com/basic/tag/optional/list',{params})
      return data
    },
    async getCompanyList(context, params){//公司列表
      let {data}=await axios.post('company/api/list/whole',params)
      return data
    },
    async getEcode(context, params){//二维码
      let {data}=await axios.post('wechat/api/generate_rq_code',params)
      return data
    },
    async companyApply(context, params) {// 申请入会
      let { data } = await axios.post("company/api/company_apply",params);
      return data;
    },
    async gatherDemandInvestigation(context, params) {// 需求调查
      let { data } = await axios.post("company/api/gather_demand_investigation",params);
      return data;
    },
  },
}
