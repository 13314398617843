import Vue from 'vue'
import baseStore from '~/baseStore'
import baseConsole from '~bac/store'
import booth from './API_booth'
import register from './API_register'


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        meeting_id: 67,
        enrollInfo: {},
        isApplyShow: false,
        talkDuration: 0,
    },
    mutations: {
        SET_ENROLL_INFO(state, payload) {
            state.enrollInfo = payload;
        },
        SET_ISAPPLY_SHOW(state, payload) {
            state.isApplyShow = payload;
        },
        SET_TALK_DURATION(state, payload) {
            state.talkDuration = payload;
        }
    },
    actions: {

    },
    modules: { baseStore, baseConsole, booth, register }
})

export default store
