export default {
  meetingBrief1_1: '“Global Logistics Enterprise Development Expo”',
  meetingBrief1_2: 'is an influential event in the logistics industry and covers related derivative industries. It will be held by two major Internet logistics platforms- JCtrans and AILA. The high-end international exhibition brand bred by the United. This "exposition" will adopt the parallel mode of ',
  meetingBrief1_3: '“online + offline”',
  meetingBrief1_4: 'and continue the face-to-face direct dialogue between exhibition companies, exhibition companies and intended customers, to help exhibition companies expand contacts, accumulate resources, promote mutual understanding, and find Cooperate with business opportunities, increase the influence of the exhibition business, provide direction guidance for the development of the industry, and build a platform for win-win cooperation between enterprises.',
  meetingBrief2_1: ' This "Expo" will break the limitations of time and space through',
  meetingBrief2_2: ' domestic brand exhibition areas, overseas exhibition areas, boutique special matchmaking, one-to-one meetings, free talk,',
  meetingBrief2_3: ' and other forms to strengthen industry cooperation and contribute to the overall environmental development of the industry and the company itself Operation and expansion provide good opportunities to encourage people in the industry to exchange experiences, gain insight into customer needs, and achieve win-win and development in the industry. A welcome dinner will be held on the day of the opening ceremony, which can put aside the boring and hustle and bustle of daily work, and discuss new opportunities for corporate development with industry elites in a relaxed and pleasant environment, and provide a communication platform for all participants.',
  hotExhibitorTitle1: 'On-site exhibition area + online cloud exhibition',
  hotExhibitorTitle2: 'Exquisite Matchmaking Conference',
  hotExhibitorTitle3: 'Smart e-business card',
  hotExhibitorTitle4: 'Red package activity',
  hotExhibitorConent1: '"On-site exhibition area + online cloud exhibition" goes in parallel. Many domestic brand booths and overseas mini booths will be set up at this expo to build a cooperative promotion platform for global freight forwarders, help participating companies quickly establish a good brand image, and use booths to promote , To provide a good opportunity for the company\'s own operation and business development. At the same time, an online cloud exhibition will be set up through the official website of the Expo to build online cloud booths for enterprises, and online video negotiations, breaking through the limitations of time and space, and achieving win-win and development of the industry.',
  hotExhibitorConent2: 'The introduction of the “Exquisite Matchmaking Conference” is another masterpiece of this expo. Industry experts and shipping veterans will be invited to share new ideas and new measures for industry development, and discuss ways to revitalize the logistics industry. It will also target the global logistics industry. Interpretation of hot topics, latest national policies and analysis of classic cases.',
  hotExhibitorConent3: 'Smart e-business card, which can set up personalized content, add mutiple display forms such as pictures, articles, audio and video, so that you can show yourself in all-round way.Smart business cards can be directly forwarded to WeChat through small programs for sharing, and can be shared by generating business card posters;One-click exchange easily, visting online and offline exhibition, no need to worry about missing business opportunities.',
  hotExhibitorConent4: 'Red package activity, vouchers will be used as a preferential carrier to replace the traditional offline coupon mode. Through the O2O marketing scene, allowing users to visit the exhibition - get coupons/cash red package - using offline;To attract more online visitors to increase booth traffic, and improve offline business transaction volume, but also provide discount to exhibitors for mobilizing the enthusiasm of the exhibitors.',
  supportUnit1: '排名不分先后',
  supportUnit2: 'QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF',
  supportUnit3: 'QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF',
  supportUnit4: 'Multi Plus Alliance ',
  supportUnit5: 'Di&DA',
  supportUnit6: 'FUDA ALLIANCE',
  supportUnit7: 'Captain Club',
  supportUnit8: 'Ningbo Cross-border E-commerce Association',
  supportUnit9: 'LY',
  supportUnit10: 'Shanghai International Freight Forwarders Association',
  supportUnit11: 'TIANJIN INTERNATIONAL FORWARDERS ASSOCIATION',
  supportUnit12: 'Wuhan Freight Logistics Agency Association',
  supportUnit13: 'ZHONGSHAN FREIGHT FORWARDERS ASSOCIATION',
  supportUnit14: 'Ningo Air Frright Association',
  jsCodeText: 'or searching for 2021 Logistics Expo on WeChat to enter the official mini program of World Expo 2021',
  copyrightText: 'Copyright © 2021 Global Logistics Enterprise Development Expo. All Rights Reserved',
  exhibitorServe1TitleBrif1: 'Online display and docking management of exhibitors',
  exhibitorServe1TitleBrif2: 'Online promotion for exhibitors',
  exhibitorServe1TitleBrif3: 'Online red package, booth promotion and publicity',
  exhibitorServeText1: 'Register/Login Console',
  exhibitorServeText2: 'Your Company Details',
  exhibitorServeText3: 'Open Live Broadcast',
  exhibitorServeText4: 'Send Voucher',
  exhibitorServeTitle1:  'How to register and log in the account',
  exhibitorServeTitle2:  'How to manage the online booth',
  exhibitorServeTitle3:  'How to set up activity on booth',
  sponsorChanceMoneyRMB: '国内价格（人民币）',
  sponsorTableText1_1: 'Homepage advertisement;',
  sponsorTableText1_2: 'Exhibition hall top advertising;',
  sponsorTableText1_3: '2 online booths ranked first in the exhibition hall;',
  sponsorTableText1_4: '1 PC booth;',
  sponsorTableText1_5: '5 seats;',
  sponsorTableText1_6: 'A live promotion；',
  sponsorTableText1_7: 'Senior executives of the enterprise accept an interview;',
  sponsorTableText1_8: 'Exclusive title sponsor report: mainstream media of the industry website. Push news articles, WeChat public account, Moments,Multi-channel social media promotion such as Douyin and Weibo;',
  sponsorTableText1_9: 'Become the exclusive sponsor of the exhibition online and offline, and organize. Party simultaneous exposure;',
  sponsorTableText1_10: 'The official announcement of the opening of the conference website: 3 days before the conference. At the end of the meeting, the advertising space is promoted;',
  sponsorTableText1_11: '1 standard booth (including all rights and interests of the booth);',
  sponsorTableText1_12: '1P free color insert;',
  sponsorTableText1_13: 'The main table of the dinner for 2 people will be seated, and enjoy VIP throughout the exhibition. Distinguished treatment;',
  sponsorTableText1_14: 'Free 2 regular-priced tickets;',
  sponsorTableText2_1: '仅限海外客户购买',
  sponsorTableText2_2: 'As the exclusive sponsor for overseas exhibition areas, it will be exposed simultaneously with the organizer.',
  sponsorTableText2_3: 'Exclusive titled business report: The mainstream media of the industry website pushes news articles, WeChat public account, Moments, Tik tok, Weibo and other multi-channel social media promotion.',
  sponsorTableText2_4: 'Exclusive mini booth at the exhibition, unique corporate logo (including all rights and interests of the mini booth). Realize timely negotiation between domestic and overseas agents through the video conference system, with novel forms and unique creativity.',
  sponsorTableText2_5: 'Priority ranking of overseas exhibition areas on the conference website.',
  sponsorTableText2_6: 'The banner picture on the homepage of the conference\'s official website will be displayed throughout the exhibition, and the booth entrance will be hung.',
  sponsorTableText2_7: 'The publicity effect covers the entire overseas exhibition area',
  sponsorTableText3_1: 'Have exclusive sponsor rights for the grand welcome dinner',
  sponsorTableText3_2: 'During the banquet, companies are entitled to 5 minutes of speech time',
  sponsorTableText3_3: '3 minutes of corporate promotional video during the complimentary dinner',
  sponsorTableText3_4: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition',
  sponsorTableText3_5: 'Hosting the dinner party to broadcast corporate advertising',
  sponsorTableText3_6: 'More than 50 industry media follow up reports',
  sponsorTableText3_7: 'Free 1 standard booth (including all rights and interests of the booth)',
  sponsorTableText3_8: 'Free conference catalogue color insert 1p',
  sponsorTableText4_1: '（含制作）/组',
  sponsorTableText4_2: '/Set',
  sponsorTableText4_3: 'There are 2 in each group, located in the high-altitude position of the exhibition hall, and you will be greeted immediately after entering the exhibition hall (size according to the actual size of the venue).',
  sponsorTableText4_4: '1p of color insert of the conference catalogue.',
  sponsorTableText4_5: 'Free 2 regular-price tickets for the exhibition (including all rights and interests of the tickets) (for overseas customers, free online cloud booths)',
  sponsorTableText4_6: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition',
  sponsorTableText4_7: '$4500/set (including production)',
  sponsorTableText5_1: '（含设计及制作）',
  sponsorTableText5_2: 'The 6m*4m super large advertisement at the entrance of the exhibition will be displayed throughout the exhibition or 2 days, with wide coverage and high attention。',
  sponsorTableText5_3: '2 free tickets for the exhibition (including all rights and interests of the tickets)',
  sponsorTableText5_4: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP honorable treatment throughout the exhibition.',
  sponsorTableText5_5: ' $4500 (including design and production)',
  sponsorTableText6_1: '$4500 (including design and production)',
  sponsorTableText6_2: 'All paid participants will be issued a set.',
  sponsorTableText6_3: 'Full printing of the sponsor\'s logo.',
  sponsorTableText6_4: 'It is of great commemorative significance, long use period, and lasting brand display effect.',
  sponsorTableText7_1: 'The size is 30cm*10cm*40cm',
  sponsorTableText7_2: 'Professional tailor-made, high-quality and beautifully printed LOGO, company name,etc.',
  sponsorTableText7_3: 'One paid customer to participate in the exhibition, the exhibition mobile advertising display platform, enhance brand influence.',
  sponsorTableText7_4: 'The sponsor\'s display board displays the company\'s LOGO throughout the process to expand the company\'s reputation.',
  sponsorTableText8_1: '5 pairs (10), placed on both sides of the entrance of the exhibition hall, size: 5M high, single-sided printing cloth: 1.2m wide, 3.5m high',
  sponsorTableText8_2: '1p of color inserts for the conference catalogue',
  sponsorTableText8_3: 'Free 1 regular-priced ticket (including all rights and interests of the ticket)。',
  sponsorTableText9_1: 'T Board Banners in Free talk area',
  sponsorTableText9_2: 'Placed around the free talk area, symmetrically placed at the entrance and on both sides.',
  sponsorTableText9_3: 'To meet the customized needs of enterprises, double-sided printing, three-dimensional display.',
  sponsorTableText9_4: 'Full display of the conference for 2 days',
  sponsorTableText9_5: 'Contains 2 T Board Banners, size: 180cm*80cm.',
  sponsorTableText9_6: '$900 / set',
  sponsorTableText10_1: 'Pros and cons reflect the sponsor’s promotional information.',
  sponsorTableText10_2: 'All participants will wear it throughout the exhibition for 2 days.',
  sponsorTableText10_3: 'Mobile advertising inside and outside the venue.。',
  sponsorTableText10_4: 'Exhibitors and negotiating customers are the only vouchers to enter the venue, with a high usage rate.',
  sponsorTableText10_5: '$1600 (including production)',
  sponsorTableText11_1: 'Full display of company name, LOGO and slogan',
  sponsorTableText11_2: 'All participants will wear it for 2 days during the meeting',
  sponsorTableText11_3: 'Mobile advertising inside and outside the venue',
  sponsorTableText11_4: '$1600 (including production)',
  sponsorTableText12_1: 'Exhibitors will issue a total of 3 dining vouchers for 2 days of lunch and 1 day of dinner.',
  sponsorTableText12_2: 'Corporate information such as company name and logo will be displayed on the meal coupons',
  sponsorTableText12_3: 'The only voucher for dining during the exhibition, with high exposure',
  sponsorTableText13_1: 'Sponsors can deliver the souvenirs printed with corporate LOGO and advertisements to the organizing committee and distribute them to participating customers. They can also customize exquisite souvenirs according to the needs of sponsors to show the company\'s strength and brand value and expand its influence.',
  sponsorTableText14_1: 'Colored advertising on the conference brochure',
  sponsorTableText14_2: '元/P',
  sponsorTableText14_3: '封一，封二，封底',
  sponsorTableText14_4: '（含制作费）',
  sponsorTableText14_5: '1000 copies',
  sponsorTableText14_6: 'Exquisite color printing of the conference catalog.',
  sponsorTableText14_7: 'The resource integration manual of the exhibition has a high utilization rate and comprehensive information display.',
  sponsorTableText14_8: 'Every paid attendee will have 1 brochure',
  sponsorTableText14_9: '$1600/$1400',
  sponsorTableText15_1: 'Advertisement on schedule board',
  sponsorTableText15_2: 'The organizer will set up at least 3 schedule boards at the entrance of the exhibition and other places that must be passed through to quickly increase attention and traffic',
  sponsorTableText15_3: 'Participating companies must see the display board during the exhibition, and sponsors display information with a wide audience and high exposure rate',
  sponsorTableText16_1: 'Drinking Water Sponsorship',
  sponsorTableText16_2: 'Drinking water during the exhibition, with sponsor information pasted on the bottle body, used for on-site conference service, mobile advertising, a total of 3000 bottles',
  sponsorTableText16_3: 'The organizer will display drinking water at the dinner, special matchmaking meeting, opening ceremony and other links, and the exposure rate is extremely high',
  sponsorTableText16_4: '$3300 (including design and production)',
  sponsorTableText17_1: 'Video Advertising',
  sponsorTableText17_2: 'Located in the core area of the exhibition, 7*3m LED high-definition video shows the corporate image of customers.',
  sponsorTableText17_3: 'Each company\'s promotional video within 2 minutes, totaling 5, LED carousel throughout the conference, very visual effects.',
  sponsorTableText18_1: '1V1 Video Page Banner',
  sponsorTableText18_2: 'Can be displayed next to the 1V1 video negotiation window for all guests (limited to 2)',
  sponsorTableText18_3: 'Limit two ad slots at the top of the video window',
  sponsorTableText18_4: 'Click on the video interface to display ads in real time',
  sponsorTableText18_5: 'Video Advertisement  2250USD,Banner Advertisement   1500USD',
  sponsorTableText19_1: 'Web page pop-up ads (limited to 2 companies)',
  sponsorTableText19_2: 'Enter a large pop-up advertisement on the Expo website and close it manually (limited to 1)',
  sponsorTableText19_3: 'Open the pop-up advertising interface on the homepage of the website',
  sponsorTableText19_4: 'Static display for 5 seconds',
  sponsorTableText20_1: 'Homepage Carousel Banner Ads',
  sponsorTableText20_2: 'Can be displayed on the banner of the Expo homepage (limited to 3)',
  sponsorTableText20_3: 'Expo homepage',
  sponsorTableText20_4: 'Support carousel display',
  sponsorTableText21_1: '1v1 window video ad',
  sponsorTableText21_2: '15 seconds before 1V1 start (limited to 1)',
  sponsorTableText22_1: 'Exhibition hall home page display',
  sponsorTableText22_2: 'Limited to 10 people on the first page',
  sponsorTableText22_3: 'Exhibition hall home page display',
  sponsorTableText22_4: 'Enter the enterprise interface to display',
  sponsorTableText23_1: 'Popular companies recommended',
  sponsorTableText23_2: 'Top three recommended by popular companies',
  sponsorTableText23_3: 'Popular exhibitor blocks on the homepage',
  sponsorTableText23_4: 'Top 3 in homepage progress',
  sponsorTableText24_1: 'Direct to Overseas Page',
  sponsorTableText24_2: 'Middle Banner(Exclusive)',
  sponsorTableText24_3: 'Direct to Overseas page,middle banner',
  sponsorTableText25_1: 'Login/Registration Page',
  sponsorTableText25_2: 'Left Banner(Exclusive)',
  sponsorTableText25_3: 'Login/Registration page,left banner',
  audienceServiceTitleBrif1: 'One-click exchange name card, no partners missed',
  audienceServiceTitleBrif2: 'Visit online booth, get voucher',
  audienceServiceTitleBrif3: 'Check agenda, all activites are here',
  audienceServiceTitle1: 'How to register and log in the account',
  audienceServiceTitle2: 'How to set up name card',
  audienceServiceTitle3: 'How to make 1V1 Meeting',
  audienceServiceText1_1: 'Register/Login Console',
  audienceServiceText2_1: 'Complete name card',
  audienceServiceText3_1: 'Offline 1V1 Meeting',
  audienceServiceText3_2: 'Online 1V1 Meeting',
  exhibitionagendaText1_0: 'Shanghai Convention & Exhibition Center of International Sourcing',
  exhibitionagendaText1_1: 'Set up the Exhibition, Pre-registation',
  exhibitionagendaText1_2: 'Enterprises Envelop Send voucher and red envelope on booth',
  //线上参展 线下参展
  off_qtmp:"Negotiation Ticket",
  stand_qy_1:"● One person for a single special matchmaking meeting is limited to one session.",
  stand_qy_2:"● One information bag (including conference brochure and other materials).",
  stand_qy_3:"● Welcome dinner for 1 person.",
  stand_qy_4:"● Participate in offline one-to-one negotiation, free negotiation.",
  stand_qy_5:"● Participate in offline exhibitions and online cloud exhibition negotiations.",
  stand_qy_6:"● Watch the live broadcast.",
  stand_qy_7:"● Business lunch 1 set/day.",
  wsmcz_title_off:"Benefits of offline exhibition",
  off_gnzq:"Domestic Exhibition Area",
  off_eve:'One to One Meeting',
  off_zxdjh:'Specialized Symposium',
  off_hywy:'Welcome Dinner',
  off_hwzq:'Overseas Exhibition Area',
  off_hwzq_min:'Mini Booth',
  off_czlc:"Online exhibition process",
  on_czlc:"Offline exhibition process",
  off_czfy:"Cost",
  time_before_1_30:"Before 28st, Feb",
  time_after_1_30:"After 28st, Feb",
  off_bzzw:"Standard Booth",
  off_tb_msg1:"● Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket. The hoarding board is designed by the customer, and the lintel board is designed by the organizer and the organizer is responsible for the construction.",
  off_tb_msg2:"● Welcome dinner for 2 person.",
  off_tb_msg3:"● One information bag (including conference brochure and other materials).",
  off_tb_msg4:"● One person for a single special matchmaking meeting is limited to one session.",
  off_tb_msg5:"● Business lunch 2 set/day.",
  off_tb_msg6:"● Including an online cloud booth.",
  off_tb_msg7:"● Starting at 3 standard booths.",
  off_tb_msg8:"● Two roll-up banners, or one TV.",
  off_tb_msg9:"● Two TVs for 5 booths.",
  off_tb_msg10:"● Two Spotlight 2. TV monitor 1. Notebook computer 1. Headset 1. Seat 1.",
  off_tb_msg11:"● 200 copies of promotional materials are printed for free and placed on the booth for customers visiting the exhibition to receive. If additional quantity is required, additional printing fees will be charged based on actual costs.",
  off_tb_msg12:"● Including 4 exhibition tickets, 2 dinner places, 2 special places, 8 business lunches.",
  off_tb_msg13:"● Including an online cloud booth.",
  off_tb_msg14:"● With a large space of 36 square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.",
  off_xhxxzt:"Association offline exhibition booth",
  off_cwzzs:"Become a sponsor",
  off_djgm:"Exclusive Sponsor",
  off_qunayi:"Offline",
  off_qunayi_item1:"Senior executives of the enterprise accept an interview.",
  off_qunayi_item2:"Exclusive title sponsor report: mainstream media of the industry website Push news articles, WeChat public account, Moments Multi-channel social media promotion such as Douyin and Weibo; ",
  off_qunayi_item3:"Become the exclusive sponsor of the exhibition online and offline, and organize Party simultaneous exposure;",
  off_qunayi_item4:"Exclusive title sponsor report: mainstream media of the industry website Push news articles, WeChat public account, Moments Multi-channel social media promotion such as Douyin and Weibo; ",
  off_qunayi_item5:"1 standard booth (including all rights and interests of the booth)",
  off_qunayi_item6:"1P free color insert",
  off_qunayi_item7:"The main table of the dinner for 2 people will be seated, and enjoy VIP throughout the exhibition Distinguished treatment",
  off_qunayi_item8:"Free 2 regular-priced tickets;",
  on_quanyi:"Online",
  on_quanyi_item1:"Homepage advertisement",
  on_quanyi_item2:"Exhibition hall top advertising",
  on_quanyi_item3:"2 online booths ranked first in the exhibition hall",
  on_quanyi_item4:"1 PC booth",
  on_quanyi_item5:"5 seats",
  on_quanyi_item6:"A live promotion",
  off_hwzq_title:"Sponsor of Overseas Exhibition",
  off_hwzq_tiem1:"As the exclusive sponsor for overseas exhibition areas, it will be exposed simultaneously with the organizer.",
  off_hwzq_tiem2:"Exclusive titled business report: The mainstream media of the industry website pushes news articles, WeChat public account, Moments, Tik tok, Weibo and other multi-channel social media promotion.",
  off_hwzq_tiem3:"Exclusive mini booth at the exhibition, unique corporate logo (including all rights and interests of the mini booth). Realize timely negotiation between domestic and overseas agents through the video conference system, with novel forms and unique creativity.",
  off_hwzq_tiem4:"Priority ranking of overseas exhibition areas on the conference website.",
  off_hwzq_tiem5:"The banner picture on the homepage of the conference's official website will be displayed throughout the exhibition, and the booth entrance will be hung.",
  off_hwzq_tiem6:"The publicity effect covers the entire overseas exhibition area",
  off_wygm_title:"Sponsor for Welcome dinner",
  off_wygm_item1:"Have exclusive sponsor rights for the grand welcome dinner",
  off_wygm_item2:"During the banquet, companies are entitled to 5 minutes of speech time",
  off_wygm_item3:"3 minutes of corporate promotional video during the complimentary dinner",
  off_wygm_item4:" The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition",
  off_wygm_item5:"Hosting the dinner party to broadcast corporate advertising",
  off_wygm_item6:"More than 50 industry media follow up reports",
  off_wygm_item7:"Free 1 standard booth (including all rights and interests of the booth)",
  off_wygm_item8:"Free conference catalogue color insert 1p",
  wsmbmxszh:"Benefits of online exhibition",
  wsmbmxszh_msg1:`Offline PC booth (mini booths), due to prevention and control of covid-19 and the trend of digital network intelligence, it adopt the new way of "online + offline"to set up offline PC booths, so that overseas exhibitors can meet offline visitors at home, which ensures that international communication to be continued during covid-19 period.`,
  wsmbmxszh_msg2:`Smart e-business card, which can set up personalized content, add mutiple display forms such as pictures, articles, audio and video, so that you can show yourself in all-round way.Smart business cards can be directly forwarded to WeChat through small programs for sharing, and can be shared by generating business card posters;One-click exchange easily, visting online and offline exhibition, no need to worry about missing business opportunities.`,
  wsmbmxszh_msg3:`Red package activity, vouchers will be used as a preferential carrier to replace the traditional offline coupon mode. Through the O2O marketing scene, allowing users to visit the exhibition - get coupons/cash red package - using offline;To attract more online visitors to increase booth traffic, and improve offline business transaction volume, but also provide discount to exhibitors for mobilizing the enthusiasm of the exhibitors.`,
  on_czs:"Exhibitor",
  on_xszt:"Online Booth",
  on_zzfw:"value-added services",
  on_xsffzt:"Paid Online Booth",
  // on_bxhnw:"（不限海内外）",
  on_tb_lin1_1:"● exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  on_tb_mini:"Mini Booth",
  on_tb_hwzh:"(for overseas exhibitor only)",
  // on_tb_jgxj:"价格详见",
  on_tb_xxmp:"Association offline exhibition booth",
  on_tb_line2_content1:"● exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  on_tb_line2_content2:"● When there is no customer in the video call, the customer promotion film, promotion PPT, promotion video, etc. will be displayed, and the materials are provided by the customer.",
  on_tb_line2_content3:"● When exhibiting visitors and overseas customers have conversations, notebooks can log into the booth negotiation system to provide functions such as visual negotiation, text chat, and online business card exchange.",
  on_tb_line2_content4:"● Customer's promotional materials and business cards can be placed for offline use and exchange by exhibitors. (For other offline rights, see 11P: Offline tickets and booths).",
  on_tb_line3_left:"Association offline exhibition booth",
  on_tb_line3_right:"● Independently opened the Association Pavilion online. According to the arrangement of 12 members/page, at least one full page is required to enjoy half price (ie 1000 yuan/booth). The order of arrangement is determined by the association, and the order of registration is by default.",
  on_tb_line4_left:"1v1 negotiation seat",
  on_tb_line4_right:"● Add 1v1 seats in the online booth.",
  on_tb_line5_left:"Pavilion display",
  on_tb_line5_right:"● Add 1 exhibition hall to the corporate booth.",
  on_tb_line6_left:"Booth red envelope",
  // on_tb_line6_center_top:"预充值",
  // on_tb_line6_center_bottom:"￥600RMB",
  on_tb_line6_right:"● Enterprise booths can be set up with cash red envelopes and business red envelopes. The total amount of cash red envelopes is guaranteed to be 600 yuan.",
  on_tb_line7_left:"Keynote Speech",
  on_tb_line7_right:"● Offline speeches can enjoy online live broadcast function, and the organizer will assist in sharing, forwarding, etc.",
  on_become_yzs:"Become a sponsor",
  on_become_item1_lable:"1V1 window video and banner ad",
  on_become_item1_small_1:"Limit two ad slots at the top of the video window",
  on_become_item1_small_2:"Click on the video interface to display ads in real time",
  on_become_item1_small_3_lable:"Video Advertisement",
  on_become_item1_small_3_orange_left:"¥15000 RMB",
  on_become_item1_small_3_orange_right:"$2250 USD",
  on_become_item1_small_4_lable:"Banner Advertisement",
  on_become_item1_small_4_orange_left:"¥10000 RMB",
  on_become_item1_small_4_orange_right:"$1500 USD",
  on_become_item2_lable:"Web pop-up ads",
  on_become_item2_small_1:"Open the pop-up advertising interface on the homepage of the website",
  on_become_item2_small_2:"Static display for 5 seconds",
  on_become_item2_small_3_lable:"Exclusive",
  on_become_item3_lable:"Homepage Carouselbanner",
  on_become_item3_small_1:"Expo homepage",
  on_become_item3_small_2:"Expo homepage",


  Offlinetour_address_tit:"Location",
  Offlinetour_address_dsc:`Shanghai International Sourcing Exhibition Center is located at the southernmost corner of Zhongjiang Road, Changfeng Ecological Business District.The perfect combination of "water, green and architecture", advanced information network system and complete commercial supporting facilities in the area reflect the comprehensive advantages of the modern service industry cluster.`,
  Offlinetour_Taxi:"By taxi",
  Offlinetour_Bus:"Public transport",
  Offlinetour_taxi_left_from1:"Shanghai Pudong International Airport",
  Offlinetour_taxi_left_way1:"is about 55km away from the venue, which takes about 58 minutes with no traffic jams and costs about 196 RMB.",
  Offlinetour_taxi_left_from2:"Shanghai Hongqiao International Airport",
  Offlinetour_taxi_left_way2:"is about 16 kilometers away from the venue, about 30 minutes if there is no traffic jam, about 50 RMB.",
  Offlinetour_taxi_right1:"Shanghai station is about 10 kilometers away from the venue, about 25 minutes if there is no traffic jam, about 33RMB.",
  Offlinetour_taxi_right2:"Shanghai Hongqiao Station is about 16km away from the venue, about 30 minutes if there is no traffic jam, about 50 RMB.",
  Offlinetour_taxi_right3:"Shanghai South Railway Station is about 13km away from the venue, about 28 minutes if there is no traffic jam, about 40 RMB.",
  Offlinetour_bus1:"☉Adjoin to inner ring line, middle ring line, Hongqiao Airport",
  Offlinetour_bus2:"☉Surrounded by rail transit 2, 13, 15 lines",
  Offlinetour_bus3:"☉Regional shuttle buses between the convention center and major bus stops",
  Offlinetour_bus4:"☉Gubei Road Bridge, Qilianshan Road Bridge,Luding Road Bridge across the north and south of Suzhou River,straight to Hongqiao Central Business District",
  Offlinetour_periphery_title:"Surrounding facilities",
  Offlinetour_periphery_item1:"☉Jackie Chan Film Art Museum.",
  Offlinetour_periphery_item2:"☉Within a radius of two kilometers, there are eleven top-grade hotels of four or five stars and more than thirty business and economic hotels.",
  Offlinetour_periphery_item3:"☉2 yacht marinas, 100 yacht berths, Changfeng Park.",
  Offlinetour_periphery_item4:`☉Long scenery side entertainment center, large commercial center "Guosheng Center".`,

  VisitExhibition_left_title:"Offline",
  VisitExhibition_quanyi:"Benefits",

  VisitExhibition_left_item2:"Free Entrance into expo",
  VisitExhibition_left_item3:"Free talk with overseas partners",
  VisitExhibition_left_item4:"Free talk area entrance",
  VisitExhibition_left_item5:"Enjoy online benifit",

  VisitExhibition_right_title:"Online",
  VisitExhibition_right_item1:"Unlimited viewing of the live broadcast",
  VisitExhibition_right_item2:"unlimited access to the booth",
  VisitExhibition_right_item3:"random negotiation",
  VisitExhibition_right_item4:"posting messages",
  VisitExhibition_right_item5:"online business card exchange",
  VisitExhibition_right_item6:"leaving messages only in the free chat area",

  // VisitExhibition_Early_bird:"早鸟票价",
  // time_before_1_31:"1月31日前",
  // VisitExhibition_youhui:"优惠票价",
  // VisitExhibition_gn_price:"国内价格",
  // VisitExhibition_hw_price:"海外价格",
  // VisitExhibition_Signupnow:"立即报名",
  VisitExhibition_Sigfree:"FREE",
  VisitExhibition_weihe_title:"Benefits of exhibition",
  VisitExhibition_Highlights_off_title:"Expo Offline Activities",
  VisitExhibition_Highlights_on_title:"Expo Online Activities",

  VisitExhibition_Highlights_on_item1:"Online Booth",
  VisitExhibition_Highlights_on_item2:"Full Time Live Broadcast",
  VisitExhibition_Highlights_on_item3:"Free Talk Zone",
  VisitExhibition_Highlights_on_item4:"Online Pavilion",
  VisitExhibition_Highlights_on_item5:"Online One to One Meeting",
  czlc_item_1:"Online registration",
  czlc_item_2:"Sponsor confirmation",
  czlc_item_3:"Confirmation of booth payment",
  czlc_item_4:"Online booth design",
  czlc_item_5:"Attend exhibition",
  myExhibitionsummary_p1:'is an influential event in the logistics industry and covers related derivative industries. It will be held by two major Internet logistics platforms- JCtrans and AiLa. The high-end international exhibition brand bred by the United. This "exposition" will adopt the parallel mode of "online + offline", and continue the face-to-face direct dialogue between exhibition companies, exhibition companies and intended customers, to help exhibition companies expand contacts, accumulate resources, promote mutual understanding, and find Cooperate with business opportunities, increase the influence of the exhibition business, provide direction guidance for the development of the industry, and build a platform for win-win cooperation between enterprises.',
  myExhibitionsummary_p2:'This "Expo" will break the limitations of time and space through domestic brand exhibition areas, overseas exhibition areas, boutique special matchmaking, one-to-one meetings, free talk, and other forms to strengthen industry cooperation and contribute to the overall environmental development of the industry and the company itself Operation and expansion provide good opportunities to encourage people in the industry to exchange experiences, gain insight into customer needs, and achieve win-win and development in the industry. A welcome dinner will be held on the day of the opening ceremony, which can put aside the boring and hustle and bustle of daily work, and discuss new opportunities for corporate development with industry elites in a relaxed and pleasant environment, and provide a communication platform for all participants.',
  myExhibitionsummary_p3:'JCtrans Logistics Network was established in 2003 with a registered capital of 100 million yuan, and is committed to becoming a global logistics transaction and settlement service platform. The company has a professional team composed of nearly 300 excellent internet and logistics industry talents to create a global logistics enterprise ecosystem of one million.',
  myExhibitionsummary_p15:'Global Logistics Enterprise Development Expo',
  myExhibitionsummary_p4:'JCtrans will continue to uphold its own corporate culture, adhere to its mission and goals, and empower small and medium logistics companies to become a global logistics transaction and settlement service platform.',
  myExhibitionsummary_p5:'AiLa (AI Logistics Alliance) intelligent international logistics platform is an application platform based on the international logistics field. AiLa is committed to providing global clients in the field of external trade with full-service process for international logistics. Every section in international logistics can be fulfilled digitally through networking and become intelligent. By deconstruction and continuous reconstruction of service sections, a new model of "data intelligence + network collaboration" is provided to global clients.',
  myExhibitionsummary_p6:'Shanghai International Sourcing Exhibition Center is located at 2739 Guangfu West Road, the center of Shanghai Changfeng Ecological Business District, across the river of Hongqiao Development Zone. Beside Changfeng Park and Suzhou River, it is a modern and international convention center integrating exhibitions, conferences, activities and catering. It has a beautiful water and green ecological environment, advanced information network system, complete commercial supporting facilities and convenient subway and bus network.',
  myExhibitionsummary_p7:'The exhibition center has an indoor exhibition hall of 16,000 square meters, a conference hall of 9,000 square meters, a restaurant of 5,000 square meters and an underground garage of 850 parking Spaces, and is equipped with all kinds of advanced and complete facilities. It is the best place for holding all kinds of high-end exhibitions, conferences and activities.',
  my_activity_p1:'1. Enterprise Award: "Popular Logistics  Enterprise" and "Anti-epidemic Contribution Enterprise".',
  my_activity_p2:'2. Individual Awards:  "Anti-Epidemic Hero", "Logistics Princess " and "Logistics Prince".',
  my_activity_p3:'Registration time: from today to 16th,April.',
  my_activity_p4:'Voting time:Mar.29th,2021-Apr. 16th,2021',
  my_activity_p5:'1. Weekly Champion Award: 9 person in total, each person will enjoy individual online live broadcast.',
  my_activity_p6:'2. Popular Champion Award: 3 people in total, the banquet will be presented with a trophy and 3 minutes of speech time.',
  my_activity_p7:'3. Star Enterprise Award: a total of 6 enterprises will be awarded with trophies at the dinner scene, and receive free / 3% discount/half price participation of any one event in 2021 of Aila or JCtrans, and get speech time of 3 minutes.',
  my_activity_p8:'4. Ultimate Grand Prize: The Organizing Committee of the banquet will award the card and get a cash reward of RMB 5,000.',
  my_activity_p9:'1. The participating enterprises automatically sign up for popular logistics enterprises;',
  my_activity_p10:'2. Exhibitors are eligible to recommend individuals, but moderately open up 4 active users, can not be restricted by enterprises to buy booth;',
  my_activity_p11:'Getting 10 votes by logging in the PC terminal platform each day, voting will be available all day;',
  my_activity_p12:'1. From March 29th,2021, the number of individual awards will be counted every week. Before the deadline, 3 weekly champions will be selected for each award, and a total of 9 weekly champions will be awarded "Weekly Champion Award".',
  my_activity_p13:'2. On April 17th,2021., the organizing committee will count the total votes of the three individual awards respectively, and finally get the three champion of popularity who can get the "Champion of popularity award".',
  my_activity_p14:'3. On April 17th,2021., the organizing committee will count the total votes of the two enterprise awards respectively, and the top three of each award, namely 6 enterprises in total, will be awarded the "Star Enterprise Award".',
  my_activity_p15:'4. On April 17th,2021., the organizing committee will conduct a comprehensive voting ranking for the enterprise awards, and the enterprise ranking the first will get the "Exposition Ultimate Grand Award".',
  my_registerSuccess_p1:'Thank you for signing up!',
  my_registerSuccess_p2:'The  exclusive service team  will  contact  you within  2  hours.  Please  wait\tpatiently',
  // 控制台
  replenishInfoText1: 'please choose service, at most 5',
  replenishInfoText2: 'Please choose shipping route, at most 5',
  replenishInfoText3: 'Nothing left',
  replenishInfoText4: 'Not required, at most 3',
  myCopywriting1:"China-ASEAN Online Logistics Meeting is jointly organized by China Fuda Alliance and ASEAN's most extensive freight forwarding organization, HIVE, powered by AILA. This meeting is the first stop of the Belt and Road Global Logistics Online Meeting planned by Fuda Alliance, and it is also the first chapter of Fuda Alliance's strategy to go international. With China's signing of the RCEP agreement, we can expect more and more business and investment opportunities between China and the ASEAN countries. Agreements to reduce tariffs related to trade in goods in a wide range of fields are eye-catching. They will undoubtedly encourage progress in customs procedures, trade facilitation, and rules of origin along with interregional trade. More and more Fuda Alliance members express their demand for the extending business to the East and South Asia. Fuda Alliance will invite its members to participate in this meeting and invite other logistics groups and companies to join this gathering free. Our overseas partners attending this meeting can also find many Carrier booking agents, airline core agents, customs brokers, inside Fuda Alliance or outside.", 
  myCopywriting2:"Fuda Alliance welcomes all forwarders home and abroad to join this event free!",
  myCopywriting3:"Major exhibitors",
  myCopywriting4:"Secretariat：Sammie Li",
  myCopywriting5:"Telephone：0755-82145737",
  myCopywriting6:"Mobile：18128370711",
  myCopywriting7:"Wechat ID：1305270950（Please add account with notes）",
  myCopywriting8:"Email：lisimin@fudafamily.com",
  myCopywriting9:"Fuda Alliance hopes to join hands with all freight forwarders, building a global service network and payment guarantee system, which can greatly accelerate the expansion of Chinese freight forwarder internationally.",
  myCopywriting10:"Online registration",
  myCopywriting11:"Registration meeting",
  myCopywriting12:"Sponsor confirmation",
  myCopywriting13:"Cost",
  myCopywriting14:"About Meeting",
  myCopywriting15:"China-ASEAN Logistics Meeting",
  myCopywriting16:"on-line",
  myCopywriting17:"Agreements to reduce tariffs related to trade in goods in a wide range of fields are eye-catching. They will undoubtedly encourage progress in customs procedures, trade facilitation, and rules of origin along with interregional trade. More and more Fuda Alliance members express their demand for the extending business to the East and South Asia. Fuda Alliance will invite its members to participate in this meeting and invite other logistics groups and companies to join this gathering free. Our overseas partners attending this meeting can also find many Carrier booking agents, airline core agents, customs brokers, inside Fuda Alliance or outside.",
  myCopywriting18:"Fuda Alliance welcomes all forwarders home and abroad to join this event free!",
  myCopywriting19:"Strong Alliance|Abundant Resources",
  myCopywriting22:'“Online Meeting”',
  myCopywriting23:"Fuda Alliance invites its allied logistics group in China, and even practitioners in the logistics industry to participate the online meeting with ASEAN’s biggest forwarder group, Hive. All exhibiters can show their advantages in certain sectors on the booth, advertising their brand and services. Visitors also can find suitable partners to work with by participating the online meeting.Fuda Alliance meeting, provides an excellent stage for international freight forwarder to exchange their view and achieve more cooperation without boundary and time gap.",
  myCopywriting24:"“Strong Alliance”",
  myCopywriting25:"Strong Alliance between Fuda and Hive. China core NVO and ASEAN strongest partners exchange complementary resources，extending service network home and abroad.",
  myCopywriting26:"Quickly understand the trade fair platform",
  myCopywriting27:"WeChat official account",
  myCopywriting28:"版权所有 © 2021中国-东盟10国物流洽谈会（线上） 备案：",
  myCopywriting29:"Online booth design",
  myCopywriting30:"online exhibition",
  myCopywriting31:"You have not yet registered for the China-ASEAN Logistics Meeting",
  myCopywriting56:"Online booth",
  myCopywriting32:"Free",
  myCopywriting33:"exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  myCopywriting34:"Value-added services",
  myCopywriting35:"1v1 negotiation seat",
  myCopywriting36:"Add 1v1 seats in the online booth.",
  myCopywriting37:"Pavilion display",
  myCopywriting38:"Add 1 exhibition hall to the corporate booth.",
  myCopywriting39:"Keynote Speech",
  myCopywriting40:"Offline speeches can enjoy online live broadcast function, and the organizer will assist in sharing, forwarding, etc.",
  myCopywriting41:"Personal application",
  myCopywriting42:"View all exhibitor information, enjoy 1V1 negotiation permissions, free chat area negotiation permissions, publish messages, exchange business cards online, and leave messages",
  myCopywriting43:"Exhibitor Information",
  myCopywriting44:"Exhibitor Information Download",
  myCopywriting45:"All exhibitor information (Electronic version)",
  myCopywriting46:"Advertisement",
  myCopywriting47:"Homepage banner ad",
  myCopywriting48:"Contact the secretariat",
  myCopywriting49:"Top page ad (Only 10 slots)",
  myCopywriting50:"Pavilion places at top ad position",
  myCopywriting51:"Ads to be placed at the top of pavilion page (Only 3 slots)",
  myCopywriting52:"1v1 first 15s ads",
  myCopywriting53:"15s advertisement can be displayed before the start of 1V1 video of all guests (limited to 1)",
  myCopywriting54:"1V1 ad space",
  myCopywriting55:"Ad display during 1v1 video negotiations",
  myCopywriting57:"Fuda Online",
  myCopywriting58:"Fuda online（www.tech-shipping.com） is the world's leading online booking platform, providing users with safe and convenient online booking services. Through digital technology, the booking business is standardized, visualized and intelligent, allowing users to enjoy the efficient office brought by technology. And quality service. When technology meets logistics, booking has never been so convenient. ",
  myCopywriting59:"hotExhibitor",
  myCopywriting60:"1V1 Meeting",
  myCopywriting61:"Video list",
  myCopywriting62:"Update anytime, let’s be easier"
}
