export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/nbkMeeting/WechatIMG612.png',
        bgColor: '#027FFF',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: true,
        notPhone: true,
    },
    basicInfoLocale: "en",//en all
}