export default {
  AbouttheAxpo:"About Expo",
  meetingBrief1_1:"Qingdao International Logistics Expo will be hold by 3 logistics organizations of Qingdao, They are the initiator of this EXPO and they are willing to make a great exhibition. AILA is the undertaker of this EXPO ,we will combine the online and offline contents and fulfill the EXPO with good events. This EXPO will make the oversea`s and Chinese shipping companies motiative again,which will break the ice from covid and make the logistics full of oppotunity.",
  meetingBrief1_2:"",
  big_guster:"POWERFUL GUESTS AND TOP FORUMS",
  big_guster_des:"The organizers of this expo will invite senior experts in the logistics and freight forwarding industry and industry leaders to tap the industry's capital potential and professional potential from the most cutting-edge perspective of the industry.They will explore the exploration and layout of the international logistics era from a digital perspective, reposition the role of the traditional freight forwarding industry, and bring new solutions and industry perspectives to the development of the industry and the regional freight forwarding logistics industry.",
  big_guster_arr_name_1:"Nicolas Tang",
  big_guster_arr_job_1:"Founder of Duckbill",
  // big_guster_arr_zi_1:"Topic: Digital Trailer",
  big_guster_arr_name_2:"Jack Yao",
  big_guster_arr_job_2:"Worldex Group & President",
  big_guster_arr_zi_2:"",
  big_guster_arr_name_3:"James Zhang",
  big_guster_arr_job_3:"AiLa founder & CEO",
  big_guster_arr_zi_3:"Topic: Digital Freight Forwarding",
  big_guster_arr_name_4:"Tony Yuan",
  big_guster_arr_job_4:"Founder of CargoMap",
  big_guster_arr_zi_4:"Topic: Cross-border E-commerce Innovation Platform",
  big_guster_arr_name_5:"Sign up for the Expo now,",
  big_guster_arr_name_6:"and face to face with more celebrities",
  meeting_add:"Qingdao International Convention Center . 4th Hall (Laoshao Area)  Address: 9#, Miaoling Road, Laoshan Area, Qingdao ",
  meeting_model:"ONLINE CLOUD EXHIBITION & OFFLINE COOPERATION  ",
  hotExhibitorTitle1: '“ONLINE CLOUD EXHIBITION--OFFLINE COOPERATION  ”',
  hotExhibitorTitle2: '“LOGISTICS RESEARCH CONFERENCES ”',
  hotExhibitorTitle3: '“THE OVERSEAS BOOTH”',
  hotExhibitorTitle4: '“VIVID LIVES”',
  hotExhibitorConent1: '“The EXPO will combine the online and offline events together.You can get the detailed information from official site and wechat when you are online and you can also get the same information by visiting the booth if interested in offline events.”',
  hotExhibitorConent2:"“The PRC is designed for the exhibitors and participators,which will combine the consulting,policy,hotspot disscusing and independent space for sharing.You can share your opinions with others in any conference and get the other good opinions from the online live. ”",
  hotExhibitorConent3: '“The overseas booth ia an important part of the EXPO,which is designed for the customers who can not participate in the offline events.They can use the EXPO APP to get deal details from EXPO visually.The overseas customers can register and get their own account,then they can arrange their meeting time by application whether online or offline.Also they can discuss or arrange the meeting course with other companies through application.”',
  hotExhibitorConent4: '“The EXPO has it own lives,you can see the lives anytime daylight.And we will also change the course if the participators needs.No matter online or offline,you can get theinformation from the EXPO all the time.”',
  supportUnit1: '排名不分先后',
  supportUnit2: 'QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF',
  supportUnit3: 'QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF',
  supportUnit4: 'Multi Plus Alliance ',
  supportUnit5: 'Di&DA',
  supportUnit6: 'FUDA ALLIANCE',
  supportUnit7: 'Captain Club',
  supportUnit8: 'Ningbo Cross-border E-commerce Association',
  supportUnit9: 'LY',
  supportUnit10: 'Shanghai International Freight Forwarders Association',
  supportUnit11: 'TIANJIN INTERNATIONAL FORWARDERS ASSOCIATION',
  supportUnit12: 'Wuhan Freight Logistics Agency Association',
  supportUnit13: 'ZHONGSHAN FREIGHT FORWARDERS ASSOCIATION',
  supportUnit14: 'Ningo Air Frright Association',
  jsCodeText: '',
  copyrightText:'Copyright © China（Qingdao）International Logistics Expo. All Rights Reserved',
  exhibitorServe1TitleBrif1: 'Online display and docking management of exhibitors',
  exhibitorServe1TitleBrif2: 'Online promotion for exhibitors',
  exhibitorServe1TitleBrif3: 'Online red package, booth promotion and publicity',
  exhibitorServeText1: 'Register/Login Console',
  exhibitorServeText2: 'Your Company Details',
  exhibitorServeText3: 'Open Live Broadcast',
  exhibitorServeText4: 'Send Voucher',
  exhibitorServeTitle1:  'How to register and log in the account',
  exhibitorServeTitle2:  'How to manage the online booth',
  exhibitorServeTitle3:  'How to set up activity on booth',
  sponsorChanceMoneyRMB: '国内价格（人民币）',
  sponsorChanceMoneyUSD:"USD",
  xiangmu:"Item",
  guangchang_qiqiu:"Square balloon",
  guangchang_qiqiu_right1:"All balloons will be set in the center of the square,you can see it directly after you enter the square(and it will be set according to the size of the square.);The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth)and become the VIP and concentration of exhibiton.",
  zhanting_bannner:"Banner of the column in the hall",
  zhanting_bannner_right1:"The banners are located in the hall of exhibition`s columns .The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton. ",
  zhanting_bannner_2:"Advertisement of the column in the hall",
  zhanting_bannner_2_right1:"The banners are located in the hall of exhibition`s columns .The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.",
  pic_shuiying:"Live Image Watermarking",
  pic_shuiying_right1:"Live Image Watermarking; Synchronously displayed on the official website and applet, each displayed picture has an exclusive watermark;",
  guan_qiang_ad:"Wall advertisement in the Museum",
  position_size_money:"Precise quotation according to location and size",
  offLine_luntan:"Offline forum topic sharing",
  offLine_luntan_right1:"In addition to guest speeches and roundtable forums, 10 special speech forums will be set up during the two days of the Expo. Product introductions and corporate promotion can be carried out;",
  offLine_luntan_text:"/25 minutes per session",
  qianting_big_banner:"Huge Advertisements",
  qianting_big_banner_right1:"The Huge advertisements will be showed around the enterance of the exhibition .The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.  Price: 30000RMB per(Advertisement Makings contained);Sell Count: Only two",
  jinzhanguan_men_banner:"Advertising on the entrance",
  jinzhanguan_men_banner_right1:"will be set on the exhibiton enterance doors.You can see it from anywhere in the front hall.The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.",
  nanqiang_LED:"LED screen in south wall of entrance hall",
  nanqiang_LED_right1:"The advertisements and other information will be showed on the LED screen which are located around the enterance of exhibition hall (The size of screen will be set according to the real size of the field)The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.",
  led_maney_rmb:"100/minute 5000/hour",
  led_maney_usd:"15/minute 770/hour",
  m_fu:"/副",
  sponsorTableText1_1: 'Homepage advertisement;',
  sponsorTableText1_2: 'Exhibition hall top advertising;',
  sponsorTableText1_3: '2 online booths ranked first in the exhibition hall;',
  sponsorTableText1_4: '1 PC booth;',
  sponsorTableText1_5: '5 seats;',
  sponsorTableText1_6: 'A live promotion；',
  sponsorTableText1_7: 'Senior executives of the enterprise accept an interview;',
  sponsorTableText1_8: 'Exclusive title sponsor report: mainstream media of the industry website. Push news articles, WeChat public account, Moments,Multi-channel social media promotion such as Douyin and Weibo;',
  sponsorTableText1_9: 'Become the exclusive sponsor of the exhibition online and offline, and organize. Party simultaneous exposure;',
  sponsorTableText1_10: 'The official announcement of the opening of the conference website: 3 days before the conference. At the end of the meeting, the advertising space is promoted;',
  sponsorTableText1_11: '1 standard booth (including all rights and interests of the booth);',
  sponsorTableText1_12: '1P free color insert;',
  sponsorTableText1_13: 'The main table of the dinner for 2 people will be seated, and enjoy VIP throughout the exhibition. Distinguished treatment;',
  sponsorTableText1_14: 'Free 2 regular-priced tickets;',
  sponsorTableText2_1: '仅限海外客户购买',
  sponsorTableText2_2: 'As the exclusive sponsor for overseas exhibition areas, it will be exposed simultaneously with the organizer.',
  sponsorTableText2_3: 'Exclusive titled business report: The mainstream media of the industry website pushes news articles, WeChat public account, Moments, Tik tok, Weibo and other multi-channel social media promotion.',
  sponsorTableText2_4: 'Exclusive mini booth at the exhibition, unique corporate logo (including all rights and interests of the mini booth). Realize timely negotiation between domestic and overseas agents through the video conference system, with novel forms and unique creativity.',
  sponsorTableText2_5: 'Priority ranking of overseas exhibition areas on the conference website.',
  sponsorTableText2_6: 'The banner picture on the homepage of the conference\'s official website will be displayed throughout the exhibition, and the booth entrance will be hung.',
  sponsorTableText2_7: 'The publicity effect covers the entire overseas exhibition area',
  sponsorTableText3_1: 'Have exclusive sponsor rights for the grand welcome dinner',
  sponsorTableText3_2: 'During the banquet, companies are entitled to 5 minutes of speech time',
  sponsorTableText3_3: '3 minutes of corporate promotional video during the complimentary dinner',
  sponsorTableText3_4: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition',
  sponsorTableText3_5: 'Hosting the dinner party to broadcast corporate advertising',
  sponsorTableText3_6: 'More than 50 industry media follow up reports',
  sponsorTableText3_7: 'Free 1 standard booth (including all rights and interests of the booth)',
  sponsorTableText3_8: 'Free conference catalogue color insert 1p',
  sponsorTableText4_1: '（含制作）/组',
  sponsorTableText4_1_01: '（含制作）/对',
  sponsorTableText4_1_02: '/对',
  sponsorTableText4_1_1: '（Including productio）/个',
  sponsorTableText4_1_2: '（含制作 不含设计',
  sponsorTableText4_1_02: ' (Including production, excluding design)/piece',
  sponsorTableText4_1_03: ' Precise quotation by size',
  sponsorTableText4_1_3: '（含制作 不含设计）/组',
  sponsorTableText4_1_4: '（含设计及制作）',
  sponsorTableText4_1_5: '(excluding design)',
  sponsorTableText4_2: '/Set',
  sponsorTableText4_3: 'There are 2 in each group, located in the high-altitude position of the exhibition hall, and you will be greeted immediately after entering the exhibition hall (size according to the actual size of the venue).',
  sponsorTableText4_4: '1p of color insert of the conference catalogue.',
  sponsorTableText4_5: 'Free 2 regular-price tickets for the exhibition (including all rights and interests of the tickets) (for overseas customers, free online cloud booths)',
  sponsorTableText4_6: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition',
  sponsorTableText4_7: '$4500/set (including production)',
  sponsorTableText5_1: '（含设计及制作）',
  sponsorTableText5_2: 'The 6m*4m super large advertisement at the entrance of the exhibition will be displayed throughout the exhibition or 2 days, with wide coverage and high attention。',
  sponsorTableText5_3: '2 free tickets for the exhibition (including all rights and interests of the tickets)',
  sponsorTableText5_4: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP honorable treatment throughout the exhibition.',
  sponsorTableText5_5: ' $4500 (including design and production)',
  sponsorTableText6_1: '$4500 (including design and production)',
  sponsorTableText6_2: 'All paid participants will be issued a set.',
  sponsorTableText6_3: 'Full printing of the sponsor\'s logo.',
  sponsorTableText6_4: 'It is of great commemorative significance, long use period, and lasting brand display effect.',
  sponsorTableText7_1: 'The size is 30cm*10cm*40cm',
  sponsorTableText7_2: 'Professional tailor-made, high-quality and beautifully printed LOGO, company name,etc.',
  sponsorTableText7_3: 'One paid customer to participate in the exhibition, the exhibition mobile advertising display platform, enhance brand influence.',
  sponsorTableText7_4: 'The sponsor\'s display board displays the company\'s LOGO throughout the process to expand the company\'s reputation.',
  sponsorTableText8_1: '5 pairs (10), placed on both sides of the entrance of the exhibition hall, size: 5M high, single-sided printing cloth: 1.2m wide, 3.5m high',
  sponsorTableText8_2: '1p of color inserts for the conference catalogue',
  sponsorTableText8_3: 'Free 1 regular-priced ticket (including all rights and interests of the ticket)。',
  sponsorTableText9_1: 'T Board Banners in Free talk area',
  sponsorTableText9_2: 'Placed around the free talk area, symmetrically placed at the entrance and on both sides.',
  sponsorTableText9_3: 'To meet the customized needs of enterprises, double-sided printing, three-dimensional display.',
  sponsorTableText9_4: 'Full display of the conference for 2 days',
  sponsorTableText9_5: 'Contains 2 T Board Banners, size: 180cm*80cm.',
  sponsorTableText9_6: '$900 / set',
  sponsorTableText10_1: 'Pros and cons reflect the sponsor’s promotional information.',
  sponsorTableText10_2: 'All participants will wear it throughout the exhibition for 2 days.',
  sponsorTableText10_3: 'Mobile advertising inside and outside the venue.。',
  sponsorTableText10_4: 'Exhibitors and negotiating customers are the only vouchers to enter the venue, with a high usage rate.',
  sponsorTableText10_5: '$1600 (including production)',
  sponsorTableText11_1: 'Full display of company name, LOGO and slogan',
  sponsorTableText11_2: 'All participants will wear it for 2 days during the meeting',
  sponsorTableText11_3: 'Mobile advertising inside and outside the venue',
  sponsorTableText11_4: '$1600 (including production)',
  sponsorTableText12_1: 'Exhibitors will issue a total of 3 dining vouchers for 2 days of lunch and 1 day of dinner.',
  sponsorTableText12_2: 'Corporate information such as company name and logo will be displayed on the meal coupons',
  sponsorTableText12_3: 'The only voucher for dining during the exhibition, with high exposure',
  sponsorTableText13_1: 'Sponsors can deliver the souvenirs printed with corporate LOGO and advertisements to the organizing committee and distribute them to participating customers. They can also customize exquisite souvenirs according to the needs of sponsors to show the company\'s strength and brand value and expand its influence.',
  sponsorTableText14_1: 'Colored advertising on the conference brochure',
  sponsorTableText14_2: '元/P',
  sponsorTableText14_3: '封一，封二，封底',
  sponsorTableText14_4: '（含制作费）',
  sponsorTableText14_5: '1000 copies',
  sponsorTableText14_6: 'Exquisite color printing of the conference catalog.',
  sponsorTableText14_7: 'The resource integration manual of the exhibition has a high utilization rate and comprehensive information display.',
  sponsorTableText14_8: 'Every paid attendee will have 1 brochure',
  sponsorTableText14_9: '$1600/$1400',
  sponsorTableText15_1: 'Advertisement on schedule board',
  sponsorTableText15_2: 'The organizer will set up at least 3 schedule boards at the entrance of the exhibition and other places that must be passed through to quickly increase attention and traffic',
  sponsorTableText15_3: 'Participating companies must see the display board during the exhibition, and sponsors display information with a wide audience and high exposure rate',
  sponsorTableText16_1: 'Drinking Water Sponsorship',
  sponsorTableText16_2: 'Drinking water during the exhibition, with sponsor information pasted on the bottle body, used for on-site conference service, mobile advertising, a total of 3000 bottles',
  sponsorTableText16_3: 'The organizer will display drinking water at the dinner, special matchmaking meeting, opening ceremony and other links, and the exposure rate is extremely high',
  sponsorTableText16_4: '$3300 (including design and production)',
  sponsorTableText17_1: 'Video Advertising',
  sponsorTableText17_2: 'Located in the core area of the exhibition, 7*3m LED high-definition video shows the corporate image of customers.',
  sponsorTableText17_3: 'Each company\'s promotional video within 2 minutes, totaling 5, LED carousel throughout the conference, very visual effects.',
  sponsorTableText18_1: '1V1 Video Page Banner',
  sponsorTableText18_2: 'Can be displayed next to the 1V1 video negotiation window for all guests (limited to 2)',
  sponsorTableText18_3: 'Limit two ad slots at the top of the video window',
  sponsorTableText18_4: 'Click on the video interface to display ads in real time',
  sponsorTableText18_5: 'Video Advertisement  2250USD,Banner Advertisement   1500USD',
  sponsorTableText19_1: 'Web page pop-up ads (limited to 2 companies)',
  sponsorTableText19_2: 'Enter a large pop-up advertisement on the Expo website and close it manually (limited to 1)',
  sponsorTableText19_3: 'Open the pop-up advertising interface on the homepage of the website',
  sponsorTableText19_4: 'Static display for 5 seconds',
  sponsorTableText20_1: 'Homepage Carousel Banner Ads',
  sponsorTableText20_2: 'Can be displayed on the banner of the Expo homepage (limited to 3)',
  sponsorTableText20_3: 'Expo homepage',
  sponsorTableText20_4: 'Support carousel display',
  sponsorTableText21_1: '1v1 window video ad',
  sponsorTableText21_2: '15 seconds before 1V1 start (limited to 1)',
  sponsorTableText22_1: 'Exhibition hall home page display',
  sponsorTableText22_2: 'Limited to 10 people on the first page',
  sponsorTableText22_3: 'Exhibition hall home page display',
  sponsorTableText22_4: 'Enter the enterprise interface to display',
  sponsorTableText23_1: 'Popular companies recommended',
  sponsorTableText23_2: 'Top four recommended by popular companies',
  sponsorTableText23_3: 'Popular exhibitor blocks on the homepage',
  sponsorTableText23_4: 'Top 3 in homepage progress',
  sponsorTableText24_1: 'Direct to Overseas Page',
  sponsorTableText24_2: 'Middle Banner(Exclusive)',
  sponsorTableText24_3: 'Direct to Overseas page,middle banner',
  sponsorTableText25_1: 'Login/Registration Page',
  sponsorTableText25_2: 'Left Banner(Exclusive)',
  sponsorTableText25_3: 'Login/Registration page,left banner',
  audienceServiceTitleBrif1: 'One-click exchange name card, no partners missed',
  audienceServiceTitleBrif2: 'Visit online booth, get voucher',
  audienceServiceTitleBrif3: 'Check agenda, all activites are here',
  audienceServiceTitle1: 'How to register and log in the account',
  audienceServiceTitle2: 'How to set up name card',
  audienceServiceTitle3: 'How to make 1V1 Meeting',
  audienceServiceText1_1: 'Register/Login Console',
  audienceServiceText2_1: 'Complete name card',
  audienceServiceText3_1: 'Offline 1V1 Meeting',
  audienceServiceText3_2: 'Online 1V1 Meeting',
  exhibitionagendaText1_0: 'Shanghai Convention & Exhibition Center of International Sourcing',
  exhibitionagendaText1_1: 'Set up the Exhibition, Pre-registation',
  exhibitionagendaText1_2: 'Enterprises Envelop Send voucher and red envelope on booth',
  //线上参展 线下参展
  offLine_tb_1_1:"● viewing of the live broadcast，access to the booth，posting messages，online business card exchange，Welcome Dinner。",
  offLine_tb_1_1_1:"● viewing of the live broadcast，access to the booth，posting messages，online business card exchange。",
  offLine_tb_1_2:"● Attend peak forum，Business lunch 1 set，One information bag。",
  offLine_tb_2_1:"Online：",
  offLine_tb_2_1_offline:"Offline：",
  offLine_tb_2_2:"●As long as you purchase an offline booth, you will get an online booth as a gift. For the rights and interests, please refer to the online paid booth。",
  offLine_tb_2_3:"●Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket,The standard booth fee includes the production and construction of the background board. The background boa rd design is completed by the customer or consulted by the organizer. The design fee is 1,200 yuan per booth (paid separately). The fascia board is designed, made and built by the organizer.",
  offLine_tb_2_4:"●Welcome dinner for 2 person。",
  offLine_tb_2_5:"●Two information bag。",
  offLine_tb_2_6:"●Business lunch 2 set/day。",
  offLine_tb_3_1:"Online：",
  offLine_tb_3_2:"●Companies with special booths will be given one online booth as a gift. For the rights and interests, please refer to the online paid booth.。",
  offLine_tb_3_3:"●One online booth and one top ten exhibition seat in the exhibition hall will be presented as a gift。",
  offLine_tb_3_4:"Offline：",
  offLine_tb_3_5:"● Including 4 exhibition tickets, 2 dinner places, 8 business lunches,4 information bag",
  offLine_tb_3_6:"●With a large space of 36 square meters/72square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.",
  online_sea_qy_1:"●1 online booth。",
  online_sea_qy_2:"●2 participants, accept 24-hour 1-to-1 reservations。",
  online_sea_qy_3:"●The corporate promotional video will be played simultaneously on 30 screens in the overseas exhibition area",
  online_sea_qy_4:"●Corporate leaflets + business cards, 500 sheets each",
  online_sea_names:"Overseas exhibition area (only for overseas customers to purchase)",



  off_qtmp:"Negotiation Ticket",
  stand_qy_1:"● One person for a single special matchmaking meeting is limited to one session.",
  stand_qy_2:"● One information bag (including conference brochure and other materials).",
  stand_qy_3:"● Welcome dinner for 1 person.",
  stand_qy_4:"● Participate in offline one-to-one negotiation, free negotiation.",
  stand_qy_5:"● Participate in offline exhibitions and online cloud exhibition negotiations.",
  stand_qy_6:"● Watch the live broadcast.",
  stand_qy_7:"● Business lunch 1 set/day.",
  wsmcz_title_off:"Benefits of offline exhibition",
  off_gnzq:"Domestic Exhibition Area",
  off_eve:'Free talk area',
  off_zxdjh:'Peak Forum',
  off_hywy:'Welcome Dinner',
  off_hwzq:'Overseas Exhibition Area',
  off_hwzq_min:'Mini Booth',
  off_czlc:"Online exhibition process",
  on_czlc:"Offline exhibition process",
  off_czfy:"Cost",
  time_before_1_30:"Before 28st, Feb",
  time_after_1_30:"After 28st, Feb",
  off_bzzw:"Standard Booth",
  off_tb_msg1:"● Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket. The hoarding board is designed by the customer, and the lintel board is designed by the organizer and the organizer is responsible for the construction.",
  off_tb_msg2:"● Welcome dinner for 2 person.",
  off_tb_msg3:"● One information bag (including conference brochure and other materials).",
  off_tb_msg4:"● One person for a single special matchmaking meeting is limited to one session.",
  off_tb_msg5:"● Business lunch 2 set/day.",
  off_tb_msg6:"● Including an online cloud booth.",
  off_tb_msg7:"● Starting at 3 standard booths.",
  off_tb_msg8:"● Two roll-up banners, or one TV.",
  off_tb_msg9:"● Two TVs for 5 booths.",
  off_tb_msg10:"● Two Spotlight 2. TV monitor 1. Notebook computer 1. Headset 1. Seat 1.",
  off_tb_msg11:"● 200 copies of promotional materials are printed for free and placed on the booth for customers visiting the exhibition to receive. If additional quantity is required, additional printing fees will be charged based on actual costs.",
  off_tb_msg12:"● Including 4 exhibition tickets, 2 dinner places, 2 special places, 8 business lunches.",
  off_tb_msg13:"● Including an online cloud booth.",
  off_tb_msg14:"● With a large space of 36 square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.",
  off_xhxxzt:"Association offline exhibition booth",
  off_cwzzs:"Become a sponsor",
  off_djgm:"Exclusive Sponsor",
  off_qunayi:"Offline",
  off_qunayi_item1:"Senior executives of the enterprise accept an interview.",
  off_qunayi_item2:"Exclusive title sponsor report: mainstream media of the industry website Push news articles, WeChat public account, Moments Multi-channel social media promotion such as Douyin and Weibo; ",
  off_qunayi_item3:"Become the exclusive sponsor of the exhibition online and offline, and organize Party simultaneous exposure;",
  off_qunayi_item4:"Exclusive title sponsor report: mainstream media of the industry website Push news articles, WeChat public account, Moments Multi-channel social media promotion such as Douyin and Weibo; ",
  off_qunayi_item5:"1 standard booth (including all rights and interests of the booth)",
  off_qunayi_item6:"1P free color insert",
  off_qunayi_item7:"The main table of the dinner for 2 people will be seated, and enjoy VIP throughout the exhibition Distinguished treatment",
  off_qunayi_item8:"Free 2 regular-priced tickets;",
  on_quanyi:"Online",
  on_quanyi_item1:"Homepage advertisement",
  on_quanyi_item2:"Exhibition hall top advertising",
  on_quanyi_item3:"2 online booths ranked first in the exhibition hall",
  on_quanyi_item4:"1 PC booth",
  on_quanyi_item5:"5 seats",
  on_quanyi_item6:"A live promotion",
  off_hwzq_title:"Sponsor of Overseas Exhibition",
  off_hwzq_tiem1:"As the exclusive sponsor for overseas exhibition areas, it will be exposed simultaneously with the organizer.",
  off_hwzq_tiem2:"Exclusive titled business report: The mainstream media of the industry website pushes news articles, WeChat public account, Moments, Tik tok, Weibo and other multi-channel social media promotion.",
  off_hwzq_tiem3:"Exclusive mini booth at the exhibition, unique corporate logo (including all rights and interests of the mini booth). Realize timely negotiation between domestic and overseas agents through the video conference system, with novel forms and unique creativity.",
  off_hwzq_tiem4:"Priority ranking of overseas exhibition areas on the conference website.",
  off_hwzq_tiem5:"The banner picture on the homepage of the conference's official website will be displayed throughout the exhibition, and the booth entrance will be hung.",
  off_hwzq_tiem6:"The publicity effect covers the entire overseas exhibition area",
  off_wygm_title:"Sponsor for Welcome dinner",
  off_wygm_item1:"Have exclusive sponsor rights for the grand welcome dinner",
  off_wygm_item2:"During the banquet, companies are entitled to 5 minutes of speech time",
  off_wygm_item3:"3 minutes of corporate promotional video during the complimentary dinner",
  off_wygm_item4:" The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition",
  off_wygm_item5:"Hosting the dinner party to broadcast corporate advertising",
  off_wygm_item6:"More than 50 industry media follow up reports",
  off_wygm_item7:"Free 1 standard booth (including all rights and interests of the booth)",
  off_wygm_item8:"Free conference catalogue color insert 1p",
  wsmbmxszh:"Benefits of online exhibition",
  wsmbmxszh_msg1_1:"The online mini booth is a highlight spot of the EXPO too,which is designed for the overseas customers who can not participate in the offline exhibition.",
  wsmbmxszh_msg1_2:"By using the digitial and intelligence solutions ,all the customers can participate in the events.By using the Intelligentize ID Card ,the customers and show their information precisely and smoothly.Also, the customers can share all of this by using wechat and application,which will get them more advantages.",
  wsmbmxszh_msg1_3:"The host of EXPO will hold the BOOTH VOTING in the exhibition`s earlier stage.This will make more communications company by company and make the companies more motiative.The winner will get a good present from the host at FIRST EXHIBITION NIGHT.",
  online_quyi:"1个展馆展示，2个洽谈席位，企业投票、支持企业自主布展（包括简介、图片、资质、视频展示、设置企业洽谈坐席等），可进行现场直播，可在线上进行一对一预约洽谈等",

  wsmbmxszh_msg2:`Smart e-business card, which can set up personalized content, add mutiple display forms such as pictures, articles, audio and video, so that you can show yourself in all-round way.Smart business cards can be directly forwarded to WeChat through small programs for sharing, and can be shared by generating business card posters;One-click exchange easily, visting online and offline exhibition, no need to worry about missing business opportunities.`,
  wsmbmxszh_msg3:`Red package activity, vouchers will be used as a preferential carrier to replace the traditional offline coupon mode. Through the O2O marketing scene, allowing users to visit the exhibition - get coupons/cash red package - using offline;To attract more online visitors to increase booth traffic, and improve offline business transaction volume, but also provide discount to exhibitors for mobilizing the enthusiasm of the exhibitors.`,
  on_czs:"Exhibitor",
  on_xszt:"Online Booth",
  on_zzfw:"value-added services",
  on_xsffzt:"Paid Online Booth",
  // on_bxhnw:"（不限海内外）",
  on_tb_lin1_1:"● exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  on_tb_mini:"Mini Booth",
  on_tb_hwzh:"(for overseas exhibitor only)",
  // on_tb_jgxj:"价格详见",
  on_tb_xxmp:"Association offline exhibition booth",
  on_tb_line2_content1:"● exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  on_tb_line2_content2:"● When there is no customer in the video call, the customer promotion film, promotion PPT, promotion video, etc. will be displayed, and the materials are provided by the customer.",
  on_tb_line2_content3:"● When exhibiting visitors and overseas customers have conversations, notebooks can log into the booth negotiation system to provide functions such as visual negotiation, text chat, and online business card exchange.",
  on_tb_line2_content4:"● Customer's promotional materials and business cards can be placed for offline use and exchange by exhibitors. (For other offline rights, see 11P: Offline tickets and booths).",
  on_tb_line3_left:"Association offline exhibition booth",
  on_tb_line3_right:"● Independently opened the Association Pavilion online. According to the arrangement of 12 members/page, at least one full page is required to enjoy half price (ie 1000 yuan/booth). The order of arrangement is determined by the association, and the order of registration is by default.",
  on_tb_line4_left:"1v1 negotiation seat",
  on_tb_line4_right:"● Add 1v1 seats in the online booth.",
  on_tb_line5_left:"Pavilion display",
  on_tb_line5_right:"● Add 1 exhibition hall to the corporate booth.",
  on_tb_line6_left:"Booth red envelope",
  // on_tb_line6_center_top:"预充值",
  // on_tb_line6_center_bottom:"￥600RMB",
  on_tb_line6_right:"● Enterprise booths can be set up with cash red envelopes and business red envelopes. The total amount of cash red envelopes is guaranteed to be 600 yuan.",
  on_tb_line7_left:"Keynote Speech",
  on_tb_line7_right:"● Offline speeches can enjoy online live broadcast function, and the organizer will assist in sharing, forwarding, etc.",
  on_become_yzs:"Become a sponsor",
  on_become_item1_lable:"1V1 window video and banner ad",
  on_become_item1_small_1:"Limit two ad slots at the top of the video window",
  on_become_item1_small_2:"Click on the video interface to display ads in real time",
  on_become_item1_small_3_lable:"Video Advertisement",
  on_become_item1_small_3_orange_left:"¥15000 RMB",
  on_become_item1_small_3_orange_right:"$2250 USD",
  on_become_item1_small_4_lable:"Banner Advertisement",
  on_become_item1_small_4_orange_left:"¥10000 RMB",
  on_become_item1_small_4_orange_right:"$1500 USD",
  on_become_item2_lable:"Web pop-up ads",
  on_become_item2_small_1:"Open the pop-up advertising interface on the homepage of the website",
  on_become_item2_small_2:"Static display for 5 seconds",
  on_become_item2_small_3_lable:"Exclusive",
  on_become_item3_lable:"Homepage Carouselbanner",
  on_become_item3_small_1:"Expo homepage",
  on_become_item3_small_2:"Expo homepage",


  Offlinetour_address_tit:"Location",
  Offlinetour_address_dsc:`Shanghai International Sourcing Exhibition Center is located at the southernmost corner of Zhongjiang Road, Changfeng Ecological Business District.The perfect combination of "water, green and architecture", advanced information network system and complete commercial supporting facilities in the area reflect the comprehensive advantages of the modern service industry cluster.`,
  Offlinetour_Taxi:"By taxi",
  Offlinetour_Bus:"Public transport",
  Offlinetour_taxi_left_from1:"Shanghai Pudong International Airport",
  Offlinetour_taxi_left_way1:"is about 55km away from the venue, which takes about 58 minutes with no traffic jams and costs about 196 RMB.",
  Offlinetour_taxi_left_from2:"Shanghai Hongqiao International Airport",
  Offlinetour_taxi_left_way2:"is about 16 kilometers away from the venue, about 30 minutes if there is no traffic jam, about 50 RMB.",
  Offlinetour_taxi_right1:"Shanghai station is about 10 kilometers away from the venue, about 25 minutes if there is no traffic jam, about 33RMB.",
  Offlinetour_taxi_right2:"Shanghai Hongqiao Station is about 16km away from the venue, about 30 minutes if there is no traffic jam, about 50 RMB.",
  Offlinetour_taxi_right3:"Shanghai South Railway Station is about 13km away from the venue, about 28 minutes if there is no traffic jam, about 40 RMB.",
  Offlinetour_bus1:"☉Adjoin to inner ring line, middle ring line, Hongqiao Airport",
  Offlinetour_bus2:"☉Surrounded by rail transit 2, 13, 15 lines",
  Offlinetour_bus3:"☉Regional shuttle buses between the convention center and major bus stops",
  Offlinetour_bus4:"☉Gubei Road Bridge, Qilianshan Road Bridge,Luding Road Bridge across the north and south of Suzhou River,straight to Hongqiao Central Business District",
  Offlinetour_periphery_title:"Surrounding facilities",
  Offlinetour_periphery_item1:"☉Jackie Chan Film Art Museum.",
  Offlinetour_periphery_item2:"☉Within a radius of two kilometers, there are eleven top-grade hotels of four or five stars and more than thirty business and economic hotels.",
  Offlinetour_periphery_item3:"☉2 yacht marinas, 100 yacht berths, Changfeng Park.",
  Offlinetour_periphery_item4:`☉Long scenery side entertainment center, large commercial center "Guosheng Center".`,

  VisitExhibition_left_title:"Offline",
  VisitExhibition_quanyi:"Benefits",
  VisitExhibition_left_title_free:"Free Audience Bnenefits",
  VisitExhibition_left_title_money:"VIP Benefits（concluding ALL Free audience benefits）",

  VisitExhibition_left_item2:"viewing of the live broadcast",
  VisitExhibition_left_item3:"access to the booth",
  VisitExhibition_left_item4:"posting messages",
  VisitExhibition_left_item5:"",
  VisitExhibition_left_item6:"Welcome Dinner",
  VisitExhibition_left_item7:" Attend peak forum",
  VisitExhibition_left_item8:" Business lunch 1 set",
  VisitExhibition_left_item9:" One information bag",

  VisitExhibition_right_title:"Online",
  VisitExhibition_right_item1:"Unlimited viewing of the live broadcast",
  VisitExhibition_right_item2:"unlimited access to the booth",
  VisitExhibition_right_item3:"random negotiation",
  VisitExhibition_right_item4:"posting messages",
  VisitExhibition_right_item5:"online business card exchange",
  VisitExhibition_right_item6:"leaving messages only in the free chat area",

  // VisitExhibition_Early_bird:"早鸟票价",
  // time_before_1_31:"1月31日前",
  // VisitExhibition_youhui:"优惠票价",
  // VisitExhibition_gn_price:"国内价格",
  // VisitExhibition_hw_price:"海外价格",
  VisitExhibition_Signupnow:"立即报名",
  VisitExhibition_Sigfree:"FREE",
  VisitExhibition_weihe_title:"Benefits of exhibition",
  VisitExhibition_Highlights_off_title:"Expo Offline Activities",
  VisitExhibition_Highlights_on_title:"Expo Online Activities",

  VisitExhibition_Highlights_on_item1:"Online Booth",
  VisitExhibition_Highlights_on_item2:"Full Time Live Broadcast",
  VisitExhibition_Highlights_on_item3:"Free Talk Zone",
  VisitExhibition_Highlights_on_item4:"Online Pavilion",
  VisitExhibition_Highlights_on_item5:"Online One to One Meeting",
  VisitExhibition_Highlights_1:"E-Name card",
  VisitExhibition_Highlights_2:"Expo Voting",
  VisitExhibition_Highlights_3:"Online Hall",
  VisitExhibition_Highlights_4:"Online Booth",
  czlc_item_1:"Online registration",
  czlc_item_2:"Sponsor confirmation",
  czlc_item_3:"Confirmation of booth payment",
  czlc_item_4:"Online booth design",
  czlc_item_5:"Attend exhibition",
  myExhibitionsummary_p1:'Qingdao International Logistics Expo will be a new great IP . We arrange to set the EXPO in QINGDAO China,one of the important port city in China,which has not only the geography anvantages but also logistics developing advantages. The four sponsors of the EXPO are all influential in Qingdao logistics.They are the initiator of this EXPO and they are willing to make a great exhibition. ShenQing Digital Supply Chain Innovation Center and AiLa are the undertaker of this EXPO ,we will combine the online and offline contents and fulfill the EXPO with good events. This EXPO will make the oversea`s and Chinese shipping companies motiative again,which will break the ice from covid and make the logistics full of oppotunity.',
  // myExhibitionsummary_p2:'This "Expo" will break the limitations of time and space through domestic brand exhibition areas, overseas exhibition areas, boutique special matchmaking, one-to-one meetings, free talk, and other forms to strengthen industry cooperation and contribute to the overall environmental development of the industry and the company itself Operation and expansion provide good opportunities to encourage people in the industry to exchange experiences, gain insight into customer needs, and achieve win-win and development in the industry. A welcome dinner will be held on the day of the opening ceremony, which can put aside the boring and hustle and bustle of daily work, and discuss new opportunities for corporate development with industry elites in a relaxed and pleasant environment, and provide a communication platform for all participants.',
  zhubanfang_big_tit:"Co-host",
  chengbanfang_big_tit:"Organizer",
  xiebanfang_big_tit:"Co-organizer",
  zhubanfang_name_1:"QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF COMMERCE",
  zhubanfang_name_2:"V-LOG UNION",
  zhubanfang_name_3:"QINGDAO SERVICE FEDERATION",
  zhubanfang_name_4:"CHINA FREIGHT FORWARDER CLUB",
  zhubanfang_content_1:"Qingdao International Logistics Chamber of Commerce is a non-profit social welfare organization in the logistics industry, and a self-regulatory social organization voluntarily formed by outstanding enterprises, individuals, and related units in the international logistics industry. The Chamber of Commerce aims to build an information, standardized and intelligent platform for Qingdao's logistics industry. Relying on the platform to promote the mutual benefit of resource interaction among member companies in the industry for exchanges and cooperation. Expand resources and channels from all walks of life, strengthen supply and demand information, partners and other resources to promote the integration of member resources. Maintain industry interests, understand relevant policies, respond to the operational demands of the logistics industry to functional departments, and assist members to grow bigger and stronger. ",
  zhubanfang_content_2:"V-LOG UNION is an excellent logistics enterprise alliance that came into being in March 2018. It is also the first alliance organization initiated and established in Qingdao, dedicated to creating truly borderless enterprise resources in the same industry. Sharing platform, one-stop solution to all customers' logistics needs in Qingdao. ",
  zhubanfang_content_2_2:"The member companies currently participating in V-LOG are all line agents that have been well-operated in the shipping and logistics field of Qingdao for many years, and each have obvious professional characteristics, and they have become complementary and win-win roles in the alliance. ",
  zhubanfang_content_3:"Qingdao Modern Service Industry Federation is composed of enterprises and institutions of various ownerships engaged in modern service industry, as well as experts, scholars, professionals, and social activists engaged in modern service industry-related work to voluntarily participate in social organizations with corporate legal personality qualifications.",
  zhubanfang_content_3_2:"Aiming at one goal, the federation will work together to build the \"Qingdao Service\" brand and promote service industry clusters; centering on two main lines, serving the government and enterprises; building three platforms, namely, expert think tanks, \"double recruitment and double introduction\", and intermediary services; The four functions of implementation are the functions of bridges, integrity and self-discipline, negotiation and rights protection, and platform windows; the realization of the five breakthrough work ideas of characteristic tourism, smart cities, entrepreneurial design, standard publicity and financial services, and joint efforts to promote the renewal of the service industry. Take a new step. ",
  zhubanfang_content_4:"",
  aila_event_name1:"AiLa Global Logistics Partners Meeting",
  aila_event_name2:"2020 The 4th China (Ningbo) International Shipping",
  aila_event_name3:"Logistics Fair (Cloud Exhibition)",

  myExhibitionsummary_p3:'JCtrans Logistics Network was established in 2003 with a registered capital of 100 million yuan, and is committed to becoming a global logistics transaction and settlement service platform. The company has a professional team composed of nearly 300 excellent internet and logistics industry talents to create a global logistics enterprise ecosystem of one million.',
  myExhibitionsummary_p15:'Global Logistics Enterprise Development Expo',
  myExhibitionsummary_p4:'JCtrans will continue to uphold its own corporate culture, adhere to its mission and goals, and empower small and medium logistics companies to become a global logistics transaction and settlement service platform.',
  myExhibitionsummary_p5:'AILA is a platform whcih is founded for dealing global logistics problems and taking solutions to the customers.By using digital technology,AILA can cooperate with the global logistics company and keep a good assiciation with them. AILA has not only Internet Platform but also Mobile terminal platform and SaaS System.Also,AILA has a new product called"Cloud Booth".By this new product,AILA can combine and initiate the online and offline events together,which is different from the traditional logistics companies.',

  jincheng_c:"",
  myExhibitionsummary_p6:'Qingdao International Convention and Exhibition Center is a modern and intelligent exhibition hall integrating exhibitions, conferences, business, catering, entertainment and other functions.It is located in Century Plaza, Laoshan District, Qingdao, with beautiful surroundings and complete facilities. It is an ideal place for holding international exhibitions and international conferences.Nearly 10,000 square meters of medium and high-end restaurants and fast food restaurants can provide guests with Chinese and Western fast food, large buffets and other different levels of catering services.',
  myExhibitionsummary_p7:'',
  myExhibitionsummary_p8:"",
  shenqing_company_name:"ShenQing Digital Supply Chain Innovation Center",
  shenqing_des:`ShenQing Digital Supply Chain Innovation Center , hereinafter referred to as the "Innovation Center", is a comprehensive consulting service platform dedicated to building a multi-channel, comprehensive and whole industry chain for private capital to widely participate in the national economy. The innovation center breaks through the traditional "chain" operation mode, creates a development model of "one platform, one network", and makes full use of 5G, cloud technology, big data analysis, blockchain, AI and other information technologies to create a smart supply chain platform. The business field is guided by the innovation and dissemination of digital supply chain management and services, conforms to the new round of technological revolution and industrial transformation trends, and adheres to the direction of globalization, digitization and intelligence. At the same time, relying on the strong industrial supply chain planning ability, we will build a “government, industry, university, research, and financial services” community platform to provide business content that integrates policy analysis, applied research, management consulting, operational practice, technology development, and talent training. With the service concept of "digital + ecological green supply chain", we provide the government, large enterprises, and various industrial development parks with industrial digital platform construction services, system integration services, and full-chain or link digital transformation services, as well as customer requirements. Customized system services such as logistics delivery platform and supply chain financial platform can enhance the security and stability of the nuclear industry chain and supply chain, and empower upstream and downstream enterprises.`,
  myExhibitionsummary_p9:"",
  my_activity_p1:'Enterprise Award: "Popular Logistics Enterprise".',
  my_activity_p2:'Individual Awards: "Logistics Princess " and "Logistics Prince".',
  my_activity_p3:'Registration time: July 20, 2021--July 31, 2022',
  my_activity_p4:'Voting time: Aug.1, 2021--18:00, Aug.3, 2022',
  my_activity_p5:`"Logistics Princess " and "Logistics Prince".  (At least 5000 vote) Champion Award: Banquet will be presented with a trophy and 3 minutes of speech time.`,
  my_activity_p6:'2. Popular Champion Award: 3 people in total, the banquet will be presented with a trophy and 3 minutes of speech time.',
  my_activity_p7:`"Popular Logistics Enterprise" (At least 5000 vote)`,
  my_activity_p8:'Gold Award: 2021or2022 Qingdao Expo 1 general booth free',
  my_activity_p16:"Silver Award: 2021or2022 Qingdao Expo 50% voucher for any sponsorship.",
  my_activity_p17:"Bronze award: 2021or2022 Qingdao Expo speech for promotion.",
  my_activity_p9:'Any participants work in logistics field who sign up in expo website.',
  my_activity_p10:'2. Exhibitors are eligible to recommend individuals, but moderately open up 4 active users, can not be restricted by enterprises to buy booth;',
  my_activity_p11:'Getting 10 votes by logging in the PC website and wechat each day, voting will be available all day. ',
  my_activity_p12:'1. From March 29th,2021, the number of individual awards will be counted every week. Before the deadline, 3 weekly champions will be selected for each award, and a total of 9 weekly champions will be awarded "Weekly Champion Award".',
  my_activity_p13:'2. On April 17th,2021., the organizing committee will count the total votes of the three individual awards respectively, and finally get the three champion of popularity who can get the "Champion of popularity award".',
  my_activity_p14:'3. On April 17th,2021., the organizing committee will count the total votes of the two enterprise awards respectively, and the top three of each award, namely 6 enterprises in total, will be awarded the "Star Enterprise Award".',
  my_activity_p15:'4. On April 17th,2021., the organizing committee will conduct a comprehensive voting ranking for the enterprise awards, and the enterprise ranking the first will get the "Exposition Ultimate Grand Award".',
  my_registerSuccess_p1:'Thank you for signing up!',
  my_registerSuccess_p2:'The  exclusive service team  will  contact  you within  2  hours.  Please  wait\tpatiently',
  // 控制台
  replenishInfoText1: 'please choose service, at most 5',
  replenishInfoText2: 'Please choose shipping route, at most 5',
  replenishInfoText3: 'Nothing left',
  replenishInfoText4: 'Not required, at most 3',
  // 酒店预定
  HotelReservation_des:`The organizer has reserved four-star hotel rooms for visitors, exhibitors and guests, and the hotel price is much lower than the original hotel price. If you need to make a reservation, please fill in the check-in information below, and check in with your ID card on the scheduled date! The non-contract price refers to the online booking price on the day; the hotel check-in time is 2 pm, and the check-out time is 12 am the next day;
  `,
  HotelReservation_tips: "Warm reminder: If you do not make a reservation through this system, there may be no room or no preferential price.",
  HotelReservation_tips2:"如您需要升级为豪华房，请直接联系主办方工作人员。",
  HotelReservation_tips_room1:"Twin room：550 yuan/room night",
  HotelReservation_tips_room_des1:"Twin room includes 2 breakfasts，If you need additional meals, please go to the hotel reception",
  HotelReservation_tips_room2:"King bed room：550 yuan/room night",
  HotelReservation_tips_room_des2:"King bed room includes 1 breakfasts，If you need additional meals, please go to the hotel reception",
  HotelReservation_breakfast_standard:"Twin room includes 2 breakfasts",
  HotelReservation_breakfast_big:"King bed room includes 1 breakfasts",
  HotelReservation_Notice_1:"1.If you need an invoice, please communicate with the hotel reception;",
  HotelReservation_Notice_2:"2.Hotel check-in time is 14:00, check-out time is 12:00 the next day, please check in in time;",
  HotelReservation_Notice_3:"Hotel check-in time is 14:00, check-out time is 12:00 the next day;",
  HotelReservation_money:"The actual amount is subject to payment when checking in offline",
  HotelReservation_yd_success:"The actual amount is subject to payment when checking in offline!",
  HotelReservation_time:"The actual amount is subject to payment when checking in offline",
  HotelReservation_placeholder:"Fill in at least one contact person for each room\nFor example：\n\nroom 1\nname \nphone number\nID card number\n\nroom 2\nname\nphone number\nID card number\n\n ...",
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  selectBooth1:'Standard Booth',
  selectBooth2:'800-900 USD',
  selectBooth3:'Online：',
  selectBooth4:'As long as you purchase an offline booth, you will get an online booth as a gift. For the rights and interests, please refer to the online paid booth。',
  selectBooth5:'Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket,The standard booth fee includes the production and construction of the background board. The background boa rd design is completed by the customer or consulted by the organizer. The design fee is 1,200 yuan per booth (paid separately). The fascia board is designed, made and built by the organizer.',
  selectBooth6:'Welcome dinner for 2 person。',
  selectBooth7:'Two information bag。',
  selectBooth8:'Business lunch 2 set/day。',
  selectBooth9:'Special Booth',
  selectBooth10:'2100-4200 USD',
  selectBooth11:'Online：',
  selectBooth12:'Companies with special booths will be given one online booth as a gift. For the rights and interests, please refer to the online paid booth.。',
  selectBooth13:'One online booth and one top ten exhibition seat in the exhibition hall will be presented as a gift。',
  selectBooth14:'Offline：',
  selectBooth15:'Including 4 exhibition tickets, 2 dinner places, 8 business lunches,4 information bag。',
  selectBooth16:'With a large space of 36 square meters/72square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.',
  selectBooth17:'Overseas exhibition area',
  selectBooth22:"500 USD",
  selectBooth18:'1 online booth。',
  selectBooth19:'2 participants, accept 24-hour 1-to-1 reservations。',
  selectBooth20:'The corporate promotional video will be played simultaneously on 30 screens in the overseas exhibition area',
  selectBooth21:'Corporate leaflets + business cards, 500 sheets each',
  selectBooth22:'11 August 2022-- 12 August 2022',
  selectBooth23:'Expo postoned, launch date is to be determined,please pay attention to the notice.',
  selectBooth24:'Voting is underway, register now to win surprise.',
  selectBooth25:'Qingdao Expo postoned,  launch date is to be determined.',
  selectBooth26:"500 USD",
  selectBooth27:"Offline：",
  selectBooth28:"rights and interests",
  selectBooth29:"As long as you purchase an offline booth, you will get an online booth as a gift. For the rights and interests, please refer to the online paid booth.",
  selectBooth30:"boothArrangement",
  selectBooth31:'Head of Domestic channels',
  selectBooth32:'Head of Foreign channels',
  selectBooth33: 'Scan code to add wechat for details.',
  bfMeeting1: "当前，世界格局复杂多变，新的国际秩序正在形成，各种资源不断整合发展，国际航运物流业正处在百年未见之变局与世纪疫情交织叠加的前线。随着5G通讯、万物互联、人工智能等前沿科技的发展，各行各业如何与新技术融合成为当下的课题。\n      天津作为中国北方乃至世界的重要港口枢纽城市，国际航运物流业如何与新技术融合未来面临挑战。同时，天津市也正在积极打造区域商贸中心城市和北方国际航运枢纽核心区，国际物流作为重要组成部分和重点支撑环节成为各界关注的焦点。\n      在此背景下，天津市商务局、天津市河西区人民政府、天津市工商业联合会主办，天津市河西区商务局、天津市多式联运行业协会、天津滨海松昌国际物流（集团）有限公司、航运界网、AiLa承办以“聚焦港产城融合发展·打造北方国际航运中心”为主题的2023 中国·天津河西国际航运服务聚集区建设发展论坛暨第三届北方国际航运物流展，旨在呼吁各界关注国际航运物流业数字化，促进跨境贸易合规与便利化，助力天津港产城融合发展，推动天津全面建设成为社会主义现代化、国际化大都市。",
  bfMeeting2: "本次物流峰会，主办方将会邀请到航运物流业资深专家和行业大咖，以最前沿的头部独角兽的视角深度挖掘行业资本潜力和专业潜力，从数字化角度进行国际航运物流时代探索与布局的探讨，重新定位传统物流行业的角色，给展会带来一场又一场视听盛宴，也给航运物流业发展带来全新的解决方案与行业视角。",
  bfMeeting3: "齐银良",
  bfMeeting4: "航运界网创始人",
    bfMeeting5: "张梓靖",
    bfMeeting6: "AiLa创始人&CEO",
    bfMeeting7: "董欣乐",
    bfMeeting8: "Weconsol网首席战略官",
    bfMeeting9: "会议亮点",
    bfMeeting10: "资源对接，沟通无阻",
    bfMeeting11: "全方位交流、一对一对接，带动各地、各业务优势企业多渠道参展，延续商贸畅通、沟通无阻的行业盛况。",
    bfMeeting12: "标准展位、赞助权益、专项论坛等形式充分展示参展企业优势与形象，拓展商机与合作。",
    bfMeeting13: "重磅嘉宾，趋势解读",
    bfMeeting14: "尖峰论坛，重磅嘉宾，强大媒体资源，以独特视角深挖行业潜力。",
    bfMeeting15: "涵盖航运物流、跨境电商、科技、金融等多领域，为参展商和观众打造一个具有行业针对性和专业深度的前沿咨询、政策、热点探讨和分享的独立空间。",
    bfMeeting16: "全景云展，以展带会",
    bfMeeting17: "线下+线上相结合的方式，通过线上官网和微信小程序设立线上360°全景展会，辅以数字化新功能，为企业搭建线上云展位。",
    bfMeeting18: "应疫情时势打造出的全新的参展参会形式，通过云展形式，突破时间和空间的限制，实现行业共赢和发展。",
    bfMeeting19: "精彩直播，实时互动",
    bfMeeting20: "将对展会做低延时现场直播，以及全天候的图文直播，不论是否到场参展都能感受展会精彩，掌握行业最新动态。",
    bfMeeting21: "网络覆盖面广，可以全面带动参展嘉宾的品牌知名度，拓展商机与合作。",
    bfMeeting22: "● 观看直播，访问展台、发布消息、在线交换名片",
    bfMeeting23: "● 观看直播，访问展台、发布消息、在线交换名片、含会议期间1人份晚宴名额+午餐、资料袋一个",
    bfMeeting24: "线上权益：",
    bfMeeting25: "凡购买线下展位，均赠送线上展位一个，权益参见线上付费展位；",
    bfMeeting26: "线下权益：",
    bfMeeting27: "(1)三面围板（高2.5米 ，宽3米， 深2米）、楣板、咨询台一个、椅子2把、射灯2个、10A插座1个。标准展位费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1000元（另行支付），楣板由主办方设计、制作及搭建；",
    bfMeeting28: "(2)含2人份午餐+1人晚宴名额，资料袋2个；",
    bfMeeting29: "(3)仅限90家；",
    bfMeeting30: "T形展板",
    bfMeeting31: "1、含1人份17日晚宴名额+自助午餐，1个资料袋；",
    bfMeeting32: "1、2米*2米，由参展商自行设计，主办方负责制作；",
    bfMeeting33: "2、赠送线上展位一个，权益参见线上付费展位；",
    bfMeeting34: "4、仅限5家；",
    bfMeeting35: "馆内大展板（N3/N4）",
    bfMeeting36: "1、5米*5米大展板，含展桌一张，椅子两把，4个资料袋；",
    bfMeeting37: "2、含2人份午餐+晚宴名额；",
    bfMeeting38: "3、展板费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1000元（另行支付）；",
    bfMeeting39: "4、赠送线上展位一个，权益参见线上付费展位；",
    bfMeeting40: "5、赠送线上1展馆前10展示席位；",
    bfMeeting41: "6、仅限10家；",
    bfMeeting42: "VIP超大展板（N2、N5、N6、N7、N8区域）",
    bfMeeting43: "1、高5米*宽8米VIP超大展板，含展桌一张，椅子两把，4个资料袋；",
    bfMeeting44: "2、含2人份午餐+晚宴名额；",
    bfMeeting45: "3、展位费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1000元（另行支付）；",
    bfMeeting46: "4、赠送线上1个展位，1展馆前10展示席位；",
    bfMeeting47: "5、仅限6家；",
    bfMeeting48: "峰会冠名",
    bfMeeting49: "线上：",
    bfMeeting50: "1、线上峰会首页Banner；",
    bfMeeting51: "2、展馆页Banner；",
    bfMeeting52: "3、2个展馆位置1的展位；",
    bfMeeting53: "4、5个坐席位；",
    bfMeeting54: "5、主题直播1场；",
    bfMeeting55: "线下： ",
    bfMeeting56: "1、标准展位1个或PC展位1个；",
    bfMeeting57: "2、企业高层接受专访；",
    bfMeeting58: "3、独家冠名商报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈等多渠道推广；",
    bfMeeting59: "4、独家冠名商，与主办方同步曝光；",
    bfMeeting60: "5、含会议期间2人份午餐+晚宴名额，晚宴2人主桌就座，展会全程享受VIP 待遇；",
    bfMeeting61: "6、赠送正价门票2张；",
    bfMeeting62: "1、拥有盛大晚宴独家冠名权；",
    bfMeeting63: "2、晚宴期间企业享有5分钟演讲时间；",
    bfMeeting64: "3、赠送晚宴期间企业宣传视频3分钟；",
    bfMeeting65: "4、含会议期间2人份晚宴名额+午餐；晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
    bfMeeting66: "5、晚宴主持人口播企业宣传广告；",
    bfMeeting67: "6、行业媒体追踪报道；",
    bfMeeting68: "7、赠送标准展位1个 （含展位所有权益）；",
    bfMeeting69: "线上迷你展位是本次峰会的一大亮点，针对部分客户不能线下参展的现实情况，采取线上坐展、线下参展的方式，通过智能化控制和数字化展示，使客户足不出户也能瞬时参展，确保疫情防控不放松、国际交流不断线。",
    bfMeeting70: "增加图片、文章和音视频等多种展示形式，让您全方位、多维化地展示自己，充分调动参展企业的积极性，深入参与到展会的各个环节中，增加企业与企业之间的互动与交流。线上线下参展逛展，无需担心错过商机。",
    bfMeeting71: "1V1视频、banner广告",
    bfMeeting72: "点开视频界面即实时展现视频广告，限1家",
    bfMeeting73: "每次点击都迎面你的精彩",
    bfMeeting74: "视频窗口上方广告位  限2家",
    bfMeeting75: "固定展示 随时映入眼帘",
    bfMeeting76: "视频广告",
    bfMeeting77: "￥15000",
    bfMeeting78: "banner广告",
    bfMeeting79: "￥10000",
    bfMeeting80: "网页弹窗广告",
    bfMeeting81: "打开网站首页弹出广告界面 静态展示 持续5秒",
    bfMeeting82: "弹窗广告",
    bfMeeting83: "￥10000",
    bfMeeting84: "首页banner轮播广告",
    bfMeeting85: "博览会网站首页，支持轮播展示 （限3家）",
    bfMeeting86: "首页banner轮播广告",
    bfMeeting87: "￥15000",
    bfMeeting88: "会展大厅首页显示",
    bfMeeting89: "会展大厅首页展示，进入企业界面即可展示 限10家",
    bfMeeting90: "￥10000",
    bfMeeting91: "热门企业推荐",
    bfMeeting92: "网站首页热门展商区块，首页进展馆时展示 限4家",
    bfMeeting93: "￥8000",
    bfMeeting94: "展会独家冠名",
    bfMeeting95: "独家",
    bfMeeting96: "国内价格（人民币，如需设计，设计费为1000/版）",
    bfMeeting97: "晚宴冠名",
    bfMeeting98: "吊顶广告",
    bfMeeting99: "仅限1家",
    bfMeeting100: "广场升空气球",
    bfMeeting101: "仅限10家",
    bfMeeting102: "前厅巨幅广告",
    bfMeeting103: "仅限2家",
    bfMeeting104: "进馆门上广告",
    bfMeeting105: "仅限5家",
    bfMeeting106: "现场图片直播水印",
    bfMeeting107: "仅限1家",
    bfMeeting108: "馆内墙面广告位",
    bfMeeting109: "仅限4家",
    bfMeeting110: "线下论坛专题分享",
    bfMeeting111: "笔本套装",
    bfMeeting112: "道旗广告",
    bfMeeting113: "T形展板",
    bfMeeting113t: "仅限8家",
    bfMeeting114: "胸卡",
    bfMeeting115: "吊绳",
    bfMeeting116: "会议用餐券",
    bfMeeting117: "纪念品",
    bfMeeting118: "矿泉水赞助",
    bfMeeting119: "资料入袋",
    bfMeeting120: "会场内部摆放易拉宝",
    bfMeeting121: "会场外部摆放易拉宝",
    bfMeeting122: "仅限20家",
    bfMeeting123: "1V1视频、Banner广告",
    bfMeeting124: "1V1视频窗口",
    bfMeeting125: "首页弹窗广告",
    bfMeeting126: "博览会首页大幅弹窗广告，手动关闭（限1家）",
    bfMeeting127: "网站首页轮播banner广告",
    bfMeeting128: "博览会首页banner位置轮播展示（限3家）",
    bfMeeting129: "1v1窗口视频广告",
    bfMeeting130: "1V1视频开启前15秒播放（限1家）",
    bfMeeting131: "会展大厅首页展示",
    bfMeeting132: "会展大厅页面Banner (限10家）",
    bfMeeting133: "热门企业推荐",
    bfMeeting134: "峰会首页热门企业推荐（限4家）",
    bfMeeting135: "（含制作 不含设计）/组",
    bfMeeting136: "（含制作）/对",
    bfMeeting137: "按尺寸仅限精准报价",
    bfMeeting138: "/幅",
    bfMeeting139: "按位置和尺寸不同精准报价",
    bfMeeting140: "/场 20分钟",
    bfMeeting141: "（含设计及制作）",
    bfMeeting142: "（含制作 不含设计）",
    bfMeeting143: "（含制作 不含设计）/组",
    bfMeeting144: "（含设计及制作）",
    bfMeeting145: "协商",
    bfMeeting146: "（不含制作费）",
    bfMeeting147: "1、线上峰会首页Banner；",
    bfMeeting148: "2、展馆页Banner；",
    bfMeeting149: "3、2个展馆位置1的展位；",
    bfMeeting150: "4、5个坐席位；",
    bfMeeting151: "5、主题直播1场；",
    bfMeeting152: "1、标准展位1个；",
    bfMeeting153: "2、企业高层接受专访；",
    bfMeeting154: "3、独家冠名商报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈等多渠道推广；",
    bfMeeting155: "4、独家冠名商，与主办方同步曝光；",
    bfMeeting156: "5、含会议期间2人份午餐+晚宴名额，晚宴2人主桌就座，展会全程享受VIP 待遇；",
    bfMeeting157: "6、赠送正价门票2张；",
    bfMeeting158: "1、拥有盛大晚宴独家冠名权；",
    bfMeeting159: "2、晚宴期间企业享有5分钟演讲时间；",
    bfMeeting160: "3、赠送晚宴期间企业宣传视频3分钟；",
    bfMeeting161: "4、含会议期间2人份晚宴名额+午餐；晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
    bfMeeting162: "5、晚宴主持人口播企业宣传广告；",
    bfMeeting163: "6、行业媒体追踪报道；",
    bfMeeting164: "7、赠送T形展板1个 （含展板所有权益）；",
    bfMeeting165: "1、每组2个，处于展馆高空位置，入馆后即刻映入眼帘（根据实际场地大小设置尺寸)；",
    bfMeeting166: "2、赠送展会付费门票2张（含门票所有权益）；",
    bfMeeting167: "3、含会议期间2人份17日晚宴名额+自助午餐；晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
    bfMeeting168: "1、每对2个，处于展馆广场进馆广场中心位置，进入广场后即刻映入眼帘（根据实际场地大小设置尺寸)；",
    bfMeeting169: "2、含会议期间1人份17日晚宴名额+自助午餐；",
    bfMeeting170: "1、展会现场照片直播水印；",
    bfMeeting171: "2、同步展示在官网和小程序，每一张展示图片均有独家水印；",
    bfMeeting172: "3、产品价格：20000元（含制作）；",
    bfMeeting173: "4、含会议期间2人份晚宴名额+午餐；",
    bfMeeting174: "5、售卖数量：仅限1家，水印样式和颜色可定制；",
    bfMeeting175: "1、峰会期间嘉宾演讲和圆桌论坛外设置专题演讲论坛活动，可进行产品介绍，企业推介；",
    bfMeeting176: "2、每场20分钟；",
    bfMeeting177: "3、含会议期间1人份晚宴名额+午餐；",
    bfMeeting178: "4、产品价格：10000/场，售卖数量：仅限5家；",
    bfMeeting179: "1、专业量身定制，高品质精美印刷LOGO、公司名等  付费参会客户人手1个，展会移动式广告展示平台，提升品牌影响力；",
    bfMeeting180: "2、赞助商展示板全程展示企业LOGO，扩大企业知名度；",
    bfMeeting181: "3、所有付费参会人员统一发放人手一套；",
    bfMeeting182: "4、赞助企业logo全套印刷； ",
    bfMeeting183: "5、极具纪念意义，使用周期长，品牌展示效果持久；",
    bfMeeting184: "6、含会议期间2人份17日晚宴名额+自助午餐；",
    bfMeeting185: "1、5对（ 10个），展馆入口两侧摆放，尺寸：高5M，单面喷绘布：宽1.2m，高3.5m；",
    bfMeeting186: "2、含会议期间2人份17日晚宴名额+自助午餐；",
    bfMeeting187: "3、产品价格：20000元， 售卖数量：独家；",
    bfMeeting188: "1、正反双面体现赞助商宣传信息；",
    bfMeeting189: "2、所有参会人员展会3天全程佩戴，会场内外移动式广告；",
    bfMeeting190: "3、含会议期间1人份17日晚宴名额+自助午餐；",
    bfMeeting191: "4、展会期间参展商进入会场的唯一凭证，使用率高；",
    bfMeeting192: "1、公司名、LOGO、宣传语全面展示；",
    bfMeeting193: "2、参展商、洽谈客户进入会场的唯一凭证， 移动广告曝光率高；",
    bfMeeting194: "3、含会议期间1人份17日晚宴名额+自助午餐；",
    bfMeeting195: "1、向参展商、洽谈客户统一发放用餐券；",
    bfMeeting196: "2、将在餐券上展示公司名、logo等企业信息;",
    bfMeeting197: "3、展会期间就餐唯一凭证，曝光率高；",
    bfMeeting198: "4、含会议期间1人份晚宴名额+午餐；",
    bfMeeting199: "5、售卖数量：独家，含设计及制作；",
    bfMeeting200: "1、赞助商可将印有企业LOGO及广告的纪念品交至组委会统一派发给参会客户，也可以根据赞助商需求定制精美纪念品，以展示公司实力和品牌价值，扩大影响力；",
    bfMeeting201: "2、含会议期间1人份17日晚宴名额+自助午餐；",
    bfMeeting202: "1、展会期间的饮用水瓶身粘贴赞助商信息，用于会议现场服务，移动式广告, 共计制作3000瓶;",
    bfMeeting203: "2、主办方将在晚宴，专项对接会，开幕式等环节摆放饮用水，曝光率极高;",
    bfMeeting204: "3、含会议期间1人份17日晚宴名额+自助午餐；",
    bfMeeting205: "点开视频界面即实时展现视频广告  限1家；",
    bfMeeting206: "每次点击都迎面你的精彩；",
    bfMeeting207: "视频窗口上方广告位  限2家；",
    bfMeeting208: "固定展示 随时映入眼帘",
    bfMeeting209: "打开网站首页弹出广告界面 静态展示 持续5秒",
    bfMeeting210: "博览会网站首页，支持轮播展示 （限3家）",
    bfMeeting211: "观看直播，访问展台、发布消息、在线交换名片",
    bfMeeting212: "观看直播，访问展台、发布消息、在线交换名片、含会议期间1人份晚宴名额+午餐、资料袋一个",
    bfMeeting213: "2023 中国 天津河西国际航运服务聚集区建设发展论坛暨第三届北方国际航运物流展将会邀请到航运物流业资深专家和行业大咖，以最前沿的头部独角兽的视角深度挖掘行业资本潜力和专业潜力，从多角度进行国际航运物流时代探索与布局的探讨，重新定位传统物流行业的角色，给展会带来一场又一场视听盛宴，也给航运物流业发展带来全新的解决方案与行业视角。",
    bfMeeting214: "此次峰会将充分调动海内外航运物流企业的积极性，办成一场线上与线下相结合，内容丰富，形式丰满的物流行业盛会。作为观众，线下观展不但可以面对面一对一洽谈沟通，还可以在展会上通过观展逛展、自由洽谈、预约洽谈、行业研讨会、答谢晚宴来进行商务洽谈与商机发现。",
    bfMeeting215: "关于展会",
    bfMeeting216: "聚焦港产城融合发展·打造北方国际航运中心",
    bfMeeting217: "2023.03.27-2023.03.28",
    bfMeeting218: "万丽天津宾馆（天津市河西区宾水道16号）",
    bfMeeting219: "线上云展会+线下实体展会",
    bfMeeting220: "当前，世界格局复杂多变，新的国际秩序正在形成，各种资源不断整合发展，国际航运物流业正处在百年未见之变局与世纪疫情交织叠加的前线。随着5G通讯、万物互联、人工智能等前沿科技的发展，各行各业如何与新技术融合成为当下的课题。\n      天津作为中国北方乃至世界的重要港口枢纽城市，国际航运物流业如何与新技术融合未来面临挑战。同时，天津市也正在积极打造区域商贸中心城市和北方国际航运枢纽核心区，国际物流作为重要组成部分和重点支撑环节成为各界关注的焦点。\n      在此背景下，天津市商务局、天津市河西区人民政府、天津市工商业联合会主办，天津市河西区商务局、天津市多式联运行业协会、天津滨海松昌国际物流（集团）有限公司、航运界网、AiLa承办以“聚焦港产城融合发展·打造北方国际航运中心”为主题的2023 中国·天津河西国际航运服务聚集区建设发展论坛暨第三届北方国际航运物流展，旨在呼吁各界关注国际航运物流业数字化，促进跨境贸易合规与便利化，助力天津港产城融合发展，推动天津全面建设成为社会主义现代化、国际化大都市。",
    bfMeeting221: "航运界网",
    bfMeeting222: "航运界网(www.ship.sh)是航运信息·数据·咨询平台。为用户提供24小时实时全球航运资讯,同时有多位航运业内专家精彩评论。",
    bfMeeting223: "航运界,让航运无界!",
    bfMeeting224: "AiLa",
    bfMeeting225: "AiLa是一家为全球物流行业协会提供数字化综合解决方案的平台，通过与全球的国际物流行业协会建立全方位战略合作模式，为协会提供网络平台、移动端平台、综合会员管理SaaS系统等，为协会进行数字化赋能以及互联网运营支持。帮助全球物流行业协会以在线化、网络化、智能化的方式更好的服务其会员单位。",
    bfMeeting226: "天津滨海松昌国际物流（集团）有限公司",
    bfMeeting227: "天津滨海松昌集团是天津口岸开展专业拼箱业务的物流公司。集团业务涵盖“传统物流”、“保税物流”、“跨境电商”、“进出口拆装箱仓储物流”、“WEconsol云平台”五大主营板块。集团设有从新港往返世界各地200多条基本航线，可覆盖全球数千港口及内陆城市。",
    bfMeeting228: "万丽天津宾馆坐落于天津市河西区宾水道16号， 坐拥12000平方米湖畔花园景观，于城中园木湖景，看一日朝飞暮卷。天津宾馆设施高档，地理位置优越，将为您带来难忘的会议及住宿享受。",
    bfMeeting229: "主办方提供会场直达大巴服务，上车地点：河西下瓦房，和平小白楼，和平津汇广场，河东十一经路",
    bfMeeting230: "附近公交站：天津华侨城欢乐谷、瀚景路欢乐谷、秀竹路、欢乐谷东、恒大绿洲、东丽湖度假村",
    bfMeeting231: "附近交通： 距离地铁5/6号线天津宾馆站C出口仅50米，毗邻天津市政府、天津大学、南开大学、五大道文化旅游区、天津水上公园、时代奥城商业区。",
    bfMeeting232: "企业LED宣传视频广告",
    bfMeeting233: "1、8米宽*5米高特大LED屏幕显示，可展示企业业务优势、公司介绍等信息（根据实际场地大小设置尺寸)，极具视觉效果；",
    bfMeeting234: "2、含会议期间1人份晚宴名额+午餐；",
    bfMeeting235: "3、5000/家，每家企业不超过2分钟，全天循环播放，仅限10家；",
    bfMeeting236: "/家",
    bfMeeting237: "5000/家",
    bfMeeting238: "企业宣传片",
    bfMeeting239: "1、地处展会核心区域，28*7m 特大LED高清视频展示客户企业形象；",
    bfMeeting240: "2、每家企业2分钟以内宣传视频，仅限5家，会议全程LED大屏轮播，极具视觉效果；",
    bfMeeting241: "3、含会议期间1人份17日晚宴名额+自助午餐；",
    bfMeeting242: "会展大厅首页显示",
    bfMeeting243: "会展大厅首页展示，进入企业界面即可展示",
    bfMeeting244: "热门企业推荐",
  bfMeeting245: "网站首页热门展商区块，首页进展馆时展示",
  bfMeeting246: "展会简介",
    bfMeeting247: "遇见河西 预见未来",
    bfMeeting248: "天津市河西区加快发展航运服务业。主要集聚在小白楼地区，楼宇载体有13座，形成与航运关联的航运金融、保险、信息咨询、海事仲裁、进出口贸易、教育培训等全产业链。目前，全区航运服务企业800余家，其中小白楼航运服务集聚区聚集航运企业300余家，2022年营收实现165亿元，具有良好发展基础和前景。\n      下一步，围绕中央商务区建设，积极打造小白楼国际航运服务聚集区，助力港产城融合发展，以泰达大厦为核心载体打造小白楼国际航运中心大厦，在泰达大厦显著位置正式挂牌“小白楼国际航运中心大厦”，突出小白楼国际航运服务聚集区主题属性，全面提升航运产业发展能级。",
    bfMeetingNew1: "标准展位",
    bfMeetingNew2: "98家",
    bfMeetingNew3: "（不含制作费，只有正面）",
    bfMeetingNew4: "场馆内大展板",
    bfMeetingNew5: "（含制作费不含设计费）",
    bfMeetingNew6: "场馆内VIP超大展板",
    bfMeetingNew7: "（不含设计费，含制作费）",
    bfMeetingNew8: "前厅大展板",
    bfMeetingNew9: "侧厅展板",
    bfMeetingNew10: "20家",
    bfMeetingNew11: "1、2米*2米，由参展商自行设计，主办方负责制作；",
    bfMeetingNew12: "2、赠送线上展位一个，权益参见线上付费展位；",
    bfMeetingNew13: "1、高3米*宽4米大展板，2个资料袋；",
    bfMeetingNew14: "2、含1人份午餐+晚宴名额；",
    bfMeetingNew15: "3、展位费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1000元（另行支付）；",
    bfMeetingNew16: "4、赠送线上1个展位；",
    bfMeetingNew17: "1、高3米*宽2.5米展板，含展桌一张，椅子两把，2个资料袋；",
    bfMeetingNew18: "2、含2人份午餐；",
    bfMeetingNew19: "3、展位费用中包含背景板制作及搭建的部分，背景板设计均由客户自行完成或咨询主办方代为设计，设计费为每个展位1000元（另行支付）；",
    bfMeetingNew20: "4、赠送线上1个展位；",
    bfMeetingNew21: "2023 中国·天津河西国际航运服务聚集区建设发展论坛暨第三届北方国际航运物流展",
    bfMeetingNew22: "组织机构",
    bfMeetingNew23: "主办单位：天津市商务局、天津市河西区人民政府、天津市工商业联合会 （排名不分前后）",
    bfMeetingNew24: "承办单位：天津市河西区商务局、天津市多式联运行业协会、天津滨海松昌国际物流（集团）有限公司、航运界网、AiLa",
    bfMeetingNew24_5: "指导单位：天津市交通运输委员会",
    bfMeetingNew25: "支持单位：中共天津市委统战部、天津市归国华侨联合会、天津海事法院、天津东疆保税港区管理委员会、天津市津商联合会、天津市侨商会、天津报关协会、\n                        ISEA天津口岸、ISEA义乌口岸、BIMCO、天津港（集团）有限公司",
    bfMeetingNew26: "技术支持：AiLa、腾讯会议",
    bfMeetingNew27: "媒体支持：天津广播电视台、搜狐网、BT财经",
    bfMeetingNew28: "天津市河西区商务局",
    bfMeetingNew29: "天津市河西区商务局天津市河西区商务局是天津市河西区人民政府工作部门。",
    bfMeetingNew30: "天津市河西区商务局贯彻落实党中央关于商务工作的方针政策，贯彻落实党中央、市委和区委关于商务工作的决策部署，在履行职责过程中，坚持和加强党对商务工作的集中统一领导。",
    bfMeetingNew31: "天津市多式联运行业协会",
    bfMeetingNew32: "天津市多式联运行业协会成立于2021年，是在天津市民政局注册的市级行业协会。",
    bfMeetingNew33: "本团体宗旨是坚持中国共产党的全面领导，遵纪守法。以习近平新时代中国特色社会主义思想为指导，充分发挥政府与企业之间的桥梁作用，配合政府加强对多式联运行业的管理，推动会员企业之间的交流与合作，依法维护本行业利益，保护会员企业合法权益，促进天津多式联运行业健康稳步发展。",
}
