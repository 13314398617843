<template>
  <el-backtop target :visibility-height="100">
    <div class="backPageTop tc"><i class="el-icon-arrow-up"></i></div>
  </el-backtop>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.backPageTop {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px 4px 4px 4px;
}
</style>