/** note: form validate
 *  各种正则验证规则可以补充在这里
 * */



/* 6-16位密码*/
export function validatePassWord(str) {
    const reg = /^[0-9a-zA-Z_.!@$#~^]{6,16}$/;
    return reg.test(str)
}

/* 合法uri*/
export function validateURL(textval) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
    const reg = /^[a-z]+$/
    return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
    const reg = /^[A-Z]+$/
    return reg.test(str)
}

/* 大小写字母*/
export function validateAlphabets(str) {
    const reg = /^[A-Za-z]+$/
    return reg.test(str)
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

/**
 * 验证必填
 * @param value
 * @returns {boolean}
 */
export function require(value, type) {
    if (type === 'lonLat') {
        if (!(typeof value === 'object')) {
            return false
        }
        const r = +value.lon * +value.lat
        if (r === 0 || isNaN(r)) {
            return false
        } else {
            return true
        }
    }
    if (value === undefined || value === null) {
        return false
    }
    return value.trim() + '' !== ''
}

/**
 * 验证字符串长度
 * @param value 待验证字符串
 * @param param。格式示例：{max:10,min:0}
 * @returns {boolean}
 */
export function length(value, param) {
    try {
        as
        if (value === undefined || value == null || value === '') return true

        let b = true
        value = value + ''
        if (param.max !== undefined) {
            b = value.length <= param.max
        }
        if (!b) return false
        if (param.min !== undefined) {
            b = value.length >= param.min
        }
        if (!b) return false
        return true
    } catch (e) {
        return false
    }
}

/**
 * 验证字符串长度，一个汉字算作2个字符，其他与楼上的一样
 * @param value 待验证字符串
 * @param param。格式示例：{max:10,min:0}
 * @returns {boolean}
 */
export function length_i18n(value, param) {
    try {
        if (value === undefined || value == null || value === '') return true

        let b = true
        value = value + ''
        value = value.replace(/[^\x00-\xff]/g, '01')
        if (param.max !== undefined) {
            b = value.length <= param.max
        }
        if (!b) return false
        if (param.min !== undefined) {
            b = value.length >= param.min
        }
        if (!b) return false
        return true
    } catch (e) {
        return false
    }
}

/**
 * 验证数值范围
 * @param value
 * @param param。格式示例：{max:100,min:10}
 * @returns {boolean}
 */
export function range(value, param) {
    try {
        if (value === undefined || value == null || value === '') return true

        const v = parseFloat(value)
        if (isNaN(v)) return false
        if (param.notScope) {
            if (v >= param.max) return false
            if (v <= param.min) return false
        } else {
            if (v > param.max) return false
            if (v < param.min) return false
        }

        return true
    } catch (e) {
        return false
    }
}

/**
 * 正则表达式验证.
 * @param value
 * @param param 格式示例：/[a-z]/g
 * @returns {boolean}
 */
export function pattern(value, param) {
    try {
        if (value === undefined || value == null || value === '') return true

        const regex = new RegExp(param)
        return regex.test(value)
    } catch (e) {
        return false
    }
}

export function mobile(value, param) {
    if (value === undefined || value == null || value === '') return true

    let pattern
    if (param === '+86') {
        pattern = /^(13[0-9]|14[0-9]|15[0-9]|18[0-9]|17[0-9]|16[0-9]|19[0-9])\d{8}$/
        return pattern.test(value)
    } else {
        return true
    }
}



/**
 * 验证正整数
 * @param value
 * @returns {boolean}
 */
export function positiveInteger(value) {
    try {
        if (value === undefined || value == null || value === '') return true

        const reg = new RegExp(/(^[0-9]\d*$)/)
        return reg.test(value)
    } catch (e) {
        return false
    }
}

/**
 * 验证数值
 * @param value
 * @returns {boolean}
 */
export function number(value) {
    if (value === undefined || value == null || value === '') return true

    const strP = /^(-|\+)?\d+(\.\d+)?$/
    if (!strP.test(value)) return false
    try {
        if (parseFloat(value) !== +value) return false
    } catch (ex) {
        return false
    }
    return true
}

/**
 * 验证整数
 * @param value
 */
export function integer(value) {
    if (value === undefined || value == null || value === '') return true

    return /^-?\d+$/.test(value)
}

/**
 * 验证小数
 * @param value
 */
export function decimal(value) {
    if (value === undefined || value == null || value === '') return true

    return /^-?\d+\.\d+$/.test(value)
}

/**
 * 验证小数,可以允许为整数
 * @param value
 */
export function decimal2(value) {
    if (value === undefined || value == null || value === '') return true

    return /^-?\d+(\.\d)?\d*$/.test(value)
}

/**
 * 验证两位小数,可以允许为整数
 * @param value
 */
export function decimal3(value) {
    if (value === undefined || value == null || value === '') return true

    return /^-?\d+(\.\d)?\d{0,1}?$/.test(value)
}

/**
 * 验证一小数,可以允许为整数
 * @param value
 */
export function decimal4(value) {
    if (value === undefined || value == null || value === '') return true

    return /^-?\d+(\.\d)?\d{0}?$/.test(value)
}



/**
 *  验证非法字符
 * @param value
 * @returns {boolean}
 */
export function illegal(value) {
    if (value === undefined || value == null || value === '') return true

    return !/[~#^$@%&!\*\(\)\[\]\{\}<>\-\+=/\?\|`]/gi.test(value)
}

/**
 *  验证所有中英文非法字符
 * @param value
 * @returns {boolean}
 */
export function allIllegal(value) {
    if (value === undefined || value == null || value === '') return true

    return !/[~#^$&!\*\(\)\[\]\{\}<>\-\+=/\?\|`……￥@%！\——\（\）\【\】《》、\？\"\”\;\；\：\:\,\，\.\。·\\\'\’]/gi.test(
        value
    )
}

/**
 *  验证非法字符，组织名称允许-
 * @param value
 * @returns {boolean}
 */
export function illegalOrgName(value) {
    if (value === undefined || value == null || value === '') return true

    return !/[~#^$@%&!\*\(\)\[\]\{\}<>\+=/\?\|`]/gi.test(value)
}

/**
 *  验证手机号
 * @param value
 * @returns {boolean}
 */
export function validateTelephone(value) {
    if (value === undefined || value == null || value === '') return true
    return /^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/.test(
        value
    )
}

/**
 *  验证手机号或邮箱
 * @param value
 * @returns {boolean}
 */
export function validateEmailOrTelephone(value) {
    if (parseInt(value) == value) {
        return validateTelephone(value)
    } else {
        return validateEmail(value)
    }
}

//验证是否是汉字
/**
 *  验证手机号
 * @param value
 * @returns {boolean}
 */
export function validateChinese(value) {
    const regex = /[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29\u{20000}-\u{2A6D6}\u{2A700}-\u{2B734}\u{2B740}-\u{2B81D}\u{2B820}-\u{2CEA1}\u{2CEB0}-\u{2EBE0}]/u;

    return regex.test(value)
}

/**
 *  验证身份证号
 * @param value
 * @returns {boolean}
 */
export function validateIdCard(value) {
    const regex = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
    return regex.test(value)
}