export default {
  navConfig: {
    logoUrl:
      "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/logo.png",
    bgColor: "#0D6BB9",
    textColor: "#fff",
    activeTextColor: "#fff",
  },
  pageConfig: {
    companyPage: {
      url: "/companyDetail",
      isEncode: true,
    },
    signPage: {
      url: "/sign",
    },
  },
  accountConfig: {
    notUseWx: true,
    notFavorite: true,
  },
  basicInfoLocale: "all", //en all
  sendCard: true, //发送名片，等待对方确认
};
