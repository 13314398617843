export default {
    actions: {
        //发送邀请列表
        async negotiationMange_getSendone2onelist(context, params) {               
            let { data } = await axios.post('daily_chat/api/send_daily_one2one_chat/list', params);
            return data;
        },

        //日常1V1操作
        async negotiationMange_dailyone2oneOperate(context, params) {              
            let { data } = await axios.post('daily_chat/api/daily_chat/invite_operate', params);
            return data;
        },
        // 收到邀约列表
        async getInone2onelist(context, params) {                     
            let { data } = await axios.post('daily_chat/api/in_daily_one2one_chat/list', params);
            return data;
        },
        // 获取个人中心邀约成功列表
        async getSuccesssInvation(content, params) {
            let { data } = await axios.post("daily_chat/api/my_invitation/list", params);
            return data;
        },
        // 日常1V1操作
        async dailyone2oneOperate(context, params) {             
            let { data } = await axios.post('daily_chat/api/daily_chat/invite_operate', params);
            return data;
        },
    }
}