const APIMYAPPLY = {
  namespaced: true,
  actions: {
    // 申请列表
    async myApplyList(content, params) {
      let { data } = await axios.post('job/api/my_apply/list', params);
      return data;
    },
    //   接受邀请， 拒绝  面试
    async handleDaily_chat(content, params) {
      let { data } = await axios.post('job/api/daily_chat/apply', params);
      return data;
    },
  },
}
export default APIMYAPPLY