//枚举值列表
export function commonEnumFormat(datalist, label = 'label') { //公共枚举过滤器，用于生成不同的过滤器
  return function (input, locale = 'en') {

    if (!input) {
      return '-'
    }
    let current = datalist.find(item => {
      return item.value == input
    });
    if (current) {
      return current[`${label}_${locale}`]
    } else {

      return '-'
    }
  }
}

export const oneToOneDailyStatus = [{     //日常1v1洽谈邀约状态
  value: 1,
  label_en: 'Waiting',
  label_zh: '等待处理'
}, {
  value: 2,
  label_en: 'Success',
  label_zh: '预约成功'
}, {
  value: 3,
  label_en: 'Reject',
  label_zh: '拒绝'
}, {
  value: 4,
  label_en: 'Cancel',
  label_zh: '取消'
}]

export const companyRole = [
  {         //企业角色管理
    value: 9,
    label_en: 'Cargo Contact',
    label_zh: '询盘联系人'
  },
  {
    value: 21,
    label_en: 'The role of information download of hall',
    label_zh: '航交会单一展馆资料下载角色'
  },
  {
    value: 34,
    label_en: 'Admin of candidate',
    label_zh: '人才专区管理员'
  }
]

export const shippingLine = [ //航线
  {
    "id": 1,
    "code": "CN",
    "label_zh": "中国",
    "label_en": "CHINA"
  },
  {
    "id": 2,
    "code": "JP",
    "label_zh": "日本线",
    "label_en": "JAPAN"
  },
  {
    "id": 3,
    "code": "KR",
    "label_zh": "韩国线",
    "label_en": "KOREA"
  },
  {
    "id": 4,
    "code": "ES",
    "label_zh": "东南亚线",
    "label_en": "SOUTHEAST ASIA"
  },
  {
    "id": 5,
    "code": "MD",
    "label_zh": "中东线",
    "label_en": "MIDDLE EAST"
  },
  {
    "id": 6,
    "code": "IP",
    "label_zh": "印巴线",
    "label_en": "INDIA& PAKISTAN"
  },
  {
    "id": 7,
    "code": "EU",
    "label_zh": "欧洲线",
    "label_en": "EUROPE"
  },
  {
    "id": 8,
    "code": "ER",
    "label_zh": "欧洲内陆点",
    "label_en": "EUROPEIN"
  },
  {
    "id": 9,
    "code": "MS",
    "label_zh": "地中海",
    "label_en": "MEDITERRANEAN SEA"
  },
  {
    "id": 10,
    "code": "US",
    "label_zh": "美国线",
    "label_en": "USA"
  },
  {
    "id": 11,
    "code": "MS",
    "label_zh": "中南美线",
    "label_en": "CENTRAL SOUTH AMERICA"
  },
  {
    "id": 12,
    "code": "CD",
    "label_zh": "加拿大线",
    "label_en": "CANADA"
  },
  {
    "id": 13,
    "code": "AU",
    "label_zh": "澳新线",
    "label_en": "AUSTRALIA LINES"
  },
  {
    "id": 14,
    "code": "AF",
    "label_zh": "非洲线",
    "label_en": "AFRICA"
  },
  {
    "id": 15,
    "code": "RU",
    "label_zh": "俄远东",
    "label_en": "RUSSIA"
  },
  {
    "id": 16,
    "code": "CA",
    "label_zh": "中亚线",
    "label_en": "CENTRAL ASIA"
  },
  {
    "id": 17,
    "code": "TW",
    "label_zh": "台湾线",
    "label_en": "TAIWAN"
  },
  {
    "id": 18,
    "code": "PA",
    "label_zh": "太平洋岛国",
    "label_en": "PACIFIC ISLAND"
  },
  {
    "id": 19,
    "code": "DP",
    "label_zh": "朝鲜线",
    "label_en": "NORTH KOREA"
  },
  {
    "id": 20,
    "code": "HK",
    "label_zh": "香港线",
    "label_en": "HONGKONG"
  },
  {
    "id": 21,
    "code": "AD",
    "label_zh": "亚得里亚海",
    "label_en": "ADRIATIC SEA"
  }
]

export const businessList = [  //业务类型列表
  {
    "id": 1,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 0,
    "desc_en": "FCL",
    "desc_zh": "整箱"
  },
  {
    "id": 2,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 1,
    "desc_en": "LCL",
    "desc_zh": "拼箱"
  },
  {
    "id": 3,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 2,
    "desc_en": "Air import",
    "desc_zh": "空运进口"
  },
  {
    "id": 4,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 3,
    "desc_en": "Air export",
    "desc_zh": "空运出口"
  },
  {
    "id": 5,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 4,
    "desc_en": "Trucking",
    "desc_zh": "货车运输"
  },
  {
    "id": 6,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 5,
    "desc_en": "Warehouse",
    "desc_zh": "仓库"
  },
  {
    "id": 7,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 6,
    "desc_en": "Customs declaration",
    "desc_zh": "海关报关"
  },
  {
    "id": 8,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 7,
    "desc_en": "FBA",
    "desc_zh": "亚马逊物流"
  },
  {
    "id": 9,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 8,
    "desc_en": "International Express",
    "desc_zh": "国际快递"
  },
  {
    "id": 10,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 9,
    "desc_en": "Railway",
    "desc_zh": "铁路"
  },
  {
    "id": 11,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 10,
    "desc_en": "Import",
    "desc_zh": "进口"
  },
  {
    "id": 61,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 11,
    "desc_en": "Export",
    "desc_zh": "出口"
  },
  {
    "id": 12,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 0,
    "desc_en": "Particular Container",
    "desc_zh": "特殊容器"
  },
  {
    "id": 13,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 1,
    "desc_en": "Reefer Container",
    "desc_zh": "冷藏箱"
  },
  {
    "id": 14,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 2,
    "desc_en": "Tank Container",
    "desc_zh": "罐式集装箱"
  },
  {
    "id": 15,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 3,
    "desc_en": "Flexitank",
    "desc_zh": "集装箱液袋  "
  },
  {
    "id": 16,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 4,
    "desc_en": "Heavy-cargo",
    "desc_zh": "重货"
  },
  {
    "id": 193,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 5,
    "desc_en": "RO-RO shipment",
    "desc_zh": "滚装"
  },
  {
    "id": 17,
    "name_en": "Services",
    "name_zh": "服务",
    "value": 0,
    "desc_en": "Insurance",
    "desc_zh": "保险"
  },
  {
    "id": 18,
    "name_en": "Services",
    "name_zh": "服务",
    "value": 1,
    "desc_en": "Law",
    "desc_zh": "法律"
  },
  {
    "id": 19,
    "name_en": "Services",
    "name_zh": "服务",
    "value": 2,
    "desc_en": "Logistics software",
    "desc_zh": "物流软件"
  },
  {
    "id": 20,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 0,
    "desc_en": "Exhibition transportation",
    "desc_zh": "展览运输"
  },
  {
    "id": 21,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 1,
    "desc_en": "Project Logistics",
    "desc_zh": "项目物流"
  },
  {
    "id": 22,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 2,
    "desc_en": "International mover",
    "desc_zh": "国际搬家"
  },
  {
    "id": 23,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 3,
    "desc_en": "Roll Bulk",
    "desc_zh": "卷散装"
  },
  {
    "id": 24,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 4,
    "desc_en": "Multimodal transport",
    "desc_zh": "多式联运"
  },
  {
    "id": 25,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 5,
    "desc_en": "Pet transport",
    "desc_zh": "宠物运输"
  },
  {
    "id": 194,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 6,
    "desc_en": "Hand Carry",
    "desc_zh": "手提运输"
  },
  {
    "id": 26,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 0,
    "desc_en": "Dangerous cargos (FCL)",
    "desc_zh": "危险货物（整箱）"
  },
  {
    "id": 27,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 1,
    "desc_en": "Dangerous cargos (LCL)",
    "desc_zh": "危险货物（拼箱）"
  },
  {
    "id": 28,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 2,
    "desc_en": "Dangerous cargos (Air)",
    "desc_zh": "危险货物（空运）"
  },
  {
    "id": 29,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 3,
    "desc_en": "Dangerous cargos (Storage)",
    "desc_zh": "危险货物（仓储）"
  },
  {
    "id": 30,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 4,
    "desc_en": "Dangerous cargos (Land)",
    "desc_zh": "危险货物（陆运）"
  },
  {
    "id": "317",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "0",
    "desc_en": "",
    "desc_zh": "贸易"
  },
  {
    "id": "318",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "1",
    "desc_en": "",
    "desc_zh": "危险品物流"
  },
  {
    "id": "319",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "2",
    "desc_en": "",
    "desc_zh": "危险品检测"
  },
  {
    "id": "320",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "3",
    "desc_en": "",
    "desc_zh": "装备产品"
  },
  {
    "id": "321",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "4",
    "desc_en": "",
    "desc_zh": "培训咨询"
  },
  {
    "id": "322",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "5",
    "desc_en": "",
    "desc_zh": "危险品咨询"
  },
  {
    "id": "323",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "6",
    "desc_en": "",
    "desc_zh": "危险品数字化管理"
  }
]
export const positionTypeList = [           //职位类型
  {
    value: 1,
    label_zh: '销售',
    label_en: 'Sales',
  },
  {
    value: 2,
    label_zh: '操作/报关/单证',
    label_en: 'Operation',
  },
  {
    value: 3,
    label_zh: '客服',
    label_en: 'Customer Service',
  },
  {
    value: 4,
    label_zh: '市场/商务',
    label_en: 'Marketing',
  },
  {
    value: 5,
    label_zh: '仓储/运输/配送',
    label_en: 'Warehouse&Delivery',
  },
  {
    value: 6,
    label_zh: '财务',
    label_en: 'Finance',
  },
  {
    value: 7,
    label_zh: '人力资源',
    label_en: ' Human Resource',
  },
  {
    value: 8,
    label_zh: '行政/后勤/文秘',
    label_en: 'Administration/Secretary',
  },
  {
    value: 9,
    label_zh: '采购/外贸',
    label_en: 'Perchase/ Trade',
  },
  {
    value: 10,
    label_zh: '高级管理',
    label_en: 'Senior Management',
  },
  {
    value: 11,
    label_zh: '其他',
    label_en: 'Other',
  }
]
export const wages = [
  {
    value: 0,
    label_en: 'Negotiable',
    label_zh: '面议',
  },
  {
    value: 1,
    label_en: '1k',
    label_zh: '1k',
  },
  {
    value: 2,
    label_en: '2k',
    label_zh: '2k',
  },
  {
    value: 3,
    label_en: '3k',
    label_zh: '3k',
  },
  {
    value: 4,
    label_en: '4k',
    label_zh: '4k',
  },
  {
    value: 5,
    label_en: '5k',
    label_zh: '5k',
  },
  {
    value: 6,
    label_en: '6k',
    label_zh: '6k',
  },
  {
    value: 7,
    label_en: '7k',
    label_zh: '7k',
  },
  {
    value: 8,
    label_en: '8k',
    label_zh: '8k',
  },
  {
    value: 9,
    label_en: '9k',
    label_zh: '9k',
  },
  {
    value: 10,
    label_en: '10k',
    label_zh: '10k',
  },
  {
    value: 11,
    label_en: '11k',
    label_zh: '11k',
  },
  {
    value: 12,
    label_en: '12k',
    label_zh: '12k',
  },
  {
    value: 13,
    label_en: '13k',
    label_zh: '13k',
  },
  {
    value: 14,
    label_en: '14k',
    label_zh: '14k',
  },
  {
    value: 15,
    label_en: '15k',
    label_zh: '15k',
  },
  {
    value: 16,
    label_en: '16k',
    label_zh: '16k',
  },
  {
    value: 17,
    label_en: '17k',
    label_zh: '17k',
  },
  {
    value: 18,
    label_en: '18k',
    label_zh: '18k',
  },
  {
    value: 19,
    label_en: '19k',
    label_zh: '19k',
  },
  {
    value: 20,
    label_en: '20k',
    label_zh: '20k',
  },
  {
    value: 21,
    label_en: 'Above 20k',
    label_zh: '20k以上',
  }
]


export const jobType = [
  {
    value: 1,
    label_en: 'Full-time Job',
    label_zh: '全职',
  },
  {
    value: 2,
    label_en: 'Part-time Job',
    label_zh: '兼职',
  }
]

export const experience = [
  {
    value: 1,
    label_zh: '不限',
    label_en: "Unlimited",
  },
  {
    value: 2,
    label_zh: '在校/应届',
    label_en: "At School/Fresh graduate",
  },
  {
    value: 3,
    label_zh: '1年以内',
    label_en: "Within a year",
  },
  {
    value: 4,
    label_zh: '1-3年',
    label_en: "1-3years",
  },
  {
    value: 5,
    label_zh: '3-5年',
    label_en: "3-5 years",
  },
  {
    value: 6,
    label_zh: '5-10年',
    label_en: "5-10 years",
  },
  {
    value: 7,
    label_zh: '10年以上',
    label_en: "more than 10 years",
  }
]
export const levelRequire = [           //学历
  {
    value: 1,
    label_zh: '初中及以下',
    label_en: "Middle School and below"
  },
  {
    value: 2,
    label_zh: '中专/中技',
    label_en: "Technical school"
  },
  {
    value: 3,
    label_zh: '高中',
    label_en: " High School"
  },
  {
    value: 4,
    label_zh: '大专',
    label_en: "High School"
  },
  {
    value: 5,
    label_zh: '本科',
    label_en: "Bachelor "
  },
  {
    value: 6,
    label_zh: '硕士',
    label_en: "Master"
  },
  {
    value: 7,
    label_zh: '博士',
    label_en: "Doctor"
  },
  {
    value: 8,
    label_zh: '不限',
    label_en: "Unlimited"
  }
]
