export default {
    namespaced: true,
    actions: {
        async wxConfig(context, params) { //微信配置
            let { data } = await axios.get('ymfr/freight/api/application/sign', {params});
            return data;
        },
     
    },
}
