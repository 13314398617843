import Vue from 'vue'
import API_user from './API/user'
import API_index from './API/index'
import API_booth from './API/booth'
import API_position from './API/position'
import API_myApply from './API/myApply'
import baseStore from '~/baseStore'
import API_company from './API/company'
import API_collection from './API/collection'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        meeting_id: 60,
        meeting_status: null,  //1,未开始，2，进行中，3.已结束
        meeting_info: null,
    },
    mutations: {
        SET_MEETING_STUTES(state, payload) {
            state.meeting_status = payload;
        },
    },
    actions: {
        async getMeetingInfo(context, params) { //获取会议的内容
            let now_time = moment(new Date()).unix();
            let { data } = await axios.get('job/api/meeting/info', { params });
            if (data.success) {
                let isTimer = null;
                if (data.data.start_time > now_time) {
                    isTimer = 1;
                } else if (data.data.start_time <= now_time && now_time < data.data.end_time) {
                    isTimer = 2;
                } else {
                    isTimer = 3;
                }
                store.state.meeting_status = isTimer;
                store.state.meeting_info = data.data;
            }
            return data;
        },
    },
    modules: { baseStore, API_user, API_index, API_position, API_company, API_myApply, API_booth, API_collection }
})

export default store
