import { commonEnumFormat } from '~/basePlugins/filters'

import { creditClass } from '~hns/utils/enumValue'                 //信用等级
export const creditClassFormat = commonEnumFormat(creditClass)

import { unitType } from '~hns/utils/enumValue'                 //单位类型
export const unitTypeFormat = commonEnumFormat(unitType)

import { getCityNameById } from '@/baseUtils/AddressConfig'
export const getCityNameByCityId = getCityNameById//根据城市id获取城市名

import { yearList } from '~hns/utils/enumValue'                 //单位类型
export const yearListFormat = commonEnumFormat(yearList)
import { staffsizeList } from '~hns/utils/enumValue'                 //信用等级
export const staffsizeFormat = commonEnumFormat(staffsizeList);
import { weeksList } from '~hns/utils/enumValue'
export const weeksFormat = function (input, locale = 'zh') {
    let str = '-'
    if (input || input == 0) {
        str = weeksList.find(item => { return input == item.value })['label_' + locale]
    }
    return str
}