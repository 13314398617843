export default {

    navConfig: {
        logoUrl: '',
        bgColor: '#027FFF',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: true,
        notPhone: true,
    },
    basicInfoLocale: "en",//en all
}