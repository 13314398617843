export default {

  navConfig: {
      logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/console.log.png',
      bgColor: 'rgb(126, 201, 241)',
      textColor: '#fff',
      activeTextColor: '#345292'
  },
  accountConfig: {
      notUseWx: true
  },
  pageConfig: {
      companyPage: {
          url: '/companyDetail',
          isEncode: true,
      },
  }
}