export default {
    actions: {
        async getUserList(contact, params) {
            //公司所有联系人
            let { data } = await axios.get("job/api/user/list", { params });
            return data;
        },
        // 职位列表
        async job_positionList(context, params) {               //发布职位列表
            let { data } = await axios.post('job/api/job_positon/manage', params);
            return data;
        },
        // 职位列表
        async job_positionSave(context, params) {               //发布职位列表
            let { data } = await axios.post('job/api/job_position/save', params);
            return data;
        },
        async job_positiondetail(context, params) {               //发布职位详情
            let { data } = await axios.post('job/api/job_position/detail', params);
            return data;
        },
        async getResumeList(context, params) {// 获取简历列表
            let { data } = await axios.post("job/api/resume/list",params);
            return data;
          },
          async changeStatus(context, params) {// 下载简历状态
            let { data } = await axios.post("job/api/user_job_position/save",params);
            return data;
          },
        async saveCheckLabelList(content, params) {//面试管理
          let {data}=await axios.post("job/api/interview/manage",params)
          return data
        },
        async changeApplyStatus9_10(content, params) {//录用不合适
          let {data}=await axios.post("job/api/apply_status/update",params)
          return data
        }
    }
}