import Vue from 'vue'
import baseStore from '~/baseStore'
import baseConsole from '~bac/store'
import home from './API_home'
import register from './API_register'
import rankingList from './API_rankingList'
import booth from './API_booth'
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        meeting_id: 70,
        enrollInfo: {},
        isApplyShow: false,
        closeTravel:false,//关闭出行弹窗
    },
    mutations: {
        SET_ENROLL_INFO(state, payload) {
            state.enrollInfo = payload;
        },
        SET_ISAPPLY_SHOW(state, payload) {
            state.isApplyShow = payload;
        },
        SET_CLOSE_TRAVEL(state,payload){
            state.closeTravel=payload
        }
    },
    actions: {

    },
    modules: { baseStore, baseConsole, home, register, rankingList, booth }
})

export default store
