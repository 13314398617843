export default {
    actions: {
        async getbiuniqueToken(context, params) {                   //获取视频聊天token
            let { data } = await axios.get('live/api/agora/rtc_token', { params });
            return data;
        },
        async getbiuniqueTalkToken(context, params) {            //获取1v1聊天token
            let { data } = await axios.get('live/api/agora/rtm_token', { params });
            return data;
        },
        async getRTMHistoryMsg(context, params) {                      //获取历史消息
            let { data } = await axios.post('live/api/agora/msg/history', params);
            return data;
        },
        async getVideoChatInfo(context, params) {                         //获取视频聊天信息
            let { data } = await axios.get('meeting/api/meeting_one2one_live/info', { params });
            return data;
        },
        async getVideoChatInfoFree(context, params) {                      //获取视频自由聊天信息(坐席，自由)
            let { data } = await axios.get('meeting/api/meeting_free_chat/info', { params });
            return data;
        },
        async reportDocUpload(content, params) {            //上传文档上报
            let { data } = await axios.post('meeting_new/api/file_upload/reporting', params);
            return data;
        },
        async addchatLog(content, params) {                  //增加日志
            let { data } = await axios.post('meeting/api/one2one_history_and_realtime/add', params);
            return data;
        }
    }
}