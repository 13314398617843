import baseConfig from '~/baseConfig/index'

export default {
  actions:{
    async getOverseasCountryList(context, params){//直通海外国家列表
      let {data}=await axios.post('inquiry/api/siffa_overseas/country_city',params)
      return data
    },
    async getOverseasList(context, params){//直通海外列表
      let {data}=await axios.post('inquiry/api/siffa_overseas/company_list',params)
      return data
    },
    async consignorOffer(context, params){//一键询盘
      let {data}=await axios.post("inquiry/api/inquiry/creat_v2",params)
      return data
    },
    async inquiry_inquiryDetail(context, params) {//询盘详情
      let { data } = await axios.get('inquiry/api/inquiry/detail', { params })
      return data;
    },
    async inquiryEditDetail(context, params){//编辑询盘详情
      let {data}=await axios.post("inquiry/api/inquiry/update_v2",params)
      return data
    },
    async baseAssociation_event(context,params){
      //协会联盟活动列表
      let { data } = await axios.post(
        "siffa/api/association_event/list",
        params
      );
      return data;
    },
    async baseGetJobPositionList(context,params){
      //职位列表
      let { data } = await axios.post("job/api/job_position/list", params);
      return data;
    },
  }
}