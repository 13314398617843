import Vue from 'vue'
import baseStore from '~/baseStore'
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {
        getInviteInfo(context, params) {
            return new Promise(async (resolve, reject) => {
                let { data } = await axios.post("daily_chat/api/daily_chat_invite/record", params)
                resolve(data);
            })
        }
    },
    modules: { baseStore }
})

export default store