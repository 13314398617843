import Vue from 'vue'

import baseStore from '~/baseStore'
import API_company from './API/company'
import API_booth from './API/booth'
import API_user from './API/user'
import API_index from './API/index'
import API_position from './API/position'
import baseConsole from '~bac/store'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },

    modules: { baseStore, API_company, API_booth, API_user, API_index, baseConsole,API_position }
})

export default store
