export default {
  namespaced: true,
  actions: {
    async companyRegister(context, params) {
      //公司注册接口
      let {data} = await axios.post("company/api/bind_company", params);
      return data;
    },
    async companyJoinMeeting(content, params) {
      //公司报名会议
      let {data} = await axios.post("expo/api/expo/company_bind", params);
      return data;
    },
    async obtainCompanyBussniess(content, params) {
      //获取公司业务
      let {data} = await axios.get("company/api/company_business/info", {
        params,
      });
      return data;
    },
    async joinMeetingTyoe(content, params) {
      //会议参展类型
      let {data} = await axios.post(
        "admin_meeting_new/api/meeting_attend_config/list",
        params
      );
      return data;
    },
    async userJoinMeeting(content, params) {
      //用户报名
      let {data} = await axios.post("expo/api/expo/registration", params);
      return data;
    },
    async getUserInfo(context, params) {
      let {data} = await axios.get("user/api/userinfo", {params});
      if (data.success) {
        context.commit("baseStore/SET_USER_INFO", data.data, {root: true});
      }
      return data;
    },
    async companyImageSave(content, params) {
      //公司图片上传
      let {data} = await axios.post("media/api/company_picture/save", params);
      return data;
    },
    async getCompanyImageList(content, params) {
      //公司图片列表
      let {data} = await axios.post("media/api/company_picture/list", params);
      return data;
    },
    async getCompanyVideoList(content, params) {
      //获取公司视频
      let {data} = await axios.post("media/api/video/list", params);
      return data;
    },
    async getCompanyNotRegister(content, params) {
      //公司未报名人员列表
      let {data} = await axios.get("vote/api/company_no_apply_user/list", {
        params,
      });
      return data;
    },
    async getCompanyRegisterPersonList(content, params) {
      //企业已报名人员
      let {data} = await axios.get("vote/api/company_apply_user/list", {
        params,
      });
      return data;
    },
    async userRegisterCompanyMeeting(content, params) {
      //企业个人报名
      let {data} = await axios.post("vote/api/vote_user_apply/save", params);
      return data;
    },
    async addVideoByCompant(content, params) {
      //公司视频上传
      let {data} = await axios.post("media/api/video_add", params);
      return data;
    },
    async saveCompanyVideo(content, params) {
      //公司视频保存
      let {data} = await axios.post("media/api/upload_video_token", params);
      return data;
    },
    async companyRegisterEpidemic(content, params) {
      //公司抗疫报名
      let {data} = await axios.post("vote/api/vote_company/add", params);
      return data;
    },
    async companyRegisterDetail(content, params) {
      //公司报名详情
      let {data} = await axios.get("vote/api/vote_company_info", {params});
      return data;
    },
    async judgeCompanyRegister(content, params) {
      //判断公司是否报过名
      let {data} = await axios.get("vote/api/vote_company/check", {params});
      return data;
    },
    async wxLogin(context, params) {
      //微信登录
      let {data} = await axios.post("user/api/login/authorized/1", params);
      return data;
    },
    async registrationinfo(content, params) {
      //会议报名信息
      let {data} = await axios.get("nsf/api/check/is_enroll", {params});
      if (data.success) {
        content.commit("SET_ENROLL_INFO", data.data, {root: true});
      }
      return data;
    },
    async bindWxAndAccount(context, params) {
      //微信和账号绑定
      let {data} = await axios.post("user/api/login/authorized/2", params);
      return data;
    },
    async wechatBindCenter(context, params) {
      //微信和个人中心绑定
      let {data} = await axios.post("user/api/wechat/bind", params);
      return data;
    },
    async search_user_company(context, params) {
      //个人报名-搜索条件联想提示
      let {data} = await axios.post(
        "vote/api/vote_user_company/list",
        params
      );
      return data;
    },
    async vote_user_applyList(context, params) {
      //  抗疫英雄、物流型男、物流女神列表接口
      let {data} = await axios.post("vote/api/vote_user_apply/list", params);
      return data;
    },
    async vote_company_applyList(context, params) {
      //企业投票列表接口
      let {data} = await axios.post(
        "vote/api/vote_company_apply/list",
        params
      );
      return data;
    },
    async vote_user_apply_detail(context, params) {
      //企业投票列表接口
      let {data} = await axios.get("vote/api/vote_user_apply/detail", {
        params,
      });
      return data;
    },
    async companyBindBussess(context, params) {
      //企业绑定优势业务
      let {data} = await axios.post(
        "company/api/company_extend/save",
        params
      );
      return data;
    },
    async getCompanyBussess(context, params) {
      //获取企业优势业务
      let {data} = await axios.get("company/api/company_business/info", {
        params,
      });
      return data;
    },
    async getOverseasList(context, params) {
      //海外直通人员的列表
      let {data} = await axios.post("expo/api/overseas/person", params);
      return data;
    },
    async getCompanyBrief(context, params) {
      //  获取企业介绍
      let {data} = await axios.get("company/api/company/info", {params});
      return data;
    },
    async getCompanyBusiness(context, params) {
      //  获取企业业务信息
      let {data} = await axios.get("company/api/company_business/info", {
        params,
      });
      return data;
    },
    async user_getUserInfo(context, params) {
      //获取用户信息
      let {data} = await axios.get("user/api/userinfo", {params});
      return data;
    },
    async getCollectionDetail(context, params) {
      //名片夹详情
      let {data} = await axios.post("expo/api/card/info", params);
      return data;
    },
    async exchangeCard(context, params) {
      // 交换名片
      let {data} = await axios.post("expo/api/exchange/card", params);
      return data;
    },
    //公司报名会议信息查询
    async companyRegisterInfo(content, params) {
      //会议报名信息
      let {data} = await axios.get("nsf/api/check/is_enroll", {params});
      return data;
    },
    //获取用户信息
    async getboothUserInfo(context, params) {
      let {data} = await axios.get("user/api/userinfo", {params});
      return data;
    },
    //展位预定
    async reserve_booth(context, params) {
      let {data} = await axios.post("shippingexpo/api/reserve_booth", params);
      return data;
    },
    //展位预定 再次预定
    async reBook(context, params) {
      let {data} = await axios.post(
        "shippingexpo/api/reserve_booth/once_more",
        params
      );
      return data;
    },
    async shippingexpoBooth(context, params) {
      let {data} = await axios.get('shippingexpo/api/hbh_booth', {params})
      return data
    },
    //expo展位预定
    async expoBoothSave(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.post('exhibition/reserve_booth',params)
      return data
    },
    //展位类型
    async boothLists(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.get('exhibition/booth',{params})
      return data
    },
    //可预订的展位
    async bookableList(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.get(`exhibition/booked_booth`,{params})
      return data
    },
    //我的预定
    async myBoothList(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.get(`exhibition/booth_book`,{params})
      return data
    },
    async allBoothType(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.get(`exhibition/booth_type`,{params})
      return data
    },
    //获取所有展馆
    async getExhibition(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.get(`exhibition/activity_pavilion`,{params})
      return data
    },
    async getExhibitionBasic(context, params) {              //获取会议基本信息
      params._PREFIX='atlas'
      let {data} = await axios.get('exhibition/basic', { params });
      return data;
    },
    //获取预定过的展位
    async getReserveExhibition(context,params){
      params._PREFIX='atlas'
      let {data}=await axios.get(`exhibition/exhibitor_pavilion`,{params})
      return data
    }
  },
};
