export default {
    namespaced: true,
    actions: {
        async getTotalMsg(context, params) { //获取数据统计
            let { data } = await axios.get('job/api/recruit/statistics', {params});
            return data;
        },
        async getInterviewerList(context, params) { //首页·招聘大厅列表
            let { data } = await axios.post('job/api/recruit/list', params);
            return data;
        },
        async getinterviewDataList(context, params) { //首页·预约面试列表
            let { data } = await axios.post('job/api/interview/appointment', params);
            return data;
        },
        async getJobRecommend(context, params) { //首页·职位推荐
            let { data } = await axios.post('job/api/newest/position', params);
            return data;
        },
        async getAgendaLiveList(context, params) {//   获取会议议程列表
            let { data } = await axios.post("nsf/api/live/list", params);
            return data
        },
        //用户在议程下的邀约成功信息
        async getUserInfoByAgenda(context, params) {
            let { data } = await axios.get("meeting_new/api/query/information", { params })
            return data
        },
    },
}
