export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/consLogo.png',
        bgColor: '#4A5A81',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: true,
        notFavorite: true,
    },
    pageConfig: {
        companyPage: {
            url: '/companyDetail',
            isEncode: true,
        },
    }
}