export default {
    namespaced: true,
    actions: {
        async postList(context, params) {                    //首页运抵达资讯
            let { data } = await axios.post('booking/api/siffa_static_content/list', params);
            return data;
        },
        async getFreight(context, params) {                    //首页危拼热门航线
            let { data } = await axios.get('new_freight/api/recommend/freight_lcl', {params});
            return data;
        },
        async getFreightList(context, params) {                    //运价查询
            let { data } = await axios.post('admin_freight/api/oss_freight/list', params);
            return data;
        },
        async getFreightHeader(context, params) {                    //运价详情头部
            let { data } = await axios.get('freight/api/lcl_freight/info', { params });
            return data;
        },
        async getnewFreightInfo(context, params) {                    //运价详情的费用方案
            let { data } = await axios.post('new_freight/api/port_surcharge/query', params);
            return data;
        },
        async downloadFreightInfo(context, params) {                    // 目的港费用导出
            let { data } = await axios.post('new_freight/api/port_surcharge/download', params);
            return data;
        },
        async getAllOrderInfo(context, params) {                    //订单查询详情
            let { data } = await axios.get('booking/api/all_order/details', {params});
            return data;
        },
        async getFclInfoList(context, params) {                    //整箱运价
            let { data } = await axios.post('freight/api/new_freight/list', params);
            return data;
        },
        async getFclInfoDetail(context, params) {                    //整箱运价详情
            let { data } = await axios.post('freight/api/new_freight/detail', params);
            return data;
        },
        async mackInquire(context, params) {                    //生成询价
            let { data } = await axios.post('freight/api/client_inquiry/save', params);
            return data;
        },
        async offer_save(context, params) {                    //主动报价
            let { data } = await axios.post('freight/api/offer/save', params);
            return data;
        },
        async check_unno(context, params) { // 检查unno 是否正确
            let { data } = await axios.get('freight/api/check/unno', {params})
            return data;
        },
        async quotation_visit_save(context, params) {                    //报价访问记录保存
            let { data } = await axios.post('freight/api/quotation_visit/save', params);
            return data;
        },
        async quotationIsShare(context, params) { //分享报价
            let { data } = await axios.post('freight/api/quotation_share/update', params);
            return data;
        },
        // async premium_percentage(context, params) { //获取溢价比例  弃用
        //     let { data } = await axios.get('admin_freight/api/premium_percentage', {params});
        //     return data;
        // },
        async freight_share_save(context, params) { //获取溢价比例
            let { data } = await axios.post('new_freight/api/freight_share/save', params);
            return data;
        },
        async arrival_tracking_list(context, params) { //查询海外到港跟踪信息
            let { data } = await axios.get('freight/api/arrival_tracking/new_details',{params});
            return data;
        },
        async cooperative_client_list(context,params) {                 //玉漭  合作客户查询
            let { data } = await axios.post('user/api/cooperative/client_list', params);
            return data;
        },
        async getBannerList(context,params) {               // 查询banner
            let { data } = await axios.get('banner/api/banners', {params});
            return data;
        },
        async dangerousEmbargoList(context,params) {               // 船司禁运名录查询
            let { data } = await axios.post('freight/api/dangerous_embargo/query', params);
            return data;
        },
        async hazardousDirectoryList(context,params) {               // 危险品名录查询
            let { data } = await axios.post('booking/api/cas/info', params);
            return data;
        },
    }
}