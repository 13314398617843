import consoleConfig from '~bac/utils/consoleConfig/index'
import { _encode } from '~/basePlugins/base64'
const BACCONFIG = consoleConfig[process.env.VUE_APP_PROJECT_NAME]
export function handleTurnToCompanyPage(params) {
    let origin = window.location.origin;
    let companyConfig = BACCONFIG.pageConfig.companyPage;
    let query = '';
    if (companyConfig.isEncode) {
        query = `${companyConfig.url}?queryParams=${_encode(params)}`
    } else {
        query = `${companyConfig.url}?${convertObj(params)}`
    }
    let openUrl = encodeURI(`${origin}${query}`);
    window.open(openUrl)
    //console.log(openUrl)
}

export function convertObj(data) {
    var _result = [];
    for (var key in data) {
        var value = data[key];
        if (value.constructor == Array) {
            value.forEach(function (_value) {
                _result.push(key + "=" + _value);
            });
        } else {
            _result.push(key + "=" + value);
        }
    }
    return _result.join("&");
};