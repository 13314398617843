export default {

  navConfig: {
      logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dls/dlsLogo.png',
      bgColor: '#344ea7',
      textColor: '#fff',
      activeTextColor: '#fff'
  },
  accountConfig: {
      notUseWx: true,
      notFavorite: true,
  },
  pageConfig: {
      companyPage: {
          url: '/companyDetail',
          isEncode: true,
      },
  },
  sendCard: true,//发送名片，等待对方确认
}