import baseConfig from '~/baseConfig/index.js'

export default {
    actions: {
        async baseSign_Login(context, params) { //用户登录
            let expires, isInfo;
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            if (params.expires) {
                expires = params.expires;
                delete params.expires;
            }
            if (params.isInfo) {
                isInfo = params.isInfo;
                delete params.isInfo;
            }
            let { data } = await axios.post('user/api/login', params);
            let _data = data;
            if (!isInfo && _data.success) {
                let { data } = await context.dispatch('baseSign_getUserInfo', { user_id: _data.data.user_id });
                context.commit('baseStore/SET_GLOBAL_USER_ID', { user_id: _data.data.user_id, expires }, { root: true });
            }
            return data;
        },
        async baseSign_getUserInfo(context, params) { //获取用户信息
            let { data } = await axios.get('user/api/userinfo', { params });
            if (data.success) {
                data.data.aila_no = data.data.source == 13||data.data.source == 30 ? data.data.is_vip : data.data.aila_no;
                context.commit('baseStore/SET_USER_INFO', data.data, { root: true });
                context.commit('baseStore/SET_GLOBAL_USER_ID', { user_id: params.user_id }, { root: true });
                let { auth } = await context.dispatch('baseSign_getUserAuth', { user_id: params.user_id })
            }
            return data;
        },
        async baseSign_getUserTokenInfo(context, params) { //通过Token获取用户信息
            let { data } = await axios.get('user/api/userinfo_by_token', { params });
            if (data.success) {
                context.commit('baseStore/SET_USER_INFO', data.data, { root: true });
                context.commit('baseStore/SET_GLOBAL_USER_ID', { user_id: data.data.id }, { root: true });
                let { auth } = await context.dispatch('baseSign_getUserAuth', { user_id: data.data.id })
            }
            return data;
        },
        async baseSign_getUserAuth(context, params) {                       //获取用户权限
            let { data } = await axios.get('user/api/permission', { params });
            if (data.success) {
                context.commit('baseStore/INIT_USERAUTH', data.data, { root: true });
            }
            return data;
        },
        async baseSign_Register(context, params) {           //用户注册
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;            //增加注册来源统计
            let { data } = await axios.post('user/api/signup', params);
            return data;
        },
        async baseSign_checkIdentifier(context, params) {     //校验
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.get('user/api/identity/check', { params });
            return data;
        },
        async baseSign_resetPassword(context, params) {             //重置密码
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.post('user/api/password/reset', params);
            return data;
        },
        async baseSign_Forget(context, params) { //忘记密码获取邮件
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.get('user/api/password/forget', { params });
            return data;
        },
        async baseSign_getActiveMailRegain(context, params) { //重新获取激活邮件
            params.source = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            let { data } = await axios.get('user/api/regain_active_mail', { params });
            return data;
        },
        async baseSign_Verify(context, params) {             //有效性校验
            let { data } = await axios.get('user/api/verify', { params });
            return data;
        },
        async getCompanyStatus(context, params) {             //获取用户公司绑定关系
            let { data } = await axios.get('user/api/user_company/status', {params});
            return data;
        },
        async baseSign_getMeetingEnrollInfo(context, params) {          //獲取會議報名信息
            let { data } = await axios.get("nsf/api/check/is_enroll", { params });
            if (data.success) {
                context.commit('baseStore/SET_ENROLL_INFO', data.data, { root: true })
            }
            return data;
        },
        async signSaveIp(context, params){//注册时保存ip
            let {data}=await axios.post("user/api/ip_register/save",params)
            return
        },
        async joinMeetingUserManage(context, params) {//参会人员管理列表
            let { data } = await axios.post('expo/api/attendee/manage', params)
            return data
        },
    }
}