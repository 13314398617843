export const COUNTRYLIST = [{
    "id": 1,
    "code": "CHN",
    "name_zh": "中国",
    "name_en": "China",
    "area_code": "+86",
    "continent": "Asia"
},
{
    "id": 2,
    "code": "ALB",
    "name_zh": "阿尔巴尼亚",
    "name_en": "Albania",
    "area_code": "+355",
    "continent": "Europe"
},
{
    "id": 3,
    "code": "DZA",
    "name_zh": "阿尔及利亚",
    "name_en": "Algeria",
    "area_code": "+213",
    "continent": "Africa"
},
{
    "id": 4,
    "code": "AFG",
    "name_zh": "阿富汗",
    "name_en": "Afghanistan",
    "area_code": "+93",
    "continent": "Asia"
},
{
    "id": 5,
    "code": "ARG",
    "name_zh": "阿根廷",
    "name_en": "Argentina",
    "area_code": "+54",
    "continent": "South America"
},
{
    "id": 6,
    "code": "ARE",
    "name_zh": "阿拉伯联合酋长国",
    "name_en": "United Arab Emirates",
    "area_code": "+971",
    "continent": "Asia"
},
{
    "id": 7,
    "code": "ABW",
    "name_zh": "阿鲁巴",
    "name_en": "Aruba",
    "area_code": "+297",
    "continent": "South America"
},
{
    "id": 8,
    "code": "OMN",
    "name_zh": "阿曼",
    "name_en": "Oman",
    "area_code": "+968",
    "continent": "Asia"
},
{
    "id": 9,
    "code": "AZE",
    "name_zh": "阿塞拜疆",
    "name_en": "Azerbaijan",
    "area_code": "+994",
    "continent": "Asia"
},
{
    "id": 10,
    "code": "ASC",
    "name_zh": "阿森松岛",
    "name_en": "Ascension Island",
    "area_code": "+247",
    "continent": "Oceania"
},
{
    "id": 11,
    "code": "EGY",
    "name_zh": "埃及",
    "name_en": "Egypt",
    "area_code": "+20",
    "continent": "Africa"
},
{
    "id": 12,
    "code": "ETH",
    "name_zh": "埃塞俄比亚",
    "name_en": "Ethiopia",
    "area_code": "+251",
    "continent": "Africa"
},
{
    "id": 13,
    "code": "IRL",
    "name_zh": "爱尔兰",
    "name_en": "Ireland",
    "area_code": "+353",
    "continent": "Europe"
},
{
    "id": 14,
    "code": "EST",
    "name_zh": "爱沙尼亚",
    "name_en": "Estonia",
    "area_code": "+372",
    "continent": "Europe"
},
{
    "id": 15,
    "code": "AND",
    "name_zh": "安道尔",
    "name_en": "Andorra",
    "area_code": "+376",
    "continent": "Europe"
},
{
    "id": 16,
    "code": "AGO",
    "name_zh": "安哥拉",
    "name_en": "Angola",
    "area_code": "+244",
    "continent": "Africa"
},
{
    "id": 17,
    "code": "AIA",
    "name_zh": "安圭拉",
    "name_en": "Anguilla",
    "area_code": "+264",
    "continent": "South America"
},
{
    "id": 18,
    "code": "ATG",
    "name_zh": "安提瓜岛和巴布达",
    "name_en": "Antigua and Barbuda",
    "area_code": "+1268",
    "continent": "Oceania"
},
{
    "id": 19,
    "code": "AUS",
    "name_zh": "澳大利亚",
    "name_en": "Australia",
    "area_code": "+61",
    "continent": "Oceania"
},
{
    "id": 20,
    "code": "AUT",
    "name_zh": "奥地利",
    "name_en": "Austria",
    "area_code": "+43",
    "continent": "Europe"
},
{
    "id": 21,
    "code": "ALA",
    "name_zh": "奥兰群岛",
    "name_en": "Aland lslands",
    "area_code": "+358",
    "continent": "Europe"
},
{
    "id": 22,
    "code": "BRB",
    "name_zh": "巴巴多斯岛",
    "name_en": "Barbados",
    "area_code": "+1246",
    "continent": "North America"
},
{
    "id": 23,
    "code": "PNG",
    "name_zh": "巴布亚新几内亚",
    "name_en": "Papua New Guinea",
    "area_code": "+675",
    "continent": "Oceania"
},
{
    "id": 24,
    "code": "BHS",
    "name_zh": "巴哈马",
    "name_en": "Bahamas",
    "area_code": "+1809",
    "continent": "North America"
},
{
    "id": 25,
    "code": "PAK",
    "name_zh": "巴基斯坦",
    "name_en": "Pakistan",
    "area_code": "+92",
    "continent": "Asia"
},
{
    "id": 26,
    "code": "PRY",
    "name_zh": "巴拉圭",
    "name_en": "Paraguay",
    "area_code": "+595",
    "continent": "South America"
},
{
    "id": 27,
    "code": "PSE",
    "name_zh": "巴勒斯坦",
    "name_en": "Palestinian Authority",
    "area_code": "+930",
    "continent": "Asia"
},
{
    "id": 28,
    "code": "BHR",
    "name_zh": "巴林",
    "name_en": "Bahrain",
    "area_code": "+973",
    "continent": "Asia"
},
{
    "id": 29,
    "code": "PAN",
    "name_zh": "巴拿马",
    "name_en": "Panama",
    "area_code": "+507",
    "continent": "North America"
},
{
    "id": 30,
    "code": "BRA",
    "name_zh": "巴西",
    "name_en": "Brazil",
    "area_code": "+55",
    "continent": "South America"
},
{
    "id": 31,
    "code": "BLR",
    "name_zh": "白俄罗斯",
    "name_en": "Belarus",
    "area_code": "+375",
    "continent": "Europe"
},
{
    "id": 32,
    "code": "BMU",
    "name_zh": "百慕大",
    "name_en": "Bermuda",
    "area_code": "+441",
    "continent": "Oceania"
},
{
    "id": 33,
    "code": "BGR",
    "name_zh": "保加利亚",
    "name_en": "Bulgaria",
    "area_code": "+359",
    "continent": "Europe"
},
{
    "id": 34,
    "code": "MNP",
    "name_zh": "北马里亚纳群岛",
    "name_en": "Northern Mariana Islands",
    "area_code": "+1670",
    "continent": "Oceania"
},
{
    "id": 35,
    "code": "BEN",
    "name_zh": "贝宁",
    "name_en": "Benin",
    "area_code": "+229",
    "continent": "Africa"
},
{
    "id": 36,
    "code": "BEL",
    "name_zh": "比利时",
    "name_en": "Belgium",
    "area_code": "+32",
    "continent": "Europe"
},
{
    "id": 37,
    "code": "ISL",
    "name_zh": "冰岛",
    "name_en": "Iceland",
    "area_code": "+354",
    "continent": "Europe"
},
{
    "id": 38,
    "code": "PRI",
    "name_zh": "波多黎各",
    "name_en": "Puerto Rico",
    "area_code": "+1787",
    "continent": "South America"
},
{
    "id": 39,
    "code": "POL",
    "name_zh": "波兰",
    "name_en": "Poland",
    "area_code": "+48",
    "continent": "Europe"
},
{
    "id": 40,
    "code": "BOL",
    "name_zh": "玻利维亚",
    "name_en": "Bolivia",
    "area_code": "+591",
    "continent": "South America"
},
{
    "id": 41,
    "code": "BIH",
    "name_zh": "波斯尼亚和黑塞哥维那",
    "name_en": "Bosnia and Herzegovina",
    "area_code": "+387",
    "continent": "Europe"
},
{
    "id": 42,
    "code": "BWA",
    "name_zh": "博茨瓦纳",
    "name_en": "Botswana",
    "area_code": "+267",
    "continent": "Africa"
},
{
    "id": 43,
    "code": "BLZ",
    "name_zh": "伯利兹",
    "name_en": "Belize",
    "area_code": "+501",
    "continent": "Oceania"
},
{
    "id": 44,
    "code": "BTN",
    "name_zh": "不丹",
    "name_en": "Bhutan",
    "area_code": "+975",
    "continent": "Asia"
},
{
    "id": 45,
    "code": "BFA",
    "name_zh": "布基纳法索",
    "name_en": "Burkina Faso",
    "area_code": "+226",
    "continent": "Africa"
},
{
    "id": 46,
    "code": "BDI",
    "name_zh": "布隆迪",
    "name_en": "Burundi",
    "area_code": "+257",
    "continent": "Africa"
},
{
    "id": 47,
    "code": "BVT",
    "name_zh": "布韦岛",
    "name_en": "Bouvet Island",
    "area_code": null,
    "continent": "Antarctica"
},
{
    "id": 48,
    "code": "PRK",
    "name_zh": "朝鲜",
    "name_en": "North Korea",
    "area_code": "+85",
    "continent": "Asia"
},
{
    "id": 49,
    "code": "DNK",
    "name_zh": "丹麦",
    "name_en": "Denmark",
    "area_code": "+45",
    "continent": "Europe"
},
{
    "id": 50,
    "code": "DEU",
    "name_zh": "德国",
    "name_en": "Germany",
    "area_code": "+49",
    "continent": "Europe"
},
{
    "id": 51,
    "code": "TLS",
    "name_zh": "东帝汶",
    "name_en": "Timor-Leste",
    "area_code": "+670",
    "continent": "Asia"
},
{
    "id": 52,
    "code": "TGO",
    "name_zh": "多哥",
    "name_en": "Togo",
    "area_code": "+228",
    "continent": "Africa"
},
{
    "id": 53,
    "code": "DMA",
    "name_zh": "多米尼加",
    "name_en": "Dominica",
    "area_code": "+1767",
    "continent": "North America"
},
{
    "id": 54,
    "code": "DOM",
    "name_zh": "多米尼加共和国",
    "name_en": "Dominican Republic",
    "area_code": "+809",
    "continent": "North America"
},
{
    "id": 55,
    "code": "RUS",
    "name_zh": "俄罗斯",
    "name_en": "Russia",
    "area_code": "+7",
    "continent": "Europe"
},
{
    "id": 56,
    "code": "ECU",
    "name_zh": "厄瓜多尔",
    "name_en": "Ecuador",
    "area_code": "+593",
    "continent": "South America"
},
{
    "id": 57,
    "code": "ERI",
    "name_zh": "厄立特里亚",
    "name_en": "Eritrea",
    "area_code": "+291",
    "continent": "Africa"
},
{
    "id": 58,
    "code": "FRA",
    "name_zh": "法国",
    "name_en": "France",
    "area_code": "+33",
    "continent": "Europe"
},
{
    "id": 59,
    "code": "FRO",
    "name_zh": "法罗群岛",
    "name_en": "Faroe Islands",
    "area_code": "+298",
    "continent": "Europe"
},
{
    "id": 60,
    "code": "PYF",
    "name_zh": "法属波利尼西亚",
    "name_en": "French Polynesia",
    "area_code": "+689",
    "continent": "Oceania"
},
{
    "id": 61,
    "code": "GUF",
    "name_zh": "法属圭亚那",
    "name_en": "French Guiana",
    "area_code": "+594",
    "continent": "South America"
},
{
    "id": 62,
    "code": "ATF",
    "name_zh": "法属南部领地",
    "name_en": "French Southern and Antarctic Lands",
    "area_code": null,
    "continent": "Antarctica"
},
{
    "id": 63,
    "code": "VAT",
    "name_zh": "梵蒂冈",
    "name_en": "Vatican City",
    "area_code": "+379",
    "continent": "Europe"
},
{
    "id": 64,
    "code": "PHL",
    "name_zh": "菲律宾",
    "name_en": "Philippines",
    "area_code": "+63",
    "continent": "Asia"
},
{
    "id": 65,
    "code": "FJI",
    "name_zh": "斐济",
    "name_en": "Fiji Islands",
    "area_code": "+679",
    "continent": "Oceania"
},
{
    "id": 66,
    "code": "FIN",
    "name_zh": "芬兰",
    "name_en": "Finland",
    "area_code": "+358",
    "continent": "Europe"
},
{
    "id": 67,
    "code": "CPV",
    "name_zh": "佛得角",
    "name_en": "Cape Verde",
    "area_code": "+238",
    "continent": "Africa"
},
{
    "id": 68,
    "code": "FLK",
    "name_zh": "弗兰克群岛",
    "name_en": "Falkland Islands",
    "area_code": null,
    "continent": "South America"
},
{
    "id": 69,
    "code": "GMB",
    "name_zh": "冈比亚",
    "name_en": "Gambia",
    "area_code": "+220",
    "continent": "Africa"
},
{
    "id": 70,
    "code": "COG",
    "name_zh": "刚果",
    "name_en": "Congo",
    "area_code": "+242",
    "continent": "Africa"
},
{
    "id": 71,
    "code": "COD",
    "name_zh": "刚果民主共和国",
    "name_en": "Congo(DRC)",
    "area_code": "+243",
    "continent": "Africa"
},
{
    "id": 72,
    "code": "COL",
    "name_zh": "哥伦比亚",
    "name_en": "Colombia",
    "area_code": "+57",
    "continent": "South America"
},
{
    "id": 73,
    "code": "CRI",
    "name_zh": "哥斯达黎加",
    "name_en": "Costa Rica",
    "area_code": "+506",
    "continent": "North America"
},
{
    "id": 74,
    "code": "GGY",
    "name_zh": "格恩西岛",
    "name_en": "Guernsey",
    "area_code": null,
    "continent": "Europe"
},
{
    "id": 75,
    "code": "GRD",
    "name_zh": "格林纳达",
    "name_en": "Grenada",
    "area_code": "+1809",
    "continent": "North America"
},
{
    "id": 76,
    "code": "GRL",
    "name_zh": "格陵兰",
    "name_en": "Greenland",
    "area_code": "+299",
    "continent": "Europe"
},
{
    "id": 77,
    "code": "CUB",
    "name_zh": "古巴",
    "name_en": "Cuba",
    "area_code": "+53",
    "continent": "North America"
},
{
    "id": 78,
    "code": "GLP",
    "name_zh": "瓜德罗普",
    "name_en": "Guadeloupe",
    "area_code": "+590",
    "continent": "North America"
},
{
    "id": 79,
    "code": "GUM",
    "name_zh": "关岛",
    "name_en": "Guam",
    "area_code": "+671",
    "continent": "Oceania"
},
{
    "id": 80,
    "code": "GUY",
    "name_zh": "圭亚那",
    "name_en": "Guyana",
    "area_code": "+592",
    "continent": "South America"
},
{
    "id": 81,
    "code": "KAZ",
    "name_zh": "哈萨克斯坦",
    "name_en": "Kazakhstan",
    "area_code": "+7",
    "continent": "Asia"
},
{
    "id": 82,
    "code": "HTI",
    "name_zh": "海地",
    "name_en": "Haiti",
    "area_code": "+509",
    "continent": "Oceania"
},
{
    "id": 83,
    "code": "KOR",
    "name_zh": "韩国",
    "name_en": "Korea",
    "area_code": "+82",
    "continent": "Asia"
},
{
    "id": 84,
    "code": "NLD",
    "name_zh": "荷兰",
    "name_en": "Netherlands",
    "area_code": "+31",
    "continent": "Europe"
},
{
    "id": 85,
    "code": "ANT",
    "name_zh": "荷属安地列斯",
    "name_en": "Netherlands Antilles",
    "area_code": null,
    "continent": "North America"
},
{
    "id": 86,
    "code": "HMD",
    "name_zh": "赫德和麦克唐纳群岛",
    "name_en": "Heard Island and McDonald Islands",
    "area_code": null,
    "continent": "Antarctica"
},
{
    "id": 87,
    "code": "HND",
    "name_zh": "洪都拉斯",
    "name_en": "Honduras",
    "area_code": "+504",
    "continent": "South America"
},
{
    "id": 88,
    "code": "KIR",
    "name_zh": "基里巴斯",
    "name_en": "Kiribati",
    "area_code": "+686",
    "continent": "Asia"
},
{
    "id": 89,
    "code": "DJI",
    "name_zh": "吉布提",
    "name_en": "Djibouti",
    "area_code": "+253",
    "continent": "Africa"
},
{
    "id": 90,
    "code": "KGZ",
    "name_zh": "吉尔吉斯斯坦",
    "name_en": "Kyrgyzstan",
    "area_code": "+996",
    "continent": "Asia"
},
{
    "id": 91,
    "code": "GIN",
    "name_zh": "几内亚",
    "name_en": "Guinea",
    "area_code": "+224",
    "continent": "Africa"
},
{
    "id": 92,
    "code": "GNB",
    "name_zh": "几内亚比绍",
    "name_en": "Guinea-Bissau",
    "area_code": "+245",
    "continent": "Africa"
},
{
    "id": 93,
    "code": "CAN",
    "name_zh": "加拿大",
    "name_en": "Canada",
    "area_code": "+1",
    "continent": "North America"
},
{
    "id": 94,
    "code": "GHA",
    "name_zh": "加纳",
    "name_en": "Ghana",
    "area_code": "+233",
    "continent": "Africa"
},
{
    "id": 95,
    "code": "GAB",
    "name_zh": "加蓬",
    "name_en": "Gabon",
    "area_code": "+241",
    "continent": "Africa"
},
{
    "id": 96,
    "code": "KHM",
    "name_zh": "柬埔寨",
    "name_en": "Cambodia",
    "area_code": "+855",
    "continent": "Asia"
},
{
    "id": 97,
    "code": "CZE",
    "name_zh": "捷克共和国",
    "name_en": "Czech Republic",
    "area_code": "+420",
    "continent": "Europe"
},
{
    "id": 98,
    "code": "ZWE",
    "name_zh": "津巴布韦",
    "name_en": "Zimbabwe",
    "area_code": "+263",
    "continent": "Africa"
},
{
    "id": 99,
    "code": "CMR",
    "name_zh": "喀麦隆",
    "name_en": "Cameroon",
    "area_code": "+237",
    "continent": "Africa"
},
{
    "id": 100,
    "code": "QAT",
    "name_zh": "卡塔尔",
    "name_en": "Qatar",
    "area_code": "+974",
    "continent": "Asia"
},
{
    "id": 101,
    "code": "CYM",
    "name_zh": "开曼群岛",
    "name_en": "Cayman Islands",
    "area_code": "+1345",
    "continent": "North America"
},
{
    "id": 102,
    "code": "CCK",
    "name_zh": "科科斯群岛",
    "name_en": "Cocos(Keeling)Islands",
    "area_code": null,
    "continent": "Oceania"
},
{
    "id": 103,
    "code": "COM",
    "name_zh": "科摩罗",
    "name_en": "Comoros",
    "area_code": "+269",
    "continent": "Africa"
},
{
    "id": 104,
    "code": "CIV",
    "name_zh": "科特迪瓦",
    "name_en": "Cote d'Ivoire",
    "area_code": "+225",
    "continent": "Africa"
},
{
    "id": 105,
    "code": "KWT",
    "name_zh": "科威特",
    "name_en": "Kuwait",
    "area_code": "+965",
    "continent": "Asia"
},
{
    "id": 106,
    "code": "HRV",
    "name_zh": "克罗地亚",
    "name_en": "Croatia",
    "area_code": "+383",
    "continent": "Europe"
},
{
    "id": 107,
    "code": "KEN",
    "name_zh": "肯尼亚",
    "name_en": "Kenya",
    "area_code": "+254",
    "continent": "Africa"
},
{
    "id": 108,
    "code": "COK",
    "name_zh": "库克群岛",
    "name_en": "Cook Islands",
    "area_code": "+682",
    "continent": "Oceania"
},
{
    "id": 109,
    "code": "LVA",
    "name_zh": "拉脱维亚",
    "name_en": "Latvia",
    "area_code": "+371",
    "continent": "Europe"
},
{
    "id": 110,
    "code": "LSO",
    "name_zh": "莱索托",
    "name_en": "Lesotho",
    "area_code": "+266",
    "continent": "Africa"
},
{
    "id": 111,
    "code": "LAO",
    "name_zh": "老挝",
    "name_en": "Laos",
    "area_code": "+856",
    "continent": "Asia"
},
{
    "id": 112,
    "code": "LBN",
    "name_zh": "黎巴嫩",
    "name_en": "Lebanon",
    "area_code": "+961",
    "continent": "Asia"
},
{
    "id": 113,
    "code": "LBR",
    "name_zh": "利比里亚",
    "name_en": "Liberia",
    "area_code": "+231",
    "continent": "Africa"
},
{
    "id": 114,
    "code": "LBY",
    "name_zh": "利比亚",
    "name_en": "Libya",
    "area_code": "+218",
    "continent": "Africa"
},
{
    "id": 115,
    "code": "LTU",
    "name_zh": "立陶宛",
    "name_en": "Lithuania",
    "area_code": "+370",
    "continent": "Europe"
},
{
    "id": 116,
    "code": "LIE",
    "name_zh": "列支敦士登",
    "name_en": "Liechtenstein",
    "area_code": "+41",
    "continent": "Europe"
},
{
    "id": 117,
    "code": "REU",
    "name_zh": "留尼旺岛",
    "name_en": "Reunion",
    "area_code": null,
    "continent": "Africa"
},
{
    "id": 118,
    "code": "LUX",
    "name_zh": "卢森堡",
    "name_en": "Luxembourg",
    "area_code": "+352",
    "continent": "Europe"
},
{
    "id": 119,
    "code": "RWA",
    "name_zh": "卢旺达",
    "name_en": "Rwanda",
    "area_code": "+250",
    "continent": "Africa"
},
{
    "id": 120,
    "code": "ROU",
    "name_zh": "罗马尼亚",
    "name_en": "Romania",
    "area_code": "+40",
    "continent": "Europe"
},
{
    "id": 121,
    "code": "MDG",
    "name_zh": "马达加斯加",
    "name_en": "Madagascar",
    "area_code": "+261",
    "continent": "North America"
},
{
    "id": 122,
    "code": "MDV",
    "name_zh": "马尔代夫",
    "name_en": "Maldives",
    "area_code": "+960",
    "continent": "Oceania"
},
{
    "id": 123,
    "code": "MLT",
    "name_zh": "马耳他",
    "name_en": "Malta",
    "area_code": "+356",
    "continent": "Europe"
},
{
    "id": 124,
    "code": "MWI",
    "name_zh": "马拉维",
    "name_en": "Malawi",
    "area_code": "+265",
    "continent": "Europe"
},
{
    "id": 125,
    "code": "MYS",
    "name_zh": "马来西亚",
    "name_en": "Malaysia",
    "area_code": "+60",
    "continent": "Asia"
},
{
    "id": 126,
    "code": "MLI",
    "name_zh": "马里",
    "name_en": "Mali",
    "area_code": "+223",
    "continent": "Africa"
},
{
    "id": 127,
    "code": "MKD",
    "name_zh": "马其顿",
    "name_en": "Macedonia,Former Yugoslav Republic of",
    "area_code": "+389",
    "continent": "Europe"
},
{
    "id": 128,
    "code": "MHL",
    "name_zh": "马绍尔群岛",
    "name_en": "Marshall Islands",
    "area_code": "+692",
    "continent": "Oceania"
},
{
    "id": 129,
    "code": "MTQ",
    "name_zh": "马提尼克",
    "name_en": "Martinique",
    "area_code": "+596",
    "continent": "North America"
},
{
    "id": 130,
    "code": "MYT",
    "name_zh": "马约特岛",
    "name_en": "Mayotte",
    "area_code": "+269",
    "continent": "Africa"
},
{
    "id": 131,
    "code": "IMN",
    "name_zh": "曼岛",
    "name_en": "Isle of Man",
    "area_code": null,
    "continent": "Europe"
},
{
    "id": 132,
    "code": "MUS",
    "name_zh": "毛里求斯",
    "name_en": "Mauritius",
    "area_code": "+230",
    "continent": "Oceania"
},
{
    "id": 133,
    "code": "MRT",
    "name_zh": "毛里塔尼亚",
    "name_en": "Mauritania",
    "area_code": "+222",
    "continent": "Africa"
},
{
    "id": 134,
    "code": "USA",
    "name_zh": "美国",
    "name_en": "United States",
    "area_code": "+1",
    "continent": "North America"
},
{
    "id": 135,
    "code": "ASM",
    "name_zh": "美属萨摩亚",
    "name_en": "American Samoa",
    "area_code": "+685",
    "continent": "Oceania"
},
{
    "id": 136,
    "code": "UMI",
    "name_zh": "美属外岛",
    "name_en": "United States Minor Outlying Islands",
    "area_code": null,
    "continent": "North America"
},
{
    "id": 137,
    "code": "MNG",
    "name_zh": "蒙古",
    "name_en": "Mongolia",
    "area_code": "+976",
    "continent": "Asia"
},
{
    "id": 138,
    "code": "MSR",
    "name_zh": "蒙特塞拉特",
    "name_en": "Montserrat",
    "area_code": "+1664",
    "continent": "Europe"
},
{
    "id": 139,
    "code": "BGD",
    "name_zh": "孟加拉",
    "name_en": "Bangladesh",
    "area_code": "+880",
    "continent": "Asia"
},
{
    "id": 140,
    "code": "FSM",
    "name_zh": "密克罗尼西亚",
    "name_en": "Micronesia",
    "area_code": "+691",
    "continent": "Oceania"
},
{
    "id": 141,
    "code": "PER",
    "name_zh": "秘鲁",
    "name_en": "Peru",
    "area_code": "+51",
    "continent": "South America"
},
{
    "id": 142,
    "code": "MMR",
    "name_zh": "缅甸",
    "name_en": "Myanmar",
    "area_code": "+95",
    "continent": "Asia"
},
{
    "id": 143,
    "code": "MDA",
    "name_zh": "摩尔多瓦",
    "name_en": "Moldova",
    "area_code": "+373",
    "continent": "South America"
},
{
    "id": 144,
    "code": "MAR",
    "name_zh": "摩洛哥",
    "name_en": "Morocco",
    "area_code": "+212",
    "continent": "Africa"
},
{
    "id": 145,
    "code": "MCO",
    "name_zh": "摩纳哥",
    "name_en": "Monaco",
    "area_code": "+377",
    "continent": "Europe"
},
{
    "id": 146,
    "code": "MOZ",
    "name_zh": "莫桑比克",
    "name_en": "Mozambique",
    "area_code": "+258",
    "continent": "Africa"
},
{
    "id": 147,
    "code": "MEX",
    "name_zh": "墨西哥",
    "name_en": "Mexico",
    "area_code": "+52",
    "continent": "North America"
},
{
    "id": 148,
    "code": "NAM",
    "name_zh": "纳米比亚",
    "name_en": "Namibia",
    "area_code": "+264",
    "continent": "Africa"
},
{
    "id": 149,
    "code": "ZAF",
    "name_zh": "南非",
    "name_en": "South Africa",
    "area_code": "+27",
    "continent": "Africa"
},
{
    "id": 150,
    "code": "ATA",
    "name_zh": "南极洲",
    "name_en": "Antarctica",
    "area_code": null,
    "continent": "Antarctica"
},
{
    "id": 151,
    "code": "SGS",
    "name_zh": "南乔治亚和南桑德威奇群岛",
    "name_en": "South Georgia and South Sandwich Islands",
    "area_code": null,
    "continent": "Antarctica"
},
{
    "id": 152,
    "code": "NRU",
    "name_zh": "瑙鲁",
    "name_en": "Nauru",
    "area_code": "+674",
    "continent": "Oceania"
},
{
    "id": 153,
    "code": "NPL",
    "name_zh": "尼泊尔",
    "name_en": "Nepal",
    "area_code": "+977",
    "continent": "Asia"
},
{
    "id": 154,
    "code": "NIC",
    "name_zh": "尼加拉瓜",
    "name_en": "Nicaragua",
    "area_code": "+505",
    "continent": "South America"
},
{
    "id": 155,
    "code": "NER",
    "name_zh": "尼日尔",
    "name_en": "Niger",
    "area_code": "+227",
    "continent": "Africa"
},
{
    "id": 156,
    "code": "NGA",
    "name_zh": "尼日利亚",
    "name_en": "Nigeria",
    "area_code": "+234",
    "continent": "Africa"
},
{
    "id": 157,
    "code": "NIU",
    "name_zh": "纽埃",
    "name_en": "Niue",
    "area_code": "+683",
    "continent": "Oceania"
},
{
    "id": 158,
    "code": "NOR",
    "name_zh": "挪威",
    "name_en": "Norway",
    "area_code": "+47",
    "continent": "Europe"
},
{
    "id": 159,
    "code": "NFK",
    "name_zh": "诺福克",
    "name_en": "Norfolk Island",
    "area_code": "+6723",
    "continent": "Europe"
},
{
    "id": 160,
    "code": "PLW",
    "name_zh": "帕劳群岛",
    "name_en": "Palau",
    "area_code": "+680",
    "continent": "Oceania"
},
{
    "id": 161,
    "code": "PCN",
    "name_zh": "皮特凯恩",
    "name_en": "Pitcairn Islands",
    "area_code": null,
    "continent": "Europe"
},
{
    "id": 162,
    "code": "PRT",
    "name_zh": "葡萄牙",
    "name_en": "Portugal",
    "area_code": "+351",
    "continent": "Europe"
},
{
    "id": 163,
    "code": "GEO",
    "name_zh": "格鲁吉亚",
    "name_en": "Georgia",
    "area_code": "+995",
    "continent": "Europe"
},
{
    "id": 164,
    "code": "JPN",
    "name_zh": "日本",
    "name_en": "Japan",
    "area_code": "+81",
    "continent": "Asia"
},
{
    "id": 165,
    "code": "SWE",
    "name_zh": "瑞典",
    "name_en": "Sweden",
    "area_code": "+46",
    "continent": "Europe"
},
{
    "id": 166,
    "code": "CHE",
    "name_zh": "瑞士",
    "name_en": "Switzerland",
    "area_code": "+41",
    "continent": "Europe"
},
{
    "id": 167,
    "code": "SLV",
    "name_zh": "萨尔瓦多",
    "name_en": "El Salvador",
    "area_code": "+503",
    "continent": "South America"
},
{
    "id": 168,
    "code": "WSM",
    "name_zh": "萨摩亚",
    "name_en": "Samoa",
    "area_code": "+685",
    "continent": "Oceania"
},
{
    "id": 169,
    "code": "SCG",
    "name_zh": "塞尔维亚",
    "name_en": "Serbia",
    "area_code": "+381",
    "continent": "Europe"
},
{
    "id": 170,
    "code": "SLE",
    "name_zh": "塞拉利昂",
    "name_en": "Sierra Leone",
    "area_code": "+232",
    "continent": "Africa"
},
{
    "id": 171,
    "code": "SEN",
    "name_zh": "塞内加尔",
    "name_en": "Senegal",
    "area_code": "+221",
    "continent": "Africa"
},
{
    "id": 172,
    "code": "CYP",
    "name_zh": "塞浦路斯",
    "name_en": "Cyprus",
    "area_code": "+357",
    "continent": "Europe"
},
{
    "id": 173,
    "code": "SYC",
    "name_zh": "塞舌尔",
    "name_en": "Seychelles",
    "area_code": "+248",
    "continent": "Oceania"
},
{
    "id": 174,
    "code": "SAU",
    "name_zh": "沙特阿拉伯",
    "name_en": "Saudi Arabia",
    "area_code": "+966",
    "continent": "Asia"
},
{
    "id": 175,
    "code": "CXR",
    "name_zh": "圣诞岛",
    "name_en": "Christmas Island",
    "area_code": "+61 9164",
    "continent": "North America"
},
{
    "id": 176,
    "code": "STP",
    "name_zh": "圣多美和普林西比",
    "name_en": "Sao Tome and Principe",
    "area_code": "+239",
    "continent": "North America"
},
{
    "id": 177,
    "code": "SHN",
    "name_zh": "圣赫勒拿",
    "name_en": "St.Helena",
    "area_code": "+290",
    "continent": "North America"
},
{
    "id": 178,
    "code": "KNA",
    "name_zh": "圣基茨和尼维斯",
    "name_en": "St.Kitts and Nevis",
    "area_code": "+1809",
    "continent": "South America"
},
{
    "id": 179,
    "code": "LCA",
    "name_zh": "圣卢西亚",
    "name_en": "St.Lucia",
    "area_code": "+1758",
    "continent": "South America"
},
{
    "id": 180,
    "code": "SMR",
    "name_zh": "圣马力诺",
    "name_en": "San Marino",
    "area_code": "+378",
    "continent": "Europe"
},
{
    "id": 181,
    "code": "SPM",
    "name_zh": "圣皮埃尔和米克隆群岛",
    "name_en": "St.Pierre and Miquelon",
    "area_code": null,
    "continent": "South America"
},
{
    "id": 182,
    "code": "VCT",
    "name_zh": "圣文森特和格林纳丁斯",
    "name_en": "St.Vincent and the Grenadines",
    "area_code": "+1784",
    "continent": "South America"
},
{
    "id": 183,
    "code": "LKA",
    "name_zh": "斯里兰卡",
    "name_en": "Sri Lanka",
    "area_code": "+94",
    "continent": "Asia"
},
{
    "id": 184,
    "code": "SVK",
    "name_zh": "斯洛伐克",
    "name_en": "Slovakia",
    "area_code": "+421",
    "continent": "Europe"
},
{
    "id": 185,
    "code": "SVN",
    "name_zh": "斯洛文尼亚",
    "name_en": "Slovenia",
    "area_code": "+386",
    "continent": "Europe"
},
{
    "id": 186,
    "code": "SJM",
    "name_zh": "斯瓦尔巴和扬马廷",
    "name_en": "Svalbard and Jan Mayen",
    "area_code": null,
    "continent": "Europe"
},
{
    "id": 187,
    "code": "SWZ",
    "name_zh": "斯威士兰",
    "name_en": "Swaziland",
    "area_code": "+268",
    "continent": "Europe"
},
{
    "id": 188,
    "code": "SDN",
    "name_zh": "苏丹",
    "name_en": "Sudan",
    "area_code": "+249",
    "continent": "Africa"
},
{
    "id": 189,
    "code": "SUR",
    "name_zh": "苏里南",
    "name_en": "Suriname",
    "area_code": "+597",
    "continent": "Africa"
},
{
    "id": 190,
    "code": "SLB",
    "name_zh": "所罗门群岛",
    "name_en": "Solomon Islands",
    "area_code": "+677",
    "continent": "Antarctica"
},
{
    "id": 191,
    "code": "SOM",
    "name_zh": "索马里",
    "name_en": "Somalia",
    "area_code": "+252",
    "continent": "Africa"
},
{
    "id": 192,
    "code": "TJK",
    "name_zh": "塔吉克斯坦",
    "name_en": "Tajikistan",
    "area_code": "+992",
    "continent": "Asia"
},
{
    "id": 193,
    "code": "THA",
    "name_zh": "泰国",
    "name_en": "Thailand",
    "area_code": "+66",
    "continent": "Asia"
},
{
    "id": 194,
    "code": "TZA",
    "name_zh": "坦桑尼亚",
    "name_en": "Tanzania",
    "area_code": "+255",
    "continent": "Africa"
},
{
    "id": 195,
    "code": "TON",
    "name_zh": "汤加",
    "name_en": "Tonga",
    "area_code": "+676",
    "continent": "Africa"
},
{
    "id": 196,
    "code": "TCA",
    "name_zh": "特克斯和凯克特斯群岛",
    "name_en": "Turks and Caicos Islands",
    "area_code": null,
    "continent": "North America"
},
{
    "id": 197,
    "code": "TAA",
    "name_zh": "特里斯坦达昆哈",
    "name_en": "Tristan da Cunha",
    "area_code": null,
    "continent": "Oceania"
},
{
    "id": 198,
    "code": "TTO",
    "name_zh": "特立尼达和多巴哥",
    "name_en": "Trinidad and Tobago",
    "area_code": "+1809",
    "continent": "North America"
},
{
    "id": 199,
    "code": "TUN",
    "name_zh": "突尼斯",
    "name_en": "Tunisia",
    "area_code": "+216",
    "continent": "Africa"
},
{
    "id": 200,
    "code": "TUV",
    "name_zh": "图瓦卢",
    "name_en": "Tuvalu",
    "area_code": "+688",
    "continent": "Africa"
},
{
    "id": 201,
    "code": "TUR",
    "name_zh": "土耳其",
    "name_en": "Turkey",
    "area_code": "+90",
    "continent": "Europe"
},
{
    "id": 202,
    "code": "TKM",
    "name_zh": "土库曼斯坦",
    "name_en": "Turkmenistan",
    "area_code": "+993",
    "continent": "Asia"
},
{
    "id": 203,
    "code": "TKL",
    "name_zh": "托克劳",
    "name_en": "Tokelau",
    "area_code": "+690",
    "continent": "Oceania"
},
{
    "id": 204,
    "code": "WLF",
    "name_zh": "瓦利斯和福图纳",
    "name_en": "Wallis and Futuna",
    "area_code": null,
    "continent": "Oceania"
},
{
    "id": 205,
    "code": "VUT",
    "name_zh": "瓦努阿图",
    "name_en": "Vanuatu",
    "area_code": "+678",
    "continent": "Oceania"
},
{
    "id": 206,
    "code": "GTM",
    "name_zh": "危地马拉",
    "name_en": "Guatemala",
    "area_code": "+502",
    "continent": "South America"
},
{
    "id": 207,
    "code": "VIR",
    "name_zh": "维尔京群岛，美属",
    "name_en": "Virgin Islands",
    "area_code": null,
    "continent": "North America"
},
{
    "id": 208,
    "code": "VGB",
    "name_zh": "维尔京群岛，英属",
    "name_en": "Virgin Islands,British",
    "area_code": null,
    "continent": "North America"
},
{
    "id": 209,
    "code": "VEN",
    "name_zh": "委内瑞拉",
    "name_en": "Venezuela",
    "area_code": "+58",
    "continent": "South America"
},
{
    "id": 210,
    "code": "BRN",
    "name_zh": "文莱",
    "name_en": "Brunei",
    "area_code": "+673",
    "continent": "Asia"
},
{
    "id": 211,
    "code": "UGA",
    "name_zh": "乌干达",
    "name_en": "Uganda",
    "area_code": "+256",
    "continent": "Africa"
},
{
    "id": 212,
    "code": "UKR",
    "name_zh": "乌克兰",
    "name_en": "Ukraine",
    "area_code": "+380",
    "continent": "Europe"
},
{
    "id": 213,
    "code": "URY",
    "name_zh": "乌拉圭",
    "name_en": "Uruguay",
    "area_code": "+598",
    "continent": "South America"
},
{
    "id": 214,
    "code": "UZB",
    "name_zh": "乌兹别克斯坦",
    "name_en": "Uzbekistan",
    "area_code": "+998",
    "continent": "Asia"
},
{
    "id": 215,
    "code": "ESP",
    "name_zh": "西班牙",
    "name_en": "Spain",
    "area_code": "+34",
    "continent": "Europe"
},
{
    "id": 216,
    "code": "GRC",
    "name_zh": "希腊",
    "name_en": "Greece",
    "area_code": "+30",
    "continent": "Europe"
},
{
    "id": 217,
    "code": "SGP",
    "name_zh": "新加坡",
    "name_en": "Singapore",
    "area_code": "+65",
    "continent": "Asia"
},
{
    "id": 218,
    "code": "NCL",
    "name_zh": "新喀里多尼亚",
    "name_en": "New Caledonia",
    "area_code": "+687",
    "continent": "Oceania"
},
{
    "id": 219,
    "code": "NZL",
    "name_zh": "新西兰",
    "name_en": "New Zealand",
    "area_code": "+64",
    "continent": "Oceania"
},
{
    "id": 220,
    "code": "HUN",
    "name_zh": "匈牙利",
    "name_en": "Hungary",
    "area_code": "+36",
    "continent": "Europe"
},
{
    "id": 221,
    "code": "SYR",
    "name_zh": "叙利亚",
    "name_en": "Syria",
    "area_code": "+963",
    "continent": "Asia"
},
{
    "id": 222,
    "code": "JAM",
    "name_zh": "牙买加",
    "name_en": "Jamaica",
    "area_code": "+1876",
    "continent": "North America"
},
{
    "id": 223,
    "code": "ARM",
    "name_zh": "亚美尼亚",
    "name_en": "Armenia",
    "area_code": "+374",
    "continent": "Europe"
},
{
    "id": 224,
    "code": "YEM",
    "name_zh": "也门",
    "name_en": "Yemen",
    "area_code": "+967",
    "continent": "Asia"
},
{
    "id": 225,
    "code": "IRQ",
    "name_zh": "伊拉克",
    "name_en": "Iraq",
    "area_code": "+964",
    "continent": "Asia"
},
{
    "id": 226,
    "code": "IRN",
    "name_zh": "伊朗",
    "name_en": "Iran",
    "area_code": "+98",
    "continent": "Asia"
},
{
    "id": 227,
    "code": "ISR",
    "name_zh": "以色列",
    "name_en": "Israel",
    "area_code": "+972",
    "continent": "Europe"
},
{
    "id": 228,
    "code": "ITA",
    "name_zh": "意大利",
    "name_en": "Italy",
    "area_code": "+39",
    "continent": "Europe"
},
{
    "id": 229,
    "code": "IND",
    "name_zh": "印度",
    "name_en": "India",
    "area_code": "+91",
    "continent": "Asia"
},
{
    "id": 230,
    "code": "IDN",
    "name_zh": "印度尼西亚",
    "name_en": "Indonesia",
    "area_code": "+62",
    "continent": "Asia"
},
{
    "id": 231,
    "code": "GBR",
    "name_zh": "英国",
    "name_en": "United Kingdom",
    "area_code": "+44",
    "continent": "Europe"
},
{
    "id": 232,
    "code": "IOT",
    "name_zh": "英属印度洋领地",
    "name_en": "British Indian Ocean Territory",
    "area_code": null,
    "continent": "Asia"
},
{
    "id": 233,
    "code": "JOR",
    "name_zh": "约旦",
    "name_en": "Jordan",
    "area_code": "+962",
    "continent": "Asia"
},
{
    "id": 234,
    "code": "VNM",
    "name_zh": "越南",
    "name_en": "Vietnam",
    "area_code": "+84",
    "continent": "Asia"
},
{
    "id": 235,
    "code": "ZMB",
    "name_zh": "赞比亚",
    "name_en": "Zambia",
    "area_code": "+260",
    "continent": "Africa"
},
{
    "id": 236,
    "code": "JEY",
    "name_zh": "泽西岛",
    "name_en": "Jersey",
    "area_code": "+44",
    "continent": "Europe"
},
{
    "id": 237,
    "code": "TCD",
    "name_zh": "乍得",
    "name_en": "Chad",
    "area_code": "+235",
    "continent": "Africa"
},
{
    "id": 238,
    "code": "GIB",
    "name_zh": "直布罗陀",
    "name_en": "Gibraltar",
    "area_code": "+350",
    "continent": "Europe"
},
{
    "id": 239,
    "code": "CHL",
    "name_zh": "智利",
    "name_en": "Chile",
    "area_code": "+56",
    "continent": "South America"
},
{
    "id": 240,
    "code": "CAF",
    "name_zh": "中非共和国",
    "name_en": "Central African Republic",
    "area_code": "+236",
    "continent": "Africa"
},
{
    "id": 241,
    "code": "ZTW",
    "name_zh": "中国台湾",
    "name_en": "China Taiwan",
    "area_code": "+886",
    "continent": "Asia"
},
{
    "id": 242,
    "code": "HS",
    "name_zh": "黑山",
    "name_en": "Montenegro",
    "area_code": "+382",
    "continent": "Europe"
},
{
    "id": 243,
    "code": "KSW",
    "name_zh": "科索沃",
    "name_en": "Kosovo",
    "area_code": "+384",
    "continent": "Europe"
}
]