export const AiLaDomainNames = {       //用于跳转或iframe跳转时
  dev: {
    zh: 'http://192.168.1.14:3000',
    en: 'https://dev.aila.site'
  },
  test: {
    zh: 'https://test2.aila.site',
    en: 'https://test2en.aila.site'
  },
  idc: {
    zh: 'https://ailaworld.com.cn',
    en: 'https://ailaworld.com'
  }
};
export const WRTDomainNames = {       //用于跳转或iframe跳转时
  dev: {
    zh: 'http://localhost:8080',
    en: 'http://localhost:8080'
  },
  test: {
    zh: 'https://test-wrt.aila.site',
    en: 'https://testen-wrt.aila.site'
  },
  idc: {
    zh: 'https://wrt.aila.site',
    en: 'https://wrten.aila.site'
  }
};


export const Industry = [ //所属行业
  {
    value: 'Trader',
    label_en: 'Trader',
    label_zh: '贸易商',
    children: [{
      value: '180',
      label_en: 'Manufacturer',
      label_zh: '制造商',
    },
      {
        value: '181',
        label_en: 'Trading Company',
        label_zh: '贸易公司',
      }
    ]
  },
  {
    value: 'Logistics Supplier',
    label_en: 'Logistics Supplier',
    label_zh: '物流供应商',
    children: [{
      value: '182',
      label_en: 'Freight Forwarder',
      label_zh: '货运代理',
    },
      {
        value: '183',
        label_en: 'Customs Declaration & Inspection Declaration',
        label_zh: '报关报检',
      },
      {
        value: '184',
        label_en: 'Warehousing',
        label_zh: '仓储',
      },
      {
        value: '185',
        label_en: 'Supply Chain Management',
        label_zh: '供应链管理',
      }
    ]
  },
  {
    value: 'Logistics Product Service Provider',//
    label_en: 'Logistics Product Service Provider',
    label_zh: '物流产品服务商',
    children: [
      {
        value:'316',
        label_en: 'Logistics Platform',
        label_zh: '物流平台'
      },
      {
        value: '186',
        label_en: 'Logistics Software',
        label_zh: '物流软件',
      },
      {
        value: '187',
        label_en: 'Logistics Facility',
        label_zh: '物流设备',
      },
      {
        value: '188',
        label_en: 'Financial Insurance',
        label_zh: '金融保险',
      },
      {
        value: '189',
        label_en: 'Legal advisor',
        label_zh: '法律顾问',
      }
    ]
  },
  {
    value: 'Industry regulator',
    label_en: 'Industry regulator',
    label_zh: '行业监管人',
    children: [{
      value: '190',
      label_en: 'Commodity Inspection Bureau',
      label_zh: '商检局',
    },
      {
        value: '191',
        label_en: 'State Administration of Foreign Exchange',
        label_zh: '外管局',
      },
      {
        value: '192',
        label_en: 'Customs',
        label_zh: '海关',
      }
    ]
  }
]

export const sexList = [ //性别列表
  {
    value: 1,
    label_en: "Male",
    label_zh: "男"
  },
  {
    value: 2,
    label_en: 'Female',
    label_zh: '女'
  }
]


export const enclosureType = [{ //附件类型
  value: 0,
  types: ['jpg', 'jpeg', 'png', 'bmp'],
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/JPG.png'
}, {
  value: 1,
  types: ['pdf'],
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/pdf.png'
}, {
  value: 2,
  types: ['doc', 'docx'],
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/doc.png'
}, {
  value: 3,
  types: ['xls', 'xlsx'],
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/XLS.png'
}, {
  value: 4,
  types: ['txt'],
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/txt.png'
}, {
  value: 5,
  types: ['rar', 'zip'],
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/zip.png'
}]

export const certificateList = [ //协会类型
  {
    value: 1,
    login_sea: false,  //检索条件中 是否需要登录才能查看，
    alwaysShow: true,  //未登录状态下  被检索出来的AILA会员公司 可以看到检索出来的logo时值为true,不能看到为false
    noLoginNoVip: false,  //未登录状态下 被检索出来的非aila会员  能否看到的协会logo
    vipUserCanSea: true, //登录状态下 被访问的公司不是aila会员时  用户只有是aila会员才可查看到logo
    name: 'AILA',
    label_en: "AILA - AI Logistics Alliance",
    label_zh: "AILA - 智能物流联盟",
    //logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/aila.png'    //7.29屏蔽AILA图标
  },
  {
    value: 2,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'NVOCC',
    label_en: 'NVOCC - non-vessel operating common carrier',
    label_zh: 'NVOCC - 无船承运人',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/nvocc.png'
  },
  {
    value: 3,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'FIATA',
    label_en: 'FIATA - International Federation of Freight Forwarders Associations',
    label_zh: 'FIATA - 国际货运代理协会联合会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/fiata.png'
  },
  {
    value: 4,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'WCA',
    label_en: 'WCA - World Cargo Alliance',
    label_zh: 'WCA - 世界货物运输联盟',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/wca.png'
  },
  {
    value: 5,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'IATA',
    label_en: 'IATA - International Air Transport Association',
    label_zh: 'IATA - 国际航空运输协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/iata.png'
  },
  {
    value: 8,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'JCTRANS',
    label_en: 'JCTRANS - JCtrans Logistics Network',
    label_zh: 'JCTRANS - 锦程物流网',
    logo: "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/jc.png"
  },
  {
    value: 7,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'WIFFA',
    label_en: 'WIFFA - WORLD INTERNATIONAL FREIGHT FORWARDER ALLIANCE',
    label_zh: 'WIFFA - 国际货代信用合作平台',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/wiffa.png'
  },
  // {
  //   value: 8,
  //   label_en: 'JC',
  //   label_zh: 'JC',
  //   logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/jc.png'
  // },
  // { value: 9, label_en: 'IFFA', label_zh: 'IFFA', logo: require('~/assets/img/association/iffa.png') },
  // {
  //   value: 10,
  //   label_en: 'OFN',
  //   label_zh: 'OFN',
  //   logo: ""
  // },
  // {
  //   value: 11,
  //   label_en: 'Honors',
  //   label_zh: 'Honors',
  //   logo: ""
  // },
  {
    value: 14,
    login_sea: true,
    alwaysShow: false,
    noLoginNoVip: false,
    vipUserCanSea: false,
    name: 'MOTION FIRST',
    label_en: 'MOTION FIRST - Motion Logistics Network ',
    label_zh: 'MOTION FIRST - Motion Logistics Network ',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/lALPD2sQsu9JvVwYZA_100_24.png'
  },
  {
    value: 16,
    login_sea: true,
    alwaysShow: false,
    noLoginNoVip: false,
    vipUserCanSea: false,
    name: 'CONQUEROR',
    label_en: 'CONQUEROR - Conqueror Freight Network',
    label_zh: 'CONQUEROR - Conqueror Freight Network',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/lALPD4BhqrqcnSUYTg_78_24.png'
  },
  {
    value: 15,
    login_sea: true,
    alwaysShow: false,
    noLoginNoVip: false,
    vipUserCanSea: false,
    name: 'WFP',
    label_en: 'WFP - World Freight Partnership',
    label_zh: 'WFP - World Freight Partnership',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/lALPD3W5LsxGzBsYLg_46_24.png'
  },
  {
    value: 13,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'NBITA',
    label_en: 'NBITA - NINGBO INTERNATIONAL TRANSPORT ASSOCIATION ',
    label_zh: 'NBITA - 宁波市国际联运协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/lALPD3W5LsxcaH4YGA_24_24.png'
  },
  {
    value: 17,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,

    name: 'Captain Club',
    label_en: 'Captain Club - Captain Club',
    label_zh: 'Captain Club - 甲必丹俱乐部',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/lALPD2eDNQ5PL_8YHg_30_24.png'
  },
  {
    value: 18,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'MPA',
    label_en: 'MPA - Multi Plus Alliance ',
    label_zh: 'MPA - MPA联盟 ',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/mpa.jpg'
  },
  {
    value: 19,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'NBDLT',
    label_en: 'NBDLT - Ningbo YinZhou District Logistics Trade Federation ',
    label_zh: 'NBDLT - 宁波市鄞州区物贸联合会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/LTF.png'
  },
  {
    value: 20,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'SIFFA',
    label_en: 'SIFFA - SHANGHAI INTERNATIONAL FREIGHT FORWARDERS ASSOCIATION',
    label_zh: 'SIFFA - 上海市国际货代代理行业协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/SIFFA.png'
  },
  {
    value: 23,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'FUDA',
    label_en: 'FUDA - FUDA ALLIANCE',
    label_zh: 'FUDA - 富达会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/fudahui.png'
  },
  {
    value: 22,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'ZSFFA',
    label_en: 'ZSFFA - ZHONGSHAN FREIGHTASSOCIATION FORWARDER ASSOCIATION',
    label_zh: 'ZSFFA - 中山市国际货运代理协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/zhangshan.png'
  },
  {
    value: 54,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'AA',
    label_en: 'AA - americas.alliance',
    label_zh: 'AA - 中南美联盟',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/association/aa.png'
  },
  {
    value: 55,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'XIFFA',
    label_en: 'XIFFA - 厦门市国际货运代理协会',
    label_zh: 'XIFFA - 厦门市国际货运代理协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/logo.png'
  },
  {
    value: 56,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'DLS',
    label_en: 'DLS - 大连市物流协会',
    label_zh: 'DLS - 大连市物流协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dls/dlsLogo.png'
  },
  {
    value: 57,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'HPH',
    label_en: 'HPH - 海派会',
    label_zh: 'HPH - 海派会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/logo-right.png'
  },
  {
    value: 58,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: false,
    vipUserCanSea: true,
    name: 'HNS',
    label_en: 'HNS - 河南省国际货运代理协会',
    label_zh: 'HNS - 河南省国际货运代理协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/logo_nav.png'
  },
  {
    value: 59,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'HYM',
    label_en: 'HYM - SHIPPING INNOVATION ALLIANCE',
    label_zh: 'HYM - 航运创新联盟',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hym/home/hymLogo.png'
  },
  {
    value: 60,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'DLC',
    label_en: 'DLC - Dalian Association of Warehousing and Distribution',
    label_zh: 'DLC - 大连市仓储与配送协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/logo.png'
  },
  {
    value: 61,
    login_sea: false,
    alwaysShow: true,
    noLoginNoVip: true,
    vipUserCanSea: false,
    name: 'HEBIFFA',
    label_en: 'HEBIFFA - 河北省国际货运代理协会',
    label_zh: 'HEBIFFA - 河北省国际货运代理协会',
    logo: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/logo.png'
  },
].sort(function (a, b) {
  return (a.label_en + '').localeCompare(b.label_en + '')
})

export const businessList = [  //业务类型列表
  {
    "id": 1,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 0,
    "desc_en": "FCL",
    "desc_zh": "整箱"
  },
  {
    "id": 2,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 1,
    "desc_en": "LCL",
    "desc_zh": "拼箱"
  },
  {
    "id": 3,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 2,
    "desc_en": "Air import",
    "desc_zh": "空运进口"
  },
  {
    "id": 4,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 3,
    "desc_en": "Air export",
    "desc_zh": "空运出口"
  },
  {
    "id": 5,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 4,
    "desc_en": "Trucking",
    "desc_zh": "货车运输"
  },
  {
    "id": 6,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 5,
    "desc_en": "Warehouse",
    "desc_zh": "仓库"
  },
  {
    "id": 7,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 6,
    "desc_en": "Customs declaration",
    "desc_zh": "海关报关"
  },
  {
    "id": 8,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 7,
    "desc_en": "FBA",
    "desc_zh": "亚马逊物流"
  },
  {
    "id": 9,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 8,
    "desc_en": "International Express",
    "desc_zh": "国际快递"
  },
  {
    "id": 10,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 9,
    "desc_en": "Railway",
    "desc_zh": "铁路"
  },
  {
    "id": 11,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 10,
    "desc_en": "Import",
    "desc_zh": "进口"
  },
  {
    "id": 61,
    "name_en": "Convertional",
    "name_zh": "常规",
    "value": 11,
    "desc_en": "Export",
    "desc_zh": "出口"
  },
  {
    "id": 12,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 0,
    "desc_en": "Particular Container",
    "desc_zh": "特殊容器"
  },
  {
    "id": 13,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 1,
    "desc_en": "Reefer Container",
    "desc_zh": "冷藏箱"
  },
  {
    "id": 14,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 2,
    "desc_en": "Tank Container",
    "desc_zh": "罐式集装箱"
  },
  {
    "id": 15,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 3,
    "desc_en": "Flexitank",
    "desc_zh": "集装箱液袋  "
  },
  {
    "id": 16,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 4,
    "desc_en": "Heavy-cargo",
    "desc_zh": "重货"
  },
  {
    "id": 193,
    "name_en": "Particular Cargo",
    "name_zh": "特殊货物",
    "value": 5,
    "desc_en": "RO-RO shipment",
    "desc_zh": "滚装"
  },
  {
    "id": 17,
    "name_en": "Services",
    "name_zh": "服务",
    "value": 0,
    "desc_en": "Insurance",
    "desc_zh": "保险"
  },
  {
    "id": 18,
    "name_en": "Services",
    "name_zh": "服务",
    "value": 1,
    "desc_en": "Law",
    "desc_zh": "法律"
  },
  {
    "id": 19,
    "name_en": "Services",
    "name_zh": "服务",
    "value": 2,
    "desc_en": "Logistics software",
    "desc_zh": "物流软件"
  },
  {
    "id": 20,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 0,
    "desc_en": "Exhibition transportation",
    "desc_zh": "展览运输"
  },
  {
    "id": 21,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 1,
    "desc_en": "Project Logistics",
    "desc_zh": "项目物流"
  },
  {
    "id": 22,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 2,
    "desc_en": "International mover",
    "desc_zh": "国际搬家"
  },
  {
    "id": 23,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 3,
    "desc_en": "Roll Bulk",
    "desc_zh": "卷散装"
  },
  {
    "id": 24,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 4,
    "desc_en": "Multimodal transport",
    "desc_zh": "多式联运"
  },
  {
    "id": 25,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 5,
    "desc_en": "Pet transport",
    "desc_zh": "宠物运输"
  },
  {
    "id": 194,
    "name_en": "Other convertional",
    "name_zh": "其他",
    "value": 6,
    "desc_en": "Hand Carry",
    "desc_zh": "手提运输"
  },
  {
    "id": 26,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 0,
    "desc_en": "Dangerous cargos (FCL)",
    "desc_zh": "危险货物（整箱）"
  },
  {
    "id": 27,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 1,
    "desc_en": "Dangerous cargos (LCL)",
    "desc_zh": "危险货物（拼箱）"
  },
  {
    "id": 28,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 2,
    "desc_en": "Dangerous cargos (Air)",
    "desc_zh": "危险货物（空运）"
  },
  {
    "id": 29,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 3,
    "desc_en": "Dangerous cargos (Storage)",
    "desc_zh": "危险货物（仓储）"
  },
  {
    "id": 30,
    "name_en": "Dangerous Cargo",
    "name_zh": "危险货物",
    "value": 4,
    "desc_en": "Dangerous cargos (Land)",
    "desc_zh": "危险货物（陆运）"
  },
  {
    "id": "317",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "0",
    "desc_en": "",
    "desc_zh": "贸易"
  },
  {
    "id": "318",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "1",
    "desc_en": "",
    "desc_zh": "危险品物流"
  },
  {
    "id": "319",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "2",
    "desc_en": "",
    "desc_zh": "危险品检测"
  },
  {
    "id": "320",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "3",
    "desc_en": "",
    "desc_zh": "装备产品"
  },
  {
    "id": "321",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "4",
    "desc_en": "",
    "desc_zh": "培训咨询"
  },
  {
    "id": "322",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "5",
    "desc_en": "",
    "desc_zh": "危险品咨询"
  },
  {
    "id": "323",
    "name_en": "Services Info",
    "name_zh": "服务信息",
    "value": "6",
    "desc_en": "",
    "desc_zh": "危险品数字化管理"
  }
]
export const shippingLine = [ //航线
  {
    "id": 1,
    "code": "CN",
    "label_zh": "中国",
    "label_en": "CHINA"
  },
  {
    "id": 2,
    "code": "JP",
    "label_zh": "日本线",
    "label_en": "JAPAN"
  },
  {
    "id": 3,
    "code": "KR",
    "label_zh": "韩国线",
    "label_en": "KOREA"
  },
  {
    "id": 4,
    "code": "ES",
    "label_zh": "东南亚线",
    "label_en": "SOUTHEAST ASIA"
  },
  {
    "id": 5,
    "code": "MD",
    "label_zh": "中东线",
    "label_en": "MIDDLE EAST"
  },
  {
    "id": 6,
    "code": "IP",
    "label_zh": "印巴线",
    "label_en": "INDIA& PAKISTAN"
  },
  {
    "id": 7,
    "code": "EU",
    "label_zh": "欧洲线",
    "label_en": "EUROPE"
  },
  {
    "id": 8,
    "code": "ER",
    "label_zh": "欧洲内陆点",
    "label_en": "EUROPEIN"
  },
  {
    "id": 9,
    "code": "MS",
    "label_zh": "地中海",
    "label_en": "MEDITERRANEAN SEA"
  },
  {
    "id": 10,
    "code": "US",
    "label_zh": "美国线",
    "label_en": "USA"
  },
  {
    "id": 11,
    "code": "MS",
    "label_zh": "中南美线",
    "label_en": "CENTRAL SOUTH AMERICA"
  },
  {
    "id": 12,
    "code": "CD",
    "label_zh": "加拿大线",
    "label_en": "CANADA"
  },
  {
    "id": 13,
    "code": "AU",
    "label_zh": "澳新线",
    "label_en": "AUSTRALIA LINES"
  },
  {
    "id": 14,
    "code": "AF",
    "label_zh": "非洲线",
    "label_en": "AFRICA"
  },
  {
    "id": 15,
    "code": "RU",
    "label_zh": "俄远东",
    "label_en": "RUSSIA"
  },
  {
    "id": 16,
    "code": "CA",
    "label_zh": "中亚线",
    "label_en": "CENTRAL ASIA"
  },
  {
    "id": 17,
    "code": "TW",
    "label_zh": "台湾线",
    "label_en": "TAIWAN"
  },
  {
    "id": 18,
    "code": "PA",
    "label_zh": "太平洋岛国",
    "label_en": "PACIFIC ISLAND"
  },
  {
    "id": 19,
    "code": "DP",
    "label_zh": "朝鲜线",
    "label_en": "NORTH KOREA"
  },
  {
    "id": 20,
    "code": "HK",
    "label_zh": "香港线",
    "label_en": "HONGKONG"
  },
  {
    "id": 21,
    "code": "AD",
    "label_zh": "亚得里亚海",
    "label_en": "ADRIATIC SEA"
  }
]
export const staffsizeList = [ //企业规模
  {
    value: 1,
    label_en: "1-50 people",
    label_zh: "1-50人"
  },
  {
    value: 2,
    label_en: "51-100 people",
    label_zh: "51-100人"
  },
  {
    value: 3,
    label_en: "101-200 people",
    label_zh: "101-200人"
  },
  {
    value: 4,
    label_en: "201-500 people",
    label_zh: "201-500人"
  },
  {
    value: 5,
    label_en: "501-1000 people",
    label_zh: "501-1000人"
  },
  {
    value: 6,
    label_en: "More than 1001 people",
    label_zh: "1001人及以上"
  }
]
export const levelRequire = [           //学历
  {
      value: 1,
      label_zh: '初中及以下',
      label_en:"Middle School and below"
  },
  {
      value: 2,
      label_zh: '中专/中技',
      label_en:"Technical school"
  },
  {
      value: 3,
      label_zh: '高中',
      label_en:" High School"
  },
  {
      value: 4,
      label_zh: '大专',
      label_en:"High School"
  },
  {
      value: 5,
      label_zh: '本科',
      label_en:"Bachelor "
  },
  {
      value: 6,
      label_zh: '硕士',
      label_en:"Master"
  },
  {
      value: 7,
      label_zh: '博士',
      label_en:"Doctor"
  },
  {
      value: 8,
      label_zh: '不限',
      label_en:"Unlimited"
  }
]
export const experience = [
  {
    value: '1',
    label_zh: '不限',
    label_en:"Unlimited",
  },
  {
    value: '2',
    label_zh: '在校/应届',
    label_en:"在校/应届",
  },
  {
    value: '3',
    label_zh: '1年以内',
    label_en:"Within a year",
  },
  {
    value: '4',
    label_zh: '1-3年',
    label_en:"1-3years",
  },
  {
    value: '5',
    label_zh: '3-5年',
    label_en:"3-5 years",
  },
  {
    value: '6',
    label_zh: '5-10年',
    label_en:"5-10 years",
  },
  {
    value: '7',
    label_zh: '10年以上',
    label_en:"more than 10 years",
  }
]

export const setYears = [ //成立年限
  {
    value: '1',
    label_en: "Within a year ",
    label_zh: "一年内"
  },
  {
    value: '2',
    label_en: "1-3years",
    label_zh: "1-3年"
  },
  {
    value: '3',
    label_en: "3-5 years",
    label_zh: "3-5年"
  },
  {
    value: '4',
    label_en: "5-10 years",
    label_zh: "5-10年"
  },
  {
    value: '5',
    label_en: "more than 10 years",
    label_zh: "10年以上"
  }
]
export const distributionKinds = [ //分拨类型
  {
    value: 1,
    label_en: 'Directed distribution',
    label_zh: '定向分拨'
  },
  {
    value: 2,
    label_en: 'N>1',
    label_zh: 'N>1'
  },
  {
    value: 3,
    label_en: 'N>N',
    label_zh: 'N>N'
  },
  {
    value: 4,
    label_en: 'Manual distribution',
    label_zh: '自助分拨'
  },
]
export const inOrOut = [ //进出口方式
  {
    value: 1,
    label_en: 'Import',
    label_zh: '进口'
  },
  {
    value: 2,
    label_en: 'Export',
    label_zh: '出口'
  }
]
export const distributionMethod = [ //分拨方式
  {
    value: 1,
    label_en: 'Recommended by AiLa',
    label_zh: 'AiLa推荐'
  }
]
export const myInquiryStatus = [ //我的询盘状态
  {
    value: 0,
    label_en: 'Draft ',
    label_zh: '草稿'
  },
  {
    value: 1,
    label_en: 'Published',
    label_zh: '已发布'
  },
  {
    value: 2,
    label_en: 'Allocated',
    label_zh: '已分拨'
  },
  {
    value: 3,
    label_en: 'In engagement',
    label_zh: '接洽中'
  },
  {
    value: 4,
    label_en: 'Completed',
    label_zh: '已完成'
  }
]
export const transportType = [{     //运输方式
  value: 2,
  label_en: 'Sea',
  label_zh: '海运',
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/shipping/shipping_2.png'

}, {
  value: 1,
  label_en: 'Air',
  label_zh: '空运',
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/shipping/shipping_1.png'
}, {
  value: 3,
  label_en: 'rail',
  label_zh: '铁路',
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/tielu.jpg'
}, {
  value: 4,
  label_en: 'multimodal',
  label_zh: '多式联运',
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/shipping/shipping_4.png'
}, {
  value: 5,
  label_en: 'road',
  label_zh: '陆运',
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/shipping/shipping_3.png'
}, {
  value: 6,
  label_en: 'International Express',
  label_zh: '国际快递',
  img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/guoji.jpg'
},
]
export const commentStatus = [ //评论选项
  {
    value: 1,
    label_en: 'Excellent ',
    label_zh: '优'
  },
  {
    value: 2,
    label_en: 'Good',
    label_zh: '良'
  },
  {
    value: 3,
    label_en: 'Normal',
    label_zh: '一般'
  },
  {
    value: 4,
    label_en: 'Poor',
    label_zh: '差'
  }
]
export const identity = [ //发盘人性质
  {
    value: 1,
    label_en: 'Direct customer',
    label_zh: '直客'
  },
  {
    value: 2,
    label_en: 'Colleague',
    label_zh: '同行'
  }
]

export const weightSelect = [ //重量单位
  {
    value: 1,
    label_en: "Kg",
    label_zh: "Kg"
  },
  {
    value: 2,
    label_en: 'T',
    label_zh: 'T'
  }
]

export const volumeSelect = [ //体积单位
  {
    value: 1,
    label_en: "m³",
    label_zh: "m³"
  },
]

export const areaSelect = [ //尺寸单位
  {
    value: 1,
    label_en: "Metres",
    label_zh: "m"
  },
  {
    value: 2,
    label_en: "CMs",
    label_zh: "CMs"
  },
]

export const clauseList = [ //贸易条款
  {
    value: 97,
    label_en: "EXW",
    label_zh: "EXW"
  },
  {
    value: 98,
    label_en: "FCA",
    label_zh: "FCA"
  },
  {
    value: 99,
    label_en: "FAS",
    label_zh: "FAS"
  },
  {
    value: 100,
    label_en: "FOB",
    label_zh: "FOB"
  },
  {
    value: 101,
    label_en: "CFR",
    label_zh: "CFR"
  },
  {
    value: 102,
    label_en: "CIF",
    label_zh: "CIF"
  },
  {
    value: 103,
    label_en: "CPT",
    label_zh: "CPT"
  },
  {
    value: 104,
    label_en: "CIP",
    label_zh: "CIP"
  },
  {
    value: 105,
    label_en: "DAF",
    label_zh: "DAF"
  },
  {
    value: 106,
    label_en: "DES",
    label_zh: "DES"
  },
  {
    value: 107,
    label_en: "DEQ",
    label_zh: "DEQ"
  },
  {
    value: 108,
    label_en: "DDU",
    label_zh: "DDU"
  },
  {
    value: 109,
    label_en: "DDP",
    label_zh: "DDP"
  },
  {
    value: 110,
    label_en: "DAP",
    label_zh: "DAP"
  }
]

export const days = [
  {
    value: '1',
    label_en: '周一',
    label_zh: '周一',
  },
  {
    value: '2',
    label_en: '周二',
    label_zh: '周二',
  },
  {
    value: '3',
    label_en: '周三',
    label_zh: '周三',
  },
  {
    value: '4',
    label_en: '周四',
    label_zh: '周四',
  },
  {
    value: '5',
    label_en: '周五',
    label_zh: '周五',
  },
  {
    value: '6',
    label_en: '周六',
    label_zh: '周六',
  },
  {
    value: '7',
    label_en: '周日',
    label_zh: '周日',
  }
]


export const packageOptions = [
  {
    value: 69,
    label_zh: "袋装",
    label_en: "Bags",
  },
  {
    value: 70,
    label_zh: "无包装",
    label_en: "Unpacked",
  },
  {
    value: 71,
    label_zh: "纸箱",
    label_en: "Cartons",
  },
  {
    value: 72,
    label_zh: "圆桶",
    label_en: "Drums",
  },
  {
    value: 73,
    label_zh: "捆装",
    label_en: "Bales",
  },
  {
    value: 74,
    label_zh: "板条箱",
    label_en: "Cases / Crates",
  },
  {
    value: 75,
    label_zh: "托盘",
    label_en: "Pallets",
  },
  {
    value: 76,
    label_zh: "LD",
    label_en: "LD",
  },
  {
    value: 77,
    label_zh: "20GP",
    label_en: "20GP",
  },
  {
    value: 78,
    label_zh: "40GP",
    label_en: "40GP",
  },
  {
    value: 79,
    label_zh: "40HQ",
    label_en: "40HQ",
  },
  {
    value: 80,
    label_zh: "45HQ",
    label_en: "45HQ",
  },
  {
    value: 81,
    label_zh: "Special equipment (OT / FR container)",
    label_en: "特殊集装箱（开顶箱、框架箱",
  },
  {
    value: 308,
    label_zh: "40OT",
    label_en: "40OT",
  },
  {
    value: 309,
    label_zh: "40FR",
    label_en: "40FR",
  },
  {
    value: 310,
    label_zh: "40HT",
    label_en: "40HT",
  },
  {
    value: 311,
    label_zh: "40TK",
    label_en: "40TK",
  },
  {
    value: 312,
    label_zh: "40HG",
    label_en: "40HG",
  },
  {
    value: 313,
    label_zh: "20RF",
    label_en: "20RF",
  },
  {
    value: 314,
    label_zh: "40RF",
    label_en: "40RF",
  },
  {
    value: 315,
    label_zh: "其他",
    label_en: "Other",
  },
]

// 贸易条款
export const TradeTerms=[
  {label: 'CFR',value: 1},
  {label: 'CPT',value: 2},
  {label: 'CIF',value: 3},
  {label: 'CIP',value: 4},
  {label: 'DAF',value: 5},
  {label: 'DAP',value: 6},
  {label: 'DAT',value: 7},
  {label: 'DEQ',value: 8},
  {label: 'DDP',value: 9},
  {label: 'DDU',value: 10},
  {label: 'DES',value: 11},
  {label: 'EXW',value: 12},
  {label: 'FOB',value: 13},
  {label: 'FAS',value: 14},
  {label: 'FCA',value: 15},
]

// 运费条款
export const FreightClause=[
  {label: 'Elsewhere',value: 1,code:'EW'},
  {label: '3rd Party',value: 2,code:'3P'},
  {label: 'Prepaid and Collect',value: 3,code:'PPAC'},
  {label: 'Prepaid and Elsewhere',value: 4,code:'PPAE'},
  {label: 'Elsewhere and Collect',value: 5,code:'EWAC'},
  {label: 'FREIGHT PREPAID',value: 6,code:'PP'},
  {label: 'FREIGHT COLLECT',value: 7,code:'CC'},
  {label: 'FREIGHT AS ARRANGED',value: 8,code:'FAA'},
]

//  运输条款：
export const TransportationTerms=[
    {label: 'CFS-CFS',value: 1},
    {label: 'CFS-CY',value: 2},
    {label: 'CFS-DOOR',value: 3},
    {label: 'CFS-FO',value: 4},
    {label: 'CY-CFS',value: 5},
    {label: 'CY-CY',value: 6},
    {label: 'CY-DOOR',value: 7},
    {label: 'CY-FO',value: 8},
    {label: 'CY-LO',value: 9},
    {label: 'CY-Hook',value: 10},
    {label: 'CY-RAILRP',value: 11},
    {label: 'CY-RAMP',value: 12},
    {label: 'CY-SHIPHK',value: 13},
    {label: 'CY-TACKLE',value: 14},
    {label: 'DOOR-CFS',value: 15},
    {label: 'DOOR-CY',value: 16},
    {label: 'DOOR-DOOR',value: 17},
    {label: 'FILO',value: 18},
    {label: 'FIO',value: 19},
    {label: 'LINER',value: 20},
    {label: 'FLT',value: 21},
    {label: 'LIFO',value: 22},
]

// 单位 前端存的单位(部分) 后台数据也有一份
export const unitArr =[
  {label: 'RT',value: 'G.W.'},
  {label: 'RT（计费）',value: 'N.W.'},
  {label: 'CBM',value: 'CBM'},
  {label: 'HBL',value: 'BL'},
  {label: 'GP',value: 'CTN'},
  {label: 'SET',value: 'SET'},
  {label: 'PLT',value: 'PLT'},
  {label: 'W/M',value: 'W.M.'},
  {label: '张',value: 'PAPER' },
  {label: '品名',value: 'UNNO'},
  {label: '车/次',value: 'T.N.'}
]

// 箱种
export const boxType =[
  {label: 'GP'},
  {label: 'OT'},
  {label: 'DG' },
  {label: 'GOH' },
  {label: 'TK' },
  {label: 'NOR' },
  {label: 'FR' },
  {label: 'RH' },
  {label: 'HQ' },
  {label: 'RF' },
  {label: 'HT' },
]

// 箱型
export const boxModel =[
  {label: '20`',value:1 },
  {label: '40`',value:2},
  {label: '45`',value:3 },
]
