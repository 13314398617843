import baseConfig from '~/baseConfig'
import { CKstore } from '~/baseUtils/storage'
import { constName } from '~/baseUtils/constName'
import { AiLaDomainNames, WRTDomainNames } from '~/baseUtils/enumValue'
import { _encode } from '~/basePlugins/base64'
export function getAilaDomainName(locale = 'en') {      //获取2.0对应域名
    return AiLaDomainNames[process.env.VUE_APP_API_ENV][locale]
}

export function getWrtDomainName(locale = 'en') {          //获取wrt对应域名
    return WRTDomainNames[process.env.VUE_APP_API_ENV][locale]
}

export function confirmProjectLocale() {                //确认项目语言版本
    let currentProjectDomainNames = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].domainNames[process.env.VUE_APP_API_ENV];
    let endomainNames = [], zhdomainNames = [];
    currentProjectDomainNames.forEach(item => {
        endomainNames.push(item.en)
        zhdomainNames.push(item.zh)
    })
    return endomainNames.indexOf(window.location.host) != -1 ? 'en' : 'zh';
}

export function getOtherLocaleDomainName(locale) {          //获取项目对应语言域名
    let needLocale = locale == 'en' ? 'zh' : 'en';
    let currentProjectDomainNames = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].domainNames[process.env.VUE_APP_API_ENV];
    let currentNames = currentProjectDomainNames.find(item => {
        return item.en == window.location.host || item.zh == window.location.host
    })
    return `${window.location.protocol}//${currentNames[needLocale]}`
}

export function handleToVideoChatPage(live_id, user_id, type, ishasBack, backUrl) {                    //跳转4.xSDK版本视频聊天页面
    let origin = getWrtDomainName(confirmProjectLocale());                     //type:a_order 议程预约   d_order 日常预约   seats坐席   free自由聊天 miniBooth迷你站台
    let pathAndParamsKey = '/negotiation?parameter=';
    let _params = {
        channel_id: live_id + "",
        user_id: user_id,
        chat_type: type
    }
    if (ishasBack) {
        if (backUrl) {
            _params.back_url = backUrl
        } else {
            _params.back_url = window.location.href;
        }
    }
    let params = _encode(_params)
    let url = `${origin}${pathAndParamsKey}${params}`
    if (ishasBack) {
        window.location.href = url;
    } else {
        window.open(url)
    }
}
export function getMultiChatPage(live_id, user_id, invite_id) {//获取多人聊天地址(需要登录)
    let origin = getWrtDomainName(confirmProjectLocale());
    let pathAndParamsKey = '/sign?parameter=';
    let _params = {
        channel_id: live_id + "",
        user_id: user_id,
        invite_id: invite_id,
    }
    let params = _encode(_params)
    let url = `${origin}${pathAndParamsKey}${params}`
    return url
}
export function handleToMultiChatPage(live_id, user_id) {//不用登录直接进入
    let origin = getWrtDomainName(confirmProjectLocale());
    let pathAndParamsKey = '/multiChat?parameter=';
    let _params = {
        channel_id: live_id + "",
        user_id: user_id,
    }
    let params = _encode(_params)
    let url = `${origin}${pathAndParamsKey}${params}`
    window.open(url)
}

export function getVideoChatPage(live_id, user_id, type) {                    //跳转4.xSDK版本视频聊天页面
    let origin = getWrtDomainName(confirmProjectLocale());                     //type:a_order 议程预约   d_order 日常预约   seats坐席   free自由聊天 miniBooth迷你站台
    let pathAndParamsKey = '/negotiation?parameter=';
    let _params = {
        channel_id: live_id + "",
        user_id: user_id,
        chat_type: type
    }
    let params = _encode(_params)
    let url = `${origin}${pathAndParamsKey}${params}`
    return url;
}

export function handleToVideoChatPageOld(live_id, user_id, type) {              //跳转3.xSDK版本视频通话
    let origin = getAilaDomainName(confirmProjectLocale());                     //type:daily,free
    let pathAndParamsKey = '/networkTalking/biunique?parameter=';
    let _params = {
        channel_id: live_id + "",
        user_id: user_id
    }
    if (type) {
        _params.type = type;
    }
    let params = _encode(_params)
    let url = `${origin}${pathAndParamsKey}${params}`
    window.open(url)
}


export async function initUserIdAndInfo(store, userApi) {           //初始化用户ID和用户信息
    let cookieUserId = CKstore.get(constName[process.env.VUE_APP_PROJECT_NAME].userId)
    if (cookieUserId) {
        let result = await store.dispatch(userApi, { user_id: cookieUserId })
    }
}

export function randomString(length) {                  //获取随机字符串
    let len = length || 16;
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
    var maxPos = $chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

export function getFileType(filePath) {                 //获取文件类型(后缀名)
    let startIndex = filePath.lastIndexOf(".");
    if (startIndex != -1)
        return filePath.substring(startIndex + 1, filePath.length).toUpperCase();
    else return "";
}

export function getFileName(filePath) {              //获取文件名
    if (!filePath) {
        return "-";
    } else {
        return filePath.replace(/(.*\/)*([^.]+).*/gi, "$2");
    }
}

export function getFileSize(fileByte) {              //获取文件尺寸
    var fileSizeByte = fileByte;
    var fileSizeMsg = "";
    if (fileSizeByte < 1048576)
        fileSizeMsg = (fileSizeByte / 1024).toFixed(2) + "KB";
    else if (fileSizeByte == 1048576) fileSizeMsg = "1MB";
    else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824)
        fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(2) + "MB";
    else if (fileSizeByte > 1048576 && fileSizeByte == 1073741824)
        fileSizeMsg = "1GB";
    else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776)
        fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    else fileSizeMsg = ">1TB";
    return fileSizeMsg;
}

// 时间日期处理时间函数
export function formatDate(sortedDateList) {
    var arr = [];
    sortedDateList.forEach(function (item, i) {
        var tmpDate = new Date(item.start_time * 1000);
        let timer = moment(tmpDate).format('YYYY-MM-DD')
        // 首先取第一个时间戳（也是最小的时间戳）
        if (i === 0) {
            var tmpObj = {};
            tmpObj.date = timer; // 时间戳对应的日期
            tmpObj.dataList = []; // 存储相同时间戳日期的数组
            tmpObj.dataList.push(item);
            arr.push(tmpObj);
        } else {
            // 判断两个时间戳对应的日期是否相等，相等就加进去，不相等就另开辟新的时间戳日期
            if (arr[arr.length - 1]["date"] === timer) {
                arr[arr.length - 1]["dataList"].push(item);
            } else {
                var tmpObj = {};
                tmpObj.date = timer;
                tmpObj.dataList = [];
                tmpObj.dataList.push(item);
                arr.push(tmpObj);
            }
        }
    });
    return arr;
}

export function formatDateTz(sortedDateList) {

    let arrLength = sortedDateList.length - 1;
    let timeList = sortedDateList.concat([]);
    let diffTime = [], afterHandleTime = [];
    for (let i = arrLength; i >= 0; i--) {
        if (!(moment(timeList[i].start_time * 1000).isSame(moment(timeList[i].end_time * 1000), 'day'))) {
            diffTime.push(timeList[i]);
            timeList.splice(i, 1)
        }
    };

    diffTime.forEach(item => {
        let firstTime = Object.assign({}, item), secondTime = Object.assign({}, item);
        let firstDayEnd = moment(item.start_time * 1000).endOf('day').subtract(30, 'minute').add(1, 'seconds');
        let secondDayStart = moment(item.end_time * 1000).startOf('day');
        firstTime.end_time = firstDayEnd.unix();
        secondTime.start_time = secondDayStart.unix();
        afterHandleTime.push(firstTime);
        afterHandleTime.push(secondTime)
    })

    timeList = timeList.concat(afterHandleTime);
    timeList = timeList.filter(item => {
        return item.start_time != item.end_time
    })
    timeList.sort((a, b) => {
        return a.start_time - b.start_time
    })
    var arr = [];
    timeList.forEach(function (item, i) {
        item._STARTTIME = moment(item.start_time * 1000).format('YYYY-MM-DD HH:mm');
        item._ENDTIME = moment(item.end_time * 1000).format('YYYY-MM-DD HH:mm')
        var tmpDate = new Date(item.start_time * 1000);
        let timer = moment(tmpDate).format('YYYY-MM-DD')
        // 首先取第一个时间戳（也是最小的时间戳）
        if (i === 0) {
            var tmpObj = {};
            tmpObj.date = timer; // 时间戳对应的日期
            tmpObj.dataList = []; // 存储相同时间戳日 期的数组
            tmpObj.dataList.push(item);
            arr.push(tmpObj);
        } else {
            // 判断两个时间戳对应的日期是否相等，相等就加进去，不相等就另开辟新的时间戳日期
            if (arr[arr.length - 1]["date"] === timer) {
                arr[arr.length - 1]["dataList"].push(item);
            } else {
                var tmpObj = {};
                tmpObj.date = timer;
                tmpObj.dataList = [];
                tmpObj.dataList.push(item);
                arr.push(tmpObj);
            }
        }
    });
    arr.sort((a, b) => {
        return moment(a.date).unix() - moment(b.date).unix()
    })
    return arr;
}

//判断对象所有属性是否都为空，无需制定key集
export function isObjectAllPropEmpty(obj) {
    let count = 0,
        _count = 0;
    Object.keys(obj).forEach(item => {
        count++;
        if (obj[item] === '' || obj[item] === null && obj[item] === undefined) {
            _count++;
        }
    });
    if (_count == count) {
        return true;
    } else {
        return false;
    }
}

// 判断对象是否全部为空
export function objectValueAllEmpty(object) {
    var isEmpty = true;
    Object.keys(object).forEach(function (x) {
        if (object[x] != null && object[x] != "") {
            isEmpty = false;
        }
    });
    if (isEmpty) {//值全为空
        return true;
    }
    return false;
}

// 判断时间段是否重复方法
export function checkTimes(arr, times) {
    return arr.every(
        item =>
            item.start_time !== times.start_time &&
            item.end_time !== times.end_time &&
            (times.start_time < item.start_time ||
                times.start_time >= item.end_time)
    );

}
//将数组分割为指定个数的小数组
export function splitArr(data, senArrLen) {
    let data_len = data.length;
    let arrOuter_len = data_len % senArrLen === 0 ? data_len / senArrLen : parseInt((data_len / senArrLen) + '') + 1;
    let arrSec_len = data_len > senArrLen ? senArrLen : data_len;
    let arrOuter = new Array(arrOuter_len);
    let arrOuter_index = 0;
    for (let i = 0; i < data_len; i++) {
        if (i % senArrLen === 0) {
            arrOuter_index++;
            let len = arrSec_len * arrOuter_index;
            arrOuter[arrOuter_index - 1] = new Array(data_len % senArrLen);
            if (arrOuter_index === arrOuter_len)
                data_len % senArrLen === 0 ?
                    len = data_len % senArrLen + senArrLen * arrOuter_index :
                    len = data_len % senArrLen + senArrLen * (arrOuter_index - 1);
            let arrSec_index = 0;
            for (let k = i; k < len; k++) {
                arrOuter[arrOuter_index - 1][arrSec_index] = data[k];
                arrSec_index++;
            }
        }
    }
    return arrOuter
}

//确认邮箱登录地址(部分常用)
export function confirmEmailLogin(email) {
    const hash = {
        'qq.com': 'http://mail.qq.com',
        'gmail.com': 'http://mail.google.com',
        'sina.com': 'http://mail.sina.com.cn',
        '163.com': 'http://mail.163.com',
        '126.com': 'http://mail.126.com',
        'yeah.net': 'http://www.yeah.net/',
        'sohu.com': 'http://mail.sohu.com/',
        'tom.com': 'http://mail.tom.com/',
        '139.com': 'http://mail.10086.cn/',
        'hotmail.com': 'http://www.hotmail.com',
        'live.com': 'http://login.live.com/',
        'live.cn': 'http://login.live.cn/',
        'live.com.cn': 'http://login.live.com.cn',
        '189.com': 'http://webmail16.189.cn/webmail/',
        'yahoo.com.cn': 'http://mail.cn.yahoo.com/',
        'yahoo.cn': 'http://mail.cn.yahoo.com/',
        'eyou.com': 'http://www.eyou.com/',
        '21cn.com': 'http://mail.21cn.com/',
        '188.com': 'http://www.188.com/',
        'foxmail.com': 'http://www.foxmail.com',
        'outlook.com': 'http://www.outlook.com',
        '2980.com': 'https://www.2980.com/',
        'aol.com': 'https://login.aol.com/',
        'gmx.com': 'https://www.gmx.com/',
        'zoho.com': 'https://www.zoho.com/mail/',
        'icloud.com': 'https://www.icloud.com/',
        'aila.site': 'https://exmail.qq.com/login'
    }
    let _email = email.split('@')[1].toLowerCase();
    if (hash.hasOwnProperty(_email)) {
        return hash[_email];
    } else {
        return false
    }
}


export function getPromise() {
    let o = {
        promise: null,
        resolve: null
    }
    o.promise = new Promise(function (resolve, reject) {
        o.resolve = resolve
        o.reject = reject
    })
    return o;
}


export function checkIsNotInitPage() {
    let url = window.location.pathname;
    if (url == '/handleInitialParams') {
        return false
    } else {
        return true;
    }
}

//复制内容
export function copyText(text) {
    const input = document.createElement("textarea");
    document.body.appendChild(input);
    input.value = text;
    input.select();
    if (document.execCommand("copy")) {
        document.execCommand("copy");
    }
    document.body.removeChild(input);
}
