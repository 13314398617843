export default {
    namespaced: true,
    actions: {
        
        async getInquireFclList(context, params) { //获取整箱询价
            let { data } = await axios.get('ymfr/freight/api/client/offer', {params});
            return data;
        },
        async needOffer(context, params) { //需要询价
            let { data } = await axios.post('ymfr/freight/api/client_inquiry/save', params);
            return data;
        },
        async historyInquireList(context, params) { //历史询价
            let { data } = await axios.post('ymfr/freight/api/client/history', params);
            return data;
        },
        async getHisNumber(context, params) { //获取未报价个数
            let { data } = await axios.get('ymfr/freight/api/no_quote/count', {params});
            return data;
        },
        async getCustomerList(context, params) { //获取客户列表
            let { data } = await axios.get('ymfr/freight/api/inquiry_user/list', {params});
            return data;
        },
        async getCustomerInquiresList(context, params) { //获取客户询价列表
            let { data } = await axios.post('ymfr/freight/api/sell_inquiry', params);
            return data;
        },
        async getInquireDetail(context, params) { //获取询价详情
            let { data } = await axios.get('ymfr/freight/api/client/history_info', {params});
            return data;
        },
        async giveOffer(context, params) { //回应询价
            let { data } = await axios.post('ymfr/freight/api/res_to_inquiry/save', params);
            return data;
        },
        async getInquireNumber(context, params) { //获取客户24小时内询价信息
            let { data } = await axios.get('ymfr/freight/api/client_inquiry/check', {params});
            return data;
        },
        async quotationIsShare(context, params) { //分享报价
            let { data } = await axios.post('ymfr/freight/api/quotation_share/update', params);
            return data;
        },
        async getDangerousList(context, params) { //危品盘查询
            let { data } = await axios.post('ymfr/freight/api/dangerous_cargo/list', params);
            return data;
        },
        async quotationVisitLog(context, params) { //报价访问记录保存
            let { data } = await axios.post('ymfr/freight/api/quotation_visit/save', params);
            return data;
        },
        async quotationVisitList(context, params) { //报价访问记录查看
            let { data } = await axios.post('ymfr/freight/api/quotation_visit/list', params);
            return data;
        },
    },
}
