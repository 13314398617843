import Vue from 'vue'
import mixin from '~bac/mixin'
import * as filters from '~bac/plugins/filters'

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
});

Vue.mixin(mixin)

