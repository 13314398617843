export default {
  namespaced: true,
  actions: {
    async getRankingList(context, params) {//榜单总和具体榜单
      let { data } = await axios.post("vote/api/ranking/list", params);
      return data;
    },
    async handleVote(context, params) {//投票
      let { data } = await axios.post("vote/api/vote", params);
      return data;
    },
    async checkUserIsRegisterActive(context, params){//检查用户是否报名过活动
      let {data}=await axios.get("vote/api/vote_user/check",{params})
      return data
    },
    async updateRegisterInfo(context, params){//投票报名信息更新
      let {data}=await axios.post("vote/api/vote_media/update",params)
      return data
    }
  }
}