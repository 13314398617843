import Vue from 'vue'

import baseStore from '~/baseStore'
import API_user from './API/user'
import API_offer from './API/offer'
import API_base from './API/base'
import API_inquire from './API/inquire'
import API_bussiness from './API/bussiness'
import { LCstore } from "~/baseUtils/storage"


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isWchat: false,
        userInfo: {},
        system:'',
        status:1
    },
    mutations: {
        SET_ISWCHAT(state, payload) {
            state.isWchat = payload
        },
        SET_SYSTEM(state,payload){
            state.system = payload
        },
        SET_USER_INFO(state,payload){
            state.userInfo = payload
        },
        SET_STATUS(state,payload){
            state.status = payload
        }
    },
    actions: {
        async getUserInfo(context, payload) {
            let params = {
                user_id: payload.userId
            }
            let data = await axios.get('ymfr/user/api/user_info', { params });
            if(data.data.success){
                console.log(data)
                context.commit('baseStore/SET_USER_INFO', data.data.data)
                context.commit('SET_USER_INFO', data.data.data)
                if(data.data.data.status == 2){
                    alert('账号权限受限，请联系系统管理员！')
                }
            }else{
                LCstore.remove('userId')
                alert('登录失败')

            }
        
           
        }
    },

    modules: { baseStore, API_user ,API_offer,API_base,API_inquire,API_bussiness},
})

export default store
