export default {
  AbouttheAxpo:"About Expo",
  meetingBrief1_1:"Qingdao International Logistics Expo will be hold by 3 logistics organizations of Qingdao, They are the initiator of this EXPO and they are willing to make a great exhibition. AILA is the undertaker of this EXPO ,we will combine the online and offline contents and fulfill the EXPO with good events. This EXPO will make the oversea`s and Chinese shipping companies motiative again,which will break the ice from covid and make the logistics full of oppotunity.",
  meetingBrief1_2:"",
  big_guster:"POWERFUL GUESTS AND TOP FORUMS",
  big_guster_des:"The organizers of this expo will invite senior experts in the logistics and freight forwarding industry and industry leaders to tap the industry's capital potential and professional potential from the most cutting-edge perspective of the industry.They will explore the exploration and layout of the international logistics era from a digital perspective, reposition the role of the traditional freight forwarding industry, and bring new solutions and industry perspectives to the development of the industry and the regional freight forwarding logistics industry.",
  big_guster_arr_name_1:"Nicolas Tang",
  big_guster_arr_job_1:"Founder of Duckbill",
  // big_guster_arr_zi_1:"Topic: Digital Trailer",
  big_guster_arr_name_2:"Jack Yao",
  big_guster_arr_job_2:"Worldex Group & President",
  big_guster_arr_zi_2:"",
  big_guster_arr_name_3:"James Zhang",
  big_guster_arr_job_3:"AiLa founder & CEO",
  big_guster_arr_zi_3:"Topic: Digital Freight Forwarding",
  big_guster_arr_name_4:"Tony Yuan",
  big_guster_arr_job_4:"Founder of CargoMap",
  big_guster_arr_zi_4:"Topic: Cross-border E-commerce Innovation Platform",
  big_guster_arr_name_5:"Sign up for the Expo now,",
  big_guster_arr_name_6:"and face to face with more celebrities",
  meeting_add:"Qingdao International Convention Center . 4th Hall (Laoshao Area)  Address: 9#, Miaoling Road, Laoshan Area, Qingdao ",
  meeting_model:"ONLINE CLOUD EXHIBITION & OFFLINE COOPERATION  ",
  hotExhibitorTitle1: '“ONLINE CLOUD EXHIBITION--OFFLINE COOPERATION  ”',
  hotExhibitorTitle2: '“LOGISTICS RESEARCH CONFERENCES ”',
  hotExhibitorTitle3: '“THE OVERSEAS BOOTH”',
  hotExhibitorTitle4: '“VIVID LIVES”',
  hotExhibitorConent1: '“The EXPO will combine the online and offline events together.You can get the detailed information from official site and wechat when you are online and you can also get the same information by visiting the booth if interested in offline events.”',
  hotExhibitorConent2:"“The PRC is designed for the exhibitors and participators,which will combine the consulting,policy,hotspot disscusing and independent space for sharing.You can share your opinions with others in any conference and get the other good opinions from the online live. ”",
  hotExhibitorConent3: '“The overseas booth ia an important part of the EXPO,which is designed for the customers who can not participate in the offline events.They can use the EXPO APP to get deal details from EXPO visually.The overseas customers can register and get their own account,then they can arrange their meeting time by application whether online or offline.Also they can discuss or arrange the meeting course with other companies through application.”',
  hotExhibitorConent4: '“The EXPO has it own lives,you can see the lives anytime daylight.And we will also change the course if the participators needs.No matter online or offline,you can get theinformation from the EXPO all the time.”',
  supportUnit1: '排名不分先后',
  supportUnit2: 'QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF',
  supportUnit3: 'QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF',
  supportUnit4: 'Multi Plus Alliance ',
  supportUnit5: 'Di&DA',
  supportUnit6: 'FUDA ALLIANCE',
  supportUnit7: 'Captain Club',
  supportUnit8: 'Ningbo Cross-border E-commerce Association',
  supportUnit9: 'LY',
  supportUnit10: 'Shanghai International Freight Forwarders Association',
  supportUnit11: 'TIANJIN INTERNATIONAL FORWARDERS ASSOCIATION',
  supportUnit12: 'Wuhan Freight Logistics Agency Association',
  supportUnit13: 'ZHONGSHAN FREIGHT FORWARDERS ASSOCIATION',
  supportUnit14: 'Ningo Air Frright Association',
  jsCodeText: '',
  copyrightText:'Copyright © China（Qingdao）International Logistics Expo. All Rights Reserved',
  exhibitorServe1TitleBrif1: 'Online display and docking management of exhibitors',
  exhibitorServe1TitleBrif2: 'Online promotion for exhibitors',
  exhibitorServe1TitleBrif3: 'Online red package, booth promotion and publicity',
  exhibitorServeText1: 'Register/Login Console',
  exhibitorServeText2: 'Your Company Details',
  exhibitorServeText3: 'Open Live Broadcast',
  exhibitorServeText4: 'Send Voucher',
  exhibitorServeTitle1:  'How to register and log in the account',
  exhibitorServeTitle2:  'How to manage the online booth',
  exhibitorServeTitle3:  'How to set up activity on booth',
  sponsorChanceMoneyRMB: '国内价格（人民币）',
  sponsorChanceMoneyUSD:"USD",
  xiangmu:"Item",
  guangchang_qiqiu:"Square balloon",
  guangchang_qiqiu_right1:"All balloons will be set in the center of the square,you can see it directly after you enter the square(and it will be set according to the size of the square.);The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth)and become the VIP and concentration of exhibiton.",
  zhanting_bannner:"Banner of the column in the hall",
  zhanting_bannner_right1:"The banners are located in the hall of exhibition`s columns .The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton. ",
  zhanting_bannner_2:"Advertisement of the column in the hall",
  zhanting_bannner_2_right1:"The banners are located in the hall of exhibition`s columns .The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.",
  pic_shuiying:"Live Image Watermarking",
  pic_shuiying_right1:"Live Image Watermarking; Synchronously displayed on the official website and applet, each displayed picture has an exclusive watermark;",
  guan_qiang_ad:"Wall advertisement in the Museum",
  position_size_money:"Precise quotation according to location and size",
  offLine_luntan:"Offline forum topic sharing",
  offLine_luntan_right1:"In addition to guest speeches and roundtable forums, 10 special speech forums will be set up during the two days of the Expo. Product introductions and corporate promotion can be carried out;",
  offLine_luntan_text:"/25 minutes per session",
  qianting_big_banner:"Huge Advertisements",
  qianting_big_banner_right1:"The Huge advertisements will be showed around the enterance of the exhibition .The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.  Price: 30000RMB per(Advertisement Makings contained);Sell Count: Only two",
  jinzhanguan_men_banner:"Advertising on the entrance",
  jinzhanguan_men_banner_right1:"will be set on the exhibiton enterance doors.You can see it from anywhere in the front hall.The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.",
  nanqiang_LED:"LED screen in south wall of entrance hall",
  nanqiang_LED_right1:"The advertisements and other information will be showed on the LED screen which are located around the enterance of exhibition hall (The size of screen will be set according to the real size of the field)The sponsors who had  paid the price will get two exhibition tickets(which has all tickets benefits)(Overseas customers will get the benefit:Cloud Booth) and become the VIP and concentration of exhibiton.",
  led_maney_rmb:"100/minute 5000/hour",
  led_maney_usd:"15/minute 770/hour",
  m_fu:"/副",
  sponsorTableText1_1: 'Homepage advertisement;',
  sponsorTableText1_2: 'Exhibition hall top advertising;',
  sponsorTableText1_3: '2 online booths ranked first in the exhibition hall;',
  sponsorTableText1_4: '1 PC booth;',
  sponsorTableText1_5: '5 seats;',
  sponsorTableText1_6: 'A live promotion；',
  sponsorTableText1_7: 'Senior executives of the enterprise accept an interview;',
  sponsorTableText1_8: 'Exclusive title sponsor report: mainstream media of the industry website. Push news articles, WeChat public account, Moments,Multi-channel social media promotion such as Douyin and Weibo;',
  sponsorTableText1_9: 'Become the exclusive sponsor of the exhibition online and offline, and organize. Party simultaneous exposure;',
  sponsorTableText1_10: 'The official announcement of the opening of the conference website: 3 days before the conference. At the end of the meeting, the advertising space is promoted;',
  sponsorTableText1_11: '1 standard booth (including all rights and interests of the booth);',
  sponsorTableText1_12: '1P free color insert;',
  sponsorTableText1_13: 'The main table of the dinner for 2 people will be seated, and enjoy VIP throughout the exhibition. Distinguished treatment;',
  sponsorTableText1_14: 'Free 2 regular-priced tickets;',
  sponsorTableText2_1: '仅限海外客户购买',
  sponsorTableText2_2: 'As the exclusive sponsor for overseas exhibition areas, it will be exposed simultaneously with the organizer.',
  sponsorTableText2_3: 'Exclusive titled business report: The mainstream media of the industry website pushes news articles, WeChat public account, Moments, Tik tok, Weibo and other multi-channel social media promotion.',
  sponsorTableText2_4: 'Exclusive mini booth at the exhibition, unique corporate logo (including all rights and interests of the mini booth). Realize timely negotiation between domestic and overseas agents through the video conference system, with novel forms and unique creativity.',
  sponsorTableText2_5: 'Priority ranking of overseas exhibition areas on the conference website.',
  sponsorTableText2_6: 'The banner picture on the homepage of the conference\'s official website will be displayed throughout the exhibition, and the booth entrance will be hung.',
  sponsorTableText2_7: 'The publicity effect covers the entire overseas exhibition area',
  sponsorTableText3_1: 'Have exclusive sponsor rights for the grand welcome dinner',
  sponsorTableText3_2: 'During the banquet, companies are entitled to 5 minutes of speech time',
  sponsorTableText3_3: '3 minutes of corporate promotional video during the complimentary dinner',
  sponsorTableText3_4: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition',
  sponsorTableText3_5: 'Hosting the dinner party to broadcast corporate advertising',
  sponsorTableText3_6: 'More than 50 industry media follow up reports',
  sponsorTableText3_7: 'Free 1 standard booth (including all rights and interests of the booth)',
  sponsorTableText3_8: 'Free conference catalogue color insert 1p',
  sponsorTableText4_1: '（含制作）/组',
  sponsorTableText4_1_01: '（含制作）/对',
  sponsorTableText4_1_02: '/对',
  sponsorTableText4_1_1: '（Including productio）/个',
  sponsorTableText4_1_2: '（含制作 不含设计',
  sponsorTableText4_1_02: ' (Including production, excluding design)/piece',
  sponsorTableText4_1_03: ' Precise quotation by size',
  sponsorTableText4_1_3: '（含制作 不含设计）/组',
  sponsorTableText4_1_4: '（含设计及制作）',
  sponsorTableText4_1_5: '(excluding design)',
  sponsorTableText4_2: '/Set',
  sponsorTableText4_3: 'There are 2 in each group, located in the high-altitude position of the exhibition hall, and you will be greeted immediately after entering the exhibition hall (size according to the actual size of the venue).',
  sponsorTableText4_4: '1p of color insert of the conference catalogue.',
  sponsorTableText4_5: 'Free 2 regular-price tickets for the exhibition (including all rights and interests of the tickets) (for overseas customers, free online cloud booths)',
  sponsorTableText4_6: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition',
  sponsorTableText4_7: '$4500/set (including production)',
  sponsorTableText5_1: '（含设计及制作）',
  sponsorTableText5_2: 'The 6m*4m super large advertisement at the entrance of the exhibition will be displayed throughout the exhibition or 2 days, with wide coverage and high attention。',
  sponsorTableText5_3: '2 free tickets for the exhibition (including all rights and interests of the tickets)',
  sponsorTableText5_4: 'The main table of 2 people will be seated at the dinner party, and enjoy VIP honorable treatment throughout the exhibition.',
  sponsorTableText5_5: ' $4500 (including design and production)',
  sponsorTableText6_1: '$4500 (including design and production)',
  sponsorTableText6_2: 'All paid participants will be issued a set.',
  sponsorTableText6_3: 'Full printing of the sponsor\'s logo.',
  sponsorTableText6_4: 'It is of great commemorative significance, long use period, and lasting brand display effect.',
  sponsorTableText7_1: 'The size is 30cm*10cm*40cm',
  sponsorTableText7_2: 'Professional tailor-made, high-quality and beautifully printed LOGO, company name,etc.',
  sponsorTableText7_3: 'One paid customer to participate in the exhibition, the exhibition mobile advertising display platform, enhance brand influence.',
  sponsorTableText7_4: 'The sponsor\'s display board displays the company\'s LOGO throughout the process to expand the company\'s reputation.',
  sponsorTableText8_1: '5 pairs (10), placed on both sides of the entrance of the exhibition hall, size: 5M high, single-sided printing cloth: 1.2m wide, 3.5m high',
  sponsorTableText8_2: '1p of color inserts for the conference catalogue',
  sponsorTableText8_3: 'Free 1 regular-priced ticket (including all rights and interests of the ticket)。',
  sponsorTableText9_1: 'T Board Banners in Free talk area',
  sponsorTableText9_2: 'Placed around the free talk area, symmetrically placed at the entrance and on both sides.',
  sponsorTableText9_3: 'To meet the customized needs of enterprises, double-sided printing, three-dimensional display.',
  sponsorTableText9_4: 'Full display of the conference for 2 days',
  sponsorTableText9_5: 'Contains 2 T Board Banners, size: 180cm*80cm.',
  sponsorTableText9_6: '$900 / set',
  sponsorTableText10_1: 'Pros and cons reflect the sponsor’s promotional information.',
  sponsorTableText10_2: 'All participants will wear it throughout the exhibition for 2 days.',
  sponsorTableText10_3: 'Mobile advertising inside and outside the venue.。',
  sponsorTableText10_4: 'Exhibitors and negotiating customers are the only vouchers to enter the venue, with a high usage rate.',
  sponsorTableText10_5: '$1600 (including production)',
  sponsorTableText11_1: 'Full display of company name, LOGO and slogan',
  sponsorTableText11_2: 'All participants will wear it for 2 days during the meeting',
  sponsorTableText11_3: 'Mobile advertising inside and outside the venue',
  sponsorTableText11_4: '$1600 (including production)',
  sponsorTableText12_1: 'Exhibitors will issue a total of 3 dining vouchers for 2 days of lunch and 1 day of dinner.',
  sponsorTableText12_2: 'Corporate information such as company name and logo will be displayed on the meal coupons',
  sponsorTableText12_3: 'The only voucher for dining during the exhibition, with high exposure',
  sponsorTableText13_1: 'Sponsors can deliver the souvenirs printed with corporate LOGO and advertisements to the organizing committee and distribute them to participating customers. They can also customize exquisite souvenirs according to the needs of sponsors to show the company\'s strength and brand value and expand its influence.',
  sponsorTableText14_1: 'Colored advertising on the conference brochure',
  sponsorTableText14_2: '元/P',
  sponsorTableText14_3: '封一，封二，封底',
  sponsorTableText14_4: '（含制作费）',
  sponsorTableText14_5: '1000 copies',
  sponsorTableText14_6: 'Exquisite color printing of the conference catalog.',
  sponsorTableText14_7: 'The resource integration manual of the exhibition has a high utilization rate and comprehensive information display.',
  sponsorTableText14_8: 'Every paid attendee will have 1 brochure',
  sponsorTableText14_9: '$1600/$1400',
  sponsorTableText15_1: 'Advertisement on schedule board',
  sponsorTableText15_2: 'The organizer will set up at least 3 schedule boards at the entrance of the exhibition and other places that must be passed through to quickly increase attention and traffic',
  sponsorTableText15_3: 'Participating companies must see the display board during the exhibition, and sponsors display information with a wide audience and high exposure rate',
  sponsorTableText16_1: 'Drinking Water Sponsorship',
  sponsorTableText16_2: 'Drinking water during the exhibition, with sponsor information pasted on the bottle body, used for on-site conference service, mobile advertising, a total of 3000 bottles',
  sponsorTableText16_3: 'The organizer will display drinking water at the dinner, special matchmaking meeting, opening ceremony and other links, and the exposure rate is extremely high',
  sponsorTableText16_4: '$3300 (including design and production)',
  sponsorTableText17_1: 'Video Advertising',
  sponsorTableText17_2: 'Located in the core area of the exhibition, 7*3m LED high-definition video shows the corporate image of customers.',
  sponsorTableText17_3: 'Each company\'s promotional video within 2 minutes, totaling 5, LED carousel throughout the conference, very visual effects.',
  sponsorTableText18_1: '1V1 Video Page Banner',
  sponsorTableText18_2: 'Can be displayed next to the 1V1 video negotiation window for all guests (limited to 2)',
  sponsorTableText18_3: 'Limit two ad slots at the top of the video window',
  sponsorTableText18_4: 'Click on the video interface to display ads in real time',
  sponsorTableText18_5: 'Video Advertisement  2250USD,Banner Advertisement   1500USD',
  sponsorTableText19_1: 'Web page pop-up ads (limited to 2 companies)',
  sponsorTableText19_2: 'Enter a large pop-up advertisement on the Expo website and close it manually (limited to 1)',
  sponsorTableText19_3: 'Open the pop-up advertising interface on the homepage of the website',
  sponsorTableText19_4: 'Static display for 5 seconds',
  sponsorTableText20_1: 'Homepage Carousel Banner Ads',
  sponsorTableText20_2: 'Can be displayed on the banner of the Expo homepage (limited to 3)',
  sponsorTableText20_3: 'Expo homepage',
  sponsorTableText20_4: 'Support carousel display',
  sponsorTableText21_1: '1v1 window video ad',
  sponsorTableText21_2: '15 seconds before 1V1 start (limited to 1)',
  sponsorTableText22_1: 'Exhibition hall home page display',
  sponsorTableText22_2: 'Limited to 10 people on the first page',
  sponsorTableText22_3: 'Exhibition hall home page display',
  sponsorTableText22_4: 'Enter the enterprise interface to display',
  sponsorTableText23_1: 'Popular companies recommended',
  sponsorTableText23_2: 'Top four recommended by popular companies',
  sponsorTableText23_3: 'Popular exhibitor blocks on the homepage',
  sponsorTableText23_4: 'Top 3 in homepage progress',
  sponsorTableText24_1: 'Direct to Overseas Page',
  sponsorTableText24_2: 'Middle Banner(Exclusive)',
  sponsorTableText24_3: 'Direct to Overseas page,middle banner',
  sponsorTableText25_1: 'Login/Registration Page',
  sponsorTableText25_2: 'Left Banner(Exclusive)',
  sponsorTableText25_3: 'Login/Registration page,left banner',
  audienceServiceTitleBrif1: 'One-click exchange name card, no partners missed',
  audienceServiceTitleBrif2: 'Visit online booth, get voucher',
  audienceServiceTitleBrif3: 'Check agenda, all activites are here',
  audienceServiceTitle1: 'How to register and log in the account',
  audienceServiceTitle2: 'How to set up name card',
  audienceServiceTitle3: 'How to make 1V1 Meeting',
  audienceServiceText1_1: 'Register/Login Console',
  audienceServiceText2_1: 'Complete name card',
  audienceServiceText3_1: 'Offline 1V1 Meeting',
  audienceServiceText3_2: 'Online 1V1 Meeting',
  exhibitionagendaText1_0: 'Shanghai Convention & Exhibition Center of International Sourcing',
  exhibitionagendaText1_1: 'Set up the Exhibition, Pre-registation',
  exhibitionagendaText1_2: 'Enterprises Envelop Send voucher and red envelope on booth',
  //线上参展 线下参展
  offLine_tb_1_1:"● viewing of the live broadcast，access to the booth，posting messages，online business card exchange，Welcome Dinner。",
  offLine_tb_1_1_1:"● viewing of the live broadcast，access to the booth，posting messages，online business card exchange。",
  offLine_tb_1_2:"● Attend peak forum，Business lunch 1 set，One information bag。",
  offLine_tb_2_1:"Online：",
  offLine_tb_2_1_offline:"Offline：",
  offLine_tb_2_2:"●As long as you purchase an offline booth, you will get an online booth as a gift. For the rights and interests, please refer to the online paid booth。",
  offLine_tb_2_3:"●Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket,The standard booth fee includes the production and construction of the background board. The background boa rd design is completed by the customer or consulted by the organizer. The design fee is 1,200 yuan per booth (paid separately). The fascia board is designed, made and built by the organizer.",
  offLine_tb_2_4:"●Welcome dinner for 2 person。",
  offLine_tb_2_5:"●Two information bag。",
  offLine_tb_2_6:"●Business lunch 2 set/day。",
  offLine_tb_3_1:"Online：",
  offLine_tb_3_2:"●Companies with special booths will be given one online booth as a gift. For the rights and interests, please refer to the online paid booth.。",
  offLine_tb_3_3:"●One online booth and one top ten exhibition seat in the exhibition hall will be presented as a gift。",
  offLine_tb_3_4:"Offline：",
  offLine_tb_3_5:"● Including 4 exhibition tickets, 2 dinner places, 8 business lunches,4 information bag",
  offLine_tb_3_6:"●With a large space of 36 square meters/72square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.",
  online_sea_qy_1:"●1 online booth。",
  online_sea_qy_2:"●2 participants, accept 24-hour 1-to-1 reservations。",
  online_sea_qy_3:"●The corporate promotional video will be played simultaneously on 30 screens in the overseas exhibition area",
  online_sea_qy_4:"●Corporate leaflets + business cards, 500 sheets each",
  online_sea_names:"Overseas exhibition area (only for overseas customers to purchase)",



  off_qtmp:"Negotiation Ticket",
  stand_qy_1:"● One person for a single special matchmaking meeting is limited to one session.",
  stand_qy_2:"● One information bag (including conference brochure and other materials).",
  stand_qy_3:"● Welcome dinner for 1 person.",
  stand_qy_4:"● Participate in offline one-to-one negotiation, free negotiation.",
  stand_qy_5:"● Participate in offline exhibitions and online cloud exhibition negotiations.",
  stand_qy_6:"● Watch the live broadcast.",
  stand_qy_7:"● Business lunch 1 set/day.",
  wsmcz_title_off:"Benefits of offline exhibition",
  off_gnzq:"Domestic Exhibition Area",
  off_eve:'Free talk area',
  off_zxdjh:'Peak Forum',
  off_hywy:'Welcome Dinner',
  off_hwzq:'Overseas Exhibition Area',
  off_hwzq_min:'Mini Booth',
  off_czlc:"Online exhibition process",
  on_czlc:"Offline exhibition process",
  off_czfy:"Cost",
  time_before_1_30:"Before 28st, Feb",
  time_after_1_30:"After 28st, Feb",
  off_bzzw:"Standard Booth",
  off_tb_msg1:"● Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket. The hoarding board is designed by the customer, and the lintel board is designed by the organizer and the organizer is responsible for the construction.",
  off_tb_msg2:"● Welcome dinner for 2 person.",
  off_tb_msg3:"● One information bag (including conference brochure and other materials).",
  off_tb_msg4:"● One person for a single special matchmaking meeting is limited to one session.",
  off_tb_msg5:"● Business lunch 2 set/day.",
  off_tb_msg6:"● Including an online cloud booth.",
  off_tb_msg7:"● Starting at 3 standard booths.",
  off_tb_msg8:"● Two roll-up banners, or one TV.",
  off_tb_msg9:"● Two TVs for 5 booths.",
  off_tb_msg10:"● Two Spotlight 2. TV monitor 1. Notebook computer 1. Headset 1. Seat 1.",
  off_tb_msg11:"● 200 copies of promotional materials are printed for free and placed on the booth for customers visiting the exhibition to receive. If additional quantity is required, additional printing fees will be charged based on actual costs.",
  off_tb_msg12:"● Including 4 exhibition tickets, 2 dinner places, 2 special places, 8 business lunches.",
  off_tb_msg13:"● Including an online cloud booth.",
  off_tb_msg14:"● With a large space of 36 square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.",
  off_xhxxzt:"Association offline exhibition booth",
  off_cwzzs:"Become a sponsor",
  off_djgm:"Exclusive Sponsor",
  off_qunayi:"Offline",
  off_qunayi_item1:"Senior executives of the enterprise accept an interview.",
  off_qunayi_item2:"Exclusive title sponsor report: mainstream media of the industry website Push news articles, WeChat public account, Moments Multi-channel social media promotion such as Douyin and Weibo; ",
  off_qunayi_item3:"Become the exclusive sponsor of the exhibition online and offline, and organize Party simultaneous exposure;",
  off_qunayi_item4:"Exclusive title sponsor report: mainstream media of the industry website Push news articles, WeChat public account, Moments Multi-channel social media promotion such as Douyin and Weibo; ",
  off_qunayi_item5:"1 standard booth (including all rights and interests of the booth)",
  off_qunayi_item6:"1P free color insert",
  off_qunayi_item7:"The main table of the dinner for 2 people will be seated, and enjoy VIP throughout the exhibition Distinguished treatment",
  off_qunayi_item8:"Free 2 regular-priced tickets;",
  on_quanyi:"Online",
  on_quanyi_item1:"Homepage advertisement",
  on_quanyi_item2:"Exhibition hall top advertising",
  on_quanyi_item3:"2 online booths ranked first in the exhibition hall",
  on_quanyi_item4:"1 PC booth",
  on_quanyi_item5:"5 seats",
  on_quanyi_item6:"A live promotion",
  off_hwzq_title:"Sponsor of Overseas Exhibition",
  off_hwzq_tiem1:"As the exclusive sponsor for overseas exhibition areas, it will be exposed simultaneously with the organizer.",
  off_hwzq_tiem2:"Exclusive titled business report: The mainstream media of the industry website pushes news articles, WeChat public account, Moments, Tik tok, Weibo and other multi-channel social media promotion.",
  off_hwzq_tiem3:"Exclusive mini booth at the exhibition, unique corporate logo (including all rights and interests of the mini booth). Realize timely negotiation between domestic and overseas agents through the video conference system, with novel forms and unique creativity.",
  off_hwzq_tiem4:"Priority ranking of overseas exhibition areas on the conference website.",
  off_hwzq_tiem5:"The banner picture on the homepage of the conference's official website will be displayed throughout the exhibition, and the booth entrance will be hung.",
  off_hwzq_tiem6:"The publicity effect covers the entire overseas exhibition area",
  off_wygm_title:"Sponsor for Welcome dinner",
  off_wygm_item1:"Have exclusive sponsor rights for the grand welcome dinner",
  off_wygm_item2:"During the banquet, companies are entitled to 5 minutes of speech time",
  off_wygm_item3:"3 minutes of corporate promotional video during the complimentary dinner",
  off_wygm_item4:" The main table of 2 people will be seated at the dinner party, and enjoy VIP treatment throughout the exhibition",
  off_wygm_item5:"Hosting the dinner party to broadcast corporate advertising",
  off_wygm_item6:"More than 50 industry media follow up reports",
  off_wygm_item7:"Free 1 standard booth (including all rights and interests of the booth)",
  off_wygm_item8:"Free conference catalogue color insert 1p",
  wsmbmxszh:"Benefits of online exhibition",
  wsmbmxszh_msg1_1:"The online mini booth is a highlight spot of the EXPO too,which is designed for the overseas customers who can not participate in the offline exhibition.",
  wsmbmxszh_msg1_2:"By using the digitial and intelligence solutions ,all the customers can participate in the events.By using the Intelligentize ID Card ,the customers and show their information precisely and smoothly.Also, the customers can share all of this by using wechat and application,which will get them more advantages.",
  wsmbmxszh_msg1_3:"The host of EXPO will hold the BOOTH VOTING in the exhibition`s earlier stage.This will make more communications company by company and make the companies more motiative.The winner will get a good present from the host at FIRST EXHIBITION NIGHT.",
  online_quyi:"1个展馆展示，2个洽谈席位，企业投票、支持企业自主布展（包括简介、图片、资质、视频展示、设置企业洽谈坐席等），可进行现场直播，可在线上进行一对一预约洽谈等",

  wsmbmxszh_msg2:`Smart e-business card, which can set up personalized content, add mutiple display forms such as pictures, articles, audio and video, so that you can show yourself in all-round way.Smart business cards can be directly forwarded to WeChat through small programs for sharing, and can be shared by generating business card posters;One-click exchange easily, visting online and offline exhibition, no need to worry about missing business opportunities.`,
  wsmbmxszh_msg3:`Red package activity, vouchers will be used as a preferential carrier to replace the traditional offline coupon mode. Through the O2O marketing scene, allowing users to visit the exhibition - get coupons/cash red package - using offline;To attract more online visitors to increase booth traffic, and improve offline business transaction volume, but also provide discount to exhibitors for mobilizing the enthusiasm of the exhibitors.`,
  on_czs:"Exhibitor",
  on_xszt:"Online Booth",
  on_zzfw:"value-added services",
  on_xsffzt:"Paid Online Booth",
  // on_bxhnw:"（不限海内外）",
  on_tb_lin1_1:"● exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  on_tb_mini:"Mini Booth",
  on_tb_hwzh:"(for overseas exhibitor only)",
  // on_tb_jgxj:"价格详见",
  on_tb_xxmp:"Association offline exhibition booth",
  on_tb_line2_content1:"● exhibition hall display, 2 negotiation seats, corporate voting, supporting the independent deployment of the corporate exhibition, live broadcast (including introduction, pictures, qualification, video display, etc).",
  on_tb_line2_content2:"● When there is no customer in the video call, the customer promotion film, promotion PPT, promotion video, etc. will be displayed, and the materials are provided by the customer.",
  on_tb_line2_content3:"● When exhibiting visitors and overseas customers have conversations, notebooks can log into the booth negotiation system to provide functions such as visual negotiation, text chat, and online business card exchange.",
  on_tb_line2_content4:"● Customer's promotional materials and business cards can be placed for offline use and exchange by exhibitors. (For other offline rights, see 11P: Offline tickets and booths).",
  on_tb_line3_left:"Association offline exhibition booth",
  on_tb_line3_right:"● Independently opened the Association Pavilion online. According to the arrangement of 12 members/page, at least one full page is required to enjoy half price (ie 1000 yuan/booth). The order of arrangement is determined by the association, and the order of registration is by default.",
  on_tb_line4_left:"1v1 negotiation seat",
  on_tb_line4_right:"● Add 1v1 seats in the online booth.",
  on_tb_line5_left:"Pavilion display",
  on_tb_line5_right:"● Add 1 exhibition hall to the corporate booth.",
  on_tb_line6_left:"Booth red envelope",
  // on_tb_line6_center_top:"预充值",
  // on_tb_line6_center_bottom:"￥600RMB",
  on_tb_line6_right:"● Enterprise booths can be set up with cash red envelopes and business red envelopes. The total amount of cash red envelopes is guaranteed to be 600 yuan.",
  on_tb_line7_left:"Keynote Speech",
  on_tb_line7_right:"● Offline speeches can enjoy online live broadcast function, and the organizer will assist in sharing, forwarding, etc.",
  on_become_yzs:"Become a sponsor",
  on_become_item1_lable:"1V1 window video and banner ad",
  on_become_item1_small_1:"Limit two ad slots at the top of the video window",
  on_become_item1_small_2:"Click on the video interface to display ads in real time",
  on_become_item1_small_3_lable:"Video Advertisement",
  on_become_item1_small_3_orange_left:"¥15000 RMB",
  on_become_item1_small_3_orange_right:"$2250 USD",
  on_become_item1_small_4_lable:"Banner Advertisement",
  on_become_item1_small_4_orange_left:"¥10000 RMB",
  on_become_item1_small_4_orange_right:"$1500 USD",
  on_become_item2_lable:"Web pop-up ads",
  on_become_item2_small_1:"Open the pop-up advertising interface on the homepage of the website",
  on_become_item2_small_2:"Static display for 5 seconds",
  on_become_item2_small_3_lable:"Exclusive",
  on_become_item3_lable:"Homepage Carouselbanner",
  on_become_item3_small_1:"Expo homepage",
  on_become_item3_small_2:"Expo homepage",


  Offlinetour_address_tit:"Location",
  Offlinetour_address_dsc:`Shanghai International Sourcing Exhibition Center is located at the southernmost corner of Zhongjiang Road, Changfeng Ecological Business District.The perfect combination of "water, green and architecture", advanced information network system and complete commercial supporting facilities in the area reflect the comprehensive advantages of the modern service industry cluster.`,
  Offlinetour_Taxi:"By taxi",
  Offlinetour_Bus:"Public transport",
  Offlinetour_taxi_left_from1:"Shanghai Pudong International Airport",
  Offlinetour_taxi_left_way1:"is about 55km away from the venue, which takes about 58 minutes with no traffic jams and costs about 196 RMB.",
  Offlinetour_taxi_left_from2:"Shanghai Hongqiao International Airport",
  Offlinetour_taxi_left_way2:"is about 16 kilometers away from the venue, about 30 minutes if there is no traffic jam, about 50 RMB.",
  Offlinetour_taxi_right1:"Shanghai station is about 10 kilometers away from the venue, about 25 minutes if there is no traffic jam, about 33RMB.",
  Offlinetour_taxi_right2:"Shanghai Hongqiao Station is about 16km away from the venue, about 30 minutes if there is no traffic jam, about 50 RMB.",
  Offlinetour_taxi_right3:"Shanghai South Railway Station is about 13km away from the venue, about 28 minutes if there is no traffic jam, about 40 RMB.",
  Offlinetour_bus1:"☉Adjoin to inner ring line, middle ring line, Hongqiao Airport",
  Offlinetour_bus2:"☉Surrounded by rail transit 2, 13, 15 lines",
  Offlinetour_bus3:"☉Regional shuttle buses between the convention center and major bus stops",
  Offlinetour_bus4:"☉Gubei Road Bridge, Qilianshan Road Bridge,Luding Road Bridge across the north and south of Suzhou River,straight to Hongqiao Central Business District",
  Offlinetour_periphery_title:"Surrounding facilities",
  Offlinetour_periphery_item1:"☉Jackie Chan Film Art Museum.",
  Offlinetour_periphery_item2:"☉Within a radius of two kilometers, there are eleven top-grade hotels of four or five stars and more than thirty business and economic hotels.",
  Offlinetour_periphery_item3:"☉2 yacht marinas, 100 yacht berths, Changfeng Park.",
  Offlinetour_periphery_item4:`☉Long scenery side entertainment center, large commercial center "Guosheng Center".`,

  VisitExhibition_left_title:"Offline",
  VisitExhibition_quanyi:"Benefits",
  VisitExhibition_left_title_free:"Free Audience Bnenefits",
  VisitExhibition_left_title_money:"VIP Benefits（concluding ALL Free audience benefits）",

  VisitExhibition_left_item2:"viewing of the live broadcast",
  VisitExhibition_left_item3:"access to the booth",
  VisitExhibition_left_item4:"posting messages",
  VisitExhibition_left_item5:"",
  VisitExhibition_left_item6:"Welcome Dinner",
  VisitExhibition_left_item7:" Attend peak forum",
  VisitExhibition_left_item8:" Business lunch 1 set",
  VisitExhibition_left_item9:" One information bag",

  VisitExhibition_right_title:"Online",
  VisitExhibition_right_item1:"Unlimited viewing of the live broadcast",
  VisitExhibition_right_item2:"unlimited access to the booth",
  VisitExhibition_right_item3:"random negotiation",
  VisitExhibition_right_item4:"posting messages",
  VisitExhibition_right_item5:"online business card exchange",
  VisitExhibition_right_item6:"leaving messages only in the free chat area",

  // VisitExhibition_Early_bird:"早鸟票价",
  // time_before_1_31:"1月31日前",
  // VisitExhibition_youhui:"优惠票价",
  // VisitExhibition_gn_price:"国内价格",
  // VisitExhibition_hw_price:"海外价格",
  VisitExhibition_Signupnow:"立即报名",
  VisitExhibition_Sigfree:"FREE",
  VisitExhibition_weihe_title:"Benefits of exhibition",
  VisitExhibition_Highlights_off_title:"Expo Offline Activities",
  VisitExhibition_Highlights_on_title:"Expo Online Activities",

  VisitExhibition_Highlights_on_item1:"Online Booth",
  VisitExhibition_Highlights_on_item2:"Full Time Live Broadcast",
  VisitExhibition_Highlights_on_item3:"Free Talk Zone",
  VisitExhibition_Highlights_on_item4:"Online Pavilion",
  VisitExhibition_Highlights_on_item5:"Online One to One Meeting",
  VisitExhibition_Highlights_1:"E-Name card",
  VisitExhibition_Highlights_2:"Expo Voting",
  VisitExhibition_Highlights_3:"Online Hall",
  VisitExhibition_Highlights_4:"Online Booth",
  czlc_item_1:"Online registration",
  czlc_item_2:"Sponsor confirmation",
  czlc_item_3:"Confirmation of booth payment",
  czlc_item_4:"Online booth design",
  czlc_item_5:"Attend exhibition",
  myExhibitionsummary_p1:'Qingdao International Logistics Expo will be a new great IP . We arrange to set the EXPO in QINGDAO China,one of the important port city in China,which has not only the geography anvantages but also logistics developing advantages. The four sponsors of the EXPO are all influential in Qingdao logistics.They are the initiator of this EXPO and they are willing to make a great exhibition. ShenQing Digital Supply Chain Innovation Center and AiLa are the undertaker of this EXPO ,we will combine the online and offline contents and fulfill the EXPO with good events. This EXPO will make the oversea`s and Chinese shipping companies motiative again,which will break the ice from covid and make the logistics full of oppotunity.',
  // myExhibitionsummary_p2:'This "Expo" will break the limitations of time and space through domestic brand exhibition areas, overseas exhibition areas, boutique special matchmaking, one-to-one meetings, free talk, and other forms to strengthen industry cooperation and contribute to the overall environmental development of the industry and the company itself Operation and expansion provide good opportunities to encourage people in the industry to exchange experiences, gain insight into customer needs, and achieve win-win and development in the industry. A welcome dinner will be held on the day of the opening ceremony, which can put aside the boring and hustle and bustle of daily work, and discuss new opportunities for corporate development with industry elites in a relaxed and pleasant environment, and provide a communication platform for all participants.',
  zhubanfang_big_tit:"Co-host",
  chengbanfang_big_tit:"Organizer",
  xiebanfang_big_tit:"Co-organizer",
  zhubanfang_name_1:"QINGDAO INTERNATIONAL LOGISTICS CHAMBER OF COMMERCE",
  zhubanfang_name_2:"V-LOG UNION",
  zhubanfang_name_3:"QINGDAO SERVICE FEDERATION",
  zhubanfang_name_4:"CHINA FREIGHT FORWARDER CLUB",
  zhubanfang_content_1:"Qingdao International Logistics Chamber of Commerce is a non-profit social welfare organization in the logistics industry, and a self-regulatory social organization voluntarily formed by outstanding enterprises, individuals, and related units in the international logistics industry. The Chamber of Commerce aims to build an information, standardized and intelligent platform for Qingdao's logistics industry. Relying on the platform to promote the mutual benefit of resource interaction among member companies in the industry for exchanges and cooperation. Expand resources and channels from all walks of life, strengthen supply and demand information, partners and other resources to promote the integration of member resources. Maintain industry interests, understand relevant policies, respond to the operational demands of the logistics industry to functional departments, and assist members to grow bigger and stronger. ",
  zhubanfang_content_2:"V-LOG UNION is an excellent logistics enterprise alliance that came into being in March 2018. It is also the first alliance organization initiated and established in Qingdao, dedicated to creating truly borderless enterprise resources in the same industry. Sharing platform, one-stop solution to all customers' logistics needs in Qingdao. ",
  zhubanfang_content_2_2:"The member companies currently participating in V-LOG are all line agents that have been well-operated in the shipping and logistics field of Qingdao for many years, and each have obvious professional characteristics, and they have become complementary and win-win roles in the alliance. ",
  zhubanfang_content_3:"Qingdao Modern Service Industry Federation is composed of enterprises and institutions of various ownerships engaged in modern service industry, as well as experts, scholars, professionals, and social activists engaged in modern service industry-related work to voluntarily participate in social organizations with corporate legal personality qualifications.",
  zhubanfang_content_3_2:"Aiming at one goal, the federation will work together to build the \"Qingdao Service\" brand and promote service industry clusters; centering on two main lines, serving the government and enterprises; building three platforms, namely, expert think tanks, \"double recruitment and double introduction\", and intermediary services; The four functions of implementation are the functions of bridges, integrity and self-discipline, negotiation and rights protection, and platform windows; the realization of the five breakthrough work ideas of characteristic tourism, smart cities, entrepreneurial design, standard publicity and financial services, and joint efforts to promote the renewal of the service industry. Take a new step. ",
  zhubanfang_content_4:"",
  aila_event_name1:"AiLa Global Logistics Partners Meeting",
  aila_event_name2:"2020 The 4th China (Ningbo) International Shipping",
  aila_event_name3:"Logistics Fair (Cloud Exhibition)",

  myExhibitionsummary_p3:'JCtrans Logistics Network was established in 2003 with a registered capital of 100 million yuan, and is committed to becoming a global logistics transaction and settlement service platform. The company has a professional team composed of nearly 300 excellent internet and logistics industry talents to create a global logistics enterprise ecosystem of one million.',
  myExhibitionsummary_p15:'Global Logistics Enterprise Development Expo',
  myExhibitionsummary_p4:'JCtrans will continue to uphold its own corporate culture, adhere to its mission and goals, and empower small and medium logistics companies to become a global logistics transaction and settlement service platform.',
  myExhibitionsummary_p5:'AILA is a platform whcih is founded for dealing global logistics problems and taking solutions to the customers.By using digital technology,AILA can cooperate with the global logistics company and keep a good assiciation with them. AILA has not only Internet Platform but also Mobile terminal platform and SaaS System.Also,AILA has a new product called"Cloud Booth".By this new product,AILA can combine and initiate the online and offline events together,which is different from the traditional logistics companies.',

  jincheng_c:"",
  myExhibitionsummary_p6:'Qingdao International Convention and Exhibition Center is a modern and intelligent exhibition hall integrating exhibitions, conferences, business, catering, entertainment and other functions.It is located in Century Plaza, Laoshan District, Qingdao, with beautiful surroundings and complete facilities. It is an ideal place for holding international exhibitions and international conferences.Nearly 10,000 square meters of medium and high-end restaurants and fast food restaurants can provide guests with Chinese and Western fast food, large buffets and other different levels of catering services.',
  myExhibitionsummary_p7:'',
  myExhibitionsummary_p8:"",
  shenqing_company_name:"ShenQing Digital Supply Chain Innovation Center",
  shenqing_des:`ShenQing Digital Supply Chain Innovation Center , hereinafter referred to as the "Innovation Center", is a comprehensive consulting service platform dedicated to building a multi-channel, comprehensive and whole industry chain for private capital to widely participate in the national economy. The innovation center breaks through the traditional "chain" operation mode, creates a development model of "one platform, one network", and makes full use of 5G, cloud technology, big data analysis, blockchain, AI and other information technologies to create a smart supply chain platform. The business field is guided by the innovation and dissemination of digital supply chain management and services, conforms to the new round of technological revolution and industrial transformation trends, and adheres to the direction of globalization, digitization and intelligence. At the same time, relying on the strong industrial supply chain planning ability, we will build a “government, industry, university, research, and financial services” community platform to provide business content that integrates policy analysis, applied research, management consulting, operational practice, technology development, and talent training. With the service concept of "digital + ecological green supply chain", we provide the government, large enterprises, and various industrial development parks with industrial digital platform construction services, system integration services, and full-chain or link digital transformation services, as well as customer requirements. Customized system services such as logistics delivery platform and supply chain financial platform can enhance the security and stability of the nuclear industry chain and supply chain, and empower upstream and downstream enterprises.`,
  myExhibitionsummary_p9:"",
  my_activity_p1:'Enterprise Award: "Popular Logistics Enterprise".',
  my_activity_p2:'Individual Awards: "Logistics Princess " and "Logistics Prince".',
  my_activity_p3:'Registration time: July 20, 2021--July 31, 2022',
  my_activity_p4:'Voting time: Aug.1, 2021--18:00, Aug.3, 2022',
  my_activity_p5:`"Logistics Princess " and "Logistics Prince".  (At least 5000 vote) Champion Award: Banquet will be presented with a trophy and 3 minutes of speech time.`,
  my_activity_p6:'2. Popular Champion Award: 3 people in total, the banquet will be presented with a trophy and 3 minutes of speech time.',
  my_activity_p7:`"Popular Logistics Enterprise" (At least 5000 vote)`,
  my_activity_p8:'Gold Award: 2021or2022 Qingdao Expo 1 general booth free',
  my_activity_p16:"Silver Award: 2021or2022 Qingdao Expo 50% voucher for any sponsorship.",
  my_activity_p17:"Bronze award: 2021or2022 Qingdao Expo speech for promotion.",
  my_activity_p9:'Any participants work in logistics field who sign up in expo website.',
  my_activity_p10:'2. Exhibitors are eligible to recommend individuals, but moderately open up 4 active users, can not be restricted by enterprises to buy booth;',
  my_activity_p11:'Getting 10 votes by logging in the PC website and wechat each day, voting will be available all day. ',
  my_activity_p12:'1. From March 29th,2021, the number of individual awards will be counted every week. Before the deadline, 3 weekly champions will be selected for each award, and a total of 9 weekly champions will be awarded "Weekly Champion Award".',
  my_activity_p13:'2. On April 17th,2021., the organizing committee will count the total votes of the three individual awards respectively, and finally get the three champion of popularity who can get the "Champion of popularity award".',
  my_activity_p14:'3. On April 17th,2021., the organizing committee will count the total votes of the two enterprise awards respectively, and the top three of each award, namely 6 enterprises in total, will be awarded the "Star Enterprise Award".',
  my_activity_p15:'4. On April 17th,2021., the organizing committee will conduct a comprehensive voting ranking for the enterprise awards, and the enterprise ranking the first will get the "Exposition Ultimate Grand Award".',
  my_registerSuccess_p1:'Thank you for signing up!',
  my_registerSuccess_p2:'The  exclusive service team  will  contact  you within  2  hours.  Please  wait\tpatiently',
  // 控制台
  replenishInfoText1: 'please choose service, at most 5',
  replenishInfoText2: 'Please choose shipping route, at most 5',
  replenishInfoText3: 'Nothing left',
  replenishInfoText4: 'Not required, at most 3',
  // 酒店预定
  HotelReservation_des:`The organizer has reserved four-star hotel rooms for visitors, exhibitors and guests, and the hotel price is much lower than the original hotel price. If you need to make a reservation, please fill in the check-in information below, and check in with your ID card on the scheduled date! The non-contract price refers to the online booking price on the day; the hotel check-in time is 2 pm, and the check-out time is 12 am the next day;
  `,
  HotelReservation_tips: "Warm reminder: If you do not make a reservation through this system, there may be no room or no preferential price.",
  HotelReservation_tips2:"如您需要升级为豪华房，请直接联系主办方工作人员。",
  HotelReservation_tips_room1:"Twin room：550 yuan/room night",
  HotelReservation_tips_room_des1:"Twin room includes 2 breakfasts，If you need additional meals, please go to the hotel reception",
  HotelReservation_tips_room2:"King bed room：550 yuan/room night",
  HotelReservation_tips_room_des2:"King bed room includes 1 breakfasts，If you need additional meals, please go to the hotel reception",
  HotelReservation_breakfast_standard:"Twin room includes 2 breakfasts",
  HotelReservation_breakfast_big:"King bed room includes 1 breakfasts",
  HotelReservation_Notice_1:"1.If you need an invoice, please communicate with the hotel reception;",
  HotelReservation_Notice_2:"2.Hotel check-in time is 14:00, check-out time is 12:00 the next day, please check in in time;",
  HotelReservation_Notice_3:"Hotel check-in time is 14:00, check-out time is 12:00 the next day;",
  HotelReservation_money:"The actual amount is subject to payment when checking in offline",
  HotelReservation_yd_success:"The actual amount is subject to payment when checking in offline!",
  HotelReservation_time:"The actual amount is subject to payment when checking in offline",
  HotelReservation_placeholder:"Fill in at least one contact person for each room\nFor example：\n\nroom 1\nname \nphone number\nID card number\n\nroom 2\nname\nphone number\nID card number\n\n ...",
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  selectBooth1:'Standard Booth',
  selectBooth2:'800-900 USD',
  selectBooth3:'Online：',
  selectBooth4:'As long as you purchase an offline booth, you will get an online booth as a gift. For the rights and interests, please refer to the online paid booth。',
  selectBooth5:'Three-sided hoarding, fascia board construction, 1 negotiation table, 2 chairs, 2 spotlights, and 1 10A socket,The standard booth fee includes the production and construction of the background board. The background boa rd design is completed by the customer or consulted by the organizer. The design fee is 1,200 yuan per booth (paid separately). The fascia board is designed, made and built by the organizer.',
  selectBooth6:'Welcome dinner for 2 person。',
  selectBooth7:'Two information bag。',
  selectBooth8:'Business lunch 2 set/day。',
  selectBooth9:'Special Booth',
  selectBooth10:'2100-4200 USD',
  selectBooth11:'Online：',
  selectBooth12:'Companies with special booths will be given one online booth as a gift. For the rights and interests, please refer to the online paid booth.。',
  selectBooth13:'One online booth and one top ten exhibition seat in the exhibition hall will be presented as a gift。',
  selectBooth14:'Offline：',
  selectBooth15:'Including 4 exhibition tickets, 2 dinner places, 8 business lunches,4 information bag。',
  selectBooth16:'With a large space of 36 square meters/72square meters, the organizing committee will provide recommended builders, and the exhibitors will directly communicate with the builders for personalized design and construction.',
  selectBooth17:'Overseas exhibition area',
  selectBooth22:"500 USD",
  selectBooth18:'1 online booth。',
  selectBooth19:'2 participants, accept 24-hour 1-to-1 reservations。',
  selectBooth20:'The corporate promotional video will be played simultaneously on 30 screens in the overseas exhibition area',
  selectBooth21:'Corporate leaflets + business cards, 500 sheets each',
  selectBooth22:'11 August 2022-- 12 August 2022',
  selectBooth23:'Expo postoned, launch date is to be determined,please pay attention to the notice.',
  selectBooth24:'Voting is underway, register now to win surprise.',
  selectBooth25:'Qingdao Expo postoned,  launch date is to be determined.',
  selectBooth26:"500 USD",
  selectBooth27:"Offline：",
  selectBooth28:"rights and interests",
  selectBooth29:"As long as you purchase an offline booth, you will get an online booth as a gift. For the rights and interests, please refer to the online paid booth.",
  selectBooth30:"boothArrangement",
  selectBooth31:'Head of Domestic channels',
  selectBooth32:'Head of Foreign channels',
  selectBooth33:'Scan code to add wechat for details.',
}
