<template>
  <div class="noDataImg_components">
    <div class="nodata_content">
      <img :src="nodata05" alt v-if="size=='0.5'" />
      <img :src="nodata075" alt v-if="size=='0.75'" />
      <img :src="nodata1" alt v-if="size=='1'" />
      <img :src="nodata2" alt v-if="size=='2'" />
      <img :src="nodata3" alt v-if="size=='3'" />
      <img :src="nodata4" alt v-if="size=='4'" />
      <img :src="nodata5" alt v-if="size=='5'" />
      <img :src="nodata6" alt v-if="size=='6'" />
      <p class="tc infoColor">{{$t("nodata")}}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    size: {
      type: String,
      default: "1"
    }
  },
  data() {
    return {
      nodata05:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata0.5.png",
      nodata075:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata0.75.png",
      nodata1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata1.png",
      nodata2:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata2.png",
      nodata3:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata3.png",
      nodata4:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata4.png",
      nodata5:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata5.png",
      nodata6:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/nodata/nodata6.png"
    };
  }
};
</script>

<style lang="less" scoped>
.noDataImg_components {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  p {
    margin-top: 8px;
  }
}
</style>