import baseConfig from "@/baseConfig";

export default {
    namespaced: true,
    actions: {
        async company_associateSearch(context, params) {                    //绑定公司联想搜索ES
            let { data } = await axios.get('company/api/search/company/by/name', { params });
            return data;
        },
        async getFreightCompanyList(context, params) {                    //船公司联系搜索ES
            let { data } = await axios.post('freight/api/shipping_company/list', params);
            return data;
        },
    }
}