export default {
    namespaced: true,
    actions: {
       
        async getExpoExhibitorList(context, params) { //获取展商，中通海外列表（联想提示）
            let { data } = await axios.post('expo/api/expo_exhibitor/list', params);
            return data;
        },
        async expCompanyGetCompanyNameList(context, params) { //获取展台公司列表(联想提示， 报名的)
            let { data } = await axios.post('nsf/api/company_info/pavilion_id', params);
            return data;
        },
        async getCompanyMsg(context, params) { //获取公司信息
            let { data } = await axios.get('company/api/company/info', {params});
            return data;
        },
      
    }
}