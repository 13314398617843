import Base64 from 'js-base64'

const $_Base64 = Base64.Base64

export default $_Base64;

export const _encode = (input) => {
    return $_Base64.encode(JSON.stringify(input))
}

export const _decode = (input) => {
    return JSON.parse($_Base64.decode(input))
}

export const _decodeServer = (input) => {
    return $_Base64.decode(input)
}

export const _decodeString = (input) => {          //用于服务端参数解析

    let queryArray = $_Base64.decode(input).split('&');
    let handleArray = queryArray.map(item => {
        return item.split('=')
    })
    let queryObject = {}
    handleArray.forEach(item => {
        queryObject[item[0]] = item[1]
    })
    return queryObject
}