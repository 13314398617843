export const constName = {
    schoolRecruitment: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    siffa: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    expoMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    fudaMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    ailaWRT: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    tradeFederation: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    lySociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    scbSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    mpaSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    overseasMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    ymPcClient: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    qlcSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    airExpoMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    gbkSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    hebeiSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    xiffaSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    dlSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    tiffaSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    tcbaSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    hphSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    henanSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    dgaJob: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    jzSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    nbkjMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    bfMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    hymSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    dlcSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    dlhySociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
}
