export default {
    namespaced: true,
    actions: {
        async getTotalMsg(context, params) { //微信登录
            let { data } = await axios.post('ymfr/user/api/login', params);
            return data;
        },
        async getUserInfo(context, params) { //获取用户信息
            let { data } = await axios.get('ymfr/user/api/user_info', {params});
            return data;
        },
        async setUserInfo(context, params) { //修改用户信息
            let { data } = await axios.post('ymfr/user/api/update/user_info', params);
            return data;
        },
        async setPass(context, params) { //修改密码
            let { data } = await axios.post('ymfr/user/api/password/update', params);
            return data;
        },
        async getCode(context, params) { //发送验证码
            let { data } = await axios.get('ymfr/sms/api/get_verify_code', {params});
            return data;
        },
        async checkCode(context, params) { //校验验证码
            let { data } = await axios.get('ymfr/sms/api/check_verify_code', {params});
            return data;
        },
        async login(context, params) { //用户登录
            let { data } = await axios.post('ymfr/user/api/customer/login', params);
            return data;
        },
        async bindTel(context, params) { //绑定手机号
            let { data } = await axios.post('ymfr/user/api/customer_mobile/update', params);
            return data;
        },
    },
}
