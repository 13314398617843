//判断是否需要填写用户基本信息及需补充类型
export function checkBasicInfo(userInfo, locale) {
    let needType = '';
    let flag = true;
    if (!userInfo.email) {
        needType = 'email'
    };
    if (!userInfo.mobile) {
        needType = 'phone'
    };
    if (locale == 'en') {
        if (!userInfo.name_en) {
            flag = false;
        }
    } else {
        if (!userInfo.name_zh) {
            flag = false;
        }
    }
    return {
        flag,
        needType
    };
}