import baseConfig from '~/baseConfig/index.js'
export default {
    data() {
        return {
            DEFAULT_AVATAR: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/globalDefaultAvatar.png',
            DEFAULT_AVATARSQUARE: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/globalDefaultAvatarSquare.jpg',
            DEFAULT_LOGO: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/globalDefaultLogo.png'
        }
    },
    methods: {
        inspectButton(auth) {               //判断按钮权限
            return this.$store.state.baseStore.auth.BUTTONAUTH.indexOf(auth) != -1;
        },
        inspectHandle(auth) {               //判断是否可操作权限
            let result = this.$store.state.baseStore.auth.HANDLEAUTH.indexOf(auth) != -1;
            return !result
        },
        BACKPAGETOP() {
            document.documentElement.scrollTop = document.body.scrollTop = 0; //页面移动到顶部
            // let top = document.documentElement.scrollTop || document.body.scrollTop
            // // 实现滚动效果
            // const timeTop = setInterval(() => {
            //     document.body.scrollTop = document.documentElement.scrollTop = top -= 50
            //     if (top <= 0) {
            //         clearInterval(timeTop)
            //     }
            // }, 10)
        }
    },
    computed: {
        PJSource() {
            return baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource           //项目source
        },
        PJID() {
            return baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJID
        },
        IS_LOGIN() {
            return this.$store.state.baseStore.userId == '' ? false : true;       //若判断是否登录，必须用该属性，不可以是否存在用户信息作为判断依据
        },
        USER_ID() {
            return this.$store.state.baseStore.userId;
        },
        USER_INFO() {
            return this.$store.state.baseStore.userInfo;
        },
        IS_EN() {
            return this.$store.state.baseStore.locale == "en";
        },
        IS_ZH() {
            return this.$store.state.baseStore.locale == "zh";
        },
        LOCALE() {
            return this.$store.state.baseStore.locale;
        }
    },
}
