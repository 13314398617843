import baseConfig from "@/baseConfig";

export default {
    namespaced: true,
    actions: {
        async ympSign_Login(context, params) { //用户登录
            let expires, isInfo;
            if (params.expires) {
                expires = params.expires;
                delete params.expires;
            }
            if (params.isInfo) {
                isInfo = params.isInfo;
                delete params.isInfo;
            }
            let { data } = await axios.post('user/api/booking/login', params);
            let _data = data;
            if (!isInfo && _data.success) {
                if(_data.user_status == 1){ // 已激活的才可登录，默认是未激活
                    let { data } = await context.dispatch('ympSign_getUserInfo', { user_id: _data.data });
                    context.commit('baseStore/SET_GLOBAL_USER_ID', { user_id: _data.data , expires }, { root: true });
                }
            }
            return data;
        },
        async ympSign_getUserInfo(context, params) { //获取用户信息
            let { data } = await axios.get('user/api/userinfo', { params });
            if (data.success) {
                context.commit('baseStore/SET_USER_INFO', data.data, { root: true });
                context.commit('baseStore/SET_GLOBAL_USER_ID', { user_id: params.user_id }, { root: true });
                let { auth } = await context.dispatch('ympSign_getUserAuth', { user_id: params.user_id })
            }
            return data;
        },
        async ympSign_getUserAuth(context, params) {                       //获取用户权限
            let { data } = await axios.get('user/api/permission', { params });
            if (data.success) {
                context.commit('baseStore/INIT_USERAUTH', data.data, { root: true });
            }
            return data;
        },
        async ympSign_Register(context, params) {           //用户注册
            let { data } = await axios.post('user/api/booking/signup', params);
            return data;
        },
        async ympSign_resetPassword(context, params) {             //重置密码
            let { data } = await axios.post('user/api/booking/password/reset', params);
            return data;
        },
    }
}