export default {
  namespaced: true,
  actions: {
    async getRankingList(context, params) {//榜单总和具体榜单
      let { data } = await axios.post("vote/api/ranking/list", params);
      return data;
    },
    async handleVote(context, params) {//投票
      let { data } = await axios.post("vote/api/vote", params);
      return data;
    },
  }
}