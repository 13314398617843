export default {
    actions: {
        async getCollectionUseList(context, params) {//名片夹
            let { data } = await axios.post('expo/api/my_collection_user/list', params)
            return data
        },
        async handleChangeCard(context, params) {//名片夹
            let { data } = await axios.post('expo/api/card/exchange', params)
            return data
        },
        async getCollectionDetail(context, params) {//名片夹详情
            let { data } = await axios.post('expo/api/card/info', params)
            return data
        },
        async followUser(context, params) {//关注
            let { data } = await axios.post('company/api/follow', params)
            return data
        },
        async cancelFollowUser(context, params) {//取消关注
            let { data } = await axios.post('company/api/cancel', params)
            return data
        },
        async attendeeManage(context, params) {//参会人员管理列表
            let { data } = await axios.post('expo/api/attendee/manage', params)
            return data
        },
        async getFollowList(context, params) {//收藏列表
            let { data } = await axios.post('expo/api/meeting_booth_like/list', params)
            return data
        },
        async handleCollectionNew(context, params) {                    //收藏
            let { data } = await axios.post('expo/api/meeting_booth/like', params);
            return data;
        },
        async attendeeManage_del(context, params) {  //参会人员管理解绑参会人员
            let { data } = await axios.get('expo/api/delete/attendee', { params })
            return data
        },
        async attendeeManage_set(context, params) {  //参会人员管理更改管理员
            let { data } = await axios.post('expo/api/set/administrator', params)
            return data
        },
        async getLIveList(context, params) {//获取直播信息(直播链接)
            let { data } = await axios.get("meeting/api/guests/list", { params });
            return data
        },
        async updateGuests(context, params) { // 修改嘉宾名称
            let { data } = await axios.post("admin_meeting_new/api/update/guests", params);
            return data
        },
        async enterMeetingLive(context, params) {//参会人员管理进入坐席
            let { data } = await axios.get("expo/api/enter/meeting_live", { params });
            return data
        },
        async getmyReservedList(context, params) {//我的预约列表
            let { data } = await axios.post("daily_chat/api/wechat_daily_one2one_chat/list", params);
            return data
        },
        async getmyReservedAddEvaluation(context, params) {//我的预约列表添加评论
            let { data } = await axios.post("daily_chat/api/evaluation/add", params);
            return data
        },
        async getmyReservedViewEvaluation(context, params) {//我的预约列表查看评论
            let { data } = await axios.post("daily_chat/api/meeting_invited_chat_evaluation/info", params);
            return data
        },
        async inUserStatusCheck(context, params) {//检查被邀请方是否有被邀请
            let { data } = await axios.post("expo/api/in_user_status/check", params);
            return data
        },
        async isCheckCancel(context, params) {//检查邀约是否已经被取消
            let { data } = await axios.get("expo/api/is_check/cancel", { params });
            return data
        },
        async expoDailyInviteOperate(context, params) {//日常邀约操作
            let { data } = await axios.post("expo/api/expo_daily_invite/operate", params);
            return data
        },
        async addorEditRedEnvelope(context, params) {//添加编辑业务红包
            let { data } = await axios.post("envelope/api/business_red_envelope/add", params);
            return data
        },

        async check_red_envelope_time(context, params) {//创建红包活动时间重叠检查
            let { data } = await axios.post("envelope/api/check_red_envelope_time", params);
            return data
        },
        async check_balance(context, params) { //检查账户余额
            let { data } = await axios.post("envelope/api/check_balance", params);
            return data
        },
        async cash_red_envelope(context, params) { //新增编辑现金红包
            let { data } = await axios.post("envelope/api/cash_red_envelope/add", params);
            return data
        },
        async envelopeAaccountInfo(context, params) { //红包 账户信息
            let { data } = await axios.get("envelope/api/account/info", { params });
            return data
        },
        async envelopeList(context, params) { //红包列表
            let { data } = await axios.post("envelope/api/red_envelope_business/list", params);
            return data
        },
        async accountRecordsList(content, params) { //账户记录列表
            let { data } = await axios.post("envelope/api/account_trade/get_list_of_company_type", params)
            return data
        },
        async accountRecordsDetails(content, params) { //账户记录详情
            let { data } = await axios.post("envelope/api/account_trade/get_details", params)
            return data
        },
        async searchRedBack(context, params) { //红包口令查询
            let { data } = await axios.post("envelope/api/business_red_envelope/record_list", params);
            return data
        },
        async hxRedBack(context, params) { //红包核销
            let { data } = await axios.post("envelope/api/business_red_envelope/do_verify", params);
            return data
        },
        async cash_red_envelopeList(context, params) { //现金红包领取列表
            let { data } = await axios.post("envelope/api/cash_red_envelope/record_list", params);
            return data
        },
        async update_remarks(context, params) { //现金红包领取列表
            let { data } = await axios.post("envelope/api/business_red_envelope/update_remarks", params);
            return data
        },
        async getCensusNum(context, params) { //红包领取数量
            let { data } = await axios.post("envelope/api/red_envelope/census_num", params);
            return data
        },
        async myBusinessRedEnvelope(context, params) { //我的业务红包列表和领取列表
            let { data } = await axios.post("envelope/api/my_business_red_envelope/list", params);
            return data
        },
        async myCashRedEnvelope(context, params) { //我的现金红包列表
            let { data } = await axios.post("envelope/api/my_cash_red_envelope/list", params);
            return data
        },
        async delMyRedEnvelope(context, params) { //删除我的业务红包
            let { data } = await axios.post("envelope/api/my_business_red_envelope/delete", params);
            return data
        },
        async getSiffaApplyList(context, params) { //入会申请  活动申请
            let { data } = await axios.post('siffa/api/siffa_apply/list', params)
            return data
        },
        async getScbaApplyFormQuery(context, params) { //入会申请  一键入会列表
            let { data } = await axios.post('scba/api/apply_form/query', params)
            return data
        },
        async meeting_booth_user(context, params) { //公司下的所有展台坐席人员
            let { data } = await axios.post('expo/api/meeting_booth_user/list', params)
            return data
        },
        async chat_record_list(context, params) { //公司下的所有展台坐席人员
            let { data } = await axios.post('expo/api/chat_record/list', params)
            return data
        },
        async immediateList(context, params) { //即时洽谈列表
            let { data } = await axios.post('expo/api/my_free_chat_record/list', params)
            return data
        },
        async getnegotationsetList(context, params) { //洽谈设置列表
            let is_self = params.is_self;
            delete params.is_self;
            let { data } = await axios.post("expo/api/Negotiation/setup/list", params)
            if (data.success) {
                if (is_self) {
                    context.commit('SET_TALK_DURATION', (data.total_time / 3600).toFixed(1), { root: true })
                }
            }
            return data
        },
        async setsetupTime(contenxt, params) {//洽谈时间设置
            let { data } = await axios.post("expo/api/Negotiation/setup/do_setup", params)
            return data
        },
        async inviteMultChat(contenxt, params) {//我的洽谈-邀请多人会议（目前只针对海外会议）
            let { data } = await axios.post("daily_chat/api/daily_chat_invite", params)
            return data
        },
        async myMultChatRecord(contenxt, params) {//我的洽谈-我的邀请记录（目前只针对海外会议）
            let { data } = await axios.post("daily_chat/api/daily_chat_invite/record", params)
            return data
        },
        async gbkApplyActivedList(contenxt, params) {//gbk申请活动列表
            let {data}=await axios.post('siffa/api/association_event_apply/list',params)
            return data
        },
        async association_event_apply(context, params) {
            //取消申请
            let { data } = await axios.post("siffa/api/association_event_apply/save", params);
            return data;
        },
        async publication_person(context, params) {
            //参会人员-会刊联系人
            let { data } = await axios.post("expo/api/set/publication_person", params);
            return data;
        },
    

          
    }
}