export default {
    namespaced: true,
    actions: {
        async getPavilionBanner(context, params) {//获取展馆banner
            let { data } = await axios.post("meeting/api/meeting_pavilion/list", params);
            return data;
        },
        async getExhibitionByMeenting(content, params) {//获取会议下展馆
            let { data } = await axios.post('nsf/api/pavilion_info/meeting', params)
            return data
        },
        async expCompanyGetCompanyNameList(context, params) { //获取展台公司列表(联想提示， 报名的)
            let { data } = await axios.post('nsf/api/company_info/pavilion_id', params);
            return data;
        },
        async getBoothList(context, params) { //获取展台列表
            let { data } = await axios.post('expo/api/expo_booth_list/whole', params);
            return data;
        },
        async getExpoExhibitorList(context, params) { //获取展商，中通海外列表（联想提示）
            let { data } = await axios.post('expo/api/expo_exhibitor/list', params);
            return data;
        },
    }
}