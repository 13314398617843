<template>
  <div class="bottom_content">
    <p class="site">
      <img
        class="gonganImg"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/gongan.png"
       :alt="$t('siffa')"
      />
       <a href="http://beian.miit.gov.cn/" rel="nofollow"> 沪ICP备07019735号-16 </a>
      <!-- <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002004911"
        > 沪公网安备 31011002004911号</a
      > -->
    </p>
    <!-- <p class="site noMargin">{{ $t("copy") }}</p> -->
  </div>
</template>

<script>
export default {
  name: "commonBottomContent",
  serverCacheKey: () => "commonBottomContentV0.0.0",
};
</script>

<style lang="less" scoped>
.bottom_content {
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  .gonganImg {
    width: 14px;
    height: 14px;
    margin-top: -5px;
  }
  p {
    &.noMargin {
      margin-top: 8px !important;
    }
    color: #666;
    a {
      color: #666;
    }
  }
}
</style>
