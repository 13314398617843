export default {
  namespaced: true,
  actions: {
    async getMeetNewsList(context, params) {//获取首页新闻资讯列表
      let { data } = await axios.post("admin_meeting_new/api/meeting_news/list", params);
      return data;
    },
    async getHomeBanner(context, params) {// 首页banner
      let { data } = await axios.get("media/api/home_page/banner", { params })
      return data
    },
  }
}