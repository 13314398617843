export default {
  namespaced: true,
  actions: {
    async getMeetNewsList(context, params) {//获取首页新闻资讯列表
      let { data } = await axios.post("admin_meeting_new/api/meeting_news/list", params);
      return data;
    },
    async getHomeBanner(context, params) {// 首页banner
      let { data } = await axios.get("media/api/home_page/banner", { params })
      return data
    },
    async questionnaireSave(context, params) {//问卷调查
      let {data}=await axios.post("nsf/api/questionnaire/save",params)
      return data
    },
    async meeting_hotel_book(context, params) {//酒店预订
      let {data}=await axios.post("meeting_new/api/meeting_hotel_book/save",params)
      return data
    },
    async meeting_hotel_list(context, params) {//酒店预订列表
      let {data}=await axios.post("meeting_new/api/meeting_hotel_book/list",params)
      return data
    },
    async getActiveList(context, params) {//获取活动列表
      let {data}=await axios.post("siffa/api/association_event/list",params)
      return data
    },
    async getEcode(context, params) {//获取二维码
      let {data}=await axios.post("wechat/api/generate_rq_code",params)
      return data
    },
    async associationEventViews(context, params) {//更新浏览量
      let {data}=await axios.post("siffa/api/association_event_views/update",params)
      return data
    },
    async enrollActive(context, params) {//更新浏览量
      let {data}=await axios.post("siffa/api/association_event_apply/save",params)
      return data
    },
    async getDynamicList(context, params) {//会场动态
      let {data}=await axios.get("shippingexpo/api/trend", params)
      return data
    },
  }
}