export default {
  meetingBrief1_1: '2021全球物流企业发展博览会',
  meetingBrief1_2: '“全球物流企业发展博览会”是物流行业具有影响力且涵盖相关衍生行业的盛会，也是锦程物流网与智能物流联盟（AiLa）两大互联网物流平台在疫情当下强强联合所孕育出的高端国际展会品牌。本次“博览会”将采用',
  meetingBrief1_3: '“线上+线下”',
  meetingBrief1_4: '并行的模式，并延续展会企业间、展会企业与意向客户间面对面直接对话的方式，帮助展会企业拓展人脉，积累资源，促进相互了解，寻找合作商机，提高展商业内影响力，为行业发展披荆斩棘提供方向指引，为企业间合作共赢搭建平台。',
  meetingBrief2_1: '本次“博览会”将通过',
  meetingBrief2_2: '国内品牌展区、海外展区、精品专项对接会、一对一洽谈、自由洽谈',
  meetingBrief2_3: '等形式打破时间和空间的交流限制，加强业内的合作，为行业整体环境发展以及企业自身经营、拓展提供良好契机，促使业内人士相互交流经验，洞察客户需求，实现行业的共赢和发展。开幕式当天将举办欢迎晚宴，可以抛开日常生活工作的烦闷和喧嚣，在轻松愉悦的环境下，与行业精英一同探讨企业发展的新契机，为所有与会者提供沟通交流平台。',
  hotExhibitorTitle1: '“现场展区+线上云展”',
  hotExhibitorTitle2: '“精品专项对接会”',
  hotExhibitorTitle3: '“智能电子名片”',
  hotExhibitorTitle4: '“展台红包”',
  hotExhibitorConent1: '“现场展区+线上云展”双向并行，本次博览会在现场将设立众多国内品牌展位及海外迷你展位，为全球货代企业搭建合作推广平台，帮助参会企业迅速树立良好品牌形象，利用展位推广，为企业自身经营及业务拓展提供良好契机。同时通过博览会官网设立线上云展，为企业搭建线上云展位，在线视频洽谈，突破时间和空间的限制，实现行业共赢和发展。',
  hotExhibitorConent2: '“精品专项对接会”的引入是本次博览会的又一力作，将邀请行业专家及航运资深人士等，分享行业发展新理念，新举措，共同探讨物流行业振兴之道，同时也将针对全球物流行业热点话题、最新国家政策进行解读及经典案例分析。专项对接会也会进行线上同步直播，通过云展平台，将更多物流行业的新探讨延展至全球。',
  hotExhibitorConent3: '“智能电子名片”，设置展会个性化名片内容，增加图片、文章和音视频等多种展示形式，让你全方位、多维化地展示自己。智能名片可以通过小程序直接转发到微信进行分享，也能够通过生成名片海报进行分享；轻松一键互换，线上线下参展逛展，无需担心错过商机。',
  hotExhibitorConent4: '“展台红包活动”，代金券作为优惠载体取代传统的线下发券模式，贯通了O2O闭环营销场景，让用户逛展-领取优惠券/现金红包-线下核销使用，一气呵成；即吸引线上展台访客增加展台流量，提高线下业务交易量，又能让参展观众得到优惠，调动参展积极性',
  supportUnit1: '排名不分先后',
  supportUnit2: '青岛市国际物流商会',
  supportUnit3: '青岛市国际物流商会',
  supportUnit4: 'MPA',
  supportUnit5: '滴答购',
  supportUnit6: '富达会',
  supportUnit7: '甲必丹',
  supportUnit8: '宁波市跨境电子商务协会',
  supportUnit9: '宁波市联运协会',
  supportUnit10: '上海市国际货运代理行业协会',
  supportUnit11: '天津市国际货运代理行业协会',
  supportUnit12: '武汉货运代理业协会',
  supportUnit13: '中山市货运代理协会',
  supportUnit14: '宁波空运协会',
  jsCodeText: '或微信搜索2021物博会进入物博会官方小程序',
  copyrightText: '版权所有 © 2021全球物流企业发展博览会 备案:',
  exhibitorServe1TitleBrif1: '参展商线上展示、对接管理',
  exhibitorServe1TitleBrif2: '参展商线上宣传推广',
  exhibitorServe1TitleBrif3: '线上发红包，展台推广宣传',
  exhibitorServeText1: '注册/登录云展厅管理平台',
  exhibitorServeText2: '完善企业信息',
  exhibitorServeText3: '创建直播活动',
  exhibitorServeText4: '展台发红包',
  exhibitorServeTitle1: '如何注册、登录账号',
  exhibitorServeTitle2: '如何管理线上展台',
  exhibitorServeTitle3: '如何举行线上展台活动',
  sponsorChanceMoneyRMB: '国内价格（人民币）',
  sponsorTableText1_1: '网站首页广告；',
  sponsorTableText1_2: '展馆置顶广告；',
  sponsorTableText1_3: '2个展馆排名第一的线上展位；',
  sponsorTableText1_4: '1个PC展台；',
  sponsorTableText1_5: '5个坐席位；',
  sponsorTableText1_6: '一场直播推广；',
  sponsorTableText1_7: '企业高层接受专访；',
  sponsorTableText1_8: '独家冠名商报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈， 抖音，微博等多渠道社交媒体宣传推广；',
  sponsorTableText1_9: '成为展会线上线下独家冠名商，与主办方同步曝光；',
  sponsorTableText1_10: '会议官网拉幕广告官宣：会议前3天至会议结束，广告位宣传；',
  sponsorTableText1_11: '赠送标准展位1个（含展位所有权益）；',
  sponsorTableText1_12: '赠送彩插1P；',
  sponsorTableText1_13: '晚宴2人主桌就座，展会全程享受VIP尊贵待遇；',
  sponsorTableText1_14: '赠送正价门票2张；',
  sponsorTableText2_1: '仅限海外客户购买',
  sponsorTableText2_2: '作为海外展区独家冠名商，与主办方同步曝光。',
  sponsorTableText2_3: '海外展区冠名报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈，抖音，微博等多渠道社交媒体宣传推广。',
  sponsorTableText2_4: '展会专属迷你展位，独特企业标识（含迷你展位所拥有权益） 。通过视频会议系统实现国内与海外客户即时洽谈，形式新颖，创意独特。',
  sponsorTableText2_5: '会议官网海外展区优先排名。',
  sponsorTableText2_6: '会议官网首页Banner图展会期间全程展示，挂展位入口',
  sponsorTableText2_7: '宣传效果覆盖整个海外展区',
  sponsorTableText3_1: '拥有盛大欢迎晚宴独家冠名权。',
  sponsorTableText3_2: '晚宴期间企业享有5分钟演讲时间。',
  sponsorTableText3_3: '赠送晚宴期间企业宣传视频3分钟。',
  sponsorTableText3_4: '晚宴2人主桌就座，展会全程享受VIP尊贵待遇。',
  sponsorTableText3_5: '晚宴主持人口播企业宣传广告 。',
  sponsorTableText3_6: '50多家行业媒体追踪报道。',
  sponsorTableText3_7: '赠送标准展位1个 （含展位所有权益）。',
  sponsorTableText3_8: '赠送会刊彩插1p',
  sponsorTableText4_1: '（含制作）/组',
  sponsorTableText4_2: '/组',
  sponsorTableText4_3: '每组2个，处于展馆高空位置，入馆后即刻映入眼帘（根据实际场地大小设置尺寸)。',
  sponsorTableText4_4: '赠送会刊彩插1p。',
  sponsorTableText4_5: '赠送展会正价门票2张（含门票所有权益）（海外客户赠送线上云展位）。',
  sponsorTableText4_6: '晚宴2人主桌就座，展会全程享受VIP尊贵待遇。',
  sponsorTableText4_7: '30000RMB/组（含制作）',
  sponsorTableText5_1: '（含设计及制作）',
  sponsorTableText5_2: '展会入口处6m*4m超大巨幅广告展会2天全程展示，覆盖范围广，关注度高 。',
  sponsorTableText5_3: '赠送展会正价门票2张（含门票所有权益）。',
  sponsorTableText5_4: '晚宴2人主桌席位，展会全程享受VIP尊贵待遇。',
  sponsorTableText5_5: '30000RMB（含设计及制作）。',
  sponsorTableText6_1: '（含制作）',
  sponsorTableText6_2: '所有付费参会人员统一发放人手一套。',
  sponsorTableText6_3: '赞助企业logo全套印刷。',
  sponsorTableText6_4: '极具纪念意义，使用周期长，品牌展示效果持久。',
  sponsorTableText7_1: '尺寸为30cm*10cm*40cm 。',
  sponsorTableText7_2: '专业量身定制，高品质精美印刷LOGO、公司名等 。',
  sponsorTableText7_3: '付费参会客户人手1个，展会移动式广告展示平台，提升品牌影响力 。',
  sponsorTableText7_4: '赞助商展示板全程展示企业LOGO，扩大企业知名度。',
  sponsorTableText8_1: '5对（ 10个），展馆入口两侧摆放，尺寸：高5M，单面喷绘布：宽1.2m，高3.5m 。',
  sponsorTableText8_2: '赠送会刊彩插1p 。',
  sponsorTableText8_3: '赠送正价门票1张（含门票所有权益）。',
  sponsorTableText9_1: '自由洽谈区-T形板',
  sponsorTableText9_2: '自由洽谈区周边位置摆放，入口及两侧对称摆放。',
  sponsorTableText9_3: '满足企业定制化需求，双面印刷，立体展示 。',
  sponsorTableText9_4: '会议2天全程展示。',
  sponsorTableText9_5: '含2个T型版，尺寸：180cm*80cm 。',
  sponsorTableText9_6: '6000RMB（含制作）/组。',
  sponsorTableText10_1: '正反双面体现赞助商宣传信息 。',
  sponsorTableText10_2: '所有参会人员展会2天全程佩戴 。',
  sponsorTableText10_3: '会场内外移动式广告 。',
  sponsorTableText10_4: '展会期间参展商进入会场的唯一凭证，使用率高。',
  sponsorTableText10_5: '10000RMB',
  sponsorTableText11_1: '公司名、LOGO、宣传语全面展示。',
  sponsorTableText11_2: '参展商、洽谈客户进入会场的唯一凭证，曝光率高。',
  sponsorTableText11_3: '会场内外移动式广告。',
  sponsorTableText11_4: '10000RMB',
  sponsorTableText12_1: '统一向参展商、洽谈客户发放2天午餐1天晚宴共3张用餐券。',
  sponsorTableText12_2: '将在餐券上展示公司名、logo等企业信息。',
  sponsorTableText12_3: '展会期间就餐唯一凭证，曝光率高。',
  sponsorTableText13_1: '赞助商可将印有企业LOGO及广告的纪念品交至组委会统一派发给参会客户，也可以根据赞助商需求定制精美纪念品，以展示公司实力和品牌价值，扩大影响力。',
  sponsorTableText14_1: '会刊彩插',
  sponsorTableText14_2: '元/P',
  sponsorTableText14_3: '封一，封二，封底',
  sponsorTableText14_4: '（含制作费）',
  sponsorTableText14_5: '会刊印刷1000册。',
  sponsorTableText14_6: '会刊采用精美彩色印刷 。',
  sponsorTableText14_7: '展会的资源整合手册，使用率高，信息展示全面 。',
  sponsorTableText14_8: '付费参会代表人手一本。',
  sponsorTableText14_9: '封一，封二，封底：10000RMB/P；其他：8000RMB/P（含制作费）。',
  sponsorTableText15_1: '日程水牌',
  sponsorTableText15_2: '主办方将在展会入口等必经之地设置至少3块日程水牌，迅速提升关注度和流量 。',
  sponsorTableText15_3: '展会期间参会企业必看展示板，赞助商展示信息浏览受众广、曝光率极高。',
  sponsorTableText16_1: '矿泉水赞助',
  sponsorTableText16_2: '展会期间的饮用水瓶身粘贴赞助商信息，用于会议现场服务，移动式广告,共计制作 3000瓶。',
  sponsorTableText16_3: '主办方将在晚宴，专项对接会，开幕式等环节摆放饮用水，曝光率极高。',
  sponsorTableText16_4: '20000RMB（含设计及制作）',
  sponsorTableText17_1: '企业宣传视频广告',
  sponsorTableText17_2: '地处展会核心区域，7*3m LED高清视频展示客户企业形象。',
  sponsorTableText17_3: '每家企业2分钟以内宣传视频，共计5家，会议全程LED轮播，极具视觉效果。',
  sponsorTableText18_1: '1V1视频页Banner',
  sponsorTableText18_2: '1V1视频窗口上方 （限2家）',
  sponsorTableText18_3: '视频窗口上方广告位 限两家',
  sponsorTableText18_4: '点开视频界面即实时展现广告',
  sponsorTableText18_5: '视频广告 15000RMB 2250USD,Banner位广告 10000RMB 1500USD',
  sponsorTableText19_1: '首页弹窗广告',
  sponsorTableText19_2: '博览会首页的大幅弹窗广告， 手动关闭（限1家）',
  sponsorTableText19_3: '打开网站首页弹出广告界面',
  sponsorTableText19_4: '静态展示 持续5秒',
  sponsorTableText20_1: '网站首页轮播 banner广告',
  sponsorTableText20_2: '博览会首页banner位置轮播展示（限3家）',
  sponsorTableText20_3: '博览会网站首页',
  sponsorTableText20_4: '支持轮播展示',
  sponsorTableText21_1: '1v1窗口视频广告',
  sponsorTableText21_2: '1V1视频开启前15秒播放（限1家）',
  sponsorTableText22_1: '会展大厅首页展示',
  sponsorTableText22_2: '会展大厅页面Banner (限10家）',
  sponsorTableText22_3: '会展大厅首页展示',
  sponsorTableText22_4: '进入企业界面即可展示',
  sponsorTableText23_1: '热门企业推荐',
  sponsorTableText23_2: '博览会首页热门企业推荐（限3家）',
  sponsorTableText23_3: '网站首页热门展商区块',
  sponsorTableText23_4: '首页进展时前三位',
  sponsorTableText24_1: '直通海外页面',
  sponsorTableText24_2: '中间横幅Banner（独家）',
  sponsorTableText24_3: '直通海外页面，中间横幅Banner',
  sponsorTableText25_1: '登录注册页面',
  sponsorTableText25_2: '左侧广告位置（独家）',
  sponsorTableText25_3: '登录注册页面，左侧广告位置',
  audienceServiceTitleBrif1: '一键交换名片，展会商机不错过',
  audienceServiceTitleBrif2: '浏览线上展台，参加线上抢红包活动',
  audienceServiceTitleBrif3: '查看议程列表，活动不错过',
  audienceServiceTitle1: '如何注册、登录账号',
  audienceServiceTitle2: '如何管理我的名片',
  audienceServiceTitle3: '如何进行1v1洽谈',
  audienceServiceText1_1: '注册/登录云展厅管理平台',
  audienceServiceText2_1: '完善名片信息',
  audienceServiceText3_1: '线下1v1洽谈',
  audienceServiceText3_2: '线上1v1洽谈',
  exhibitionagendaText1_0: '上海跨国采购会展中心',
  exhibitionagendaText1_1: '布展、提前签到',
  exhibitionagendaText1_2: '展位发送企业红包、代金券',
  //线上参展 线下参展
  off_qtmp:"洽谈门票",
  stand_qy_1:"● 单场专项对接会1人，限一场。",
  stand_qy_2:"● 资料袋一个（含会刊等参会资料）。",
  stand_qy_3:"● 欢迎晚宴1人。",
  stand_qy_4:"● 参加线下一对一洽谈，自由洽谈。",
  stand_qy_5:"● 参加线下展会，线上云展洽谈。",
  stand_qy_6:"● 观看直播。",
  stand_qy_7:"● 商务午餐1份/天。",
  wsmcz_title_off:"为什么参展",
  off_gnzq:"国内展区",
  off_eve:'现场一对一洽谈',
  off_zxdjh:'专项对接会',
  off_hywy:'欢迎晚宴',
  off_hwzq:'海外展区',
  off_hwzq_min:'海外展区-迷你展台',
  off_czlc:"线下参展流程",
  on_czlc:"线上参展流程",
  off_czfy:"参展费用",
  time_before_1_30:"2月28日前",
  time_after_1_30:"2月28日后",
  off_bzzw:"标准展位",
  off_tb_msg1:"● 三面围板、楣板搭建、洽谈桌1张、椅子2把、射灯2个、10A插座1个。围板由客户自行设计，楣板由主办方设计，由主办方负责搭建。",
  off_tb_msg2:"● 欢迎晚宴2人。",
  off_tb_msg3:"● 资料袋2个（含会刊等参会资料）。",
  off_tb_msg4:"● 单场专项对接会1人（仅限一场，1人参加）。",
  off_tb_msg5:"● 商务午餐2份/天。",
  off_tb_msg6:"● 含线上云展位一个。",
  off_tb_msg7:"● 3个标准展位起售。",
  off_tb_msg8:"● 两个易拉宝，或一台电视。",
  off_tb_msg9:"● 5个展位送两个电视。",
  off_tb_msg10:"● 射灯2 电视显示器1 笔记本电脑1 耳麦1 座椅1。",
  off_tb_msg11:"● 免费印刷200份宣传资料，摆放在展位上，供逛展客户领取，如需加量则根据实际产生费用收取加印费。",
  off_tb_msg12:"● 含4张展会门票，2个晚宴名额，2个专项名额，8份商务午餐。",
  off_tb_msg13:"● 含线上云展位一个。",
  off_tb_msg14:"● 36平方米超大空间，组委会提供推荐搭建商，由参展商直接沟通搭建商，个性化设计及搭建。",
  off_xhxxzt:"协会线下展台",
  off_cwzzs:"成为赞助商",
  off_djgm:"博览会独家冠名",
  off_qunayi:"线下权益",
  off_qunayi_item1:"企业高层接受专访；",
  off_qunayi_item2:"独家冠名商报道：行业网站主流媒体推送新闻撰稿， 微信公众号，朋友圈，抖音，微博等多渠道社交媒体宣传推广；",
  off_qunayi_item3:"成为展会线上线下独家冠名商，与主办方同步曝光；",
  off_qunayi_item4:"会议官网拉幕广告官宣：会议前3天至会议结束，广告位宣传；",
  off_qunayi_item5:"赠送标准展位1个（含展位所有权益）；",
  off_qunayi_item6:"赠送彩插1P；",
  off_qunayi_item7:"晚宴2人主桌就座，展会全程享受VIP尊贵待遇；",
  off_qunayi_item8:"线下赠送正价门票2张；",
  on_quanyi:"线上权益",
  on_quanyi_item1:"网址首页广告",
  on_quanyi_item2:"展馆指定广告",
  on_quanyi_item3:"2个展馆排名第一的线上展位",
  on_quanyi_item4:"1个PC展台",
  on_quanyi_item5:"五个坐席位",
  on_quanyi_item6:"一场直播推广",
  off_hwzq_title:"海外展区冠名",
  off_hwzq_tiem1:"作为海外展区独家冠名商，与主办方同步曝光",
  off_hwzq_tiem2:"海外展区冠名报道：行业网站主流媒体推送新闻撰稿，微信公众号，朋友圈，抖音，微博等多渠道社交媒体宣传推广",
  off_hwzq_tiem3:"展会专属迷你展位，独特企业标识（含迷你展位所有权益）。通过视频会议系统实现国内与海外客户即时洽谈，形式新颖，创意独特",
  off_hwzq_tiem4:"会议官网海外展区优先排名",
  off_hwzq_tiem5:"会议官网首页Banner图展会期间全程展示，挂展位入口",
  off_hwzq_tiem6:"宣传效果覆盖整个海外展区",
  off_wygm_title:"晚宴冠名",
  off_wygm_item1:"拥有盛大欢迎晚宴独家冠名权。",
  off_wygm_item2:"晚宴期间企业享有5分钟演讲时间。",
  off_wygm_item3:"赠送晚宴期间企业宣传视频3分钟。",
  off_wygm_item4:"晚宴2人主桌就坐，展会全程享受VIP尊贵待遇。",
  off_wygm_item5:"晚宴主持人口播企业宣广告。",
  off_wygm_item6:"50多家行业媒体追踪报道。",
  off_wygm_item7:"赠送标准展位一个（含展位所以权益）。",
  off_wygm_item8:"赠送会刊彩插1p。",
  wsmbmxszh:"为什么报名线上展会",
  wsmbmxszh_msg1:"线下PC展位（Mini Booth），根据疫情防控需要，顺应数字化网络化智能化趋势，采用‘线上＋线下’的新方式，设置线下PC展位，让海外展商足不出户接待线下展会访客，确保疫情防控不放松、国际交流不断线。",
  wsmbmxszh_msg2:"智能电子名片，设置展会个性化名片内容，增加图片、文章和音视频等多种展示形式，让你全方位、多维化地展示自己。智能名片可以通过小程序直接转发到微信进行分享，也能够通过生成名片海报进行分享；轻松一键互换，线上线下参展逛展，无需担心错过商机。",
  wsmbmxszh_msg3:"线下核销使用，一气呵成；即吸引线上展台访客增加展台流量，提高线下业务交易量，又能让参展观众得到优惠，调动参展积极性。",
  on_czs:"参展商",
  on_xszt:"线上展台",
  on_zzfw:"增值服务",
  on_xsffzt:"付费线上展台",
  on_bxhnw:"（不限海内外）",
  on_tb_lin1_1:"● 展馆展示，2个洽谈席位，企业投票、支持企业自主布展，可进行现场直播（包含简介、图片、资质、视频展示等）。",
  on_tb_mini:"迷你展位",
  on_tb_hwzh:"（仅针对海外客户）",
  on_tb_jgxj:"价格详见",
  on_tb_xxmp:"线下门票及展位",
  on_tb_line2_content1:"● 展馆展示，2个洽谈席位，企业投票、支持企业自主布展，可进行现场直播（包含简介、图片、资质、视频展示等）。",
  on_tb_line2_content2:"● 无客户视频通话时展示客户宣传片、宣传PPT、宣传视频等，素材由客户提供。",
  on_tb_line2_content3:"● 展览游客与海外客户对话时，笔记本可登陆展位洽谈系统，提供可视洽谈、文字聊天、线上名片互换等功能。",
  on_tb_line2_content4:"● 可放置客户的宣传材料和名片供参展客户线下取用交换。（其他线下权益见：线下门票及展位）。",
  on_tb_line3_left:"协会线下展台",
  on_tb_line3_right:"● 线上独立开设协会展馆。按照12家会员/页排布，至少需要排满一页，可享受半价（即1000元/展台），排列顺序由协会决定，默认按报名顺序先后。",
  on_tb_line4_left:"1v1洽谈坐席",
  on_tb_line4_right:"● 线上展台内增设1v1坐席。",
  on_tb_line5_left:"展馆展示",
  on_tb_line5_right:"● 为企业展台增加1个展馆展示。",
  on_tb_line6_left:"展台红包",
  // on_tb_line6_center_top:"预充值",
  // on_tb_line6_center_bottom:"￥600 功能免费",
  on_tb_line6_right:"● 企业展台可设置现金红包和业务红包，现金红包总金额保底600元。",
  on_tb_line7_left:"主题演讲",
  on_tb_line7_right:"● 展台直播可享有线上直播主办方会协助分享、转发等。",
  on_become_yzs:"成为云展赞助商",
  on_become_item1_lable:"1V1窗口视频及banner广告",
  on_become_item1_small_1:"视频窗口上方广告位 限两家 ",
  on_become_item1_small_2:"点开视频界面即实时展现广告 视频广告",
  on_become_item1_small_3_lable:"视频广告",
  on_become_item1_small_3_orange_left:"¥15000 RMB",
  on_become_item1_small_3_orange_right:"$2250 USD",
  on_become_item1_small_4_lable:"Banner位广告",
  on_become_item1_small_4_orange_left:"¥10000 RMB",
  on_become_item1_small_4_orange_right:"$1500 USD",
  on_become_item2_lable:"网页弹窗广告",
  on_become_item2_small_1:"打开网站首页弹出广告界面",
  on_become_item2_small_2:"静态展示 持续5秒",
  on_become_item2_small_3_lable:"限一家",
  on_become_item3_lable:"网站首页轮播banner",
  on_become_item3_small_1:"博览会网站首页",
  on_become_item3_small_2:"支持轮播展示",
  Offlinetour_address_tit:"地理位置",
  Offlinetour_address_dsc:"上海跨国采购会展中心，位于长风生态商务区中江路最南端。区域内“水、绿、建筑”完美组合，先进的信息网络系统，齐全的商业配套设施，体现了现代服务业集聚区的综合优势。",
  Offlinetour_Taxi:"出租车",
  Offlinetour_Bus:"公共交通",
  Offlinetour_taxi_left_from1:"上海浦东国际机场",
  Offlinetour_taxi_left_way1:"距会场约55公里 ，无堵车的情况下约58分钟，约196元人民币。",
  Offlinetour_taxi_left_from2:"上海虹桥国际机场",
  Offlinetour_taxi_left_way2:"距会场约16公里，无堵车的情况下约30分钟,约50元人民币。",
  Offlinetour_taxi_right1:"上海站  距会场约10公里，无堵车的情况下约25分钟，约33元人民币。",
  Offlinetour_taxi_right2:"上海虹桥站  距会场约16公里，无堵车的情况下约30分钟，约50元人民币。",
  Offlinetour_taxi_right3:"上海南站  距会场约13公里，无堵车的情况下约28分钟，约40元人民币。",
  Offlinetour_bus1:"☉毗邻内环线、中环线、虹桥机场",
  Offlinetour_bus2:"☉周边环绕轨道交通2、13、15号线",
  Offlinetour_bus3:"☉区域巴士将在会展中心和各大公交站点穿梭",
  Offlinetour_bus4:"☉古北路桥、祁连山路桥、泸定路桥横跨苏州河南北，直抵虹桥商务中心区",
  Offlinetour_periphery_title:"周边配套",
  Offlinetour_periphery_item1:"☉成龙电影艺术馆；",
  Offlinetour_periphery_item2:"☉方圆两公里内十一家四五星级高档酒店和三十多家商务经济型酒店；",
  Offlinetour_periphery_item3:"☉2个游艇码头100个游艇泊位、长风公园；",
  Offlinetour_periphery_item4:"☉长风景畔娱乐中心、大型商业中心“国盛中心”。",
  VisitExhibition_left_title:"线下观展",
  VisitExhibition_quanyi:"权益说明",

  VisitExhibition_left_item2:"无限制逛展",
  VisitExhibition_left_item3:"无限制与海外展商交流",
  VisitExhibition_left_item4:"自由聊天区洽谈",
  VisitExhibition_left_item5:"同时享有线上观展权益 ",

  VisitExhibition_right_title:"线上观展",
  VisitExhibition_right_item1:"无限制观看直播；",
  VisitExhibition_right_item2:"无限制访问展台；",
  VisitExhibition_right_item3:"仅在自由聊天区可随机洽谈；",
  VisitExhibition_right_item4:"发布消息；",
  VisitExhibition_right_item5:"在线交换名片；",
  VisitExhibition_right_item6:"留言",

  // VisitExhibition_Early_bird:"早鸟票价",
  // time_before_1_31:"1月31日前",
  // VisitExhibition_youhui:"优惠票价",
  // VisitExhibition_gn_price:"国内价格",
  // VisitExhibition_hw_price:"海外价格",
  // VisitExhibition_Signupnow:"立即报名",

  VisitExhibition_Sigfree:"免费报名",
  VisitExhibition_weihe_title:"为何观展",
  VisitExhibition_Highlights_off_title:"线下展会亮点",
  VisitExhibition_Highlights_on_title:"线上展会亮点",
  VisitExhibition_Highlights_on_item1:"企业云展台",
  VisitExhibition_Highlights_on_item2:"全程直播",
  VisitExhibition_Highlights_on_item3:"自由洽谈区",
  VisitExhibition_Highlights_on_item4:"线上展馆",
  VisitExhibition_Highlights_on_item5:"在线1v1洽谈",
  czlc_item_1:"网上注册",
  czlc_item_2:"主办方审核确认",
  czlc_item_3:"展位付费确认",
  czlc_item_4:"线上布展/ 线下展位提供 设计图等素材",
  czlc_item_5:"展馆现场布展参展",
  myExhibitionsummary_p1:'是物流行业具有影响力且涵盖相关衍生行业的盛会，也是锦程物流网与智能物流联盟（AiLa）两大互联网物流平台在疫情当下强强联合所孕育出的高端国际展会品牌。本次“博览会”将采用“线上+线下”并行的模式，并延续展会企业间、展会企业与意向客户间面对面直接对话的方式，帮助展会企业拓展人脉，积累资源，促进相互了解，寻找合作商机，提高展商业内影响力，为行业发展披荆斩棘提供方向指引，为企业间合作共赢搭建平台。',
  myExhibitionsummary_p2:'本次“博览会”将采用“线上+线下”并行的模式，并延续展会企业间、展会企业与意向客户间面对面直接对话的方式，帮助展会企业拓展人脉，积累资源，促进相互了解，寻找合作商机，提高展商业内影响力，为行业发展披荆斩棘提供方向指引，为企业间合作共赢搭建平台。 本次“博览会”将通过国内品牌展区、海外展区、精品专项对接会、一对一洽谈、自由洽谈等形式打破时间和空间的交流限制，加强业内的合作，为行业整体环境发展以及企业自身经营、拓展提供良好契机，促使业内人士相互交流经验，洞察客户需求，实现行业的共赢和发展。开幕式当天将举办欢迎晚宴，可以抛开日常生活工作的烦闷和喧嚣，在轻松愉悦的环境下，与行业精英一同探讨企业发展的新契机，为所有与会者提供沟通交流平台。',
  myExhibitionsummary_p15:`全球物流企业发展博览会`,
  myExhibitionsummary_p3:'锦程物流网成立于2003年，注册资金1亿元人民币，致力成为全球物流交易和结算服务平台。企业拥有近300名优秀的互联网和物流行业人才组成的专业团队，打造全球百万物流企业生态圈。',
  myExhibitionsummary_p4:'锦程物流网将继续秉承自己的企业文化、坚守使命与目标，为中小物流企业赋能，成为全球物流交易和结算服务平台。',
  myExhibitionsummary_p5:'AiLa（AI Logistics Alliance）智能国际物流平台，是基于国际物流领域的产业互联网应用平台。AiLa致力于为全球的外贸货主提供国际物流的全流程服务，通过外贸客户的需求驱动，将国际物流的各环节实现在线化、网络化和智能化，并经过对服务环节的解构与持续重构，以全新的“数据智能+网络协同”的模式，提供给全球的客户，并通过智能算法与迭代，满足平台客户对国际物流服务的升级需求。',
  myExhibitionsummary_p6:'上海跨国采购会展中心位于光复西路2739号，地处上海长风生态商务区中心，与虹桥开发区隔河相望。傍长风公园，临苏州河而立，是一座集展览、会议、活动及餐饮等多功能于一体的现代化、国际化的会展中心，具有优美的水绿生态环境，先进的信息网络系统，齐全的商业配套设施和便捷的地铁公交网线。',
  myExhibitionsummary_p7:'整个会展中心拥有16000平方米的室内展厅、9000平方米的会议厅、5000平方米的餐厅和850个车位的地下车库，并配备各类先进齐全的设施，是举办各类高端的展览、会议和活动的最佳场所。',
  my_activity_p1:'1、企业奖项：预设“物流人气企业”和“抗疫贡献企业”两个奖项。',
  my_activity_p2:'2、个人奖项：预设“抗疫英雄”、“物流女神”、“物流型男”三个奖项。',
  my_activity_p3:'报名时间：2021.01.15-2021.04.16',
  my_activity_p4:'投票时间：2021.03.29-2021.04.16',
  my_activity_p5:'1、周冠军奖励：共计9人次，每人赠送个人线上直播一场。',
  my_activity_p6:'2、人气冠军奖励：共计3人，晚宴现场颁发奖杯，并获得3分钟演讲时间。',
  my_activity_p7:'3、明星企业奖励：共计6家企业，晚宴现场颁发奖杯，赠送锦程或AiLa2021年任意一一场活动的免费/3折/半价参与权，并获得3分钟演讲时间。',
  my_activity_p8:'4、终极大奖：晚宴现场组委会授牌并获得现金奖励5000元。',
  my_activity_p9:'1、参展企业默认自动报名物流人气企业；',
  my_activity_p10:'2、参展企业有资格推荐个人，但适度放开 4个人活跃用户，即可不受企业购买展台限制；',
  my_activity_p11:'每日登录PC端平台或小程序各自可获得10票，每日全天均可投票；',
  my_activity_p12:'1、2021年3月29日起，每周对个人奖项进行投票数统计，截止日期前每个奖项票选出3位周冠军，共计9人次周冠军，获得“周冠军奖励”。',
  my_activity_p13:'2、2021年4月17日，组委会将对三个个人奖项的总票数分别进行统计，最终得到票选的3位人气总冠军，获得“人气冠军奖励”。',
  my_activity_p14:'3、2021年4月17日，组委会将对两个企业奖项的总票数分别进行统计，每个奖项的前三名，即共计6家企业将获得“明星企业奖励”。',
  my_activity_p15:'4、2021年4月17日，组委会将对企业奖项进行综合投票排名，排名第一的企业将获得“博览会终极大奖”。',
  my_registerSuccess_p1:'感谢您报名中国-东盟10国物流洽谈会!',
  my_registerSuccess_p2:'您的专属服务团队将会在2个小时内联系到您，请耐心等候，并保持联系方式畅通',
  // 控制台
  replenishInfoText1: '请选择业务，不超过5个',
  replenishInfoText2: '请选择航线，不超过5个',
  replenishInfoText3: '这个人很懒，什么都没留下',
  replenishInfoText4: '非必填，最多添加不超过3个',
  myCopywriting1:'中国-东盟10国物流洽谈会，是由中国富达会和东盟最大的货代组织HIVE联合主办的线上物流洽谈会，由AiLa提供技术支持。本次物流洽谈会，是“富达百万人商交会”项目的第一个系列，也是富达会策划的一带一路全球物流线上交流会的首站，同时也是富达会走出去战略的第一步。', 
  myCopywriting2:"富达会一直提倡：诚信经营，资源互补。随着RCEP条约的签订，中国与东盟之间的经贸往来必将更加频繁，会员对于海外服务网络的需求也急剧上升。富达会正是为了帮助会员企业对接海内外资源，启动了本次线上物流洽谈会。中国境内所有的物流企业，都可以免费注册，成为参展企业。物流从业人员也可以以个人身份，注册参与洽谈活动。与线上的国内外参展商进行互动，交流。参展企业可以通过视频，图片和文字，甚至直播方式展示企业的优势，形象，也可以在线上发布企业对于物流资源的需求。",
  myCopywriting3:"主要参展商",
  myCopywriting4:"秘书处黎思敏",
  myCopywriting5:"联系电话：0755-82145737",
  myCopywriting6:"手机号：18128370711",
  myCopywriting7:"微信：1305270950（加号请备注）",
  myCopywriting8:"邮箱：lisimin@fudafamily.com",
  myCopywriting9:"富达会希望携手全国各地的货代企业，共同走向世界，建立全球性的服务和保障体系，促进广大中国货代拓展海外市场。",
  myCopywriting10:"注册登录",
  myCopywriting11:"报名参会",
  myCopywriting12:"主办方审核",
  myCopywriting13:"费用",
  myCopywriting14:"关于商交会",
  myCopywriting15:"中国-东盟10国物流洽谈会",
  myCopywriting16:"线上",
  myCopywriting17:"2021中国-东盟物流洽谈会是由富达会和东盟最大的货代组织HIVE联合主办的线上洽谈会。这也是富达会策划的一带一路全球物流交流会的首站，是富达会走出去战略的第一步。富达会一直提倡诚信经营，资源互补。随着RCEP条约的签订，中国与东盟之间的经贸往来必将更加频繁，会员对于海外服务网络的需求也急剧上升。富达会自身拥有100多家全国性的大中型货代企业，拥有中国优势的船东合约，本地服务网络以及丰富的货源。富达会希望携手全国各地的货代企业，共同走向世界，建立全球性的服务和保障体系，促进广大中国货代拓展海外市场。受疫情影响，本次活动只采取线交流洽谈。未来富达会也会择机拓展线下物流峰会。本次中国-东盟物流洽谈会，展馆分为海运整箱，海运拼箱,陆路运输，报关服务,空运，电商物流。全国各地货代企业可以免费注册成为参展企业，完善相关企业信息后，可以根据自己业务特点选择参加不同的展馆，也可以参加多个展馆。同一个企业可以注册多个参展人员，在交流会期间，可以与参展的国内外企业进行交流。温馨提示：因为这是一次免费的洽谈会，本组织机构只提供供需双方的合作洽谈机会和虚拟场所，并未对非富达会的参会会员资质进行审核，所以不承担本次洽谈会企业成员交易产生的任何风险。",
  myCopywriting18:"本次中国-东盟物流洽谈会由智能物流联盟（AILA）提供独家技术支持。",
  myCopywriting19:"强强联合，资源横溢",
  myCopywriting22:'“线上云展”',
  myCopywriting23:"本次会议邀请富达会，东盟最大货代组织HIVE, 以及其他友会的会员免费参加，为参展企业搭建线上云展位。参展企业可以借助展台，展示实力，宣传品牌。参展商与访客，可以通过在线视频洽谈，拓展国内外的同行资源。富达线上商交会，突破时间和空间的限制，为全球货代企业的交流合作提供了舞台。",
  myCopywriting24:"“强强联合”",
  myCopywriting25:"中国最强亚洲线庄家与东盟最强货代强强合作，资源高度互补，打通供应链的各个环节。",
  myCopywriting26:"快速了解商交会平台",
  myCopywriting27:"关注官方微信公众号",
  myCopywriting28:"版权所有 © 2021中国-东盟10国物流洽谈会（线上） 备案：",
  myCopywriting29:"部署展台",
  myCopywriting30:"线上参展",
  myCopywriting31:"您还未报名中国-东盟10国物流洽谈会",
  myCopywriting56:"线上展台",
  myCopywriting32:"免费",
  myCopywriting33:"展馆展示，5个洽谈席位，支持企业自主布展，可进行现场直播（包含简介、图片、资质、视频展示等）。",
  myCopywriting34:"增值服务",
  myCopywriting35:"1v1洽谈位",
  myCopywriting36:"线上展台内增设1v1坐席。",
  myCopywriting37:"展馆展示",
  myCopywriting38:"为企业展台增加1个展馆展示。",
  myCopywriting39:"直播演讲",
  myCopywriting40:"展台直播可享有线上直播。",
  myCopywriting41:"个人报名",
  myCopywriting42:"查看所有参展商信息、享有1V1洽谈权限、自由聊天区洽谈权限、发布消息、在线交换名片、留言",
  myCopywriting43:"资料下载",
  myCopywriting44:"参展商资料下载",
  myCopywriting45:"云展会的全部展商资料（电子版）",
  myCopywriting46:"广告",
  myCopywriting47:"首页banner",
  myCopywriting48:"联系秘书处",
  myCopywriting49:"限首页10位，开展期间广告首页置顶",
  myCopywriting50:"展馆置顶广告",
  myCopywriting51:"在展馆页面顶部的广告（限3家）",
  myCopywriting52:"1v1前15s广告",
  myCopywriting53:"可在所有嘉宾1V1视频开始前展示15s广告（限1名）",
  myCopywriting54:"1v1视频页顶部广告",
  myCopywriting55:"可在所有嘉宾1V1视频洽谈窗口旁展示（限2名）",
  myCopywriting57:"富达网",
  myCopywriting58:"“富达网”是全球领先的在线订舱平台，为用户提供安全便捷的线上订舱服务，通过数字化技术使订舱业务标准化、可视化、智能化，让用户畅享科技带来的高效办公和品质服务。科技与物流相遇，订舱从未如此便捷。",
  myCopywriting59:"热门展商",
  myCopywriting60:"预约",
  myCopywriting61:"玩转商交会",
  myCopywriting62:"不断更新，让产品的使用更简单！"
}
