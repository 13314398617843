export default {
    namespaced: true,
    actions: {
        async user_Login(context, params) { //用户登录
            let seven = params.seven;
            delete params.seven;
            let { data } = await axios.post('user/api/login', params);
            let _data = data;
            if (_data.success) {
                let { data } = await context.dispatch('user_getUserInfo', { user_id: _data.data.user_id });
                if (seven) {
                    context.commit('baseStore/SET_USER_ID', _data.data.user_id, { root: true });
                } else {
                    context.commit('baseStore/SET_USER_ID_SESSION', _data.data.user_id, { root: true });
                }
            }
            return data;
        },
        async user_getUserInfo(context, params) { //获取用户信息
            let { data } = await axios.get('user/api/userinfo', { params });
            if (data.success) {
                context.commit('baseStore/SET_USER_INFO', data.data, { root: true });
            }
            return data;
        },
        async user_infoComplete(context, params) {             //保存个人基本信息及公司基本信息
            let { data } = await axios.post('user/api/info/complate', params);
            if (data.success) {
                let { result } = await context.dispatch('user_getUserInfo', { user_id: params.user_id, isSetId: true });
            }
            return data;
        },
        async user_checkIdentifier(context, params) {     //校验
            params.source = 41;
            let { data } = await axios.get('user/api/identity/check', { params });
            return data;
        },
        async user_resetPassword(context, params) {             //重置密码
            let { data } = await axios.post('user/api/password/reset', params);
            return data;
        },
        async user_Forget(context, params) { //忘记密码获取邮件
            let { data } = await axios.get('user/api/password/forget', { params });
            return data;
        },
        async user_Register(context, params) {           //用户注册
            params.source = 41;                                      //增加注册来源统计
            let { data } = await axios.post('user/api/signup', params);
            return data;
        },

        async user_Verify(context, params) {             //有效性校验
            let { data } = await axios.get('user/api/verify', { params });
            return data;
        },
        async user_getActiveMailRegain(context, params) {   //重新获取激活邮件
            let { data } = await axios.get('user/api/regain_active_mail', { params });
            return data;
        },
        async user_getMyresume(context, params) {   //获取简历信息
            let { data } = await axios.get('job/api/my_resume/list', { params });
            return data;
        },
    }
}
