import Vue from 'vue'
import { _encode, _decode } from './base64'
import './axios'
import './i18n'
import './element'
import * as filters from './filters'
import mixin from './mixin'
import { Message } from 'element-ui';
import '~/baseAssets/baseStyle/base.less'
import '~/baseAssets/baseStyle/resetElement.less'

import noDataImg from '~/baseComponents/noDataImg'
import alImage from '~/baseComponents/alImage'
import MetaInfo from 'vue-meta-info';

import { confirmProjectLocale } from '~/baseUtils'

import tableHeight from './tableHeight'
// import './routerGuard'
Vue.use(tableHeight)

Vue.component('noDataImg', noDataImg)
Vue.component('alImage',alImage)
Vue.use(MetaInfo);
Vue.prototype._encode = _encode;
Vue.prototype._decode = _decode;

const bus = new Vue;
Vue.prototype.$GLOBALEVENT = bus;



Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.mixin(mixin)

Vue.prototype.$moment = moment;

Vue.prototype.$seTip = () => {    //server Error
    let errorText = confirmProjectLocale() == 'en' ? 'System Busy' : '系统繁忙';
    Message.error(errorText);
}

Vue.prototype.$ssTip = () => {
    let successText = confirmProjectLocale() == 'en' ? 'Save Successfully' : '保存成功';
    Message.success(successText);
}

Vue.prototype.$csTip = () => {
    let successText = confirmProjectLocale() == 'en' ? 'Change Successfully' : '修改成功';
    Message.success(successText);
}
Vue.prototype.$delTip = () => {
    let successText = confirmProjectLocale() == 'en' ? 'Delete Successfully' : '删除成功';
    Message.success(successText);
}
Vue.prototype.$osTip = () => {
    let successText = confirmProjectLocale() == 'en' ? 'Operation Successfully' : '操作成功';
    Message.success(successText);
}